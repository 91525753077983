import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles, CardStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { DatexFormControl } from './models/datex-form-control';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Discussions_ShellService, EModalSize, EToasterType, EToasterPosition } from './Discussions.shell.service';
import { Discussions_OperationService } from './Discussions.operation.service';
import { Discussions_DatasourceService } from './Discussions.datasource.index';
import { Discussions_FlowService } from './Discussions.flow.index';
import { Discussions_ReportService } from './Discussions.report.index';
import { Discussions_LocalizationService } from './Discussions.localization.service';
import { Language } from './localization.service';
import { $types } from './Discussions.types'


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Discussions-discussion_card',
  templateUrl: './Discussions.discussion_card.component.html'
})
export class Discussions_discussion_cardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  styles = new CardStyles(null);

  inParams: { entity_name?: string, entity_id?: string, reference?: string, discussion?: string, created_by?: string, created_on?: string, read_on?: string, alerted_on?: string, application_id?: string, id?: string } = { entity_name: null, entity_id: null, reference: null, discussion: null, created_by: null, created_on: null, read_on: null, alerted_on: null, application_id: null, id: null };
  //#region Inputs
  @Input('entity_name') set $inParams_entity_name(v: string) {
    this.inParams.entity_name = v;
  }
  get $inParams_entity_name(): string {
    return this.inParams.entity_name;
  }
  @Input('entity_id') set $inParams_entity_id(v: string) {
    this.inParams.entity_id = v;
  }
  get $inParams_entity_id(): string {
    return this.inParams.entity_id;
  }
  @Input('reference') set $inParams_reference(v: string) {
    this.inParams.reference = v;
  }
  get $inParams_reference(): string {
    return this.inParams.reference;
  }
  @Input('discussion') set $inParams_discussion(v: string) {
    this.inParams.discussion = v;
  }
  get $inParams_discussion(): string {
    return this.inParams.discussion;
  }
  @Input('created_by') set $inParams_created_by(v: string) {
    this.inParams.created_by = v;
  }
  get $inParams_created_by(): string {
    return this.inParams.created_by;
  }
  @Input('created_on') set $inParams_created_on(v: string) {
    this.inParams.created_on = v;
  }
  get $inParams_created_on(): string {
    return this.inParams.created_on;
  }
  @Input('read_on') set $inParams_read_on(v: string) {
    this.inParams.read_on = v;
  }
  get $inParams_read_on(): string {
    return this.inParams.read_on;
  }
  @Input('alerted_on') set $inParams_alerted_on(v: string) {
    this.inParams.alerted_on = v;
  }
  get $inParams_alerted_on(): string {
    return this.inParams.alerted_on;
  }
  @Input('application_id') set $inParams_application_id(v: string) {
    this.inParams.application_id = v;
  }
  get $inParams_application_id(): string {
    return this.inParams.application_id;
  }
  @Input('id') set $inParams_id(v: string) {
    this.inParams.id = v;
  }
  get $inParams_id(): string {
    return this.inParams.id;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  vars: { icon?: string, iconText?: string, title?: string } = { };
  //#endregion
  //#region Events
  @Output()
  refreshList = new EventEmitter<void>();
  
  events = {
    refreshList: this.refreshList,
  }
  //#endregion

  @Output()
  $refreshEvent = new EventEmitter();

  actionbar = {
      save: new ToolModel(new ButtonModel('save', new ButtonStyles(['primary'], null), false, 'Save', '')
    ),
      read: new ToolModel(new ButtonModel('read', new ButtonStyles(['primary'], null), false, 'Read', '')
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  formGroupContent: FormGroup = new FormGroup({
    discussion: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });


  content = {
    fields: {
        comment_tag: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(['status-complete'], null), '', false)
,
        discussion: new FieldModel(new TextBoxModel(this.formGroupContent.controls['discussion'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), '', false)
,
    },
    fieldsets: {
  newGroup1: new FieldsetModel('', true, false, true),
}
  }


  
  //#region content fields inParams
  //#endregion content fields inParams



  get headerTitle() {
    const $card = this;
    const $utils = this.utils;
    const expr = $card.vars.title;
    return expr;
  }


  get headerIcon() {
    const $card = this;
    const $utils = this.utils;
    const expr = $card.vars.icon;
    return expr;
  }

  get headerIconText() {
    const $card = this;
    const $utils = this.utils;
    const expr = $card.vars.iconText;
    return expr;
  }

  get headerIconSize() {
      return "";
  }

  get headerIconColor() {
      return "";
  }

  get headerIconTransparency() {
      return "";
  }


  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Discussions_ShellService,
private datasources: Discussions_DatasourceService,
private flows: Discussions_FlowService,
private reports: Discussions_ReportService,
private localization: Discussions_LocalizationService,
private operations: Discussions_OperationService,
) {
    super(); 
    this.$subscribeFormControlValueChanges();

  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {

    const $card = this;
    const $utils = this.utils;

    


    await this.on_init();

    this.initialized = true;

  }

  private $subscribeFormControlValueChanges() {

  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $card: Discussions_discussion_cardComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
  var deleteCapability = (await $operations.Discussions.Enable_Discussions_AbilityToDelete.isAuthorized());
  
  
  
  // Discussion record available
  if ($utils.isDefined($card.inParams.discussion) && $card.inParams.discussion != 'null') {
  
      $card.content.fields.discussion.control.value = $card.inParams.discussion;
      $card.content.fields.discussion.control.readOnly = true;
  
      if (deleteCapability) {
          $card.actionbar.on_delete.hidden = false;
      } else {
          $card.actionbar.on_delete.hidden = true;
      }
  
      $card.actionbar.cancel.hidden = true;
      $card.actionbar.save.hidden = true;
      $card.styles.setAttentionClass();
      $card.vars.icon = 'CannedChat';
  
      if ($utils.isAllDefined($card.inParams.created_by, $card.inParams.created_on)
          && $card.inParams.created_by != 'null'
          && $card.inParams.created_on != 'null') {
          const createdOn = new Date($card.inParams.created_on).toLocaleString();
          $card.vars.title = `${$card.inParams.created_by} commented`
          $card.content.fields.comment_tag.control.text = `${createdOn}`
      }
  
      if ($utils.isDefined($card.inParams.read_on)) {
          $card.actionbar.read.hidden = true;
      }
      else {
          $card.actionbar.read.hidden = false;
      }
  }
  // New Discussion
  else {
      $card.content.fields.discussion.label = 'Add a comment. @ to mention a person.';
      $card.content.fields.discussion.control.focus();
      $card.content.fields.discussion.control.readOnly = false;
  
      $card.actionbar.on_delete.hidden = true;
      $card.actionbar.read.hidden = true;
  
      $card.styles.setCreationClass();
      $card.vars.icon = 'Chat';
      $card.vars.iconText = 'New discussion';
  }
  
  
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $card: Discussions_discussion_cardComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
  
  
  
  await $flows.Discussions.storage_discussions({
      action: 'Write', payload: {
          entity_name: $card.inParams.entity_name,
          entity_id: $card.inParams.entity_id,
          discussion: $card.content.fields.discussion.control.value
      }
  
  })
  
  
  
  $card.events.refreshList.emit()
  }
  on_cancel(event = null) {
    return this.on_cancelInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancelInternal(
    $card: Discussions_discussion_cardComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
   $card.content.fields.discussion.control.value = '';
   $card.content.fields.discussion.control.focus();
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $card: Discussions_discussion_cardComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
  
  
  
  
  await $flows.Discussions.storage_discussions({
      action: 'Delete', payload: {
          id: $card.inParams.id
      }
  
  })
  
  
  
  $card.events.refreshList.emit()
  }
  on_read(event = null) {
    return this.on_readInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_readInternal(
    $card: Discussions_discussion_cardComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
  try {
  
      let entityId = $card.inParams.id
      await $flows.Discussions.storage_discussions({
          action: "Update", payload: {
              id: entityId,
              read_on: "set"
          }
      });
      $card.actionbar.read.hidden = true;
  
  
  } catch (error) {
  
      let target_error = error;
  
      if (typeof target_error === "string") {
          target_error = { message: target_error };
      } else {
          while ($utils.isDefined(target_error.error)) {
              target_error = target_error.error;
          };
      };
  
      if (!$utils.isDefined(target_error.message)) {
          target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
      };
  
  
  };
  }
  //#endregion private flows

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);

    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }


}
