import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'

import { FootPrintManager_invoice_lines_gridComponent } from './FootPrintManager.invoice_lines_grid.component';
import { Invoices_invoice_total_units_widgetComponent } from './Invoices.invoice_total_units_widget.component';
import { Invoices_invoice_total_billed_widgetComponent } from './Invoices.invoice_total_billed_widget.component';
import { Invoices_invoice_types_dd_singleComponent } from './Invoices.invoice_types_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Invoices_invoice_terms_dd_singleComponent } from './Invoices.invoice_terms_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_invoice_lines_gridComponent),
    forwardRef(() => Invoices_invoice_total_units_widgetComponent),
    forwardRef(() => Invoices_invoice_total_billed_widgetComponent),
    forwardRef(() => Invoices_invoice_types_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Invoices_invoice_terms_dd_singleComponent),
  ],
  selector: 'FootPrintManager-invoice_editor',
  templateUrl: './FootPrintManager.invoice_editor.component.html'
})
export class FootPrintManager_invoice_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { invoiceId: number } = { invoiceId: null };
  //#region Inputs
  @Input('invoiceId') set $inParams_invoiceId(v: number) {
    this.inParams.invoiceId = v;
  }
  get $inParams_invoiceId(): number {
    return this.inParams.invoiceId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shipmentId?: number, billingAddressId?: number } = { };
  //#endregion
  entity: { Id?: number, AttentionOf?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, CurrencyId?: number, DueDate?: string, InvoiceDate?: string, LookupCode?: string, Notes?: string, ProjectId?: number, ReferenceCode?: string, StatusId?: number, TermId?: number, TypeId?: number, Currency?: { ShortLabel?: string }, Status?: { Name?: string }, Term?: { Name?: string }, Project?: { OwnerId?: number }, InvoiceLines?: { Id?: number }[] };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    invoice_type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    invoice_terms: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    due_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    attention_of: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular')
    ),
      status_change: new ToolModel(new ButtonModel('status_change', new ButtonStyles(null, null), true, 'Change status', 'ms-Icon ms-Icon--SyncStatus')
    ),
      on_export: new ToolModel(new ButtonModel('on_export', new ButtonStyles(null, null), false, 'Export', 'ms-Icon ms-Icon--CloudDownload')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), true, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular')
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, 'Print', 'icon-ic_fluent_print_20_regular')
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_attach_20_regular')
    ),
      discussions: new ToolModel(new ButtonModel('discussions', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_chat_empty_20_regular')
    ),
      email_requests: new ToolModel(new ButtonModel('email_requests', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_mail_arrow_up_20_regular')
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Invoice code', true)
,
    invoice_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['invoice_type'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Type', true)
,
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', true)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', true)
,
    invoice_status: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Status', false)
,
    invoice_terms: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['invoice_terms'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Invoice terms', true)
,
    due_date: new FieldModel(new DateBoxModel(this.formGroup.controls['due_date'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'Due date', false)
,
    created_date: new FieldModel(new DateBoxModel(this.formGroup.controls['created_date'] as DatexFormControl, null, true, '', 'datetime')
, new ControlContainerStyles(null, null), 'Created date', false)
,
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Reference', false)
,
    attention_of: new FieldModel(new TextBoxModel(this.formGroup.controls['attention_of'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Attention of', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Invoice', false, true, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      invoice_lines: new TabItemModel(
        this.rootTabGroup, 
        'Invoice lines', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_invoice_lines_invoice_lines_grid_inParams_invoiceId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.invoiceId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_invoice_lines', { read: FootPrintManager_invoice_lines_gridComponent }) $tabs_invoice_lines: FootPrintManager_invoice_lines_gridComponent;
    //#endregion tabs children
    widgets = {
      invoice_total_units_widget: new WidgetModel(),
      invoice_total_billed_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_invoice_total_units_widget_inParams_invoiceId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.invoiceId;
      
      return expr;
    }
  
    get $widgets_invoice_total_billed_widget_inParams_invoiceId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.invoiceId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_invoice_total_units_widget', { read:  Invoices_invoice_total_units_widgetComponent }) $widgets_invoice_total_units_widget: Invoices_invoice_total_units_widgetComponent;
      @ViewChild('$widgets_invoice_total_billed_widget', { read:  Invoices_invoice_total_billed_widgetComponent }) $widgets_invoice_total_billed_widget: Invoices_invoice_total_billed_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    invoice_type: this.fields.invoice_type.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    invoice_terms: this.fields.invoice_terms.control.valueChanges
    ,
    due_date: this.fields.due_date.control.valueChanges
    ,
    created_date: this.fields.created_date.control.valueChanges
    ,
    reference: this.fields.reference.control.valueChanges
    ,
    attention_of: this.fields.attention_of.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.invoice_lines,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.invoiceId)) {
        this.$missingRequiredInParams.push('invoiceId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Invoice';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      invoiceId:  $editor.inParams.invoiceId 
    };

    const data = await this.datasources.Invoices.ds_invoice_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.invoice_type.control as SelectBoxModel).reset($editor.entity.TypeId);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.invoice_status.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.invoice_terms.control as SelectBoxModel).reset($editor.entity.TermId);
    (this.fields.due_date.control as DateBoxModel).reset($editor.entity.DueDate);
    (this.fields.created_date.control as DateBoxModel).reset($editor.entity.CreatedSysDateTime);
    (this.fields.reference.control as TextBoxModel).reset($editor.entity.ReferenceCode);
    (this.fields.attention_of.control as TextBoxModel).reset($editor.entity.AttentionOf);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_invoice_total_units_widget') {
      if (!isNil(this.$widgets_invoice_total_units_widget) && !this.widgets.invoice_total_units_widget.hidden) {
        this.$widgets_invoice_total_units_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_invoice_total_billed_widget') {
      if (!isNil(this.$widgets_invoice_total_billed_widget) && !this.widgets.invoice_total_billed_widget.hidden) {
        this.$widgets_invoice_total_billed_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_invoice_lines') {
        if (!isNil(this.$tabs_invoice_lines) && !this.tabs.invoice_lines.hidden) {
          this.$tabs_invoice_lines.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .invoice_type
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .invoice_terms
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .due_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .created_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .attention_of
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.Invoices.is_invoice_deletable({ invoiceId: $editor.entity.Id});
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Invoice cannot be deleted.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Delete Invoice', `Are you sure you want to delete invoice  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const result = await $flows.Invoices.delete_invoice_flow({ invoiceId: $editor.entity.Id });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Invoice cannot be deleted.', reason);
                  return;
              }
              else {
                  $editor.close();
              }
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Invoice ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Invoice delete error', 'An error occurred during the deletion of the invoice', [errorDescription], null, [{ message: errorDescription, detail: errorDetail}]);
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.Invoices.is_invoice_cancelable({
      invoiceId: $editor.entity.Id
  });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Invoice cannot be canceled.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Cancel invoice', `Are you sure you want to cancel invoice ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              const user = (await $flows.Utilities.get_username_flow({})).userName;
              var userName = user.substring(
                  user.indexOf(`\\`) + 1,
                  user.indexOf('@') >= 0 ? user.indexOf('@') : user.length
              );
  
              const dateNow = $utils.date.format($utils.date.now(), 'MM/DD/YY LT');
  
              const flowParams = {
                  invoiceId: $editor.entity.Id,
                  statusId: 4, // Cancelled,
                  notes: `Invoice cancelled on ${dateNow} by ${userName}`
              }
              const result = await $flows.Invoices.update_invoice_status_flow(flowParams);
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.Invoices.openErrorDialog('Invoice cannot be cancelled.', reason);
                  return;
              }
              else {
  
                  const title = 'Cancel Invoice';
                  const message = `Invoice ${$editor.entity.LookupCode} cancelled.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $editor.refresh();
              }
  
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Invoice ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Cancel invoice error', 'An error occurred during the cancelling of the invoice', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const result = await $flows.Invoices.is_invoice_completable({ invoiceId: $editor.entity.Id });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Invoice cannot be completed.', reason);
      return;
  } else {
  
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Complete Invoice', `Are you sure you want to complete invoice  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              const user = (await $flows.Utilities.get_username_flow({})).userName;
              var userName = user.substring(
                  user.indexOf(`\\`) + 1,
                  user.indexOf('@') >= 0 ? user.indexOf('@') : user.length
              );
  
              const dateNow = $utils.date.format($utils.date.now(), 'MM/DD/YY LT');
  
              const flowParams = {
                  invoiceId: $editor.entity.Id,
                  statusId: 3, // Completed
                  notes: `Invoice completed on ${dateNow} by ${userName}`
              }
              const result = await $flows.Invoices.update_invoice_status_flow(flowParams);
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Invoice cannot be completed.', reason);
                  return;
              }
              else {
  
                  const title = 'Complete Invoice';
                  const message = `Invoice ${$editor.entity.LookupCode} completed.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $editor.refresh();
              }
  
  
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Invoice ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Invoice complete error', 'An error occurred during the completion of the invoice', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  // Set editor title
  $editor.title = `Invoice ${$editor.entity.LookupCode}`;
  
  // Discussions feature
  let discussionCheck = (await $operations.Discussions.Enable_Discussions_Feature.isAuthorized())
  
  if (discussionCheck) {
      const discussions = (await $datasources.Discussions.ds_storage_discussions.getList({
          entity_name: 'Invoice',
          entity_id: $editor.inParams.invoiceId.toString()
      })).result;
  
      if ($utils.isDefined(discussions)) {
  
          let containsUnread = false;
          let containsDiscussion = false;
  
          for (let discussion of discussions) {
              let read_on = discussion.read_on
              let discussionData = discussion.discussion
  
              if (!$utils.isDefined(read_on) && discussion.id != '0') {
                  containsUnread = true;
              }
              if ($utils.isDefined(discussionData) && discussion.id != '0') {
                  containsDiscussion = true;
              }
          }
          if (containsDiscussion) {
              if (containsUnread) {
                  $editor.toolbar.discussions.control.icon = "icon ms-Icon ms-Icon--OfficeChat";
                  $editor.toolbar.discussions.control.styles.setDestructiveClass();
              }
              else {
                  $editor.toolbar.discussions.control.icon = "icon ms-Icon ms-Icon--CannedChat";
                  $editor.toolbar.discussions.control.styles.setDestructiveClass();
              }
          }
          else {
  
              $editor.toolbar.discussions.control.icon = "icon ms-Icon ms-Icon--Chat";
              $editor.toolbar.discussions.control.styles.setCreationClass();
  
          }
      }
  }
  else {
      $editor.toolbar.discussions.hidden = true;
  }
  
  
  // created status
  if ($editor.entity.StatusId === 1) {
  
      $editor.toolbar.status_change.hidden = false;
      $editor.toolbar.status_change.control.readOnly = false;
  
      $editor.toolbar.on_export.hidden = false;
      $editor.toolbar.on_export.control.readOnly = false;
  
      $editor.toolbar.complete.hidden = true;
  
      $editor.toolbar.cancel.hidden = false;
      $editor.toolbar.cancel.control.readOnly = false;
  
      $editor.toolbar.on_delete.hidden = false;
      $editor.toolbar.on_delete.control.readOnly = false;
  
  
  
      $editor.fields.lookupcode.control.readOnly = false;
  
      // disable owner project if order lines exist
      const hasInvoiceLines = $editor.entity.InvoiceLines.length > 0;
      $editor.fields.owner.control.readOnly = hasInvoiceLines;
      $editor.fields.project.control.readOnly = hasInvoiceLines;
  
      $editor.fields.invoice_type.control.readOnly = false;
  
      // Exported
  } else if ($editor.entity.StatusId === 2) {
  
      $editor.toolbar.status_change.hidden = false;
      $editor.toolbar.status_change.control.readOnly = false;
  
      $editor.toolbar.on_export.hidden = true;
      $editor.toolbar.on_export.control.readOnly = true;
  
      $editor.toolbar.complete.hidden = false;
      $editor.toolbar.complete.control.readOnly = false;
  
      $editor.toolbar.cancel.hidden = false;
      $editor.toolbar.cancel.control.readOnly = false;
  
      $editor.toolbar.on_delete.hidden = true;
  
  
      $editor.fields.lookupcode.control.readOnly = true;
      $editor.fields.owner.control.readOnly = true;
      $editor.fields.project.control.readOnly = true;
      $editor.fields.invoice_type.control.readOnly = true;
  
  
  
  
      // Completed
  } else if ($editor.entity.StatusId === 3) {
  
      $editor.toolbar.status_change.hidden = false;
      $editor.toolbar.status_change.control.readOnly = false;
  
      $editor.toolbar.on_export.hidden = true;
      $editor.toolbar.on_export.control.readOnly = true;
  
      $editor.toolbar.complete.hidden = true;
      $editor.toolbar.complete.control.readOnly = true;
  
      $editor.toolbar.cancel.hidden = false;
      $editor.toolbar.cancel.control.readOnly = false;
  
      $editor.toolbar.on_delete.hidden = true;
  
  
      $editor.fields.lookupcode.control.readOnly = true;
      $editor.fields.owner.control.readOnly = true;
      $editor.fields.project.control.readOnly = true;
      $editor.fields.invoice_type.control.readOnly = true;
  
  
      // Cancelled
  } else if ($editor.entity.StatusId === 4) {
  
      $editor.toolbar.status_change.hidden = false;
      $editor.toolbar.status_change.control.readOnly = false;
  
      $editor.toolbar.on_export.hidden = true;
      $editor.toolbar.on_export.control.readOnly = true;
  
      $editor.toolbar.complete.hidden = true;
      $editor.toolbar.complete.control.readOnly = true;
  
      $editor.toolbar.cancel.hidden = true;
      $editor.toolbar.cancel.control.readOnly = true;
  
      $editor.toolbar.on_delete.hidden = true;
  
  
      $editor.fields.lookupcode.control.readOnly = true;
      $editor.fields.owner.control.readOnly = true;
      $editor.fields.project.control.readOnly = true;
      $editor.fields.invoice_type.control.readOnly = true;
  
  
  } else {
      $editor.toolbar.status_change.hidden = false;
      $editor.toolbar.status_change.control.readOnly = false;
  
      $editor.toolbar.on_export.hidden = false;
      $editor.toolbar.on_export.control.readOnly = false;
  
      $editor.toolbar.complete.hidden = false;
      $editor.toolbar.complete.control.readOnly = false;
  
      $editor.toolbar.cancel.hidden = false;
      $editor.toolbar.cancel.control.readOnly = false;
  
      $editor.toolbar.on_delete.hidden = true;
  
  
      $editor.fields.lookupcode.control.readOnly = true;
      $editor.fields.owner.control.readOnly = true;
      $editor.fields.project.control.readOnly = true;
      $editor.fields.invoice_type.control.readOnly = true;
  
  
  
  
  
  }
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  // Set Date/Time Formats
  $editor.fields.due_date.control.format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  $editor.fields.created_date.control.format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.project.control.value = null;
  }
  on_status_change_clicked(event = null) {
    return this.on_status_change_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_status_change_clickedInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const invoice = (await $datasources.Invoices.ds_get_invoice_by_invoiceId.get({invoiceId: $editor.inParams.invoiceId})).result;
  const invoiceStatusId = invoice.StatusId;
  
  
  const dialogResult = await $shell.FootPrintManager.openinvoice_status_change_options_formDialog({excludeStatusId: invoiceStatusId
  });
  var userConfirmed = dialogResult.confirm;
  var statusId = dialogResult.statusId;
  var notes = dialogResult.notes;
  
  if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
      return;
  }
  
  var statuses = (await $datasources.Invoices.ds_get_invoicestatus_by_statusId.get({ statusId: statusId })).result;
  var statusName = statuses.Name;
  
  
  try {
  
      const user = (await $flows.Utilities.get_username_flow({})).userName;
      var userName = user.substring(
          user.indexOf(`\\`) + 1,
          user.indexOf('@') >= 0 ? user.indexOf('@') : user.length
      );
  
      const dateNow = $utils.date.format($utils.date.now(), 'MM/DD/YY LT');
  
      const flowParams = {
          invoiceId: $editor.entity.Id,
          statusId: statusId, // 
          notes: `Invoice ${statusName} on ${dateNow} by ${userName} ` + notes
      }
      const result = await $flows.Invoices.update_invoice_status_flow(flowParams);
      const reason = result.reason;
  
      if ($utils.isDefined(reason)) {
          await $shell.Invoices.openErrorDialog(`Invoice cannot be changed to status ${statusName}.`, reason);
          return;
      }
      else {
  
          const title = 'Invoice Status Changed';
          const message = `Invoice ${$editor.entity.LookupCode} changed to status ${statusName}.`;
          await $shell.FootPrintManager.openInfoDialog(title, message);
          await $editor.refresh();
      }
  
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Invoice ${$editor.entity.LookupCode} - ${errorMessage}`;
      await $shell.FootPrintManager.openErrorDialog('Invoice status change error', 'An error occurred during the status change of the selected invoice', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }
  
  
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.invoice_type.control.value,
      $editor.fields.project.control.value,
      $editor.fields.invoice_terms.control.value
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  
  
  
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.invoice_type.control.isChanged) {
      payload.TypeId = $editor.fields.invoice_type.control.value;
  }
  if ($editor.fields.reference.control.isChanged) {
      payload.ReferenceCode = $editor.fields.reference.control.value;
  }
  if ($editor.fields.attention_of.control.isChanged) {
      payload.AttentionOf = $editor.fields.attention_of.control.value;
  }
  if ($editor.fields.due_date.control.isChanged) {
      payload.DueDate = $editor.fields.due_date.control.value;
  }
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  if ($editor.fields.invoice_terms.control.isChanged) {
      payload.TermId = $editor.fields.invoice_terms.control.value;
  
      // Set a new due date if the terms are changed
      const terms = (await $datasources.Invoices.ds_get_invoice_terms.get({ invoiceTermId: $editor.fields.invoice_terms.control.value })).result;
      if ($utils.isDefined(terms)) {
  
          const period = terms.PeriodInDays;
          if ($utils.isDefined(period)) {
              var createdDate = new Date($editor.entity.CreatedSysDateTime);
              createdDate.setHours(0, 0, 0, 0);
              createdDate.setDate(createdDate.getDate() + period);
  
              payload.DueDate = $utils.date.format(createdDate.toString(), "YYYY-MM-DD")
          }
      }
  
  }
  
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Invoices', id: $editor.entity.Id, entity: payload });
  
  
  await $editor.refresh();
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  
  
  await $shell.Invoices.openinvoice_reportDialog({ invoiceId: $editor.inParams.invoiceId });
  
  }
  on_export_clicked(event = null) {
    return this.on_export_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_clickedInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const result = await $flows.Invoices.is_invoice_exportable({ invoiceId: $editor.entity.Id });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Invoice cannot be exported.', reason);
      return;
  } else {
  
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Export Invoice', `Are you sure you want to export invoice  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              const user = (await $flows.Utilities.get_username_flow({})).userName;
              var userName = user.substring(
                  user.indexOf(`\\`) + 1,
                  user.indexOf('@') >= 0 ? user.indexOf('@') : user.length
              );
  
              const dateNow = $utils.date.format($utils.date.now(), 'MM/DD/YY LT');
  
              const flowParams = {
                  invoiceId: $editor.entity.Id,
                  statusId: 2, // Exported
                  notes: `Invoice exported on ${dateNow} by ${userName}`
              }
              const result = await $flows.Invoices.update_invoice_status_flow(flowParams);
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Invoice cannot be exported.', reason);
                  return;
              }
              else {
  
                  const title = 'Export Invoice';
                  const message = `Invoice ${$editor.entity.LookupCode} exported.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $editor.refresh();
              }
  
  
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Invoice ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Invoice export error', 'An error occurred during the exporting of the invoice', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_discussions_clicked(event = null) {
    return this.on_discussions_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_discussions_clickedInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  await $shell.Discussions.opendiscussions_listDialog({
      entity_name: 'Invoice',
      entity_id: $editor.inParams.invoiceId.toString()
  },
  'flyout')
  
  $editor.refresh();
  }
  on_email_requests_clicked(event = null) {
    return this.on_email_requests_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_email_requests_clickedInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.Notifications.openemail_requests_gridDialog({
      entityType: 'Invoice',
      entityId: $editor.entity.Id
  }, 'flyout', EModalSize.Large);
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: FootPrintManager_invoice_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.FootPrintManager.openentity_attachments_gridDialog({entityType: 'Invoice', entityKeys: [{ name: 'Id', value: $editor.entity.Id }] }, 'flyout', EModalSize.Xlarge);
  }
  //#endregion private flows
}
