import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';

interface IInvoices_invoice_reportServiceInParams {
  invoiceId: number}

interface IInvoices_invoice_reportServiceData {
  header?: { result?: { Id?: number, DueDate?: string, InvoiceDate?: string, LookupCode?: string, Notes?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string, Enterprise?: { Name?: string } } } } }
  owner_address?: { result?: { Id?: number, Project?: { Id?: number, Owner?: { Id?: number, OwnersContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } }
  warehouse?: { result?: { Id?: number, InvoiceLines?: { Id?: number, BillingRecords?: { BillingTaskId?: number, BillingTask?: { WarehouseId?: number } }[] }[], Warehouse?: { Id?: number, Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }
  warehouse_only?: { result?: { Id?: number, Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }
  line?: { result?: { details?: { Id?: number, CreatedSysDateTime?: string, Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, Details?: { BillingTaskId?: number, BillingTask?: { CreatedSysDateTime?: string, LicensePlateId?: number, LocationId?: number, LotId?: number, MaterialId?: number, Notes?: string, OperationCodeId?: number, OrderId?: number, ShipmentId?: number, VendorLotId?: number, WarehouseId?: number, Warehouse?: { Name?: string }, Lot?: { LookupCode?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Material?: { Description?: string, LookupCode?: string, Name?: string }, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Order?: { LookupCode?: string, OwnerReference?: string }, Shipment?: { LookupCode?: string }, OperationCode?: { Name?: string } } } }[], lineCountWithShipment?: number, lineCountWithoutShipment?: number } }
}

@Injectable({ providedIn: 'root' })
export class Invoices_invoice_reportService extends ReportBaseService<IInvoices_invoice_reportServiceInParams, IInvoices_invoice_reportServiceData> {

  protected reportReferenceName = 'invoice_report';
  protected appReferenceName = 'Invoices';

  constructor(
    utils: UtilsService,
    private datasources: Invoices_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IInvoices_invoice_reportServiceInParams) {
  }

  protected async getData(inParams: IInvoices_invoice_reportServiceInParams): Promise<IInvoices_invoice_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IInvoices_invoice_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IInvoices_invoice_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      const dsData = await this.datasources.Invoices.ds_get_invoice_header_by_invoiceId.get(dsParams);
      
      data.header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      const dsData = await this.datasources.Invoices.ds_get_owner_address_by_invoiceId.get(dsParams);
      
      data.owner_address = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      const dsData = await this.datasources.Invoices.ds_get_first_warehouse_address_by_invoiceId.get(dsParams);
      
      data.warehouse = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
      };
      const dsData = await this.datasources.Invoices.ds_get_first_warehouse.get(dsParams);
      
      data.warehouse_only = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      const dsData = await this.datasources.Invoices.ds_get_invoice_lines_for_report_by_invoiceId.get(dsParams);
      
      data.line = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
