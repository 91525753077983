import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  ChangeDetectorRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ViewerComponent } from '@grapecity/activereports-angular';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';
import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { WorkOrders_ShellService, EModalSize, EToasterType, EToasterPosition } from './WorkOrders.shell.service';
import { WorkOrders_OperationService } from './WorkOrders.operation.service';
import { WorkOrders_DatasourceService } from './WorkOrders.datasource.index';
import { WorkOrders_FlowService } from './WorkOrders.flow.index';
import { WorkOrders_ReportService } from './WorkOrders.report.index';
import { WorkOrders_LocalizationService } from './WorkOrders.localization.service';
import { Language } from './localization.service';
import { $types } from './WorkOrders.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'WorkOrders-work_order_report',
  templateUrl: './WorkOrders.work_order_report.component.html'
})
export class WorkOrders_work_order_reportComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('reportViewer', { static: false }) reportViewer: ViewerComponent;

  report: any;
  reportParameters: any;

  inParams: { workOrderId: number } = { workOrderId: null };
  //#region Inputs
  @Input('workOrderId') set $inParams_workOrderId(v: number) {
    this.inParams.workOrderId = v;
  }
  get $inParams_workOrderId(): number {
    return this.inParams.workOrderId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: WorkOrders_ShellService,
    private datasources: WorkOrders_DatasourceService,
    private flows: WorkOrders_FlowService,
    private reports: WorkOrders_ReportService,
    private localization: WorkOrders_LocalizationService,
    private operations: WorkOrders_OperationService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.workOrderId)) {
        this.$missingRequiredInParams.push('workOrderId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Work Order Report';
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $report = this;
    const $utils = this.utils;

    // We want to use reportViewer.open only in the onViewerInit, otherwise
    // the reportViewer might not be ready. In order to do that, the viewer has to be recreated.
    // We recreate it by using *ngIf="report"

    // set the report to null so that the reportViewer gets destroyed (initially this will change nothing)
    this.report = null;

    // actually destroy the viewer
    this.changeDetector.detectChanges();

    // set the report to the actual one which should create the viewer
    this.report = await this.reports.WorkOrders.work_order_report.getReport(this.inParams);
    this.reportParameters = this.reports.WorkOrders.work_order_report.getReportParameters(this.inParams);
  }

  onViewerInit() {
    this.reportViewer?.open(this.report, {
        ReportParams: this.reportParameters
      });
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    
    return result;
  }

  close() {
    this.$finish.emit();
  }
}
