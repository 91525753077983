import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles, CardStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { DatexFormControl } from './models/datex-form-control';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-dock_door_card',
  templateUrl: './FootPrintManager.dock_door_card.component.html'
})
export class FootPrintManager_dock_door_cardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  styles = new CardStyles(['creation','border-left']);

  inParams: { dockDoorId: number } = { dockDoorId: null };
  //#region Inputs
  @Input('dockDoorId') set $inParams_dockDoorId(v: number) {
    this.inParams.dockDoorId = v;
  }
  get $inParams_dockDoorId(): number {
    return this.inParams.dockDoorId;
  }
  //#endregion Inputs

  //#region Outputs
  outParams: { refreshedDate?: string } = { refreshedDate: null };
  //#endregion
  //#region Variables
  vars: { warehouseId?: number, doorType?: string, doorName?: string } = { };
  //#endregion
  //#region Events
  @Output()
  openEditorClick = new EventEmitter<number>();
  @Output()
  outParamsChange = new EventEmitter<{ refreshedDate?: string }>();
  
  events = {
    openEditorClick: this.openEditorClick,
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  @Output()
  $refreshEvent = new EventEmitter();

  actionbar = {
      create_appointment: new ToolModel(new ButtonModel('create_appointment', new ButtonStyles(null, null), false, 'Create appointment', 'ms-Icon ms-Icon--Add')
    )
  };

  formGroupContent: FormGroup = new FormGroup({
    active: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });


  content = {
    fields: {
        active: new FieldModel(new CheckBoxModel(this.formGroupContent.controls['active'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Enabled', false)
,
        current_appointment: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Active - scheduled departure', false)
,
        upcoming_appointment: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Upcoming - scheduled arrival', false)
,
    },
    fieldsets: {
  newGroup1: new FieldsetModel('Label of newGroup1', true, false, true),
}
  }


  
  //#region content fields inParams
  //#endregion content fields inParams



  get headerTitle() {
    const $card = this;
    const $utils = this.utils;
    const expr = `${$card.vars.doorName}   ${$card.vars.doorType}`;
    return expr;
  }






  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super(); 
    this.$subscribeFormControlValueChanges();

  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.dockDoorId)) {
        this.$missingRequiredInParams.push('dockDoorId');
      }
  }

  initialized = false;

  async $init() {

    const $card = this;
    const $utils = this.utils;



    await this.on_init();

    this.initialized = true;

  }

  private $subscribeFormControlValueChanges() {
    this.formGroupContent
      .controls['active']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_active_clicked();
      });

  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $card: FootPrintManager_dock_door_cardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Initialize
  
  var dateFormat = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  // Gather dock door data
  const dockDoor = (await $datasources.DockAppointments.ds_get_dock_door_by_locationId.get({
      locationId: $card.inParams.dockDoorId
  })).result;
  if ($utils.isDefined(dockDoor)) {
  
      var childLocationName = dockDoor.ChildLocationContainers[0]?.Name;
      var inboundOnly = dockDoor.EligibleForAllocation;
      var outboundOnly = dockDoor.IsPrimaryPick;
      var doorStatus = dockDoor.StatusId;
      var childIsLoose = dockDoor.ChildLocationContainers[0]?.IsLoose;
  
      $card.vars.doorName = dockDoor.Name;
  
      $card.content.fields.active.control.value = (doorStatus == 1) ? true : false;
      if (inboundOnly && !outboundOnly) {
          $card.vars.doorType = 'Inbound Only'
      }
      else if (!inboundOnly && outboundOnly) {
          $card.vars.doorType = 'Outbound Only'
  
      }
      else {
          $card.vars.doorType = ''
      }
  
  }
  
  // Get a list of active appointments for the given dock door
  const activeAppointments = (await $datasources.DockAppointments.ds_get_upcoming_dock_appointments_by_dock_door.get({
      locationId: $card.inParams.dockDoorId
  })).result;
  
  if ($utils.isDefined(activeAppointments)) {
  
      // Get the current date time at the warehouse
      const warehouseId = activeAppointments[0].WarehouseId;
      const currentDateTimeUTC: Date = new Date();
      const currentDateTimeUTCString: string = currentDateTimeUTC.toISOString();
  
      const currentWarehouseDate = (await $datasources.DockAppointments.ds_get_current_date_from_utc.get({
          utcDate: currentDateTimeUTCString,
          warehouseId: warehouseId
      })).result;
  
      const warehouseDateConverted = currentWarehouseDate?.convertedDate;
  
  
      // Loop thru the current appointments to check for a appointment that is actively assigned to the dock door
      var currentAppointment = null;
      for (let activeAppointment of activeAppointments) {
  
          const dockAppointmentStatusId = activeAppointment.StatusId;
  
          // Find the first appointment for the dock door that is in a door assigned or in process status
          if ([2, 3].includes(dockAppointmentStatusId)) {
              const appointment = $utils.date.format(activeAppointment.convertedScheduledDeparture?.convertedDate.toString(), dateFormat);
              currentAppointment = activeAppointment.Id + ' - ' + appointment;
              break;
  
          }
      }
      // If a active appointment by door assigned or in process status is not found loop thru again to find the current appointment based on scheduled arrival/departure times.
      if (!$utils.isDefined(currentAppointment)) {
          for (let activeAppointment of activeAppointments) {
  
              const scheduledArrival = activeAppointment.convertedScheduledArrival?.convertedDate;
              const scheduledDeparture = activeAppointment.convertedScheduledDeparture?.convertedDate;
  
              if ($utils.isAllDefined(scheduledArrival, scheduledDeparture, warehouseDateConverted)) {
                  if (scheduledArrival <= warehouseDateConverted && scheduledDeparture >= warehouseDateConverted) {
                      const appointment = $utils.date.format(activeAppointment.convertedScheduledDeparture?.convertedDate.toString(), dateFormat);
                      currentAppointment = activeAppointment.Id + ' - ' + appointment;
                      break;
  
                  }
              }
          }
  
      }
      if ($utils.isDefined(currentAppointment)) {
          $card.content.fields.current_appointment.hidden = false;
          $card.content.fields.current_appointment.control.text = currentAppointment?.toString();
          $card.styles.setAttentionClass();
      }
      else {
          $card.styles.setCreationClass();
          $card.content.fields.current_appointment.hidden = true;
      }
  
      // Loop thru the current appointments to check for the first upcoming appointment
      var upcomingAppointment = null;
      for (let activeAppointment of activeAppointments) {
  
          const scheduledArrival = activeAppointment.convertedScheduledArrival?.convertedDate;
          if ($utils.isAllDefined(scheduledArrival, warehouseDateConverted)) {
              if (scheduledArrival > warehouseDateConverted) {
                  const appointment = $utils.date.format(activeAppointment.convertedScheduledArrival?.convertedDate.toString(), dateFormat);
                  upcomingAppointment = activeAppointment.Id + ' - ' + appointment;
                  break;
              }
          }
      }
      if ($utils.isDefined(upcomingAppointment)) {
          $card.content.fields.upcoming_appointment.hidden = false;
          $card.content.fields.upcoming_appointment.control.text = upcomingAppointment?.toString();
  
      }
      else {
  
          $card.content.fields.upcoming_appointment.hidden = true;
      }
  
  } else {
      $card.styles.setCreationClass();
      $card.content.fields.current_appointment.hidden = true;
      $card.content.fields.upcoming_appointment.hidden = true;
  }
  
  
  
  
  
  }
  on_open_editor_clicked(event = null) {
    return this.on_open_editor_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_open_editor_clickedInternal(
    $card: FootPrintManager_dock_door_cardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const dialogResponse = (await $shell.FootPrintManager.opendock_door_editorDialog({
      locationId: $card.inParams.dockDoorId
  }, 'flyout'));
  if ($utils.isDefined(dialogResponse)) {
  
      const confirm = dialogResponse.confirm;
      if (confirm) {
  
          ($card as any).refresh();
          $card.outParams.refreshedDate = $utils.date.now();
          $card.events.outParamsChange.emit();
  
      }
  }
  
  
  }
  on_active_clicked(event = null) {
    return this.on_active_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_active_clickedInternal(
    $card: FootPrintManager_dock_door_cardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  try {
  
      // Construct payload with any values that have changed
      const payload: any = {};
  
  
      //  Properties    
      if ($card.content.fields.active.control.isChanged) {
          payload.StatusId = ($card.content.fields.active.control.value == true) ? 1 : 2; // Set to active if true else inactive
  
  
      }
  
      (await $flows.Utilities.crud_update_flow({
          entitySet: 'LocationContainers',
          id: $card.inParams.dockDoorId,
          entity: payload
      }));
  
  
      ($card as any).refresh();
      $card.outParams.refreshedDate = $utils.date.now();
      $card.events.outParamsChange.emit();
  
  }
  catch (error) {
      $shell.DockAppointments.openErrorDialog('Save', 'Error on save');
      throw error; // To prevent display mode
  }
  
  }
  on_create_appointment_clicked(event = null) {
    return this.on_create_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_appointment_clickedInternal(
    $card: FootPrintManager_dock_door_cardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: "DockAppointment" })).nextId;
  
  
  const locations = (await $datasources.DockAppointments.ds_get_locationcontainer_by_locationId.get({ locationId: $card.inParams.dockDoorId })).result;
  
  if ($utils.isDefined(locations)) {
  
      const warehouseId = locations.WarehouseId;
  
      const dialogResult = (await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
          warehouseId: [warehouseId],
          lookupcode: nextAppointmentId.toString(),
          scheduledArrival: $utils.date.now(),
          scheduledDeparture: $utils.date.add(1, 'hour', $utils.date.now()),
          dockDoor: $card.inParams.dockDoorId
      }));
  
      const userConfirm = dialogResult.confirm;
      if (userConfirm) {
          ($card as any).refresh();
          $card.outParams.refreshedDate = $utils.date.now();
          $card.events.outParamsChange.emit();
      }
  
  
  
  
  }
  else {
      throw new Error('Unable to determine warehouse from the selected dock door.')
  }
  
  }
  //#endregion private flows

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);

    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }


}
