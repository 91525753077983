import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService, EModalSize, EToasterType, EToasterPosition } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { $types } from './Invoices.types'

import { Invoices_invoicing_transactions_gridComponent } from './Invoices.invoicing_transactions_grid.component';
import { Invoices_invoicing_logs_gridComponent } from './Invoices.invoicing_logs_grid.component';
import { Invoices_invoice_terms_dd_singleComponent } from './Invoices.invoice_terms_dd_single.component'
import { Invoices_tax_schedules_dd_singleComponent } from './Invoices.tax_schedules_dd_single.component'
import { Invoices_billing_aggregation_strategies_dd_multiComponent } from './Invoices.billing_aggregation_strategies_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_invoicing_transactions_gridComponent),
    forwardRef(() => Invoices_invoicing_logs_gridComponent),
    forwardRef(() => Invoices_invoice_terms_dd_singleComponent),
    forwardRef(() => Invoices_tax_schedules_dd_singleComponent),
    forwardRef(() => Invoices_billing_aggregation_strategies_dd_multiComponent),
  ],
  selector: 'Invoices-invoicing_instructions_editor',
  templateUrl: './Invoices.invoicing_instructions_editor.component.html'
})
export class Invoices_invoicing_instructions_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { instructionId: string, billingContractId?: number, projectId?: number, active?: boolean } = { instructionId: null, billingContractId: null, projectId: null, active: null };
  //#region Inputs
  @Input('instructionId') set $inParams_instructionId(v: string) {
    this.inParams.instructionId = v;
  }
  get $inParams_instructionId(): string {
    return this.inParams.instructionId;
  }
  @Input('billingContractId') set $inParams_billingContractId(v: number) {
    this.inParams.billingContractId = v;
  }
  get $inParams_billingContractId(): number {
    return this.inParams.billingContractId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('active') set $inParams_active(v: boolean) {
    this.inParams.active = v;
  }
  get $inParams_active(): boolean {
    return this.inParams.active;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { billingStrategyIds?: number[] } = { };
  //#endregion
  entity: { instructionId?: string, billingContractId?: number, projectId?: number, active?: boolean, instructionType?: string, instructionName?: string, billingAggregationStrategyIds?: number[], oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, termId?: number, taxScheduleId?: number, createdOn?: string, createdBy?: string, modifiedOn?: string, modifiedBy?: string, useExistingInvoice?: boolean };

  formGroup: FormGroup = new FormGroup({
    name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    terms: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    tax_schedule: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    billing_aggregation_strategies: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    one_invoice_per_warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    one_invoice_per_shipment: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    active: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    create_new_invoice: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      save: new ToolModel(new ButtonModel('save', new ButtonStyles(['creation'], null), false, 'Save', 'ms-Icon ms-Icon--Save')
    )
  };

  fields = {
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, 'Auto generated from the billing aggregation strategies')
, new ControlContainerStyles(null, null), 'Name', false)
,
    terms: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['terms'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Invoice terms', false)
,
    tax_schedule: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['tax_schedule'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Tax schedule', false)
,
    billing_aggregation_strategies: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['billing_aggregation_strategies'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Billing aggregation strategies', true)
,
    one_invoice_per_warehouse: new FieldModel(new CheckBoxModel(this.formGroup.controls['one_invoice_per_warehouse'] as DatexFormControl, null, false, 'Invoice by warehouse')
, new ControlContainerStyles(null, null), '', false)
,
    one_invoice_per_shipment: new FieldModel(new CheckBoxModel(this.formGroup.controls['one_invoice_per_shipment'] as DatexFormControl, null, false, 'Invoice by shipment')
, new ControlContainerStyles(null, null), '', false)
,
    active: new FieldModel(new CheckBoxModel(this.formGroup.controls['active'] as DatexFormControl, null, false, 'Instruction is active')
, new ControlContainerStyles(null, null), '', false)
,
    create_new_invoice: new FieldModel(new CheckBoxModel(this.formGroup.controls['create_new_invoice'] as DatexFormControl, null, false, 'Create new invoice')
, new ControlContainerStyles(null, null), '', false)
,
  };

  fieldsets = {
  instructions: new FieldsetModel('Instuctions', false, true, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      transactions: new TabItemModel(
        this.rootTabGroup, 
        'Transactions', 
        ),
      logs: new TabItemModel(
        this.rootTabGroup, 
        'Logs', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_transactions_invoicing_transactions_grid_inParams_instructionId(): string {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.instructionId;
      
      return expr;
    }
  
    get $tabs_logs_invoicing_logs_grid_inParams_instructionId(): string {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.instructionId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_transactions', { read: Invoices_invoicing_transactions_gridComponent }) $tabs_transactions: Invoices_invoicing_transactions_gridComponent;
      @ViewChild('$tabs_logs', { read: Invoices_invoicing_logs_gridComponent }) $tabs_logs: Invoices_invoicing_logs_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  cacheValueFor_$fields_billing_aggregation_strategies_selector_inParams_id: number[];
  get $fields_billing_aggregation_strategies_selector_inParams_id(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.billingStrategyIds;
    
    if(!isEqual(this.cacheValueFor_$fields_billing_aggregation_strategies_selector_inParams_id, expr)) {
      this.cacheValueFor_$fields_billing_aggregation_strategies_selector_inParams_id = expr;
    }
    return this.cacheValueFor_$fields_billing_aggregation_strategies_selector_inParams_id;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    name: this.fields.name.control.valueChanges
    ,
    terms: this.fields.terms.control.valueChanges
    ,
    tax_schedule: this.fields.tax_schedule.control.valueChanges
    ,
    billing_aggregation_strategies: this.fields.billing_aggregation_strategies.control.valueChanges
    ,
    one_invoice_per_warehouse: this.fields.one_invoice_per_warehouse.control.valueChanges
    ,
    one_invoice_per_shipment: this.fields.one_invoice_per_shipment.control.valueChanges
    ,
    active: this.fields.active.control.valueChanges
    ,
    create_new_invoice: this.fields.create_new_invoice.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Invoices_ShellService,
    private datasources: Invoices_DatasourceService,
    private flows: Invoices_FlowService,
    private reports: Invoices_ReportService,
    private localization: Invoices_LocalizationService,
    private operations: Invoices_OperationService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.transactions,
      this.tabs.logs,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.instructionId)) {
        this.$missingRequiredInParams.push('instructionId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Invoicing Instructions';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      billingContractId:  $editor.inParams.billingContractId ,
      projectId:  $editor.inParams.projectId ,
      active:  $editor.inParams.active ,
      instructionId:  $editor.inParams.instructionId 
    };

    const data = await this.datasources.Invoices.ds_invoicing_instructions_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.name.control as TextBoxModel).reset($editor.entity.instructionName);
    (this.fields.terms.control as SelectBoxModel).reset($editor.entity.termId);
    (this.fields.tax_schedule.control as SelectBoxModel).reset($editor.entity.taxScheduleId);
    (this.fields.billing_aggregation_strategies.control as SelectBoxModel).reset($editor.entity.billingAggregationStrategyIds);
    (this.fields.one_invoice_per_warehouse.control as CheckBoxModel).reset($editor.entity.oneInvoicePerWarehouse ?? false);
    (this.fields.one_invoice_per_shipment.control as CheckBoxModel).reset($editor.entity.oneInvoicePerShipment ?? false);
    (this.fields.active.control as CheckBoxModel).reset($editor.entity.active ?? false);
    (this.fields.create_new_invoice.control as CheckBoxModel).reset(!($editor.entity.useExistingInvoice ?? false));

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_transactions') {
        if (!isNil(this.$tabs_transactions) && !this.tabs.transactions.hidden) {
          this.$tabs_transactions.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_logs') {
        if (!isNil(this.$tabs_logs) && !this.tabs.logs.hidden) {
          this.$tabs_logs.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .terms
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .tax_schedule
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .billing_aggregation_strategies
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_billing_aggregation_strategies_change();
      });
    this.$formGroupFieldValidationObservables
      .one_invoice_per_warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .one_invoice_per_shipment
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_invoice_per_shipment_change();
      });
    this.$formGroupFieldValidationObservables
      .active
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_activate();
      });
    this.$formGroupFieldValidationObservables
      .create_new_invoice
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: Invoices_invoicing_instructions_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/30/2024
  
  let instruction = $editor.entity;
  
  let instructionName = $editor.fields.name.control.value ?? "";
  
  if (instructionName === "") {
      if ($utils.isDefined($editor.fields.billing_aggregation_strategies)) {
  
          let bs = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({ id: $editor.fields.billing_aggregation_strategies.control.value })).result;
  
          let main: typeof bs;
  
          main = bs.filter(bs => bs.Name.toUpperCase().includes("INBOUND"));
  
          if (!$utils.isDefined(main)) {
              main = bs.filter(bs => bs.Name.toUpperCase().includes("OUTBOUND"));
          }
  
          if (!$utils.isDefined(main)) {
              main = bs.filter(bs => bs.Name.toUpperCase().includes("RECURRING"));
          }
  
          if (!$utils.isDefined(main)) {
              main = bs.filter(bs => bs.Name.toUpperCase().includes("INITIAL"));
          }
  
          if (!$utils.isDefined(main)) {
              main = bs.filter(bs => bs.Name.toUpperCase().includes("ACCESSORIAL"));
          }
  
          if (!$utils.isDefined(main)) {
              main = bs;
          }
  
          main = main.sort((a, b) => a.Id - b.Id);
  
          instructionName = main[0].EnumName;
  
          if (bs.length > 1) {
              instructionName = `${instructionName}+${bs.length - 1}`
          }
      }
  }
  
  let billingAggregationStrategyIds: number[] = $editor.fields.billing_aggregation_strategies.control.value;
  
  instruction = {
      instructionId: instruction.instructionId,
      instructionType: "Auto Invoicing",
      instructionName: instructionName,
      active: $editor.fields.active.control.value ?? true,
      billingAggregationStrategyIds: billingAggregationStrategyIds,
      oneInvoicePerShipment: $editor.fields.one_invoice_per_shipment.control.value ?? false,
      oneInvoicePerWarehouse: $editor.fields.one_invoice_per_warehouse.control.value ?? false,
      useExistingInvoice: !($editor.fields.create_new_invoice.control.value ?? false),
      termId: $editor.fields.terms.control.value,
      taxScheduleId: $editor.fields.tax_schedule.control.value
  };
  
  await $flows.Invoices.invoicing_instruction({ action: "Update", payload: instruction });
  
  $editor.close();
  }
  on_activate(event = null) {
    return this.on_activateInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_activateInternal(
    $editor: Invoices_invoicing_instructions_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/17/2024
  
  if ($utils.isDefined($editor.fields.active)) {
  
      if ($editor.fields.active) {
  
          if ($utils.isAllDefined(
               $editor.fields.billing_aggregation_strategies.control.value,
              $editor.fields.terms.control.value,
              $editor.fields.tax_schedule.control.value)) {
                  //Nothing
          } else {
              await $shell.Invoices.openErrorDialog("Cannot activate!", "The instruction cannot be activated because a necessary field is not defined!");
              $editor.fields.active.control.value = false;
          }
      }
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: Invoices_invoicing_instructions_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/30/2024
  
  let allowDuplicates: boolean = false;
  let option = (await $flows.Invoices.invoicing_option({ action: "Read", payload: { dimension: "AllowDuplicateStrategies" } })).payload;
  if (option.length > 0) {
      let truth: any[] = ["TRUE", "1"]
      if (truth.includes(option[0].value.toUpperCase())) {
          allowDuplicates = true
      }
  } else {
      await $flows.Invoices.invoicing_option({ action: "Write", payload: { dimension: "AllowDuplicateStrategies", value: `${allowDuplicates}` } })
  }
  
  if (!allowDuplicates) {
  
      let instructions = (await $flows.Invoices.invoicing_instruction({
          action: "Read",
          payload: {
              projectId: $editor.inParams.projectId,
              billingContractId: $editor.inParams.billingContractId
          }
      })).payload;
  
      instructions = instructions.filter(i => i.instructionId.toUpperCase() !== $editor.inParams.instructionId.toUpperCase())
  
      if (instructions.length > 0) {
  
          const bs = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({})).result;
          const accessorial_ids = bs.filter(bs => bs.Name.toUpperCase().includes("ACCESSORIAL")).map(bs => bs.Id);
          const bs_already_used = instructions.filter(i => $utils.isDefined(i.billingAggregationStrategyIds)).map(i => i.billingAggregationStrategyIds).flat();
  
          let bs_available = bs.filter(bs => bs_already_used.indexOf(bs.Id) === -1)?.map(bs => bs.Id)
  
          bs_available.push(...accessorial_ids);
  
          if (bs_available.length === 0) {
              bs_available.push(0);
          }
  
          $editor.vars.billingStrategyIds = bs_available;
      }
  }
  }
  on_invoice_per_shipment_change(event = null) {
    return this.on_invoice_per_shipment_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_invoice_per_shipment_changeInternal(
    $editor: Invoices_invoicing_instructions_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/23/2024
  
  if ($editor.fields.one_invoice_per_shipment.control.value) {
  
      let bs_selected = $editor.fields.billing_aggregation_strategies.control.value;
  
      if ($utils.isDefined(bs_selected)) {
  
          let bs = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({ id: bs_selected })).result;
  
          //Check if they are of not shipment types
          bs = bs.filter(bs =>
              !bs.Name.toUpperCase().includes("INITIAL") &&
              !bs.Name.toUpperCase().includes("INBOUND") &&
              !bs.Name.toUpperCase().includes("OUTBOUND") &&
              !bs.Name.toUpperCase().includes("ACCESSORIAL")
          )
  
          //If we have strategies of not shipment type we cannot have a one invoice per shipment
          if (bs.length > 0) {
              $editor.fields.one_invoice_per_shipment.control.value = false;
              await $shell.Invoices.openErrorDialog("Invalid selection!", "You have billing aggregation strategies not related to shipments, you cannot use this option!")
          }
      }
  }
  }
  on_billing_aggregation_strategies_change(event = null) {
    return this.on_billing_aggregation_strategies_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_billing_aggregation_strategies_changeInternal(
    $editor: Invoices_invoicing_instructions_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/23/2024
  
  let bs_selected = $editor.fields.billing_aggregation_strategies.control.value;
  
  if ($utils.isDefined(bs_selected)) {
  
      let bs = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({ id: bs_selected })).result;
  
      //Check if they are of not shipment types
      bs = bs.filter(bs =>
          !bs.Name.toUpperCase().includes("INITIAL") &&
          !bs.Name.toUpperCase().includes("INBOUND") &&
          !bs.Name.toUpperCase().includes("OUTBOUND") &&
          !bs.Name.toUpperCase().includes("ACCESSORIAL")
      )
  
      //If we have strategies of not shipment type we cannot have a one invoice per shipment
      if (bs.length > 0) {
          $editor.fields.one_invoice_per_shipment.control.value = false;
          $editor.fields.one_invoice_per_shipment.control.readOnly = true;
      } else {
          $editor.fields.one_invoice_per_shipment.control.readOnly = false;
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: Invoices_invoicing_instructions_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/17/2024
  
  $editor.on_billing_aggregation_strategies_change();
  }
  //#endregion private flows
}
