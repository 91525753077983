import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'

import { FootPrintManager_assigned_orders_on_wave_gridComponent } from './FootPrintManager.assigned_orders_on_wave_grid.component';
import { FootPrintManager_manual_allocations_gridComponent } from './FootPrintManager.manual_allocations_grid.component';
import { FootPrintManager_pick_slips_gridComponent } from './FootPrintManager.pick_slips_grid.component';
import { FootPrintManager_sales_order_picking_gridComponent } from './FootPrintManager.sales_order_picking_grid.component';
import { FootPrintManager_sales_order_tasks_gridComponent } from './FootPrintManager.sales_order_tasks_grid.component';
import { Waves_order_count_widgetComponent } from './Waves.order_count_widget.component';
import { Waves_pick_tasks_widgetComponent } from './Waves.pick_tasks_widget.component';
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { Waves_wave_priorities_dd_singleComponent } from './Waves.wave_priorities_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_assigned_orders_on_wave_gridComponent),
    forwardRef(() => FootPrintManager_manual_allocations_gridComponent),
    forwardRef(() => FootPrintManager_pick_slips_gridComponent),
    forwardRef(() => FootPrintManager_sales_order_picking_gridComponent),
    forwardRef(() => FootPrintManager_sales_order_tasks_gridComponent),
    forwardRef(() => Waves_order_count_widgetComponent),
    forwardRef(() => Waves_pick_tasks_widgetComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => Waves_wave_priorities_dd_singleComponent),
  ],
  selector: 'FootPrintManager-single_wave_hub',
  templateUrl: './FootPrintManager.single_wave_hub.component.html'
})
export class FootPrintManager_single_wave_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { waveId: number, waveDescription?: string, waveStatus?: string, ownerId?: number, projectId?: number, warehouseIds?: number[] } = { waveId: null, waveDescription: null, waveStatus: null, ownerId: null, projectId: null, warehouseIds: [] };
  //#region Inputs
  @Input('waveId') set $inParams_waveId(v: number) {
    this.inParams.waveId = v;
  }
  get $inParams_waveId(): number {
    return this.inParams.waveId;
  }
  @Input('waveDescription') set $inParams_waveDescription(v: string) {
    this.inParams.waveDescription = v;
  }
  get $inParams_waveDescription(): string {
    return this.inParams.waveDescription;
  }
  @Input('waveStatus') set $inParams_waveStatus(v: string) {
    this.inParams.waveStatus = v;
  }
  get $inParams_waveStatus(): string {
    return this.inParams.waveStatus;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('warehouseIds') set $inParams_warehouseIds(v: number[]) {
    this.inParams.warehouseIds = v;
  }
  get $inParams_warehouseIds(): number[] {
    return this.inParams.warehouseIds;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string, hubDescription?: string, waveStatusId?: number, warehouseId?: number } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    is_batch_pick: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    staging_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    priority: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      Edit: new ToolModel(new ButtonModel('Edit', new ButtonStyles(null, null), false, 'Edit', 'icon-ic_fluent_edit_20_regular')
    ),
      add_to_wave: new ToolModel(new ButtonModel('add_to_wave', new ButtonStyles(null, null), false, 'Add orders', 'ms-Icon ms-Icon--CircleAddition')
    ),
      process_wave: new ToolModel(new ButtonModel('process_wave', new ButtonStyles(null, null), false, 'Process', 'ms-Icon ms-Icon--Process')
    ),
      release_wave: new ToolModel(new ButtonModel('release_wave', new ButtonStyles(null, null), false, 'Release', 'ms-Icon ms-Icon--Movers')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      cancel_wave: new ToolModel(new ButtonModel('cancel_wave', new ButtonStyles(null, null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      print: new ToolModel(new SplitButtonModel<{ print_pickslip: ButtonModel, pick_slip_consolidated: ButtonModel }>(
        'print',
        new ButtonStyles(null, null),
        false,
        'Print',
        'icon-ic_fluent_print_20_regular',
        [
          new ButtonModel('print_pickslip', new ButtonStyles(null, null), false, 'Pick slip', ''),
          new ButtonModel('pick_slip_consolidated', new ButtonStyles(null, null), false, 'Pick slip consolidated', '')
        ])
    )
  };

  actionbar = {
  };

 filters = {
    is_batch_pick: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_batch_pick'] as DatexFormControl, null, true, 'Batch')
, new ControlContainerStyles(null, null), '', false)
,
    staging_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['staging_location'] as DatexFormControl, 
  null, null,
  true, 
  '')
, new ControlContainerStyles(null, null), 'Staging location', false)
,
    priority: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['priority'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  true, 
  '')
, new ControlContainerStyles(null, null), 'Priority', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
  };


  filtersets = {
  header: new FieldsetModel('Header', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      assigned_orders: new TabItemModel(
        this.rootTabGroup, 
        'Assigned orders', 
        ),
      manual_allocations: new TabItemModel(
        this.rootTabGroup, 
        'Manual allocations', 
        ),
      pick_slips_grid: new TabItemModel(
        this.rootTabGroup, 
        'Pick slips', 
        ),
      pick_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Pick tasks', 
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_assigned_orders_assigned_orders_on_wave_grid_inParams_waveId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.waveId;
      
      return expr;
    }
  
    get $tabs_assigned_orders_assigned_orders_on_wave_grid_inParams_waveStatusId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.waveStatusId;
      
      return expr;
    }
  
    get $tabs_manual_allocations_manual_allocations_grid_inParams_waveId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.waveId;
      
      return expr;
    }
  
    get $tabs_pick_slips_grid_pick_slips_grid_inParams_waveId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.waveId;
      
      return expr;
    }
  
    get $tabs_pick_tasks_sales_order_picking_grid_inParams_waveId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.waveId;
      
      return expr;
    }
  
    get $tabs_pick_tasks_sales_order_picking_grid_inParams_waveStatusId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.waveStatusId;
      
      return expr;
    }
  
    get $tabs_tasks_sales_order_tasks_grid_inParams_waveId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.waveId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_assigned_orders', { read: FootPrintManager_assigned_orders_on_wave_gridComponent }) $tabs_assigned_orders: FootPrintManager_assigned_orders_on_wave_gridComponent;
      @ViewChild('$tabs_manual_allocations', { read: FootPrintManager_manual_allocations_gridComponent }) $tabs_manual_allocations: FootPrintManager_manual_allocations_gridComponent;
      @ViewChild('$tabs_pick_slips_grid', { read: FootPrintManager_pick_slips_gridComponent }) $tabs_pick_slips_grid: FootPrintManager_pick_slips_gridComponent;
      @ViewChild('$tabs_pick_tasks', { read: FootPrintManager_sales_order_picking_gridComponent }) $tabs_pick_tasks: FootPrintManager_sales_order_picking_gridComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_sales_order_tasks_gridComponent }) $tabs_tasks: FootPrintManager_sales_order_tasks_gridComponent;
    //#endregion tabs children
    widgets = {
      order_count: new WidgetModel(),
      pick_tasks_count: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_order_count_inParams_waveId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.waveId;
      
      return expr;
    }
  
    get $widgets_pick_tasks_count_inParams_waveId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.waveId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_order_count', { read:  Waves_order_count_widgetComponent }) $widgets_order_count: Waves_order_count_widgetComponent;
      @ViewChild('$widgets_pick_tasks_count', { read:  Waves_pick_tasks_widgetComponent }) $widgets_pick_tasks_count: Waves_pick_tasks_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_staging_location_selector_inParams_warehouseId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.vars.warehouseId;
    
    return expr;
  }



  get $fields_staging_location_selector_inParams_typeId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 3;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubDescription;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.assigned_orders,
      this.tabs.manual_allocations,
      this.tabs.pick_slips_grid,
      this.tabs.pick_tasks,
      this.tabs.tasks,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.waveId)) {
        this.$missingRequiredInParams.push('waveId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Single wave';
    
    const $hub = this;
    const $utils = this.utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_order_count') {
      if (!isNil(this.$widgets_order_count) && !this.widgets.order_count.hidden) {
        this.$widgets_order_count.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_pick_tasks_count') {
      if (!isNil(this.$widgets_pick_tasks_count) && !this.widgets.pick_tasks_count.hidden) {
        this.$widgets_pick_tasks_count.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_assigned_orders') {
      if (!isNil(this.$tabs_assigned_orders) && !this.tabs.assigned_orders.hidden) {
        this.$tabs_assigned_orders.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_manual_allocations') {
      if (!isNil(this.$tabs_manual_allocations) && !this.tabs.manual_allocations.hidden) {
        this.$tabs_manual_allocations.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_pick_slips_grid') {
      if (!isNil(this.$tabs_pick_slips_grid) && !this.tabs.pick_slips_grid.hidden) {
        this.$tabs_pick_slips_grid.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_pick_tasks') {
      if (!isNil(this.$tabs_pick_tasks) && !this.tabs.pick_tasks.hidden) {
        this.$tabs_pick_tasks.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_tasks') {
      if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
        this.$tabs_tasks.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_single_wave_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.toolbar.cancel_wave.hidden = true;
  $hub.toolbar.process_wave.hidden = true;
  $hub.toolbar.print.hidden = true;
  $hub.toolbar.release_wave.hidden = true;
  $hub.toolbar.add_to_wave.hidden = true;
  $hub.toolbar.separator1.hidden = true;
  $hub.toolbar.separator2.hidden = true;
  
  let orderCount: number = 0;
  let waveIds = [];
  waveIds.push($hub.inParams.waveId);
  
  let hideManualAllocations = (await $datasources.Waves.get_manual_allocations.get({ WaveIds: waveIds })).result
  
  let checkOrders = (await $datasources.Waves.ds_get_shipments_by_waveId.get({ waveId: $hub.inParams.waveId })).result
  let waveInfo = (await $datasources.Waves.ds_get_wave_by_wave_id.get({ waveId: $hub.inParams.waveId })).result
  
  $hub.title = `Wave ${waveInfo.Id}`
  $hub.vars.hubTitle = `Wave ${waveInfo.Id} Status ${waveInfo.Status.Name}`;
  $hub.vars.hubDescription = waveInfo.Description;
  
  $hub.vars.warehouseId = waveInfo.Warehouse?.Id;
  
  $hub.filters.is_batch_pick.control.value = waveInfo.BatchPick;
  $hub.filters.notes.control.value = waveInfo.Notes;
  $hub.filters.priority.control.value = waveInfo.Priority?.Id;
  $hub.filters.staging_location.control.value = waveInfo.StagingLocationId;
  
  
  $hub.filters.notes.hidden = !$utils.isDefinedTrimmed(waveInfo.Notes);
  
  
  
  // Set wave status hub variable which is used in the pick tasks grid to effect behavior
  $hub.vars.waveStatusId = waveInfo.Status?.Id;
  
  if ($utils.isDefined(checkOrders)) {
      orderCount = orderCount + checkOrders.length
  }
  
  if (!$utils.isDefined(hideManualAllocations[0]?.TaskCount)) {
      $hub.tabs.manual_allocations.hidden = true;
  }
  
  
  //Button Checks
  if (waveInfo.Status.Name === 'Created') {
      if (orderCount < 1) {
          $hub.toolbar.add_to_wave.hidden = false;
          $hub.toolbar.separator1.hidden = false;
          $hub.toolbar.cancel_wave.hidden = false;
      } else {
          $hub.toolbar.cancel_wave.hidden = false;
          $hub.toolbar.separator1.hidden = false;
          $hub.toolbar.process_wave.hidden = false;
          $hub.toolbar.add_to_wave.hidden = false;
      }
  }
  if (waveInfo.Status.Name === 'Processed') {
      $hub.toolbar.release_wave.hidden = false;
      $hub.toolbar.separator1.hidden = false;
      $hub.toolbar.cancel_wave.hidden = false;
  }
  if (waveInfo.Status.Name === 'Processing') {
      $hub.toolbar.release_wave.hidden = true;
      $hub.toolbar.process_wave.hidden = false;
      $hub.toolbar.separator1.hidden = false;
      $hub.toolbar.cancel_wave.hidden = false;
  }
  if (waveInfo.Status.Name === 'Released') {
      $hub.toolbar.print.hidden = false;
      $hub.toolbar.separator2.hidden = false;
      $hub.toolbar.cancel_wave.hidden = false;
  }
  if (waveInfo.Status.Name === 'Completed') {
      $hub.toolbar.print.hidden = false;
  }
  if (waveInfo.Status.Name === 'Canceled') {
      $hub.toolbar.print.hidden = false;
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $hub: FootPrintManager_single_wave_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let message: string;
  let canceledWaveIds: number[] = [];
  let failedWaveIds: { id: number, errors: string }[] = [];
  let candidates: number[] = [];
  
  candidates.push($hub.inParams.waveId)
  
  const confirmation = await $shell.Waves.openConfirmationDialog(`Cancel ${candidates.length === 1 ? 'wave' : 'waves'} ${candidates.join(', ')}?`, `Once cancelled, waves cannot be restored.`, 'Confirm', 'Cancel')
  
  if (confirmation) {
      var result = (await $flows.Waves.cancel_wave_flow({ waveId: $hub.inParams.waveId }))
      var reason = result.reasons[0];
      if ($utils.isDefined(reason)) {
          await $shell.Waves.openErrorDialog(`Unable to cancel wave ${$hub.inParams.waveId}`, reason)
          failedWaveIds.push({ id: $hub.inParams.waveId, errors: reason });
          return;
      }
      if (!$utils.isDefined(reason)) {
          canceledWaveIds.push($hub.inParams.waveId);
      }
  }
  message = '';
  if ($utils.isDefined(canceledWaveIds)) {
      message += `Successfully cancelled ${canceledWaveIds.length === 1 ? 'wave' : 'waves'} ${canceledWaveIds.join(', ')}. `;
  }
  if ($utils.isDefined(failedWaveIds)) {
      message += `Failed to cancel ${failedWaveIds.length === 1 ? 'wave' : 'waves'} ${failedWaveIds.map(c => `${c.id} (${c.errors})`).join(', ')}. `;
  }
  await $shell.Waves.openInfoDialog(`Cancel wave`, message)
  
  await $hub.on_init();
  
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $hub: FootPrintManager_single_wave_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  // Begin processing
  $hub.toolbar.process_wave.control.readOnly = true;
  $hub.toolbar.process_wave.control.icon = "icon ms-Icon ms-Icon--ProgressRingDots";
  $hub.toolbar.process_wave.control.label = 'Processing wave'
  
  const result = (await $flows.SalesOrders.process_single_wave_flow({ waveId: $hub.inParams.waveId, createManualAllocationTasks: false }));
  const reason = result.reason;
  
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Wave cannot be processed', reason);
  
  }
  $hub.toolbar.process_wave.control.styles.resetStyle();
  $hub.toolbar.process_wave.control.icon = "icon ms-Icon ms-Icon--Process";
  $hub.toolbar.process_wave.control.label = 'Process';
  
  $hub.toolbar.process_wave.control.readOnly = false;
  
  
  await $hub.refresh_hub();
  }
  on_release_clicked(event = null) {
    return this.on_release_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_release_clickedInternal(
    $hub: FootPrintManager_single_wave_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Begin releasing
  $hub.toolbar.release_wave.control.readOnly = true;
  $hub.toolbar.release_wave.control.icon = "icon ms-Icon ms-Icon--ProgressRingDots";
  $hub.toolbar.release_wave.control.label = 'Releasing wave'
  
  
  
  let result = (await $flows.Waves.release_single_wave_flow({waveId: $hub.inParams.waveId}))
  
  if ($utils.isDefined(result.reason)) {
      await $shell.FootPrintManager.openErrorDialog('Wave cannot be released', result.reason);
  }
  
  $hub.toolbar.release_wave.control.styles.resetStyle();
  $hub.toolbar.release_wave.control.icon = "icon ms-Icon ms-Icon--Movers";
  $hub.toolbar.release_wave.control.label = 'Release';
  
  $hub.toolbar.release_wave.control.readOnly = false;
  
  await $hub.refresh_hub();
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $hub: FootPrintManager_single_wave_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.Waves.openpick_slip_by_wave_id_reportDialog({waveId: $hub.inParams.waveId})
  }
  on_add_to_wave_clicked(event = null) {
    return this.on_add_to_wave_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_to_wave_clickedInternal(
    $hub: FootPrintManager_single_wave_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  let waveInfo = (await $datasources.Waves.ds_get_wave_by_wave_id.get({ waveId: $hub.inParams.waveId })).result
  let orderCount = 0;
  
  let result = (await $shell.FootPrintManager.openwave_editorDialog({
      waveId: $hub.inParams.waveId,
      projectId: $hub.inParams.projectId,
      ownerId: $hub.inParams.ownerId,
      warehouseId: waveInfo?.Warehouse?.Id
  }))
  
  if (result.confirm) {
      let checkOrders = (await $datasources.Waves.ds_get_shipments_by_waveId.get({ waveId: $hub.inParams.waveId })).result
      if ($utils.isDefined(checkOrders)) {
          orderCount = orderCount + checkOrders.length
      }
      if (orderCount < 1) {
          $hub.toolbar.add_to_wave.hidden = false;
          $hub.toolbar.separator1.hidden = false;
          $hub.toolbar.cancel_wave.hidden = false;
          $hub.toolbar.process_wave.hidden = true;
      } else {
          $hub.toolbar.cancel_wave.hidden = false;
          $hub.toolbar.separator1.hidden = false;
          $hub.toolbar.process_wave.hidden = false;
          $hub.toolbar.add_to_wave.hidden = false;
      }
  }
  
  
  await $hub.refresh();
  
  
  
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootPrintManager_single_wave_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  let result = (await $shell.FootPrintManager.openwave_update_formDialog({
      waveId: $hub.inParams.waveId
  }, "flyout"))
  
  if (result.confirm){
      await $hub.on_init(); 
  }
  }
  refresh_hub(event = null) {
    return this.refresh_hubInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async refresh_hubInternal(
    $hub: FootPrintManager_single_wave_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let waveIds: number[] = [];
  
  waveIds.push($hub.inParams.waveId)
  let hideManualAllocations = (await $datasources.Waves.get_manual_allocations.get({ WaveIds: waveIds })).result
  
  if (!$utils.isDefined(hideManualAllocations[0]?.TaskCount)) {
      $hub.tabs.manual_allocations.hidden = true;
  }
  
  $hub.on_init();
  
  $hub.refresh();
  }
  on_print_consolidated_clicked(event = null) {
    return this.on_print_consolidated_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_consolidated_clickedInternal(
    $hub: FootPrintManager_single_wave_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.Waves.openpick_slip_by_wave_id_consolidated_reportDialog({waveId: $hub.inParams.waveId})
  }
  //#endregion private flows
}
