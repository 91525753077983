import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'

import { FootPrintManager_projects_gridComponent } from './FootPrintManager.projects_grid.component';
import { FootPrintManager_materials_by_base_packaging_gridComponent } from './FootPrintManager.materials_by_base_packaging_grid.component';
import { FootPrintManager_inventory_location_gridComponent } from './FootPrintManager.inventory_location_grid.component';
import { FootPrintManager_ownerscarrierslookup_gridComponent } from './FootPrintManager.ownerscarrierslookup_grid.component';
import { FootPrintManager_contact_addresses_gridComponent } from './FootPrintManager.contact_addresses_grid.component';
import { FootPrintManager_instructions_gridComponent } from './FootPrintManager.instructions_grid.component';
import { Materials_materials_by_count_widgetComponent } from './Materials.materials_by_count_widget.component';
import { Inventory_total_packaged_amount_widgetComponent } from './Inventory.total_packaged_amount_widget.component';
import { Inventory_total_gross_weight_widgetComponent } from './Inventory.total_gross_weight_widget.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_projects_gridComponent),
    forwardRef(() => FootPrintManager_materials_by_base_packaging_gridComponent),
    forwardRef(() => FootPrintManager_inventory_location_gridComponent),
    forwardRef(() => FootPrintManager_ownerscarrierslookup_gridComponent),
    forwardRef(() => FootPrintManager_contact_addresses_gridComponent),
    forwardRef(() => FootPrintManager_instructions_gridComponent),
    forwardRef(() => Materials_materials_by_count_widgetComponent),
    forwardRef(() => Inventory_total_packaged_amount_widgetComponent),
    forwardRef(() => Inventory_total_gross_weight_widgetComponent),
  ],
  selector: 'FootPrintManager-single_owner_hub',
  templateUrl: './FootPrintManager.single_owner_hub.component.html'
})
export class FootPrintManager_single_owner_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { ownerId: number } = { ownerId: null };
  //#region Inputs
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string, hubDescription?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      edit: new ToolModel(new ButtonModel('edit', new ButtonStyles(null, null), false, 'Edit', 'icon-ic_fluent_edit_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      create_inventory: new ToolModel(new ButtonModel('create_inventory', new ButtonStyles(null, null), false, 'Create inventory', 'icon-ic_fluent_add_20_regular')
    ),
      create_project: new ToolModel(new ButtonModel('create_project', new ButtonStyles(null, null), false, 'Create project', 'icon-ic_fluent_add_20_regular')
    ),
      create_material: new ToolModel(new ButtonModel('create_material', new ButtonStyles(null, null), false, 'Create material', 'icon-ic_fluent_add_20_regular')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    ),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_attach_20_regular')
    )
  };

  actionbar = {
      ship_from_addresses: new ToolModel(new ButtonModel('ship_from_addresses', new ButtonStyles(null, null), false, 'Ship from addresses', 'icon-ic_fluent_person_square_20_regular')
    ),
      ship_to_addresses: new ToolModel(new ButtonModel('ship_to_addresses', new ButtonStyles(null, null), false, 'Ship to addresses', 'ms-Icon ms-Icon--AddGroup')
    ),
      assigned_containers_action: new ToolModel(new ButtonModel('assigned_containers_action', new ButtonStyles(null, null), false, 'Assign containers', 'ms-Icon ms-Icon--TaskList')
    )
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      projects: new TabItemModel(
        this.rootTabGroup, 
        'Projects', 
        ),
      materials: new TabItemModel(
        this.rootTabGroup, 
        'Materials', 
        ),
      inventory: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        ),
      carriers: new TabItemModel(
        this.rootTabGroup, 
        'Carriers', 
        ),
      contacts: new TabItemModel(
        this.rootTabGroup, 
        'Contacts', 
        ),
      instructions: new TabItemModel(
        this.rootTabGroup, 
        'Instructions', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_projects_projects_grid_inParams_projectStatusId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = 1;
      
      return expr;
    }
  
    get $tabs_projects_projects_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.ownerId;
      
      return expr;
    }
  
    get $tabs_materials_materials_by_base_packaging_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.ownerId;
      
      return expr;
    }
  
    get $tabs_inventory_inventory_location_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.ownerId;
      
      return expr;
    }
  
    get $tabs_carriers_ownerscarrierslookup_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.ownerId;
      
      return expr;
    }
  
    get $tabs_contacts_contact_addresses_grid_inParams_entity(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Owner';
      
      return expr;
    }
  
    get $tabs_contacts_contact_addresses_grid_inParams_entityId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.ownerId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_contacts_contact_addresses_grid_inParams_typeIds: number[];
    get $tabs_contacts_contact_addresses_grid_inParams_typeIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [1,3];
      
      if(!isEqual(this.cacheValueFor_$tabs_contacts_contact_addresses_grid_inParams_typeIds, expr)) {
        this.cacheValueFor_$tabs_contacts_contact_addresses_grid_inParams_typeIds = expr;
      }
      return this.cacheValueFor_$tabs_contacts_contact_addresses_grid_inParams_typeIds;
    }
  
    get $tabs_instructions_instructions_grid_inParams_entityName(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Owner';
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_enabled(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_entityKey1(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.ownerId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_projects', { read: FootPrintManager_projects_gridComponent }) $tabs_projects: FootPrintManager_projects_gridComponent;
      @ViewChild('$tabs_materials', { read: FootPrintManager_materials_by_base_packaging_gridComponent }) $tabs_materials: FootPrintManager_materials_by_base_packaging_gridComponent;
      @ViewChild('$tabs_inventory', { read: FootPrintManager_inventory_location_gridComponent }) $tabs_inventory: FootPrintManager_inventory_location_gridComponent;
      @ViewChild('$tabs_carriers', { read: FootPrintManager_ownerscarrierslookup_gridComponent }) $tabs_carriers: FootPrintManager_ownerscarrierslookup_gridComponent;
      @ViewChild('$tabs_contacts', { read: FootPrintManager_contact_addresses_gridComponent }) $tabs_contacts: FootPrintManager_contact_addresses_gridComponent;
      @ViewChild('$tabs_instructions', { read: FootPrintManager_instructions_gridComponent }) $tabs_instructions: FootPrintManager_instructions_gridComponent;
    //#endregion tabs children
    widgets = {
      materials_by_count_widget: new WidgetModel(),
      total_packaged_amount_widget: new WidgetModel(),
      total_gross_weight_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_materials_by_count_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.ownerId;
      
      return expr;
    }
  
    get $widgets_total_packaged_amount_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.ownerId;
      
      return expr;
    }
  
    get $widgets_total_gross_weight_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.ownerId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_materials_by_count_widget', { read:  Materials_materials_by_count_widgetComponent }) $widgets_materials_by_count_widget: Materials_materials_by_count_widgetComponent;
      @ViewChild('$widgets_total_packaged_amount_widget', { read:  Inventory_total_packaged_amount_widgetComponent }) $widgets_total_packaged_amount_widget: Inventory_total_packaged_amount_widgetComponent;
      @ViewChild('$widgets_total_gross_weight_widget', { read:  Inventory_total_gross_weight_widgetComponent }) $widgets_total_gross_weight_widget: Inventory_total_gross_weight_widgetComponent;
    //#endregion widgets children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubDescription;;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.projects,
      this.tabs.materials,
      this.tabs.inventory,
      this.tabs.carriers,
      this.tabs.contacts,
      this.tabs.instructions,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.ownerId)) {
        this.$missingRequiredInParams.push('ownerId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Single Owner Hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_materials_by_count_widget') {
      if (!isNil(this.$widgets_materials_by_count_widget) && !this.widgets.materials_by_count_widget.hidden) {
        this.$widgets_materials_by_count_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_packaged_amount_widget') {
      if (!isNil(this.$widgets_total_packaged_amount_widget) && !this.widgets.total_packaged_amount_widget.hidden) {
        this.$widgets_total_packaged_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_gross_weight_widget') {
      if (!isNil(this.$widgets_total_gross_weight_widget) && !this.widgets.total_gross_weight_widget.hidden) {
        this.$widgets_total_gross_weight_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_projects') {
      if (!isNil(this.$tabs_projects) && !this.tabs.projects.hidden) {
        this.$tabs_projects.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_materials') {
      if (!isNil(this.$tabs_materials) && !this.tabs.materials.hidden) {
        this.$tabs_materials.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_inventory') {
      if (!isNil(this.$tabs_inventory) && !this.tabs.inventory.hidden) {
        this.$tabs_inventory.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_carriers') {
      if (!isNil(this.$tabs_carriers) && !this.tabs.carriers.hidden) {
        this.$tabs_carriers.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_contacts') {
      if (!isNil(this.$tabs_contacts) && !this.tabs.contacts.hidden) {
        this.$tabs_contacts.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_instructions') {
      if (!isNil(this.$tabs_instructions) && !this.tabs.instructions.hidden) {
        this.$tabs_instructions.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_single_owner_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  // Initialize title and descriptions.
  const owner = (await $datasources.Owners.ds_get_owner_by_ownerId.get({ ownerId: $hub.inParams.ownerId })).result;
  if ($utils.isDefined(owner)) {
  
      $hub.title = `Owner ${owner[0].LookupCode}`;
  
      const title = `Owner ${owner[0].LookupCode}    Status ${owner[0].Status.Label}  `;
  
      if ($utils.isDefined(owner[0].Notes)) {
          const description = `Notes ${owner[0].Notes}  `;
          $hub.vars.hubDescription = description;
      }
  
      $hub.vars.hubTitle = title;
  
  }
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootPrintManager_single_owner_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  
  const dialogResults = await $shell.FootPrintManager.openowner_editorDialog({ ownerId: $hub.inParams.ownerId }, 'flyout');
  const userConfirmed = dialogResults.confirm
  
  if (userConfirmed) {
      await $hub.on_init();
      await $hub.refresh();
  }
  }
  on_ship_to_addresses_clicked(event = null) {
    return this.on_ship_to_addresses_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_to_addresses_clickedInternal(
    $hub: FootPrintManager_single_owner_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  await $shell.FootPrintManager.openownersaccounts_gridDialog({
      ownerId:  $hub.inParams.ownerId,
      typeIds: [2,3,4,5],
      accountTypeId: 2,
      allowSelection:  false
  });
  }
  on_ship_from_addresses_clicked(event = null) {
    return this.on_ship_from_addresses_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_addresses_clickedInternal(
    $hub: FootPrintManager_single_owner_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  await $shell.FootPrintManager.openownersaccounts_gridDialog({
      ownerId:  $hub.inParams.ownerId,
      typeIds: [1,3,4,5],
      accountTypeId: 1,
      allowSelection:  false
  })
  }
  on_create_inventory_clicked(event = null) {
    return this.on_create_inventory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_inventory_clickedInternal(
    $hub: FootPrintManager_single_owner_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dialogResult = await $shell.FootPrintManager.openinventory_creation_formDialog({
      ownerId: $hub.inParams.ownerId
  });
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      $hub.refresh()
  }
  
  
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $hub: FootPrintManager_single_owner_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const errors = [];
  const flowParams = { 
      ownerId: $hub.inParams.ownerId
  };
  const result = await $flows.Owners.is_owner_deletable_flow(flowParams);
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      errors.push(`${reason}`);
  
      const title = 'Delete owner errors';
      const errorMessage = `Owner cannot be deleted.`;
      const errorList = errors;
      await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
      return;
  
  }
  
  
  let confirmClicked = false;
  const title = 'Are you sure you want to delete the owner?';
  const message = '';
  confirmClicked = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
  
  if (confirmClicked) {
      var errorMsgList = [];
      var errorMsgListDetails = [];
      try {
          const flowParams = {
              ownerId: $hub.inParams.ownerId
          }
          await $flows.Owners.delete_owner_flow(flowParams);
  
      } catch (error) {
  
          var errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          var errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          var errorDescription = `${errorMessage}`;
          errorMsgList.push(errorDescription);
          errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
      }
  
  }
  
  if (!$utils.isDefined(errorMessage)) {
      const title = 'Delete owner request';
      const message = `Owner has been successfully deleted.`;
      await $shell.FootPrintManager.openInfoDialog(title, message);
      await $hub.close();
  
  }
  else {
      const title = 'Delete owner errors';
      const message = `Owner cannot be deleted.`;
      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
  
  }
  }
  on_create_project_clicked(event = null) {
    return this.on_create_project_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_project_clickedInternal(
    $hub: FootPrintManager_single_owner_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  const dialogResult = (await $shell.FootPrintManager.openproject_creation_formDialog({
      ownerId: $hub.inParams.ownerId
      }));
  const userConfirm = dialogResult.confirm;
  if (userConfirm){
      await $hub.refresh();
  }
  }
  on_create_material_clicked(event = null) {
    return this.on_create_material_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_material_clickedInternal(
    $hub: FootPrintManager_single_owner_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const dialogResult = (await $shell.FootPrintManager.openmaterial_creation_formDialog({
      ownerId: $hub.inParams.ownerId
      }));
  const userConfirm = dialogResult.confirm;
  if (userConfirm){
      await $hub.refresh();
  }
  }
  on_assignment_clicked(event = null) {
    return this.on_assignment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_assignment_clickedInternal(
    $hub: FootPrintManager_single_owner_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.FootPrintManager.opencontainer_type_assignment_gridDialog({ownerId: $hub.inParams.ownerId, assignmentLevel: 'OWNER'})
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $hub: FootPrintManager_single_owner_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  await $shell.FootPrintManager.openentity_attachments_gridDialog({ 
      entityType: 'Owner', entityKeys: [{ name: 'Id', value: $hub.inParams.ownerId }]});
  
  
  }
  //#endregion private flows
}
