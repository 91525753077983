import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'

import { FootPrintManager_count_tasks_listComponent } from './FootPrintManager.count_tasks_list.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_count_tasks_listComponent),
  ],
  selector: 'FootPrintManager-count_task_editor',
  templateUrl: './FootPrintManager.count_task_editor.component.html'
})
export class FootPrintManager_count_task_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { taskId: number } = { taskId: null };
  //#region Inputs
  @Input('taskId') set $inParams_taskId(v: number) {
    this.inParams.taskId = v;
  }
  get $inParams_taskId(): number {
    return this.inParams.taskId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, Material?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string }, Status?: { Id?: number, Name?: string }, InventoryCountTaskProperties?: { InventoryCountId?: number, InventoryCount?: { LookupCode?: string } }[], InventoryCountDiscrepancies?: { ActualAmount?: number, ExpectedAmount?: number, ResolvedDatetime?: string, StatusId?: number }[] };

  formGroup: FormGroup = new FormGroup({
    material: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendorlot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    licenseplate: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    packaging: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_packaged_amount: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    actual_amount: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
  };

  fields = {
    material: new FieldModel(new TextBoxModel(this.formGroup.controls['material'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Material', false)
,
    vendorlot: new FieldModel(new TextBoxModel(this.formGroup.controls['vendorlot'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Vendor lot', false)
,
    lot: new FieldModel(new TextBoxModel(this.formGroup.controls['lot'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Lot', false)
,
    location: new FieldModel(new TextBoxModel(this.formGroup.controls['location'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Location', false)
,
    licenseplate: new FieldModel(new TextBoxModel(this.formGroup.controls['licenseplate'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'License plate', false)
,
    packaging: new FieldModel(new TextBoxModel(this.formGroup.controls['packaging'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Packaging', false)
,
    expected_packaged_amount: new FieldModel(new TextBoxModel(this.formGroup.controls['expected_packaged_amount'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Expected amount', false)
,
    actual_amount: new FieldModel(new TextBoxModel(this.formGroup.controls['actual_amount'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Actual amount', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Expected', false, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      count_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Counted', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_count_tasks_count_tasks_list_inParams_countTaskId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.taskId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_count_tasks', { read: FootPrintManager_count_tasks_listComponent }) $tabs_count_tasks: FootPrintManager_count_tasks_listComponent;
    //#endregion tabs children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    material: this.fields.material.control.valueChanges
    ,
    vendorlot: this.fields.vendorlot.control.valueChanges
    ,
    lot: this.fields.lot.control.valueChanges
    ,
    location: this.fields.location.control.valueChanges
    ,
    licenseplate: this.fields.licenseplate.control.valueChanges
    ,
    packaging: this.fields.packaging.control.valueChanges
    ,
    expected_packaged_amount: this.fields.expected_packaged_amount.control.valueChanges
    ,
    actual_amount: this.fields.actual_amount.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.count_tasks,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.taskId)) {
        this.$missingRequiredInParams.push('taskId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'count_task_editor';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      taskId:  $editor.inParams.taskId 
    };

    const data = await this.datasources.InventoryCounts.ds_count_task_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.material.control as TextBoxModel).reset($editor.entity.Material?.LookupCode);
    (this.fields.vendorlot.control as TextBoxModel).reset($editor.entity.VendorLot?.LookupCode);
    (this.fields.lot.control as TextBoxModel).reset($editor.entity.Lot?.LookupCode);
    (this.fields.location.control as TextBoxModel).reset($editor.entity.ExpectedSourceLocation?.Name);
    (this.fields.licenseplate.control as TextBoxModel).reset($editor.entity.ExpectedSourceLicensePlate?.LookupCode);
    (this.fields.packaging.control as TextBoxModel).reset($editor.entity.ExpectedPackagedPack?.Name);
    (this.fields.expected_packaged_amount.control as TextBoxModel).reset(($editor.entity.ExpectedPackagedAmount ?? $editor.entity.InventoryCountDiscrepancies[0]?.ExpectedAmount ?? '').toString());
    (this.fields.actual_amount.control as TextBoxModel).reset($utils.isDefined($editor.entity.InventoryCountDiscrepancies[0]) ? ($editor.entity.InventoryCountDiscrepancies[0]?.ActualAmount ?? '').toString() : ($editor.entity.ActualPackagedAmount ?? '').toString());

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_count_tasks') {
        if (!isNil(this.$tabs_count_tasks) && !this.tabs.count_tasks.hidden) {
          this.$tabs_count_tasks.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .material
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .vendorlot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .licenseplate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .expected_packaged_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .actual_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_count_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Initialize
  $editor.title = `Count ${$editor.inParams.taskId}`;
  
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_count_task_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Hide blind fields
  if (!$utils.isDefined($editor.entity.Material)) {
      $editor.fields.material.hidden = true;
  }
  
  if (!$utils.isDefined($editor.entity.VendorLot)) {
      $editor.fields.vendorlot.hidden = true;
  }
  
  if (!$utils.isDefined($editor.entity.Lot)) {
      $editor.fields.lot.hidden = true;
  }
  
  if (!$utils.isDefined($editor.entity.ExpectedSourceLocation)) {
      $editor.fields.location.hidden = true;
  }
  
  if (!$utils.isDefined($editor.entity.ExpectedSourceLicensePlate)) {
      $editor.fields.licenseplate.hidden = true;
  }
  
  if (!$utils.isDefined($editor.entity.ExpectedPackagedPack)) {
      $editor.fields.packaging.hidden = true;
  }
  
  if (!$utils.isDefined($editor.entity.ExpectedPackagedAmount)) {
      $editor.fields.expected_packaged_amount.hidden = true;
  }
  
  if ($editor.entity.Status.Id === 2 && ($editor.entity.InventoryCountDiscrepancies[0]?.StatusId ?? 3) === 3) {
      $editor.fields.actual_amount.hidden = false;
  }
  else {
      $editor.fields.actual_amount.hidden = true;
  }
  }
  //#endregion private flows
}
