import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { FootPrintManager_pallet_classes_dd_singleComponent } from './FootPrintManager.pallet_classes_dd_single.component'


interface IFootPrintManager_pallet_transactions_gridComponentEntity {
Id?: number, AccountId?: number, Amount?: number, CarrierId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Date?: string, Employee?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, PalletClassId?: number, ProjectId?: number, Reference?: string, ReferenceCarrierId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OrderClass?: { Id?: number, OrderClassTypeId?: number, OrderTypeId?: number } } }[] }, Account?: { Name?: string }, Carrier?: { Name?: string }, PalletClass?: { Name?: string }, Project?: { LookupCode?: string }}

interface IFootPrintManager_pallet_transactions_gridComponentInParams {
  project_id?: number, pallet_class_id?: number, project_lookup_code?: string, pallet_class_name?: string}


class FootPrintManager_pallet_transactions_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_pallet_transactions_gridComponent;
  entity: IFootPrintManager_pallet_transactions_gridComponentEntity;


  options: { edit_transaction: ButtonModel } = {
    edit_transaction: new ButtonModel('edit_transaction', new ButtonStyles(null, null), false, 'Edit transaction', '')

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    Project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Order: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    PalletClass: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Employee: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    CreatedSysDateTime: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    CreatedSysUser: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ModifiedSysDateTime: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ModifiedSysUser: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_pallet_transactions_gridComponent, entity: IFootPrintManager_pallet_transactions_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_pallet_transactions_gridComponent, entity?: IFootPrintManager_pallet_transactions_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      pallet_class_ids:  $grid.filters.pallet_class.control.value ? [$grid.filters.pallet_class.control.value] : null ,
      project_ids:  $grid.filters.project.control.value ? [$grid.filters.project.control.value] : null ,
      account_ids:  null ,
      carrier_ids:  null ,
      shipment_ids:  null ,
      full_text_search:  $grid.fullTextSearch ,
      date_from:  $grid.filters.date_from.control.value ,
      date_to:  $grid.filters.date_to.control.value ,
    };
    const data = await this.datasources.PalletTransactions.ds_pallet_transactions_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Project.displayControl as TextModel).text = $row.entity.Project?.LookupCode;
    (this.cells.PalletClass.displayControl as TextModel).text = $row.entity.PalletClass?.Name;
    (this.cells.Amount.displayControl as TextModel).text = $row.entity.Amount?.toLocaleString();
    (this.cells.Reference.displayControl as TextModel).text = $row.entity.Reference;
    (this.cells.Notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.Employee.displayControl as TextModel).text = $row.entity.Employee;
    (this.cells.Date.displayControl as TextModel).text = $row.entity.Date?.toString();
    (this.cells.CreatedSysDateTime.displayControl as TextModel).text = $row.entity.CreatedSysDateTime?.toString();
    (this.cells.CreatedSysUser.displayControl as TextModel).text = $row.entity.CreatedSysUser;
    (this.cells.ModifiedSysDateTime.displayControl as TextModel).text = $row.entity.ModifiedSysDateTime?.toString();
    (this.cells.ModifiedSysUser.displayControl as TextModel).text = $row.entity.ModifiedSysUser;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'Order' : {
        this.on_order_click();
        break;
      }
    }
  }

  //#region private flows
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $row: FootPrintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootPrintManager_pallet_transactions_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/14/2024
  
  /*
  let Id = $row.entity.Id;
  let ProjectId: number = $row.entity.ProjectId ?? $grid.inParams.project_id;
  let ShipmentId: number = $row.entity.ShipmentId ?? $grid.inParams.shipment_id;
  
  let Amount: number = $row.cells.Amount.editControl.value;
  let Reference: string = $row.cells.Reference.editControl.value;
  let Notes: string = $row.cells.Notes.editControl.value;
  
  let PalletClassId: number = $row.cells.PalletClass.editControl.value ?? $grid.inParams.pallet_class_id;
  
  if (!$utils.isDefined(ProjectId)) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `The project cannot be null!`);
      return;
  }
  
  if (!$utils.isDefined(PalletClassId)) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `The pallet class cannot be null!`);
      return;
  }
  
  if (!$utils.isDefined(Amount)) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `The pallet class cannot be null!`);
      return;
  }
  
  let payload: {
      remove?: boolean,
      id?: number,
      project_id: number,
      shipment_id?: number,
      carrier_id?: number,
      account_id?: number,
      pallet_class_id: number,
      reference?: string,
      notes?: string,
      amount: number
  }
  
  payload = {
      id: Id,
      amount: Amount,
      project_id: ProjectId,
      pallet_class_id: PalletClassId,
  }
  
  $row.cells.Direction.displayControl.text = (Amount > 0 ? "In" : (Amount < 0 ? "Out" : ""));
  
  if ($utils.isDefined(ShipmentId)) { payload.shipment_id = ShipmentId }
  if ($utils.isDefined(Reference)) { payload.reference = Reference.trim() }
  if ($utils.isDefined(Notes)) { payload.notes = Notes.trim() }
  
  try {
  
      let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
  
      if (!create_or_update.success) {
          await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
      }
  
      if (!$utils.isDefined(Id)) {
          $row.entity.Id = create_or_update.id;
      }
      $row.refresh();
  } catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) { targetError = targetError.error; };
      if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception! ${JSON.stringify(targetError)}` } };
      await $shell.PalletTransactions.openErrorDialog(`Failed!`, targetError.message);
  }
  */
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootPrintManager_pallet_transactions_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/10/2024
  
  // Set Date/Time Formats
  const format = `${$settings.PalletTransactions.DateFormat}, ${$settings.PalletTransactions.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined($row.entity.CreatedSysDateTime)) {
      $row.cells.CreatedSysDateTime.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime, format);
  }
  
  if ($utils.isDefined($row.entity.ModifiedSysDateTime)) {
      $row.cells.ModifiedSysDateTime.displayControl.text = $utils.date.format($row.entity.ModifiedSysDateTime, format);
  }
  
  if ($utils.isDefined($row.entity.Date)) {
      $row.cells.Date.displayControl.text = $utils.date.format($row.entity.Date, format);
  }
  
  let Amount: number = $row.entity.Amount ?? 0;
  
  $row.cells.Amount.displayControl.text = (Amount > 0 ? `${Amount} in` : (Amount < 0 ? `${-1 * Amount} out` : ""));
  
  /*
  if (Amount > 0) {
      $row.cells.Amount.displayControl.styles.setStyle("setClass", "creation");
  } else {
      $row.cells.Amount.displayControl.styles.setStyle("setClass", "important");
  }
  */
  
  let OrderLookups = $row.entity.Shipment?.OrderLookups;
  
  if ($utils.isDefined(OrderLookups)) {
      $row.cells.Order.displayControl.text = OrderLookups[0].Order.LookupCode
  }
  }
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootPrintManager_pallet_transactions_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/14/2024
  
  let PalletClassId = $grid.inParams.pallet_class_id;
  let ProjectId = $grid.inParams.project_id;
  
  $row.entity = { ProjectId: ProjectId, PalletClassId: PalletClassId, Amount: null }
  }
  on_pallet_class_click(event = null) {
    return this.on_pallet_class_clickInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_pallet_class_clickInternal(
    $row: FootPrintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootPrintManager_pallet_transactions_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/14/2024
  
  await $shell.FootPrintManager.openpallet_transactions_gridDialog({
      project_id: $row.entity.ProjectId,
      pallet_class_id: $row.entity.PalletClassId,
      project_lookup_code: $row.entity.Project.LookupCode,
      pallet_class_name: $row.entity.PalletClass.Name
  })
  
  $grid.refresh();
  }
  on_order_click(event = null) {
    return this.on_order_clickInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_clickInternal(
    $row: FootPrintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootPrintManager_pallet_transactions_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/09/2024
  
  let OrderLookups = $row.entity.Shipment?.OrderLookups;
  
  if ($utils.isDefined(OrderLookups)) {
  
      let Order = OrderLookups[0].Order;
  
      if (Order.OrderClass.OrderTypeId === 2) {
  
          $shell.FootPrintManager.opensales_order_editor({ orderId: Order.Id })
      } else {
  
          if (Order.OrderClass.OrderClassTypeId === 12) {
  
              $shell.FootPrintManager.openasn_order_editor({ order_id: Order.Id })
          } else {
  
              $shell.FootPrintManager.openpurchase_order_editor({ orderId: Order.Id })
          }
      }
  }
  
  }
  on_edit_transaction(event = null) {
    return this.on_edit_transactionInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_transactionInternal(
    $row: FootPrintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootPrintManager_pallet_transactions_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/25/2024
  
  await $shell.FootPrintManager.openpallet_transaction_creation_formDialog({
      project_id: $row.entity.ProjectId,
      shipment_id: $row.entity.ShipmentId,
      pallet_class_id: $row.entity.PalletClassId,
      order_lookup_code: $row.entity.Shipment?.OrderLookups[0]?.Order?.LookupCode,
      transaction_id: $row.entity.Id,
      amount: $row.entity.Amount
  }, "flyout")
  
  await $row.refresh()
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => FootPrintManager_pallet_classes_dd_singleComponent),
  ],
  selector: 'FootPrintManager-pallet_transactions_grid',
  templateUrl: './FootPrintManager.pallet_transactions_grid.component.html'
})
export class FootPrintManager_pallet_transactions_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_pallet_transactions_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_pallet_transactions_gridComponentInParams = { project_id: null, pallet_class_id: null, project_lookup_code: null, pallet_class_name: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       Order: new GridHeaderModel(new HeaderStyles(null, null), 'Order', false, false, null),       PalletClass: new GridHeaderModel(new HeaderStyles(null, null), 'Pallet class', false, false, null),       Amount: new GridHeaderModel(new HeaderStyles(null, null), 'Amount', false, false, null),       Reference: new GridHeaderModel(new HeaderStyles(null, null), 'Reference', false, false, null),       Notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       Employee: new GridHeaderModel(new HeaderStyles(null, null), 'Entered by', false, false, null),       Date: new GridHeaderModel(new HeaderStyles(null, null), 'Date and time', false, false, null),       CreatedSysDateTime: new GridHeaderModel(new HeaderStyles(null, null), 'Created date', false, false, null),       CreatedSysUser: new GridHeaderModel(new HeaderStyles(null, null), 'Created user', false, false, null),       ModifiedSysDateTime: new GridHeaderModel(new HeaderStyles(null, null), 'Modified date', false, false, null),       ModifiedSysUser: new GridHeaderModel(new HeaderStyles(null, null), 'Modified user', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_pallet_transactions_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('pallet_class_id') set $inParams_pallet_class_id(value: any) {
    this.inParams['pallet_class_id'] = value;
  }
  get $inParams_pallet_class_id(): any {
    return this.inParams['pallet_class_id'] ;
  }
  @Input('project_lookup_code') set $inParams_project_lookup_code(value: any) {
    this.inParams['project_lookup_code'] = value;
  }
  get $inParams_project_lookup_code(): any {
    return this.inParams['project_lookup_code'] ;
  }
  @Input('pallet_class_name') set $inParams_pallet_class_name(value: any) {
    this.inParams['pallet_class_name'] = value;
  }
  get $inParams_pallet_class_name(): any {
    return this.inParams['pallet_class_name'] ;
  }

  topToolbar = {
      new_transaction: new ToolModel(new ButtonModel('new_transaction', new ButtonStyles(null, null), false, 'New pallet transaction', 'icon-ic_fluent_add_20_regular')
    ),
      delete_selected: new ToolModel(new ButtonModel('delete_selected', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    pallet_class: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', false)
,
    pallet_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['pallet_class'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Pallet class', false)
,
    date_from: new FieldModel(new DateBoxModel(this.formGroup.controls['date_from'] as DatexFormControl, null, false, 'l, LT', 'datetime')
, new ControlContainerStyles(null, null), 'Date from', false)
,
    date_to: new FieldModel(new DateBoxModel(this.formGroup.controls['date_to'] as DatexFormControl, null, false, 'l, LT', 'datetime')
, new ControlContainerStyles(null, null), 'Date to', false)
,
  }

  //#region filters inParams
  get $fields_project_selector_inParams_statusId(): number {
    const $grid = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_pallet_class_selector_inParams_project_id(): number {
    const $grid = this;
    const $utils = this.utils;
    const expr = $grid.filters.project.control.value;
    
    return expr;
  }

  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    super();
    this.title = 'Pallet transactions grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.project.control as SelectBoxModel).reset($grid.inParams.project_id);
    (this.filters.pallet_class.control as SelectBoxModel).reset($grid.inParams.pallet_class_id);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      pallet_class_ids:  $grid.filters.pallet_class.control.value ? [$grid.filters.pallet_class.control.value] : null ,
      project_ids:  $grid.filters.project.control.value ? [$grid.filters.project.control.value] : null ,
      account_ids:  null ,
      carrier_ids:  null ,
      shipment_ids:  null ,
      full_text_search:  $grid.fullTextSearch ,
      date_from:  $grid.filters.date_from.control.value ,
      date_to:  $grid.filters.date_to.control.value ,
    };
    try {
    const data = await this.datasources.PalletTransactions.ds_pallet_transactions_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_pallet_transactions_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_select();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_pallet_transactions_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/09/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { reference: string, id: number, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.PalletTransactions.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.PalletTransactions.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let entity = row.entity;
              let current_error: string;
              let success = false;
  
              try {
  
                  let remove = (await $flows.PalletTransactions.pallet_transaction_create_or_update({ id: entity.Id, remove: true }))
  
                  success = remove.success;
                  current_error = remove.exceptions;
  
              } catch (error) {
                  let targetError = error;
                  while ($utils.isDefined(targetError?.error)) { targetError = targetError.error; }
                  if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception! ${JSON.stringify(targetError)}` } }
                  current_error = targetError.message;
              }
  
              if (success) {
                  deleted_records.push(entity.Id.toString())
              } else {
                  errors.push({ reference: entity.Id.toString(), id: entity.Id, errorMessages: [current_error] });
              }
          }
  
          if ($utils.isDefined(errors)) {
              await $shell.PalletTransactions.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: deleted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} Reference: ${error.reference}` })))
          }
      }
  }
  
  $grid.refresh();
  }
  on_select(event = null) {
    return this.on_selectInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_selectInternal(
    $grid: FootPrintManager_pallet_transactions_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/09/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_selected.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_pallet_transactions_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/24/2024
  
  $grid.topToolbar.delete_selected.control.readOnly = true;
  
  $grid.headers.CreatedSysDateTime.hidden = true;
  $grid.headers.CreatedSysUser.hidden = true;
  $grid.headers.ModifiedSysDateTime.hidden = true;
  $grid.headers.ModifiedSysUser.hidden = true;
  
  $grid.title = `Pallet transactions grid`;
  
  if ($utils.isDefined($grid.inParams.project_lookup_code) && !$utils.isDefined($grid.inParams.pallet_class_name)) {
      $grid.title = `Pallet transactions for project ${$grid.inParams.project_lookup_code}`
  }
  
  if ($utils.isDefined($grid.inParams.project_lookup_code) && $utils.isDefined($grid.inParams.pallet_class_name)) {
      $grid.title = `Pallet transactions for project ${$grid.inParams.project_lookup_code} and pallet class ${$grid.inParams.pallet_class_name}`
  }
  
  if ($utils.isDefined($grid.inParams.project_id)) {
      $grid.filters.project.hidden = true;
  }
  
  if ($utils.isDefined($grid.inParams.pallet_class_id)) {
      $grid.filters.pallet_class.hidden = true;
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_pallet_transactions_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/14/2024
  
  if ($utils.isDefined($grid.inParams.pallet_class_id) && $utils.isDefined($grid.inParams.project_id)) {
      $grid.headers.PalletClass.hidden = true;    
      $grid.headers.Project.hidden = true;    
  } else {
      $grid.headers.PalletClass.hidden = false;    
      $grid.headers.Project.hidden = false;    
  }
  }
  on_create_clicked(event = null) {
    return this.on_create_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_clickedInternal(
    $grid: FootPrintManager_pallet_transactions_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/07/2024
  
  await $shell.FootPrintManager.openpallet_transaction_creation_formDialog({
      project_id: $grid.filters.project.control.value,
      pallet_class_id: $grid.filters.pallet_class.control.value
  }, "flyout")
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
