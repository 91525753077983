<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="create_shipment" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_new_outbound_shipment($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator1" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="cross_dock" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_cross_dock($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="auto_load" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_auto_load_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="complete" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_complete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator2" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="on_delete" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="state">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.state.displayControl.styles.style"
          [ngClass]="row.cells.state.displayControl.styles.classes">{{row.cells.state.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate_count">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.licenseplate_count.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate_count.displayControl.styles.classes">{{row.cells.licenseplate_count.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="available_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.available_date.displayControl.styles.style"
          [ngClass]="row.cells.available_date.displayControl.styles.classes">{{row.cells.available_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['available_date_edit']"
        [format]="row.cells.available_date.editControl.format"
        [mode]="row.cells.available_date.editControl.mode"
        [ngStyle]="row.cells.available_date.editControl.styles.style"
        [ngClass]="row.cells.available_date.editControl.styles.classes">
    </app-datebox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="bill_of_lading">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.bill_of_lading.displayControl.styles.style"
          [ngClass]="row.cells.bill_of_lading.displayControl.styles.classes">{{row.cells.bill_of_lading.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['bill_of_lading_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.bill_of_lading.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.bill_of_lading.editControl.placeholder}}"
            [ngStyle]="row.cells.bill_of_lading.editControl.styles.style"
            [ngClass]="row.cells.bill_of_lading.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="reference_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.reference_number.displayControl.styles.style"
          [ngClass]="row.cells.reference_number.displayControl.styles.classes">{{row.cells.reference_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['reference_number_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.reference_number.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.reference_number.editControl.placeholder}}"
            [ngStyle]="row.cells.reference_number.editControl.styles.style"
            [ngClass]="row.cells.reference_number.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expected_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.expected_date.displayControl.styles.style"
          [ngClass]="row.cells.expected_date.displayControl.styles.classes">{{row.cells.expected_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['expected_date_edit']"
        [format]="row.cells.expected_date.editControl.format"
        [mode]="row.cells.expected_date.editControl.mode"
        [ngStyle]="row.cells.expected_date.editControl.styles.style"
        [ngClass]="row.cells.expected_date.editControl.styles.classes">
    </app-datebox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pickup_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.pickup_date.displayControl.styles.style"
          [ngClass]="row.cells.pickup_date.displayControl.styles.classes">{{row.cells.pickup_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['pickup_date_edit']"
        [format]="row.cells.pickup_date.editControl.format"
        [mode]="row.cells.pickup_date.editControl.mode"
        [ngStyle]="row.cells.pickup_date.editControl.styles.style"
        [ngClass]="row.cells.pickup_date.editControl.styles.classes">
    </app-datebox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="booking_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.booking_number.displayControl.styles.style"
          [ngClass]="row.cells.booking_number.displayControl.styles.classes">{{row.cells.booking_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['booking_number_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.booking_number.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.booking_number.editControl.placeholder}}"
            [ngStyle]="row.cells.booking_number.editControl.styles.style"
            [ngClass]="row.cells.booking_number.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="broker_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.broker_reference.displayControl.styles.style"
          [ngClass]="row.cells.broker_reference.displayControl.styles.classes">{{row.cells.broker_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['broker_reference_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.broker_reference.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.broker_reference.editControl.placeholder}}"
            [ngStyle]="row.cells.broker_reference.editControl.styles.style"
            [ngClass]="row.cells.broker_reference.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="trailer_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.trailer_id.displayControl.styles.style"
          [ngClass]="row.cells.trailer_id.displayControl.styles.classes">{{row.cells.trailer_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['trailer_id_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.trailer_id.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.trailer_id.editControl.placeholder}}"
            [ngStyle]="row.cells.trailer_id.editControl.styles.style"
            [ngClass]="row.cells.trailer_id.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="carrier">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.carrier.displayControl.styles.style"
          [ngClass]="row.cells.carrier.displayControl.styles.classes">{{row.cells.carrier.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Carriers-carriers_ownerscarriers_dd_single 
        data-cy="selector"
        [type]="row.cells.carrier.editControl.type"
        [formControl]="row.formGroup.controls['carrier_edit']"
        (displayTextChange)="row.cells.carrier.editControl.displayText=$event"
        [placeholder]="row.cells.carrier.editControl.placeholder"
        [styles]="row.cells.carrier.editControl.styles"
      [ownerId]="row.$fields_carrier_selector_inParams_ownerId"
    >
    </Carriers-carriers_ownerscarriers_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="carrier_service">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.carrier_service.displayControl.styles.style"
          [ngClass]="row.cells.carrier_service.displayControl.styles.classes">{{row.cells.carrier_service.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Carriers-carrierservicetype_dd_single 
        data-cy="selector"
        [type]="row.cells.carrier_service.editControl.type"
        [formControl]="row.formGroup.controls['carrier_service_edit']"
        (displayTextChange)="row.cells.carrier_service.editControl.displayText=$event"
        [placeholder]="row.cells.carrier_service.editControl.placeholder"
        [styles]="row.cells.carrier_service.editControl.styles"
      [carrierId]="row.$fields_carrier_service_selector_inParams_carrierId"
    >
    </Carriers-carrierservicetype_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['notes_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.notes.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.notes.editControl.placeholder}}"
            [ngStyle]="row.cells.notes.editControl.styles.style"
            [ngClass]="row.cells.notes.editControl.styles.classes"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
