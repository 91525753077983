import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'


interface IFootPrintManager_temperature_readings_gridComponentEntity {
Id?: number, DateCaptured?: string, Employee?: string, LicensePlateId?: number, LoadContainerId?: number, Notes?: string, ReadingPointId?: number, ReadingTypeId?: number, ShipmentId?: number, ShipmentLineId?: number, Temperature1?: number, Temperature2?: number, Temperature3?: number, LicensePlate?: { LookupCode?: string }, LoadContainer?: { LookupCode?: string }, ReadingPoint?: { Name?: string }, ReadingType?: { Name?: string }, Shipment?: { LookupCode?: string, OrderLookups?: { OrderId?: number }[] }, ShipmentLine?: { LineNumber?: number }, Temperature1MeasurementUnit?: { Name?: string, Short_name?: string }, Temperature2MeasurementUnit?: { Name?: string, Short_name?: string }, Temperature3MeasurementUnit?: { Name?: string, Short_name?: string }}

interface IFootPrintManager_temperature_readings_gridComponentInParams {
  loadContainerId?: number, shipmentId?: number, licensePlateId?: number, orderId?: number}


class FootPrintManager_temperature_readings_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_temperature_readings_gridComponent;
  entity: IFootPrintManager_temperature_readings_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    Id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    DateCaptured: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Employee: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Temperature1: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Temperature1MeasurementUnit_Short_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Temperature2: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Temperature2MeasurementUnit_Short_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Temperature3: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Temperature3MeasurementUnit_Short_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ReadingPoint_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ReadingType_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    LicensePlate_LookupCode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    LoadContainer_LookupCode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Shipment_LookupCode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ShipmentLine_LineNumber: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_temperature_readings_gridComponent, entity: IFootPrintManager_temperature_readings_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_temperature_readings_gridComponent, entity?: IFootPrintManager_temperature_readings_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      shipmentId:  $grid.inParams.shipmentId ,
      shipmentLineId:  null ,
      licensePlateId:  $grid.inParams.licensePlateId ,
      loadContainerId:  $grid.inParams.loadContainerId ,
      orderId:  $grid.inParams.orderId ,
    };
    const data = await this.datasources.TemperatureReadings.ds_temperature_readings_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Id.displayControl as TextModel).text = $row.entity.Id?.toString();
    (this.cells.DateCaptured.displayControl as TextModel).text = $row.entity.DateCaptured?.toString();
    (this.cells.Employee.displayControl as TextModel).text = $row.entity.Employee;
    (this.cells.Notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.Temperature1.displayControl as TextModel).text = $row.entity.Temperature1?.toString();
    (this.cells.Temperature1MeasurementUnit_Short_name.displayControl as TextModel).text = $row.entity.Temperature1MeasurementUnit?.Short_name;
    (this.cells.Temperature2.displayControl as TextModel).text = $row.entity.Temperature2?.toString();
    (this.cells.Temperature2MeasurementUnit_Short_name.displayControl as TextModel).text = $row.entity.Temperature2MeasurementUnit?.Short_name;
    (this.cells.Temperature3.displayControl as TextModel).text = $row.entity.Temperature3?.toString();
    (this.cells.Temperature3MeasurementUnit_Short_name.displayControl as TextModel).text = $row.entity.Temperature3MeasurementUnit?.Short_name;
    (this.cells.ReadingPoint_Name.displayControl as TextModel).text = $row.entity.ReadingPoint?.Name;
    (this.cells.ReadingType_Name.displayControl as TextModel).text = $row.entity.ReadingType?.Name;
    (this.cells.LicensePlate_LookupCode.displayControl as TextModel).text = $row.entity.LicensePlate?.LookupCode;
    (this.cells.LoadContainer_LookupCode.displayControl as TextModel).text = $row.entity.LoadContainer?.LookupCode;
    (this.cells.Shipment_LookupCode.displayControl as TextModel).text = $row.entity.Shipment?.LookupCode;
    (this.cells.ShipmentLine_LineNumber.displayControl as TextModel).text = $row.entity.ShipmentLine?.LineNumber?.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_temperature_readings_gridComponentRowModel,
  $grid: FootPrintManager_temperature_readings_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // O.Arias - 07/21/2023
  
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.DateCaptured)) {
          $row.cells.DateCaptured.displayControl.text = $utils.date.format($row.entity.DateCaptured, format);
      };
  };
  
  // Set the shipment id variable
  if (!$utils.isDefined($grid.vars.shipmentId)) {
      if ($utils.isDefined($row.entity.ShipmentId)) {
          $grid.vars.shipmentId = $row.entity.ShipmentId
      };
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-temperature_readings_grid',
  templateUrl: './FootPrintManager.temperature_readings_grid.component.html'
})
export class FootPrintManager_temperature_readings_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_temperature_readings_gridComponentEntity[];

  pageSize = 15;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);


  inParams: IFootPrintManager_temperature_readings_gridComponentInParams = { loadContainerId: null, shipmentId: null, licensePlateId: null, orderId: null };


  //#region Variables
  vars: { shipmentId?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Id: new GridHeaderModel(new HeaderStyles(null, null), 'ID', false, false, null),       DateCaptured: new GridHeaderModel(new HeaderStyles(null, null), 'Date captured', false, false, null),       Employee: new GridHeaderModel(new HeaderStyles(null, null), 'Employee', false, false, null),       Notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       Temperature1: new GridHeaderModel(new HeaderStyles(null, null), 'Temperature 1', false, false, null),       Temperature1MeasurementUnit_Short_name: new GridHeaderModel(new HeaderStyles(null, null), 'Unit 1', false, false, null),       Temperature2: new GridHeaderModel(new HeaderStyles(null, null), 'Temperature 2', false, false, null),       Temperature2MeasurementUnit_Short_name: new GridHeaderModel(new HeaderStyles(null, null), 'Unit 2', false, false, null),       Temperature3: new GridHeaderModel(new HeaderStyles(null, null), 'Temperature 3', false, false, null),       Temperature3MeasurementUnit_Short_name: new GridHeaderModel(new HeaderStyles(null, null), 'Unit 3', false, false, null),       ReadingPoint_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Reading point', false, false, null),       ReadingType_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Reading type', false, false, null),       LicensePlate_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'License plate', false, false, null),       LoadContainer_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Load container', false, false, null),       Shipment_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment', false, false, null),       ShipmentLine_LineNumber: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment line number', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_temperature_readings_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('loadContainerId') set $inParams_loadContainerId(value: any) {
    this.inParams['loadContainerId'] = value;
  }
  get $inParams_loadContainerId(): any {
    return this.inParams['loadContainerId'] ;
  }
  @Input('shipmentId') set $inParams_shipmentId(value: any) {
    this.inParams['shipmentId'] = value;
  }
  get $inParams_shipmentId(): any {
    return this.inParams['shipmentId'] ;
  }
  @Input('licensePlateId') set $inParams_licensePlateId(value: any) {
    this.inParams['licensePlateId'] = value;
  }
  get $inParams_licensePlateId(): any {
    return this.inParams['licensePlateId'] ;
  }
  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }

  topToolbar = {
      new_reading: new ToolModel(new ButtonModel('new_reading', new ButtonStyles(null, null), false, 'New reading', 'ms-Icon ms-Icon--CircleAddition')
    ),
      delete_reading: new ToolModel(new ButtonModel('delete_reading', new ButtonStyles(null, null), false, 'Delete selected', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    super();
    this.title = 'Temperature readings grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      shipmentId:  $grid.inParams.shipmentId ,
      shipmentLineId:  null ,
      licensePlateId:  $grid.inParams.licensePlateId ,
      loadContainerId:  $grid.inParams.loadContainerId ,
      orderId:  $grid.inParams.orderId ,
    };
    try {
    const data = await this.datasources.TemperatureReadings.ds_temperature_readings_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_temperature_readings_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_new_clicked(event = null) {
    return this.on_new_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_clickedInternal(
    $grid: FootPrintManager_temperature_readings_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/19/2023
  
  let shipmentId = $grid.inParams.shipmentId
  
  //Set the shipment id variable
  if (!$utils.isDefined(shipmentId)) {
  
      if ($utils.isDefined($grid.vars.shipmentId)) {
          shipmentId = $grid.vars.shipmentId;
      } else {
  
          if ($utils.isDefined($grid.inParams.orderId)) {
              let shipments = (await $datasources.SalesOrders.ds_get_shipments_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
              if ($utils.isDefined(shipments)) {
                  shipmentId = shipments[0].ShipmentId;
              };
          };
      };
  };
  
  await $shell.FootPrintManager.opentemperature_capture_formDialog({ shipmentId: shipmentId, loadContainerId: $grid.inParams.loadContainerId, licensePlateId: $grid.inParams.licensePlateId });
  
  $grid.refresh();
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_temperature_readings_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/19/2023
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Error removing readings', 'No readings selected.');
      return;
  } else {
  
      const candidates = [];
      const failures = [];
      const errors = [];
  
      for (const row of selectedRows) {
  
          //No validation needed, we'll populate the candidates right away
  
          candidates.push(row);
  
      };
  
      //No candidate
      if (candidates.length === 0) {
          const title = 'Error removing readings';
          const errorMessage = `Readings(s) ${failures.map(c => c.entity.Id).join(',')} cannot be removed`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
  
          const confirmCandidates = `Readings(s) ${candidates.map(c => c.entity.Id).join(',')} - will be deleted!`
  
          let confirm = false;
  
  
          if (failures.length >= 1) {
  
              const title = 'Some Readings(s) cannot be removed';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
  
          } else {
  
              const title = 'Remove the following readings(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
  
              const candidateSuccess = [];
              const candidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
  
                      await $flows.Utilities.crud_delete_flow({entitySet: "TemperatureReadings", id: candidate.entity.Id});
                      candidateSuccess.push(candidate);
  
                  } catch (error) {
                      candidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Readings ${candidate.entity.Id} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              //All succeeded
              if (candidateSuccess.length === candidates.length) {
  
                  const title = 'All Readings(s) removed';
                  const message = `Readings(s) ${candidateSuccess.map(c => c.entity.Id).join(',')} removed`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
  
              } else {
  
                  //All failures
                  if (candidateFailures.length === candidates.length) {
  
                      const title = 'All readings(s) failed to be removed';
                      const message = `Readings(s) ${candidateFailures.map(c => c.entity.Id).join(',')} could not be removed`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
  
                  } else {
  
                      const title = 'Some readings(s) could not be removed';
                      const success = `Readings(s) ${candidateSuccess.map(c => c.entity.Id).join(',')} were removed.`;
                      const errors = `The following readings(s) could not be removed: ${candidateFailures.map(c => c.entity.Id).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
  
                  };
              };
          };
      };
  };
  
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
