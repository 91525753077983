import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { CalendarDayViewComponent } from './components/calendar/calendar-day-view.component';
import { CalendarEvent } from './components/calendar/calendar-utils';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { DockAppointments_areas_dd_multiComponent } from './DockAppointments.areas_dd_multi.component'
import { DockAppointments_dock_doors_dd_multiComponent } from './DockAppointments.dock_doors_dd_multi.component'


import { FootPrintManager_dock_appointment_cardComponent } from './FootPrintManager.dock_appointment_card.component';

interface IFootPrintManager_dock_appointments_calendarComponentEntityData {
Id?: number, AssignedOn?: string, CheckedInOn?: string, CompletedOn?: string, InProcessOn?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, ScheduledProject?: { Id?: number, LookupCode?: string }, Status?: { Id?: number, Name?: string }, AssignedLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInOn?: { id?: number, convertedDate?: string }, convertedAssignedOn?: { id?: number, convertedDate?: string }, convertedInProcessOn?: { id?: number, convertedDate?: string }, convertedCompletedOn?: { id?: number, convertedDate?: string }, order?: { Id?: number, ItemEntityId?: number }, LocalScheduledArrival?: string, LocalScheduledDeparture?: string}

interface IFootPrintManager_dock_appointments_calendarComponentColumnData {
Id?: number, Name?: string}

interface IFootPrintManager_dock_appointments_calendarComponentInParams {
  warehouseIds: number[], typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], dockDoorIds?: number[], viewDate?: string}

interface IFootPrintManager_dock_appointments_calendarComponentOutParams {
  filters?: { viewDate?: string, dockDoorIds?: number[] }}

class FootPrintManager_dock_appointments_calendarComponentCalendarEventModel extends CalendarEvent<IFootPrintManager_dock_appointments_calendarComponentEntityData> {
  calendar: FootPrintManager_dock_appointments_calendarComponent;
  
  //#region eventContent inParams

  cacheValueFor_$eventContent_FootPrintManager_dock_appointment_card_inParams_dockAppointment: { lookupCode?: string, carrier?: string, referenceNumber?: string, status?: string, id?: number, typeId?: number, warehouseId?: number, orderId?: number };

  get $eventContent_FootPrintManager_dock_appointment_card_inParams_dockAppointment(): { lookupCode?: string, carrier?: string, referenceNumber?: string, status?: string, id?: number, typeId?: number, warehouseId?: number, orderId?: number } {

    const $calendar = this.calendar;
    const $utils = this.utils;
    const $calendarEvent = this;

    const expr = {lookupCode: $calendarEvent.entity.LookupCode, carrier: $calendarEvent.entity.ScheduledCarrier?.Name, referenceNumber:$calendarEvent.entity.ReferenceNumber, status:$calendarEvent.entity.Status?.Name, id:$calendarEvent.entity.Id, typeId:$calendarEvent.entity.TypeId, warehouseId: $calendar.inParams.warehouseIds[0], orderId: $calendarEvent.entity.order?.ItemEntityId};
    
    if(!isEqual(this.cacheValueFor_$eventContent_FootPrintManager_dock_appointment_card_inParams_dockAppointment, expr)) {
      this.cacheValueFor_$eventContent_FootPrintManager_dock_appointment_card_inParams_dockAppointment = expr;
    }
    return this.cacheValueFor_$eventContent_FootPrintManager_dock_appointment_card_inParams_dockAppointment;
  }
  //#endregion eventContent inParams


  constructor(
    private utils: UtilsService) {
      super();
  }
}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => DockAppointments_areas_dd_multiComponent),
    forwardRef(() => DockAppointments_dock_doors_dd_multiComponent),
    forwardRef(() => FootPrintManager_dock_appointment_cardComponent)
  ],
  selector: 'FootPrintManager-dock_appointments_calendar',
  templateUrl: './FootPrintManager.dock_appointments_calendar.component.html'
})
export class FootPrintManager_dock_appointments_calendarComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_dock_appointments_calendarComponentEntityData[];
  columns: IFootPrintManager_dock_appointments_calendarComponentColumnData[];
  calendarEvents: FootPrintManager_dock_appointments_calendarComponentCalendarEventModel[] = [];

  dayStartHour = 0;
  dayStartMinute = 0;
  dayEndHour = 23;
  dayEndMinute = 59;
  hourSegments = 1;
  hourSegmentHeight = 250;

  columnsPageSize = 6;
  columnsPageSkip = 0;
  columnsTotalCount = 0;
  calendarEventsTotalCount = 0;
  calendarEventsFetchedCount = 0;

  unscheduledEntities: IFootPrintManager_dock_appointments_calendarComponentEntityData[];
  unscheduledCalendarEvents: FootPrintManager_dock_appointments_calendarComponentCalendarEventModel[] = [];

  unscheduledCalendarEventsPageSize = 15;
  unscheduledCalendarEventsPageSkip = 0;
  unscheduledCalendarEventsTotalCount = 0;
  unscheduledCalendarEventsFullTextSearch : string;

  // TODO: 
  containerClass: string;


  fullTextSearch: string;

  inParams: IFootPrintManager_dock_appointments_calendarComponentInParams = { warehouseIds: [], typeIds: [], ownerId: null, projectId: null, carrierId: null, statusIds: [], dockDoorIds: [], viewDate: null };

  outParams: IFootPrintManager_dock_appointments_calendarComponentOutParams = { filters: { viewDate: null, dockDoorIds: [] } };

  //#region Variables
  vars: { timeZoneDates?: { timeZoneId?: string, viewDate?: string }[] } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ filters?: { viewDate?: string, dockDoorIds?: number[] } }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  @ViewChild('$calendarComponent', { read:  CalendarDayViewComponent}) $calendarComponent: CalendarDayViewComponent;

  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }
  @Input('typeIds') set $inParams_typeIds(value: any) {
    this.inParams['typeIds'] = value;
  }
  get $inParams_typeIds(): any {
    return this.inParams['typeIds'] ;
  }
  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('carrierId') set $inParams_carrierId(value: any) {
    this.inParams['carrierId'] = value;
  }
  get $inParams_carrierId(): any {
    return this.inParams['carrierId'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('dockDoorIds') set $inParams_dockDoorIds(value: any) {
    this.inParams['dockDoorIds'] = value;
  }
  get $inParams_dockDoorIds(): any {
    return this.inParams['dockDoorIds'] ;
  }
  @Input('viewDate') set $inParams_viewDate(value: any) {
    this.inParams['viewDate'] = value;
  }
  get $inParams_viewDate(): any {
    return this.inParams['viewDate'] ;
  }

  topToolbar = {
      on_previous_day: new ToolModel(new ButtonModel('on_previous_day', new ButtonStyles(null, null), false, 'Previous day ', 'ms-Icon ms-Icon--NavigateBack')
    ),
      on_next_day: new ToolModel(new ButtonModel('on_next_day', new ButtonStyles(null, null), false, 'Next day', 'ms-Icon ms-Icon--NavigateForward')
    ),
      on_today: new ToolModel(new ButtonModel('on_today', new ButtonStyles(null, null), false, 'Today', 'ms-Icon ms-Icon--DateTime')
    ),
      create_appointment: new ToolModel(new ButtonModel('create_appointment', new ButtonStyles(null, null), false, 'Create Appointment', 'ms-Icon ms-Icon--Add')
    )
  };

  formGroup: FormGroup = new FormGroup({
    view_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    area: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dock_doors: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    view_date: new FieldModel(new DateBoxModel(this.formGroup.controls['view_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Current date', false)
,
    area: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['area'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Area', false)
,
    dock_doors: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dock_doors'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Dock doors', false)
,
  }

  //#region filters inParams
  cacheValueFor_$fields_area_selector_inParams_warehouseIds: number[];
  get $fields_area_selector_inParams_warehouseIds(): number[] {
    const $calendar = this;
    const $utils = this.utils;
    const expr = $calendar.inParams.warehouseIds;
    
    if(!isEqual(this.cacheValueFor_$fields_area_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_area_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_area_selector_inParams_warehouseIds;
  }

  cacheValueFor_$fields_dock_doors_selector_inParams_warehouseIds: number[];
  get $fields_dock_doors_selector_inParams_warehouseIds(): number[] {
    const $calendar = this;
    const $utils = this.utils;
    const expr = $calendar.inParams.warehouseIds;
    
    if(!isEqual(this.cacheValueFor_$fields_dock_doors_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_dock_doors_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_dock_doors_selector_inParams_warehouseIds;
  }

  cacheValueFor_$fields_dock_doors_selector_inParams_parentIds: number[];
  get $fields_dock_doors_selector_inParams_parentIds(): number[] {
    const $calendar = this;
    const $utils = this.utils;
    const expr = $calendar.filters.area.control.value;
    
    if(!isEqual(this.cacheValueFor_$fields_dock_doors_selector_inParams_parentIds, expr)) {
      this.cacheValueFor_$fields_dock_doors_selector_inParams_parentIds = expr;
    }
    return this.cacheValueFor_$fields_dock_doors_selector_inParams_parentIds;
  }

  //#endregion filters inParams

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    this.title = 'Dock appointments';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseIds)) {
        this.$missingRequiredInParams.push('warehouseIds');
      }
  }

  initialized = false;

  async $init() {
    const $calendar = this;
    const $utils = this.utils;

    (this.filters.view_date.control as DateBoxModel).reset($calendar.inParams.viewDate);
    (this.filters.dock_doors.control as SelectBoxModel).reset($calendar.inParams.dockDoorIds);

    await this.on_init();
    this.initialized = true;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['view_date']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });
    this.formGroup
      .controls['area']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_area_changed();
      });
    this.formGroup
      .controls['dock_doors']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.columnsPageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $calendarEventPropertyChangeCallback (source: CalendarEvent, property: string): void {
    if (property === 'selected') {
      this.$calendarComponent.updateAllSelected();
    }
  }

  get viewDate(): string {
    const $calendar = this;
    const $utils = this.utils;
    return $calendar.filters.view_date.control.value;
  }

  $matchEventToColumn(
    $column: IFootPrintManager_dock_appointments_calendarComponentColumnData, 
    $calendarEvent: FootPrintManager_dock_appointments_calendarComponentCalendarEventModel): boolean {
    const $calendar = this;
    const $utils = this.utils;
    return $column.Id === ($calendarEvent.entity?.AssignedLocation?.Id ?? $calendarEvent.entity.ScheduledLocation?.Id);
  }

  async $dataLoad() {
    if(!this.formGroup.valid) {
      return;
    }

    await this.$dataLoadColumns();
    await this.$dataLoadEvents();
    this.unscheduledCalendarEventsPageSkip = 0;
    this.unscheduledEntities = [];
    await this.$dataLoadUnscheduledEvents();
    await this.$dataLoaded();
  }

  async $dataLoadColumns() {
    const $calendar = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.columnsPageSize,
      $skip: this.columnsPageSkip,
      locationIds:  $calendar.filters.dock_doors.control.value ,
      fullTextSearch:  null ,
      warehouseId:  $calendar.inParams.warehouseIds ,
      parentIds:  $calendar.filters.area.control.value ,
    };
    const data = await this.datasources.DockAppointments.ds_dockdoors_calendar.getList(inParams);
    this.columns = data.result;
    this.columnsTotalCount = data.totalCount;
  }

  async $dataLoadEvents() {
    const $calendar = this;
    const $utils = this.utils;
    const inParams = {
      locationIds:  $calendar.filters.dock_doors.control.value ,
      typeIds:  $calendar.inParams.typeIds ,
      ownerId:  $calendar.inParams.ownerId ,
      projectId:  $calendar.inParams.projectId ,
      carrierId:  $calendar.inParams.carrierId ,
      statusIds:  $calendar.inParams.statusIds ,
      fullTextSearch:  $calendar.fullTextSearch ,
      warehouseIds:  $calendar.inParams.warehouseIds ,
      timeZoneDates:  $calendar.vars.timeZoneDates as {timeZoneId: string, viewDate: string}[] ,
    };
    const data = await this.datasources.DockAppointments.ds_dock_appointments_calendar.getList(inParams);
    this.entities = data.result;
    this.calendarEventsTotalCount = data.totalCount;
    this.calendarEventsFetchedCount = this.entities?.length;
  }  

  async $dataLoadUnscheduledEvents() {
    const $calendar = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.unscheduledCalendarEventsPageSize,
      $skip: this.unscheduledCalendarEventsPageSkip,
      typeIds:  $calendar.inParams.typeIds ,
      ownerId:  $calendar.inParams.ownerId ,
      projectId:  $calendar.inParams.projectId ,
      carrierId:  $calendar.inParams.carrierId ,
      statusIds:  $calendar.inParams.statusIds ,
      fullTextSearch:  $calendar.unscheduledCalendarEventsFullTextSearch ,
      warehouseIds:  $calendar.inParams.warehouseIds ,
      timeZoneDates:  $calendar.vars.timeZoneDates as {timeZoneId: string, viewDate: string}[] ,
    };
    const data = await this.datasources.DockAppointments.ds_unscheduled_dock_appointments_calendar.getList(inParams);
    this.unscheduledEntities.push(...data.result);
    this.unscheduledCalendarEventsTotalCount = data.totalCount;
  }


  async $loadMoreUnscheduledEvents() {
    await this.$dataLoadUnscheduledEvents();
    await this.$dataLoaded();    
  }  

  async $onUnscheduleEventDropped($dropEvent) {
    const $event = {
      calendarEvent: $dropEvent.event as FootPrintManager_dock_appointments_calendarComponentCalendarEventModel};
    const $calendar = this;
    const $shell = this.shell;
    const $datasources = this.datasources;
    const $flows = this.flows;
    const $reports = this.reports;
    const $settings = this.settings;
    const $utils = this.utils;

    const result = await $shell.DockAppointments.openConfirmationDialog('Move dock appointment',
    `Move dock appointment to unscheduled list`, 'Move', 'Cancel');
if (result === true) {


    let payload: any = {};

    const dockAppointment = (await $datasources.DockAppointments.ds_get_dock_appointment_by_appointmentId.get({ appointmentId: $event.calendarEvent.entity.Id })).result;
    if ($utils.isDefined(dockAppointment)) {

        if (dockAppointment.StatusId == 0 || dockAppointment.StatusId == 1) { // Open or In-Yard

            payload.ScheduledLocationContainerId = null;

        }
        else {


            // Update dock appointment back to In-Yard

            payload.StatusId = 1;
            payload.ScheduledLocationContainerId = null;
            payload.AssignedlLocationContainerId = null;



        }

    await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $event.calendarEvent.entity.Id, entity: payload });

    $calendar.refresh();

    }


}
  }

  $unscheduledCalendarEventsTitle() : string {
      const $calendar = this;
      const $shell = this.shell;
      return 'Unassigned';
  }


  async $dataLoaded() {
    
    const calendarEvents = [];

    if(this.entities) {
      for (let entity of this.entities) {
        const $calendar = this;
        const $utils = this.utils;        
        const $calendarEvent = new FootPrintManager_dock_appointments_calendarComponentCalendarEventModel(this.utils);
        $calendarEvent.entity = entity;
        $calendarEvent.calendar = this;

        $calendarEvent.startDate = $calendarEvent.entity.convertedCheckInOn?.convertedDate ?? $calendarEvent.entity.convertedScheduledArrival?.convertedDate;
        $calendarEvent.endDate = $calendarEvent.entity.convertedCompletedOn?.convertedDate ?? $calendarEvent.entity.convertedScheduledDeparture?.convertedDate;
        $calendarEvent.title = $calendarEvent.entity.LookupCode;
        $calendarEvent.draggable = true;
        $calendarEvent.selected = false;

        let $isMatchEventToColumn = false;
        for (var column of this.columns) {
          if (this.$matchEventToColumn(column,$calendarEvent )) {
            $isMatchEventToColumn = true;
            break;
          }
        }
        if ($isMatchEventToColumn) {
          $calendarEvent.$propertyChangeCallback = this.$calendarEventPropertyChangeCallback.bind(this);
          calendarEvents.push($calendarEvent);
        }
      }
    }

    this.calendarEvents = calendarEvents;

    const unscheduledCalendarEvents = [];

    if(this.unscheduledEntities) {
      for (let entity of this.unscheduledEntities) {
        const $calendar = this;
        const $utils = this.utils;        
        const $calendarEvent = new FootPrintManager_dock_appointments_calendarComponentCalendarEventModel(this.utils);
        $calendarEvent.entity = entity;
        $calendarEvent.calendar = this;

        $calendarEvent.startDate = $calendarEvent.entity.convertedCheckInOn?.convertedDate ?? $calendarEvent.entity.convertedScheduledArrival?.convertedDate;
        $calendarEvent.endDate = $calendarEvent.entity.convertedCompletedOn?.convertedDate ?? $calendarEvent.entity.convertedScheduledDeparture?.convertedDate;
        $calendarEvent.title = $calendarEvent.entity.LookupCode;
        $calendarEvent.draggable = true;
        $calendarEvent.selected = false;

        //$calendarEvent.$propertyChangeCallback = this.$calendarEventPropertyChangeCallback.bind(this);
        unscheduledCalendarEvents.push($calendarEvent);
      }
    }

    this.unscheduledCalendarEvents = unscheduledCalendarEvents;

    await this.on_data_loaded();
  }

  async $columnsPageChange() {
    await this.$dataLoadColumns();
    await this.$dataLoaded();
  }

  $getHeaderHtml(
    $column: IFootPrintManager_dock_appointments_calendarComponentColumnData
    ): string {
    const $calendar = this;
    const $utils = this.utils;
    return `${$column.Name}`;
  }

  async $onHourSegmentClicked($orgEvent: {
    date: string;
    column: IFootPrintManager_dock_appointments_calendarComponentColumnData
    sourceEvent: MouseEvent;
  }) {
    console.log('onHourSegmentClicked', $orgEvent);

    const $event = {date: $orgEvent.date, column: $orgEvent.column};
    const $calendar = this;
    const $shell = this.shell;
    const $datasources = this.datasources;
    const $flows = this.flows;
    const $reports = this.reports;
    const $settings = this.settings;
    const $utils = this.utils;

    


const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({entity: "DockAppointment" })).nextId;


const locations = (await $datasources.DockAppointments.ds_get_locationcontainer_by_locationId.get({ locationId: $event.column.Id })).result;

if ($utils.isDefined(locations)) {

    const warehouseId = locations.WarehouseId;

    const dialogResult = (await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
        warehouseId: [warehouseId],
        lookupcode: nextAppointmentId.toString(),
        scheduledArrival: $event.date,
        scheduledDeparture: $utils.date.add(1, 'hour', $event.date),
        dockDoor: $event.column.Id,
        typeId: $calendar.inParams.typeIds[0],
        ownerId: $calendar.inParams.ownerId,
        projectId: $calendar.inParams.projectId,
        carrierId: $calendar.inParams.carrierId
    }));

    const userConfirm = dialogResult.confirm;
    if (userConfirm) {
        $calendar.refresh();
    }




}
else {
    throw new Error ('Unable to determine warehouse from the selected dock door.')
}

  }

  async $onHourSegmentEventDropped($orgEvent: {
    date: string, 
    column: IFootPrintManager_dock_appointments_calendarComponentColumnData,
    event: any
   }){
    const $event = {
      date: $orgEvent.date, 
      column: $orgEvent.column, 
      calendarEvent: $orgEvent.event as FootPrintManager_dock_appointments_calendarComponentCalendarEventModel};
    const $calendar = this;
    const $shell = this.shell;
    const $datasources = this.datasources;
    const $flows = this.flows;
    const $reports = this.reports;
    const $settings = this.settings;
    const $utils = this.utils;

    
const dockAppointment = (await $datasources.DockAppointments.ds_get_dock_appointment_by_appointmentId.get({
    appointmentId: $event.calendarEvent.entity.Id
})).result;

var timezone = dockAppointment.Warehouse.TimeZoneId

var warehouseId = dockAppointment.Warehouse.Id;
var warehouseName = dockAppointment.Warehouse.Name;

// Validate that the warehouse on the appointment matches the warehouse on the target dock door
const locationContainer = (await $datasources.DockAppointments.ds_get_locationcontainer_by_locationId.get({locationId: $event.column.Id})).result;
const locationWarehouseId = locationContainer.WarehouseId;
const locationWarehouseName = locationContainer.Warehouse.Name;

if (locationWarehouseId != warehouseId) {
    throw new Error (`Selected appointment belongs to warehouse ${warehouseName} while selected dock door belongs to warehouse ${locationWarehouseName}.`)
}

const startTime = new Date(dockAppointment.ScheduledArrival);
const endTime = new Date(dockAppointment.ScheduledDeparture);

// Calculate the difference between the two times in milliseconds
const diffInMilliseconds = endTime.getTime() - startTime.getTime();

// Calculate the difference in hours by dividing the difference in milliseconds by the number of milliseconds in an hour
const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

const newStartDate = $event.date;
const newEndDate = $utils.date.add(diffInHours, 'hour', newStartDate.toString());



if (dockAppointment.StatusId == 0) {
    if ($utils.isAllDefined(newStartDate, newEndDate)) {

        let resultStart = await $flows.DockAppointments.get_utc_date_by_timezone({
            dateTime: newStartDate,
            timezone: timezone
        });
        if ($utils.isDefined(resultStart)) {
            var scheduledArrivalUtc = resultStart.utcDate;

        }

        let resultEnd = await $flows.DockAppointments.get_utc_date_by_timezone({
            dateTime: newEndDate,
            timezone: timezone
        });
        if ($utils.isDefined(resultEnd)) {
            var scheduledDepartureUtc = resultEnd.utcDate;

        }

        const payload = {
            "ScheduledLocationContainerId": $event.column.Id,
            "ScheduledArrival": scheduledArrivalUtc,
            "ScheduledDeparture": scheduledDepartureUtc
        };

        try {

            await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $event.calendarEvent.entity.Id, entity: payload });



        }

        catch (error) {
            $shell.DockAppointments.showErrorDetails('Save', 'Error on save.', error);
            throw error; // to prevent displayMode 

        }


        $calendar.refresh();


    }
    else {
        throw new Error('Unable to calculate new start and end dates.')
    }


}

else {
    const payload = {
        "AssignedlLocationContainerId": $event.column.Id,
        "ScheduledLocationContainerId": $event.column.Id

    };

    try {

        await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $event.calendarEvent.entity.Id, entity: payload });

    }

    catch (error) {
        $shell.DockAppointments.showErrorDetails('Save', 'Error on save.', error);
        throw error; // to prevent displayMode 

    }


    $calendar.refresh();

}
  }


  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_next_day(event = null) {
    return this.on_next_dayInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_next_dayInternal(
    $calendar: FootPrintManager_dock_appointments_calendarComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $calendar.filters.view_date.control.value = $utils.date.add(1, 'day', $calendar.filters.view_date.control.value);
  $calendar.refresh();
  
  
  // Emit filters event
  await $calendar.on_filter_changed();
  }
  on_previous_day(event = null) {
    return this.on_previous_dayInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_previous_dayInternal(
    $calendar: FootPrintManager_dock_appointments_calendarComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $calendar.filters.view_date.control.value = $utils.date.add(-1, 'day', $calendar.filters.view_date.control.value);
  $calendar.refresh();
  
  // Emit filters event
  await $calendar.on_filter_changed();
  }
  on_today(event = null) {
    return this.on_todayInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_todayInternal(
    $calendar: FootPrintManager_dock_appointments_calendarComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $calendar.filters.view_date.control.value = $utils.date.now();
  $calendar.refresh();
  
  
  // Emit filters event
  await $calendar.on_filter_changed();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $calendar: FootPrintManager_dock_appointments_calendarComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  // Clear dock door filters
  $calendar.filters.dock_doors.control.value = $calendar.inParams.dockDoorIds;
  
  // Default current date
  $calendar.filters.view_date.control.value = $calendar.inParams.viewDate ?? $utils.date.now();
  
  // Emit filters event
  await $calendar.on_filter_changed();
  
  
  // Set Date/Time Formats
  const format = `${$settings.DockAppointments.DateFormat}`;
  $calendar.filters.view_date.control.format = format;
  
  
  }
  on_create_appointment_clicked(event = null) {
    return this.on_create_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_appointment_clickedInternal(
    $calendar: FootPrintManager_dock_appointments_calendarComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($calendar.inParams.typeIds.length > 1){
      $calendar.inParams.typeIds = [];
  }
  
  const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
  
  const dialogResult = (await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
      warehouseId: $calendar.inParams.warehouseIds,
      lookupcode: nextAppointmentId.toString(),
      scheduledArrival: $utils.date.now(),
      scheduledDeparture: $utils.date.add(1, 'hour', $utils.date.now()),
      typeId: $calendar.inParams.typeIds[0],
      ownerId: $calendar.inParams.ownerId,
      projectId: $calendar.inParams.projectId,
      carrierId: $calendar.inParams.carrierId
  }));
  
  const userConfirm = dialogResult.confirm;
  if (userConfirm){
       $calendar.refresh();
  }
  
  
  }
  on_filter_changed(event = null) {
    return this.on_filter_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_filter_changedInternal(
    $calendar: FootPrintManager_dock_appointments_calendarComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Update and emit output parameters
  $calendar.outParams.filters.viewDate = $calendar.filters.view_date.control.value;
  $calendar.outParams.filters.dockDoorIds = $calendar.filters.dock_doors.control.value;
  
  $calendar.events.outParamsChange.emit();
  
  // Convert date by warehouses timezones
  if ($utils.isDefined($calendar.filters.view_date.control.value)) {
      $calendar.vars.timeZoneDates = (await $flows.DockAppointments.convert_dates_by_warehouse_timezones({ warehouseIds: $calendar.inParams.warehouseIds, dates: { viewDate: $utils.date.startOf('day', $calendar.filters.view_date.control.value) } })).timeZoneDates?.map(tz => ({ timeZoneId: tz.timeZoneId, viewDate: tz.dates.viewDate }));
  }
  else {
      $calendar.vars.timeZoneDates = [];
  }
  
  $calendar.refresh();
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $calendar: FootPrintManager_dock_appointments_calendarComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const dateSelection = $utils.date.format($calendar.filters.view_date.control.value, 'MM/DD/YY');
  const currentDate =  $utils.date.format($utils.date.now(), 'MM/DD/YY');
  
  console.log(dateSelection);
  console.log(currentDate);
  
  if (dateSelection == currentDate) {
  
      $calendar.dayStartHour = (new Date().getHours() - 1) <= 0 ? 0 : (new Date().getHours() - 1);
  
  }
  else {
  
      $calendar.dayStartHour = 0;
  }
  
  }
  on_area_changed(event = null) {
    return this.on_area_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_area_changedInternal(
    $calendar: FootPrintManager_dock_appointments_calendarComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Clear dock doors
  $calendar.filters.dock_doors.control.value = null;
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }

}
