import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Returns_ShellService, EModalSize, EToasterType, EToasterPosition } from './Returns.shell.service';
import { Returns_OperationService } from './Returns.operation.service';
import { Returns_DatasourceService } from './Returns.datasource.index';
import { Returns_FlowService } from './Returns.flow.index';
import { Returns_ReportService } from './Returns.report.index';
import { Returns_LocalizationService } from './Returns.localization.service';
import { Language } from './localization.service';
import { $types } from './Returns.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Returns-returns_library_home',
  templateUrl: './Returns.returns_library_home.component.html'
})
export class Returns_returns_library_homeComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    tomato: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    description: new FieldModel(new LabelModel(null, null)
, new ControlContainerStyles(null, null), 'Description', false)
,
    tomato: new FieldModel(new TextBoxModel(this.formGroup.controls['tomato'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Contents', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Returns Library', false, true, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    tomato: this.fields.tomato.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Returns_ShellService,
private datasources: Returns_DatasourceService,
private flows: Returns_FlowService,
private reports: Returns_ReportService,
private localization: Returns_LocalizationService,
private operations: Returns_OperationService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Home';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.description.control as LabelModel).label = 'Library to access features to return inventory that has been shipped back into a warehouse.';
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .tomato
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: Returns_returns_library_homeComponent,
  
    $shell: Returns_ShellService,
    $datasources: Returns_DatasourceService,
    $flows: Returns_FlowService,
    $reports: Returns_ReportService,
    $settings: SettingsValuesService,
    $operations: Returns_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Returns_LocalizationService,
    $event: any
  ) {
  
  }
  //#endregion private flows
}
