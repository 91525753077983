import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintApiManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintApiManager.shell.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintApiManager.types'
import { FootPrintApiManager_integrations_dd_singleComponent } from './FootPrintApiManager.integrations_dd_single.component'
import { FootPrintApiManager_directions_dd_singleComponent } from './FootPrintApiManager.directions_dd_single.component'


interface IFootPrintApiManager_control_gridComponentEntity {
id?: string, type?: string, identifier?: string, direction?: string, count?: number, created_date?: string, modified_date?: string, integration_name?: string, user_name?: string}

interface IFootPrintApiManager_control_gridComponentInParams {
  integration_name?: string, user_name?: string}


class FootPrintApiManager_control_gridComponentRowModel extends GridRowModel {
  grid: FootPrintApiManager_control_gridComponent;
  entity: IFootPrintApiManager_control_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    integration_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    user_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    type_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    identifier_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    direction_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    count_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    integration_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['integration_name_edit'] as DatexFormControl, null, false, '')
      ),
    user_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['user_name_edit'] as DatexFormControl, null, false, '')
      ),
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['type_edit'] as DatexFormControl, null, false, '')
      ),
    identifier: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['identifier_edit'] as DatexFormControl, null, false, '')
      ),
    direction: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['direction_edit'] as DatexFormControl, null, false, '')
      ),
    count: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['count_edit'] as DatexFormControl, null, false, '', '')
      ),
    created_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modified_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintApiManager_ShellService,
private datasources: FootPrintApiManager_DatasourceService,
private flows: FootPrintApiManager_FlowService,
private reports: FootPrintApiManager_ReportService,
private localization: FootPrintApiManager_LocalizationService,
private operations: FootPrintApiManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintApiManager_control_gridComponent, entity: IFootPrintApiManager_control_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintApiManager_control_gridComponent, entity?: IFootPrintApiManager_control_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      type:  null ,
      direction:  $grid.filters.direction.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
      integration_name:  $grid.filters.integration_name.control.value ,
      user_name:  $grid.inParams.user_name ,
      current:  $grid.filters.only_current.control.value ,
    };
    const data = await this.datasources.FootPrintApiManager.ds_storage_control.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity.id;
    (this.cells.integration_name.displayControl as TextModel).text = $row.entity.integration_name;
    (this.cells.integration_name.editControl as TextBoxModel).reset($row.entity.integration_name);
    (this.cells.user_name.displayControl as TextModel).text = $row.entity.user_name;
    (this.cells.user_name.editControl as TextBoxModel).reset($row.entity.user_name);
    (this.cells.type.displayControl as TextModel).text = $row.entity.type;
    (this.cells.type.editControl as TextBoxModel).reset($row.entity.type);
    (this.cells.identifier.displayControl as TextModel).text = $row.entity.identifier;
    (this.cells.identifier.editControl as TextBoxModel).reset($row.entity.identifier);
    (this.cells.direction.displayControl as TextModel).text = $row.entity.direction;
    (this.cells.direction.editControl as TextBoxModel).reset($row.entity.direction);
    (this.cells.count.displayControl as TextModel).text = $row.entity.count?.toString();
    (this.cells.count.editControl as NumberBoxModel).reset($row.entity.count);

    await this.on_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_existing_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $row: FootPrintApiManager_control_gridComponentRowModel,
  $grid: FootPrintApiManager_control_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 12/07/2023
  
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.created_date)) {
          $row.cells.created_date.displayControl.text = $utils.date.format($row.entity.created_date, format);
      };
      if ($utils.isDefined($row.entity.modified_date)) {
          $row.cells.modified_date.displayControl.text = $utils.date.format($row.entity.modified_date, format);
      };
  };
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintApiManager_control_gridComponentRowModel,
  $grid: FootPrintApiManager_control_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/13/2024
  
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.count.editControl.value) &&
      $utils.isDefined($row.cells.direction.editControl.value) &&
      $utils.isDefined($row.cells.identifier.editControl.value) &&
      $utils.isDefined($row.cells.integration_name.editControl.value) &&
      $utils.isDefined($row.cells.type.editControl.value)
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootPrintApiManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  };
  
  if ($row.cells.count.editControl.isChanged ||
      $row.cells.direction.editControl.isChanged ||
      $row.cells.identifier.editControl.isChanged ||
      $row.cells.integration_name.editControl.isChanged ||
      $row.cells.user_name.editControl.isChanged ||
      $row.cells.type.editControl.isChanged) {
  
      let id: string = $row.entity?.id ?? "";
      let count: number = $row.cells.count.editControl.value;
      let direction: string = $row.cells.direction.editControl.value;
      let identifier: string = $row.cells.identifier.editControl.value;
      let integration_name: string = $grid.inParams.integration_name ?? "";
      let type: string = $row.cells.type.editControl.value;
      let user_name: string = $grid.inParams.user_name ?? "";
  
      if (integration_name === "") {
          integration_name = $row.cells.integration_name.editControl.value ?? "";
      };
  
      if (user_name === "") {
          user_name = $row.cells.user_name.editControl.value ?? "";
      };
  
      try {
  
          if (id === "" && integration_name === "") {
              throw new Error("You need to specify the integration to insert a control record! Please select it from the grid drop-down!")
          };
  
          let action: string = "Update";
  
          if (id === "") {
              action = "Write";
          };
  
          await $flows.FootPrintApiManager.storage_control({
              action: action,
              payload: {
                  id: id,
                  count: count,
                  direction: direction,
                  identifier: identifier,
                  type: type,
                  user_name: user_name
              },
              integration_name: integration_name
          });
  
          await $grid.refresh();
  
      } catch (error) {
          $shell.FootPrintApiManager.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      };
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintApiManager_integrations_dd_singleComponent),
    forwardRef(() => FootPrintApiManager_directions_dd_singleComponent),
  ],
  selector: 'FootPrintApiManager-control_grid',
  templateUrl: './FootPrintApiManager.control_grid.component.html'
})
export class FootPrintApiManager_control_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintApiManager_control_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintApiManager_control_gridComponentInParams = { integration_name: null, user_name: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'Id', false, false, null),       integration_name: new GridHeaderModel(new HeaderStyles(null, null), 'Integration name', true, false, null),       user_name: new GridHeaderModel(new HeaderStyles(null, null), 'User name', false, false, null),       type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', true, false, null),       identifier: new GridHeaderModel(new HeaderStyles(null, null), 'Identifier', true, false, null),       direction: new GridHeaderModel(new HeaderStyles(null, null), 'Direction', true, false, null),       count: new GridHeaderModel(new HeaderStyles(null, null), 'Count', true, false, null),       created_date: new GridHeaderModel(new HeaderStyles(null, null), 'Created', false, false, null),       modified_date: new GridHeaderModel(new HeaderStyles(null, null), 'Modified', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintApiManager_control_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('integration_name') set $inParams_integration_name(value: any) {
    this.inParams['integration_name'] = value;
  }
  get $inParams_integration_name(): any {
    return this.inParams['integration_name'] ;
  }
  @Input('user_name') set $inParams_user_name(value: any) {
    this.inParams['user_name'] = value;
  }
  get $inParams_user_name(): any {
    return this.inParams['user_name'] ;
  }

  topToolbar = {
      delete_message: new ToolModel(new ButtonModel('delete_message', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };

  formGroup: FormGroup = new FormGroup({
    only_current: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    integration_name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    direction: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    only_current: new FieldModel(new CheckBoxModel(this.formGroup.controls['only_current'] as DatexFormControl, null, false, 'Current')
, new ControlContainerStyles(null, null), '', false)
,
    integration_name: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['integration_name'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Integration', false)
,
    direction: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['direction'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Direction', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintApiManager_ShellService,
    private datasources: FootPrintApiManager_DatasourceService,
    private flows: FootPrintApiManager_FlowService,
    private reports: FootPrintApiManager_ReportService,
    private localization: FootPrintApiManager_LocalizationService,
    private operations: FootPrintApiManager_OperationService,
    ) {
    super();
    this.title = 'Control grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.only_current.control as CheckBoxModel).reset(true);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      type:  null ,
      direction:  $grid.filters.direction.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
      integration_name:  $grid.filters.integration_name.control.value ,
      user_name:  $grid.inParams.user_name ,
      current:  $grid.filters.only_current.control.value ,
    };
    try {
    const data = await this.datasources.FootPrintApiManager.ds_storage_control.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintApiManager_control_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintApiManager_control_gridComponentEntity) {
    const row = new FootPrintApiManager_control_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintApiManager_control_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/19/2024
  
  $grid.title = 'FootPrint API Manager Control Grid'
  $grid.headers.id.hidden = true;
  $grid.filters.only_current.control.value = true;
  
  let integration_name: string = $grid.inParams.integration_name ?? "";
  
  if (integration_name.toUpperCase() === "NULL") {
      integration_name = "";
  };
  
  if (integration_name !== "") {
      $grid.headers.integration_name.hidden = true;
      $grid.filters.integration_name.hidden = true;
      $grid.filters.integration_name.control.value = integration_name;
  };
  
  let user_name: string = $grid.inParams.user_name ?? "";
  
  if (user_name.toUpperCase() === "NULL") {
      user_name = "";
  };
  
  if (user_name !== "") {
      $grid.headers.user_name.hidden = true;
  };
  
  $grid.topToolbar.delete_message.hidden = true;
  $grid.canEdit = false;
  $grid.canAdd = false;
  
  if (await $operations.FootPrintApiManager.Enable_ApiManager_ConfigurationsGrid.isAuthorized()) {
  
      const admin = (await $flows.FootPrintApiManager.get_configuration({ name: "Admin", integration_name: integration_name })).value ?? "";
  
      if (admin.toUpperCase() === "TRUE") {
          $grid.topToolbar.delete_message.hidden = false;
          $grid.canEdit = true;
          $grid.canAdd = true;
      };
  
  } else {
      $grid.headers.count.hidden = true;
      $grid.headers.created_date.hidden = true;
      $grid.headers.direction.hidden = true;
      $grid.headers.id.hidden = true;
      $grid.headers.identifier.hidden = true;
      $grid.headers.integration_name.hidden = true;
      $grid.headers.modified_date.hidden = true;
      $grid.headers.type.hidden = true;
      $grid.headers.user_name.hidden = true;
      $shell.FootPrintApiManager.openErrorDialog("Not authorized!", "You are not authorized to view the FootPrint API Manager control records!")
  };
  
  $grid.headers.id.hidden = true;
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintApiManager_control_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/14/2024
  
  $grid.title = 'FootPrint API Manager Control Grid'
  
  let filter: string[] = [];
  
  filter = $grid.rows.filter(r => $utils.isDefined(r.entity.user_name))?.map(r => r.entity.id);
  if (filter.length === 0) {
      $grid.headers.user_name.hidden = true;
  };
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintApiManager_control_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  const selected_rows = $grid.selectedRows;
  
  let errors: { reference: string, id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.FootPrintApiManager.openErrorDialog('Operation error!', 'No records were selected!');
      return;
  } else {
  
      for (const row of selected_rows) {
  
          let current_error: string;
          let success = false;
          current_error = null;
  
          try {
  
              let entity = row.entity
  
              await control_delete({ "id": entity.id }, entity.integration_name);
  
              await log_add({
                  "process": "control_grid",
                  "level": 1,
                  "notes": `Deleted record! Payload: [${JSON.stringify(row.entity)}]`
              }, entity.integration_name);
  
              success = true;
  
          } catch (error) {
  
              let target_error = error;
  
              if (typeof target_error === "string") {
                  target_error = { message: target_error };
              } else {
                  while ($utils.isDefined(target_error.error)) {
                      target_error = target_error.error;
                  };
              };
  
              if (!$utils.isDefined(target_error.message)) {
                  target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
              };
  
              current_error = target_error.message;
          };
  
          if (success) {
              deleted_records.push(row.entity.id.toString())
          }
          // else {
          //     errors.push({
          //         reference: row.entity.reference, id: row.entity.id, errorMessages: [current_error]
          //     });
          // };
      };
  
      if ($utils.isDefined(errors)) {
          await $shell.FootPrintApiManager.openErrorDialog(
              `Revert record${selected_rows.length > 1 ? 's' : ''}`,
              deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: deleted_records })).formattedValue} successfully reverted.` : `No records were reverted.`,
              null,
              `Errors revert record${selected_rows.length > 1 ? 's' : ''}`,
              errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} Reference: ${error.reference}` })))
      };
  };
  
  const log_schema = (await $flows.FootPrintApiManager.storage_logs({ action: "", integration_name: "" })).payload;
  async function log_add(input: typeof log_schema[0], integration_name: string) {
      await $flows.FootPrintApiManager.storage_logs({ action: "Write", payload: input, integration_name: integration_name });
  };
  
  const control_schema = (await $flows.FootPrintApiManager.storage_control({ action: "", integration_name: "" })).payload;
  async function control_delete(input: typeof control_schema[0], integration_name: string) {
      await $flows.FootPrintApiManager.storage_control({ action: "Delete", payload: input, integration_name: integration_name });
  };
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintApiManager_control_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 02/05/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_message.control.readOnly = (selectedRowsCount > 0 ? false : true);
  
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootPrintApiManager_control_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  if ($grid.rows.length > 0) {
  
      let ids: string[] = [];
  
      if ($utils.isDefined($grid.selectedRows)) {
          if ($grid.selectedRows.length > 0) {
              ids = $grid.selectedRows.map(o => o.entity.id);
          };
      };
  
      let notes = $grid.fullTextSearch;
      let integration_name = $grid.inParams.integration_name;
      let user_name = $grid.inParams.user_name;
      let direction = $grid.filters.direction.control.value;
      let only_current = $grid.filters.only_current.control.value;
  
      let data = (await $datasources.FootPrintApiManager.ds_storage_control.getList({
          direction: direction,
          user_name: user_name,
          integration_name: integration_name,
          fullTextSearch: notes,
          current: only_current
      }));
  
      if (data.totalCount > 0) {
  
          let result = data.result;
  
          if (ids.length > 0) {
              result = result.filter(r => ids.indexOf(r.id) >= 0);
          };
  
          let controls = result.map(r => {
              return {
                  "Integration Name": r.integration_name,
                  "User Name": r.user_name,
                  "Type": r.type,
                  "Identifier": r.identifier,
                  "Direction": r.direction,
                  "Count": r.count,
                  "Created On (UTC)": r.created_date,
                  "Modified On (UTC)": r.modified_date
              }
          });
  
          let wb = $event;
          let ws = $utils.excel.json_to_sheet(controls);
          $utils.excel.book_append_sheet(wb, ws, "Control");
  
      } else {
          $shell.FootPrintApiManager.openInfoDialog('Error', 'The parameters did not return any records to export.')
      };
  } else {
      $shell.FootPrintApiManager.openInfoDialog('Error', 'There are no records to export!')
  };
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootPrintApiManager_control_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
