import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles, CardStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { DatexFormControl } from './models/datex-form-control';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'

import { FootPrintManager_licenseplatecontent_listComponent } from './FootPrintManager.licenseplatecontent_list.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_licenseplatecontent_listComponent),
  ],
  selector: 'FootPrintManager-licenseplate_with_content_card',
  templateUrl: './FootPrintManager.licenseplate_with_content_card.component.html'
})
export class FootPrintManager_licenseplate_with_content_cardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  styles = new CardStyles(null);

  inParams: { licenseplateId: number } = { licenseplateId: null };
  //#region Inputs
  @Input('licenseplateId') set $inParams_licenseplateId(v: number) {
    this.inParams.licenseplateId = v;
  }
  get $inParams_licenseplateId(): number {
    return this.inParams.licenseplateId;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  @Output()
  $refreshEvent = new EventEmitter();


  formGroupContent: FormGroup = new FormGroup({
  });


  content = {
    fields: {
        lookupcode: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'License plate', false)
,
        location: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Location', false)
,
    },
    fieldsets: {
  newGroup1: new FieldsetModel('', true, false, true),
}
  }


    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      licenseplatecontents: new TabItemModel(
        this.rootTabGroup, 
        'Contents', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_licenseplatecontents_licenseplatecontent_list_inParams_licenseplateId(): number {
      const $card = this;
      const $utils = this.utils;
      const expr = $card.inParams.licenseplateId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_licenseplatecontents', { read: FootPrintManager_licenseplatecontent_listComponent }) $tabs_licenseplatecontents: FootPrintManager_licenseplatecontent_listComponent;
    //#endregion tabs children
  
  //#region content fields inParams
  //#endregion content fields inParams









  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super(); 
    this.$subscribeFormControlValueChanges();

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.licenseplatecontents,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.licenseplateId)) {
        this.$missingRequiredInParams.push('licenseplateId');
      }
  }

  initialized = false;

  async $init() {

    const $card = this;
    const $utils = this.utils;




    this.initialized = true;

  }

  private $subscribeFormControlValueChanges() {

  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $card: FootPrintManager_licenseplate_with_content_cardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_licenseplate_hub({licenseplateId: $card.inParams.licenseplateId})
  }
  //#endregion private flows

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);

    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_licenseplatecontents') {
      if (!isNil(this.$tabs_licenseplatecontents) && !this.tabs.licenseplatecontents.hidden) {
        this.$tabs_licenseplatecontents.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }


}
