import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { DockAppointments_ShellService, EModalSize, EToasterType, EToasterPosition } from './DockAppointments.shell.service';
import { DockAppointments_OperationService } from './DockAppointments.operation.service';
import { DockAppointments_DatasourceService } from './DockAppointments.datasource.index';
import { DockAppointments_FlowService } from './DockAppointments.flow.index';
import { DockAppointments_ReportService } from './DockAppointments.report.index';
import { DockAppointments_LocalizationService } from './DockAppointments.localization.service';
import { Language } from './localization.service';
import { $types } from './DockAppointments.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'DockAppointments-total_appt_checked_in_by_date_widget',
  templateUrl: './DockAppointments.total_appt_checked_in_by_date_widget.component.html'
})
export class DockAppointments_total_appt_checked_in_by_date_widgetComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds?: number[], timeZoneDates?: { timeZoneId?: string, viewDate?: string }[] } = { typeIds: [], ownerId: null, projectId: null, carrierId: null, statusIds: [], warehouseIds: [], timeZoneDates: [] };
  //#region Inputs
  @Input('typeIds') set $inParams_typeIds(v: number[]) {
    this.inParams.typeIds = v;
  }
  get $inParams_typeIds(): number[] {
    return this.inParams.typeIds;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('carrierId') set $inParams_carrierId(v: number) {
    this.inParams.carrierId = v;
  }
  get $inParams_carrierId(): number {
    return this.inParams.carrierId;
  }
  @Input('statusIds') set $inParams_statusIds(v: number[]) {
    this.inParams.statusIds = v;
  }
  get $inParams_statusIds(): number[] {
    return this.inParams.statusIds;
  }
  @Input('warehouseIds') set $inParams_warehouseIds(v: number[]) {
    this.inParams.warehouseIds = v;
  }
  get $inParams_warehouseIds(): number[] {
    return this.inParams.warehouseIds;
  }
  @Input('timeZoneDates') set $inParams_timeZoneDates(v: { timeZoneId?: string, viewDate?: string }[]) {
    this.inParams.timeZoneDates = v;
  }
  get $inParams_timeZoneDates(): { timeZoneId?: string, viewDate?: string }[] {
    return this.inParams.timeZoneDates;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion  
  private entity: { TotalAppointments?: number };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: DockAppointments_ShellService,
private datasources: DockAppointments_DatasourceService,
private flows: DockAppointments_FlowService,
private reports: DockAppointments_ReportService,
private localization: DockAppointments_LocalizationService,
private operations: DockAppointments_OperationService,
) { 
    super();

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      typeIds:  $widget.inParams.typeIds ,
      ownerId:  $widget.inParams.ownerId ,
      projectId:  $widget.inParams.projectId ,
      carrierId:  $widget.inParams.carrierId ,
      statusIds:  $widget.inParams.statusIds ,
      warehouseIds:  $widget.inParams.warehouseIds ,
      timeZoneDates:  $widget.inParams.timeZoneDates as {timeZoneId: string, viewDate: string}[] 
    };

    const data = await this.datasources.DockAppointments.ds_total_appt_checked_in_by_date_widget.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    this.value = $widget.entity.TotalAppointments;

    await this.on_data_loaded();
    
    this.originalValue = $widget.entity.TotalAppointments;
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $widget: DockAppointments_total_appt_checked_in_by_date_widgetComponent,
  
    $shell: DockAppointments_ShellService,
    $datasources: DockAppointments_DatasourceService,
    $flows: DockAppointments_FlowService,
    $reports: DockAppointments_ReportService,
    $settings: SettingsValuesService,
    $operations: DockAppointments_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: DockAppointments_LocalizationService,
    $event: any
  ) {
  var apptCount = $widget.entity.TotalAppointments;
  
  if ($utils.isDefined(apptCount)) {
  
      if (apptCount >= 1 && apptCount <= 5) {
  
          $widget.styles.setMediumClass();
      }
      else {
          $widget.styles.setGoodClass();
      }
  
  }
  else {
      $widget.styles.setBadClass();
  }
  }
  //#endregion private flows
}
