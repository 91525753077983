import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { FootPrintManager_asn_order_creation_formComponent } from './FootPrintManager.asn_order_creation_form.component';
import { FootPrintManager_asn_order_transfer_outstanding_options_formComponent } from './FootPrintManager.asn_order_transfer_outstanding_options_form.component';
import { FootPrintManager_auto_load_options_formComponent } from './FootPrintManager.auto_load_options_form.component';
import { FootPrintManager_auto_pick_options_formComponent } from './FootPrintManager.auto_pick_options_form.component';
import { FootPrintManager_auto_receive_and_putaway_formComponent } from './FootPrintManager.auto_receive_and_putaway_form.component';
import { FootPrintManager_billing_cancellation_options_formComponent } from './FootPrintManager.billing_cancellation_options_form.component';
import { FootPrintManager_billing_contract_creation_formComponent } from './FootPrintManager.billing_contract_creation_form.component';
import { FootPrintManager_billing_contract_line_creation_formComponent } from './FootPrintManager.billing_contract_line_creation_form.component';
import { FootPrintManager_blind_return_project_selector_formComponent } from './FootPrintManager.blind_return_project_selector_form.component';
import { FootPrintManager_bulk_licenseplate_directed_move_formComponent } from './FootPrintManager.bulk_licenseplate_directed_move_form.component';
import { FootPrintManager_bulk_receiving_row_input_formComponent } from './FootPrintManager.bulk_receiving_row_input_form.component';
import { FootPrintManager_carrier_creation_formComponent } from './FootPrintManager.carrier_creation_form.component';
import { FootPrintManager_carrierservicetype_creation_formComponent } from './FootPrintManager.carrierservicetype_creation_form.component';
import { FootPrintManager_dock_appointment_assign_door_options_formComponent } from './FootPrintManager.dock_appointment_assign_door_options_form.component';
import { FootPrintManager_dock_appointment_cancel_options_formComponent } from './FootPrintManager.dock_appointment_cancel_options_form.component';
import { FootPrintManager_dock_appointment_check_in_options_formComponent } from './FootPrintManager.dock_appointment_check_in_options_form.component';
import { FootPrintManager_dock_appointment_creation_formComponent } from './FootPrintManager.dock_appointment_creation_form.component';
import { FootPrintManager_dock_door_creation_formComponent } from './FootPrintManager.dock_door_creation_form.component';
import { FootPrintManager_finalize_audit_formComponent } from './FootPrintManager.finalize_audit_form.component';
import { FootPrintManager_footprint_manager_homeComponent } from './FootPrintManager.footprint_manager_home.component';
import { FootPrintManager_generic_formComponent } from './FootPrintManager.generic_form.component';
import { FootPrintManager_get_average_serial_weights_formComponent } from './FootPrintManager.get_average_serial_weights_form.component';
import { FootPrintManager_get_licenseplate_formComponent } from './FootPrintManager.get_licenseplate_form.component';
import { FootPrintManager_inbound_orders_update_formComponent } from './FootPrintManager.inbound_orders_update_form.component';
import { FootPrintManager_inspection_select_task_status_formComponent } from './FootPrintManager.inspection_select_task_status_form.component';
import { FootPrintManager_inspection_task_creation_formComponent } from './FootPrintManager.inspection_task_creation_form.component';
import { FootPrintManager_integration_info_by_order_idComponent } from './FootPrintManager.integration_info_by_order_id.component';
import { FootPrintManager_inventory_adjustment_formComponent } from './FootPrintManager.inventory_adjustment_form.component';
import { FootPrintManager_inventory_and_licenseplate_move_formComponent } from './FootPrintManager.inventory_and_licenseplate_move_form.component';
import { FootPrintManager_inventory_creation_formComponent } from './FootPrintManager.inventory_creation_form.component';
import { FootPrintManager_inventory_transfer_creation_formComponent } from './FootPrintManager.inventory_transfer_creation_form.component';
import { FootPrintManager_inventory_transfer_formComponent } from './FootPrintManager.inventory_transfer_form.component';
import { FootPrintManager_inventory_type_selection_formComponent } from './FootPrintManager.inventory_type_selection_form.component';
import { FootPrintManager_invoice_creation_formComponent } from './FootPrintManager.invoice_creation_form.component';
import { FootPrintManager_invoice_creation_options_formComponent } from './FootPrintManager.invoice_creation_options_form.component';
import { FootPrintManager_invoice_status_change_options_formComponent } from './FootPrintManager.invoice_status_change_options_form.component';
import { FootPrintManager_load_container_create_formComponent } from './FootPrintManager.load_container_create_form.component';
import { FootPrintManager_location_creation_formComponent } from './FootPrintManager.location_creation_form.component';
import { FootPrintManager_location_replenishment_creation_formComponent } from './FootPrintManager.location_replenishment_creation_form.component';
import { FootPrintManager_lot_bulk_update_formComponent } from './FootPrintManager.lot_bulk_update_form.component';
import { FootPrintManager_lot_bulk_update_status_formComponent } from './FootPrintManager.lot_bulk_update_status_form.component';
import { FootPrintManager_lot_creation_formComponent } from './FootPrintManager.lot_creation_form.component';
import { FootPrintManager_lot_creation_form_with_materialComponent } from './FootPrintManager.lot_creation_form_with_material.component';
import { FootPrintManager_material_copy_formComponent } from './FootPrintManager.material_copy_form.component';
import { FootPrintManager_material_creation_formComponent } from './FootPrintManager.material_creation_form.component';
import { FootPrintManager_material_update_formComponent } from './FootPrintManager.material_update_form.component';
import { FootPrintManager_order_completion_options_formComponent } from './FootPrintManager.order_completion_options_form.component';
import { FootPrintManager_outbound_orders_update_formComponent } from './FootPrintManager.outbound_orders_update_form.component';
import { FootPrintManager_owner_creation_formComponent } from './FootPrintManager.owner_creation_form.component';
import { FootPrintManager_owner_update_formComponent } from './FootPrintManager.owner_update_form.component';
import { FootPrintManager_pack_verification_failure_formComponent } from './FootPrintManager.pack_verification_failure_form.component';
import { FootPrintManager_pallet_class_creation_formComponent } from './FootPrintManager.pallet_class_creation_form.component';
import { FootPrintManager_pallet_transaction_creation_formComponent } from './FootPrintManager.pallet_transaction_creation_form.component';
import { FootPrintManager_print_license_plate_labels_formComponent } from './FootPrintManager.print_license_plate_labels_form.component';
import { FootPrintManager_print_order_labels_formComponent } from './FootPrintManager.print_order_labels_form.component';
import { FootPrintManager_project_creation_formComponent } from './FootPrintManager.project_creation_form.component';
import { FootPrintManager_project_update_formComponent } from './FootPrintManager.project_update_form.component';
import { FootPrintManager_purchase_order_copy_formComponent } from './FootPrintManager.purchase_order_copy_form.component';
import { FootPrintManager_purchase_order_creation_formComponent } from './FootPrintManager.purchase_order_creation_form.component';
import { FootPrintManager_reason_code_creation_formComponent } from './FootPrintManager.reason_code_creation_form.component';
import { FootPrintManager_return_order_creation_formComponent } from './FootPrintManager.return_order_creation_form.component';
import { FootPrintManager_sales_order_cancellation_options_formComponent } from './FootPrintManager.sales_order_cancellation_options_form.component';
import { FootPrintManager_sales_order_copy_formComponent } from './FootPrintManager.sales_order_copy_form.component';
import { FootPrintManager_sales_order_creation_formComponent } from './FootPrintManager.sales_order_creation_form.component';
import { FootPrintManager_sales_order_pick_task_cancellation_options_formComponent } from './FootPrintManager.sales_order_pick_task_cancellation_options_form.component';
import { FootPrintManager_sales_orders_processing_options_formComponent } from './FootPrintManager.sales_orders_processing_options_form.component';
import { FootPrintManager_select_user_formComponent } from './FootPrintManager.select_user_form.component';
import { FootPrintManager_temperature_capture_formComponent } from './FootPrintManager.temperature_capture_form.component';
import { FootPrintManager_transfer_licenseplate_formComponent } from './FootPrintManager.transfer_licenseplate_form.component';
import { FootPrintManager_transfer_transload_licenseplate_formComponent } from './FootPrintManager.transfer_transload_licenseplate_form.component';
import { FootPrintManager_transload_order_creation_formComponent } from './FootPrintManager.transload_order_creation_form.component';
import { FootPrintManager_unloading_formComponent } from './FootPrintManager.unloading_form.component';
import { FootPrintManager_unpicking_formComponent } from './FootPrintManager.unpicking_form.component';
import { FootPrintManager_unreceiving_all_options_formComponent } from './FootPrintManager.unreceiving_all_options_form.component';
import { FootPrintManager_unreceiving_single_taskId_formComponent } from './FootPrintManager.unreceiving_single_taskId_form.component';
import { FootPrintManager_vendorlot_creation_formComponent } from './FootPrintManager.vendorlot_creation_form.component';
import { FootPrintManager_warehouse_creation_formComponent } from './FootPrintManager.warehouse_creation_form.component';
import { FootPrintManager_warehouse_transfer_creation_formComponent } from './FootPrintManager.warehouse_transfer_creation_form.component';
import { FootPrintManager_wave_update_formComponent } from './FootPrintManager.wave_update_form.component';
import { FootPrintManager_work_class_creation_formComponent } from './FootPrintManager.work_class_creation_form.component';
import { FootPrintManager_work_order_creation_formComponent } from './FootPrintManager.work_order_creation_form.component';
import { FootPrintManager_asn_order_editorComponent } from './FootPrintManager.asn_order_editor.component';
import { FootPrintManager_billing_contract_editorComponent } from './FootPrintManager.billing_contract_editor.component';
import { FootPrintManager_billing_contract_line_editorComponent } from './FootPrintManager.billing_contract_line_editor.component';
import { FootPrintManager_billing_record_editorComponent } from './FootPrintManager.billing_record_editor.component';
import { FootPrintManager_carrier_editorComponent } from './FootPrintManager.carrier_editor.component';
import { FootPrintManager_carrierservicetype_editorComponent } from './FootPrintManager.carrierservicetype_editor.component';
import { FootPrintManager_count_task_editorComponent } from './FootPrintManager.count_task_editor.component';
import { FootPrintManager_dock_appointment_editorComponent } from './FootPrintManager.dock_appointment_editor.component';
import { FootPrintManager_dock_door_editorComponent } from './FootPrintManager.dock_door_editor.component';
import { FootPrintManager_inspection_task_editorComponent } from './FootPrintManager.inspection_task_editor.component';
import { FootPrintManager_inventory_count_editorComponent } from './FootPrintManager.inventory_count_editor.component';
import { FootPrintManager_inventory_transfer_editorComponent } from './FootPrintManager.inventory_transfer_editor.component';
import { FootPrintManager_invoice_editorComponent } from './FootPrintManager.invoice_editor.component';
import { FootPrintManager_licenseplate_editorComponent } from './FootPrintManager.licenseplate_editor.component';
import { FootPrintManager_load_container_add_ordersComponent } from './FootPrintManager.load_container_add_orders.component';
import { FootPrintManager_load_container_editorComponent } from './FootPrintManager.load_container_editor.component';
import { FootPrintManager_location_editorComponent } from './FootPrintManager.location_editor.component';
import { FootPrintManager_location_replenishment_editorComponent } from './FootPrintManager.location_replenishment_editor.component';
import { FootPrintManager_lot_editorComponent } from './FootPrintManager.lot_editor.component';
import { FootPrintManager_material_editorComponent } from './FootPrintManager.material_editor.component';
import { FootPrintManager_material_kit_editorComponent } from './FootPrintManager.material_kit_editor.component';
import { FootPrintManager_material_substitutions_editorComponent } from './FootPrintManager.material_substitutions_editor.component';
import { FootPrintManager_owner_editorComponent } from './FootPrintManager.owner_editor.component';
import { FootPrintManager_pack_verification_completion_editorComponent } from './FootPrintManager.pack_verification_completion_editor.component';
import { FootPrintManager_pack_verification_shipping_container_properties_editorComponent } from './FootPrintManager.pack_verification_shipping_container_properties_editor.component';
import { FootPrintManager_pack_verification_verify_container_editorComponent } from './FootPrintManager.pack_verification_verify_container_editor.component';
import { FootPrintManager_project_editorComponent } from './FootPrintManager.project_editor.component';
import { FootPrintManager_purchase_order_editorComponent } from './FootPrintManager.purchase_order_editor.component';
import { FootPrintManager_reason_code_editorComponent } from './FootPrintManager.reason_code_editor.component';
import { FootPrintManager_return_order_editorComponent } from './FootPrintManager.return_order_editor.component';
import { FootPrintManager_sales_order_editorComponent } from './FootPrintManager.sales_order_editor.component';
import { FootPrintManager_sales_order_line_kit_editorComponent } from './FootPrintManager.sales_order_line_kit_editor.component';
import { FootPrintManager_serialnumber_editorComponent } from './FootPrintManager.serialnumber_editor.component';
import { FootPrintManager_shipping_container_editorComponent } from './FootPrintManager.shipping_container_editor.component';
import { FootPrintManager_task_assignment_editorComponent } from './FootPrintManager.task_assignment_editor.component';
import { FootPrintManager_transload_order_editorComponent } from './FootPrintManager.transload_order_editor.component';
import { FootPrintManager_warehouse_editorComponent } from './FootPrintManager.warehouse_editor.component';
import { FootPrintManager_warehouse_transfer_editorComponent } from './FootPrintManager.warehouse_transfer_editor.component';
import { FootPrintManager_warehouse_transfer_inbound_order_editorComponent } from './FootPrintManager.warehouse_transfer_inbound_order_editor.component';
import { FootPrintManager_warehouse_transfer_outbound_order_editorComponent } from './FootPrintManager.warehouse_transfer_outbound_order_editor.component';
import { FootPrintManager_wave_editorComponent } from './FootPrintManager.wave_editor.component';
import { FootPrintManager_work_class_editorComponent } from './FootPrintManager.work_class_editor.component';
import { FootPrintManager_work_order_editorComponent } from './FootPrintManager.work_order_editor.component';
import { FootPrintManager_accessorial_tasks_gridComponent } from './FootPrintManager.accessorial_tasks_grid.component';
import { FootPrintManager_accounts_gridComponent } from './FootPrintManager.accounts_grid.component';
import { FootPrintManager_activity_gridComponent } from './FootPrintManager.activity_grid.component';
import { FootPrintManager_asn_order_children_tasks_gridComponent } from './FootPrintManager.asn_order_children_tasks_grid.component';
import { FootPrintManager_asn_order_licenseplates_gridComponent } from './FootPrintManager.asn_order_licenseplates_grid.component';
import { FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent } from './FootPrintManager.asn_order_licenseplates_receiving_tasks_grid.component';
import { FootPrintManager_asn_order_receiving_tasks_gridComponent } from './FootPrintManager.asn_order_receiving_tasks_grid.component';
import { FootPrintManager_asn_order_tasks_gridComponent } from './FootPrintManager.asn_order_tasks_grid.component';
import { FootPrintManager_asn_orderlines_gridComponent } from './FootPrintManager.asn_orderlines_grid.component';
import { FootPrintManager_assigned_orders_on_wave_gridComponent } from './FootPrintManager.assigned_orders_on_wave_grid.component';
import { FootPrintManager_audit_serial_numbers_gridComponent } from './FootPrintManager.audit_serial_numbers_grid.component';
import { FootPrintManager_available_inventory_gridComponent } from './FootPrintManager.available_inventory_grid.component';
import { FootPrintManager_billing_contract_lines_gridComponent } from './FootPrintManager.billing_contract_lines_grid.component';
import { FootPrintManager_billing_contracts_gridComponent } from './FootPrintManager.billing_contracts_grid.component';
import { FootPrintManager_billing_projects_gridComponent } from './FootPrintManager.billing_projects_grid.component';
import { FootPrintManager_billing_rates_gridComponent } from './FootPrintManager.billing_rates_grid.component';
import { FootPrintManager_billing_records_by_invoiceline_gridComponent } from './FootPrintManager.billing_records_by_invoiceline_grid.component';
import { FootPrintManager_billing_records_gridComponent } from './FootPrintManager.billing_records_grid.component';
import { FootPrintManager_billing_supplemental_fees_gridComponent } from './FootPrintManager.billing_supplemental_fees_grid.component';
import { FootPrintManager_blind_shipping_container_return_tasks_gridComponent } from './FootPrintManager.blind_shipping_container_return_tasks_grid.component';
import { FootPrintManager_carriers_gridComponent } from './FootPrintManager.carriers_grid.component';
import { FootPrintManager_carrierservices_gridComponent } from './FootPrintManager.carrierservices_grid.component';
import { FootPrintManager_carrierservicetypes_gridComponent } from './FootPrintManager.carrierservicetypes_grid.component';
import { FootPrintManager_child_serialnumbers_gridComponent } from './FootPrintManager.child_serialnumbers_grid.component';
import { FootPrintManager_contact_addresses_gridComponent } from './FootPrintManager.contact_addresses_grid.component';
import { FootPrintManager_container_type_assignment_gridComponent } from './FootPrintManager.container_type_assignment_grid.component';
import { FootPrintManager_container_types_gridComponent } from './FootPrintManager.container_types_grid.component';
import { FootPrintManager_count_tasks_gridComponent } from './FootPrintManager.count_tasks_grid.component';
import { FootPrintManager_count_tasks_no_filter_gridComponent } from './FootPrintManager.count_tasks_no_filter_grid.component';
import { FootPrintManager_customer_return_shipping_containers_gridComponent } from './FootPrintManager.customer_return_shipping_containers_grid.component';
import { FootPrintManager_dock_appointment_types_gridComponent } from './FootPrintManager.dock_appointment_types_grid.component';
import { FootPrintManager_dock_appointments_dashboard_gridComponent } from './FootPrintManager.dock_appointments_dashboard_grid.component';
import { FootPrintManager_dock_appointments_gridComponent } from './FootPrintManager.dock_appointments_grid.component';
import { FootPrintManager_dock_appointments_selection_gridComponent } from './FootPrintManager.dock_appointments_selection_grid.component';
import { FootPrintManager_entity_attachments_gridComponent } from './FootPrintManager.entity_attachments_grid.component';
import { FootPrintManager_entity_import_requests_gridComponent } from './FootPrintManager.entity_import_requests_grid.component';
import { FootPrintManager_entity_user_defined_field_values_gridComponent } from './FootPrintManager.entity_user_defined_field_values_grid.component';
import { FootPrintManager_field_selector_gridComponent } from './FootPrintManager.field_selector_grid.component';
import { FootPrintManager_inbound_orders_gridComponent } from './FootPrintManager.inbound_orders_grid.component';
import { FootPrintManager_inspection_operation_codes_gridComponent } from './FootPrintManager.inspection_operation_codes_grid.component';
import { FootPrintManager_inspection_tasks_gridComponent } from './FootPrintManager.inspection_tasks_grid.component';
import { FootPrintManager_instructions_gridComponent } from './FootPrintManager.instructions_grid.component';
import { FootPrintManager_inventory_by_licenseplate_selection_gridComponent } from './FootPrintManager.inventory_by_licenseplate_selection_grid.component';
import { FootPrintManager_inventory_by_licenseplate_single_selection_gridComponent } from './FootPrintManager.inventory_by_licenseplate_single_selection_grid.component';
import { FootPrintManager_inventory_by_location_selection_gridComponent } from './FootPrintManager.inventory_by_location_selection_grid.component';
import { FootPrintManager_inventory_by_lot_selection_gridComponent } from './FootPrintManager.inventory_by_lot_selection_grid.component';
import { FootPrintManager_inventory_by_lot_single_selection_gridComponent } from './FootPrintManager.inventory_by_lot_single_selection_grid.component';
import { FootPrintManager_inventory_by_serialnumber_selection_gridComponent } from './FootPrintManager.inventory_by_serialnumber_selection_grid.component';
import { FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent } from './FootPrintManager.inventory_by_serialnumber_single_selection_grid.component';
import { FootPrintManager_inventory_counts_gridComponent } from './FootPrintManager.inventory_counts_grid.component';
import { FootPrintManager_inventory_licenseplate_gridComponent } from './FootPrintManager.inventory_licenseplate_grid.component';
import { FootPrintManager_inventory_licenseplate_snapshot_gridComponent } from './FootPrintManager.inventory_licenseplate_snapshot_grid.component';
import { FootPrintManager_inventory_location_gridComponent } from './FootPrintManager.inventory_location_grid.component';
import { FootPrintManager_inventory_lot_gridComponent } from './FootPrintManager.inventory_lot_grid.component';
import { FootPrintManager_inventory_lot_snapshot_gridComponent } from './FootPrintManager.inventory_lot_snapshot_grid.component';
import { FootPrintManager_inventory_material_gridComponent } from './FootPrintManager.inventory_material_grid.component';
import { FootPrintManager_inventory_material_snapshot_gridComponent } from './FootPrintManager.inventory_material_snapshot_grid.component';
import { FootPrintManager_inventory_measurement_units_gridComponent } from './FootPrintManager.inventory_measurement_units_grid.component';
import { FootPrintManager_inventory_parent_licenseplate_gridComponent } from './FootPrintManager.inventory_parent_licenseplate_grid.component';
import { FootPrintManager_inventory_statuses_gridComponent } from './FootPrintManager.inventory_statuses_grid.component';
import { FootPrintManager_inventory_tasks_gridComponent } from './FootPrintManager.inventory_tasks_grid.component';
import { FootPrintManager_inventory_transfer_lines_gridComponent } from './FootPrintManager.inventory_transfer_lines_grid.component';
import { FootPrintManager_inventory_transfers_gridComponent } from './FootPrintManager.inventory_transfers_grid.component';
import { FootPrintManager_invoice_lines_gridComponent } from './FootPrintManager.invoice_lines_grid.component';
import { FootPrintManager_invoices_gridComponent } from './FootPrintManager.invoices_grid.component';
import { FootPrintManager_licenseplate_gridComponent } from './FootPrintManager.licenseplate_grid.component';
import { FootPrintManager_load_container_orders_gridComponent } from './FootPrintManager.load_container_orders_grid.component';
import { FootPrintManager_load_container_unassigned_orders_gridComponent } from './FootPrintManager.load_container_unassigned_orders_grid.component';
import { FootPrintManager_load_containers_gridComponent } from './FootPrintManager.load_containers_grid.component';
import { FootPrintManager_location_projects_gridComponent } from './FootPrintManager.location_projects_grid.component';
import { FootPrintManager_locations_gridComponent } from './FootPrintManager.locations_grid.component';
import { FootPrintManager_locations_navigational_gridComponent } from './FootPrintManager.locations_navigational_grid.component';
import { FootPrintManager_locations_replenishments_gridComponent } from './FootPrintManager.locations_replenishments_grid.component';
import { FootPrintManager_locations_selector_gridComponent } from './FootPrintManager.locations_selector_grid.component';
import { FootPrintManager_lots_gridComponent } from './FootPrintManager.lots_grid.component';
import { FootPrintManager_lots_grid_flatComponent } from './FootPrintManager.lots_grid_flat.component';
import { FootPrintManager_lots_udf_gridComponent } from './FootPrintManager.lots_udf_grid.component';
import { FootPrintManager_manifesting_shipments_gridComponent } from './FootPrintManager.manifesting_shipments_grid.component';
import { FootPrintManager_manual_allocations_gridComponent } from './FootPrintManager.manual_allocations_grid.component';
import { FootPrintManager_material_components_gridComponent } from './FootPrintManager.material_components_grid.component';
import { FootPrintManager_material_kits_gridComponent } from './FootPrintManager.material_kits_grid.component';
import { FootPrintManager_material_packagings_by_project_gridComponent } from './FootPrintManager.material_packagings_by_project_grid.component';
import { FootPrintManager_material_packagings_gridComponent } from './FootPrintManager.material_packagings_grid.component';
import { FootPrintManager_material_substitutions_gridComponent } from './FootPrintManager.material_substitutions_grid.component';
import { FootPrintManager_materials_by_base_packaging_gridComponent } from './FootPrintManager.materials_by_base_packaging_grid.component';
import { FootPrintManager_materials_navigational_gridComponent } from './FootPrintManager.materials_navigational_grid.component';
import { FootPrintManager_materials_selector_gridComponent } from './FootPrintManager.materials_selector_grid.component';
import { FootPrintManager_materials_udf_gridComponent } from './FootPrintManager.materials_udf_grid.component';
import { FootPrintManager_orderaddresses_gridComponent } from './FootPrintManager.orderaddresses_grid.component';
import { FootPrintManager_outbound_orders_eligible_for_return_gridComponent } from './FootPrintManager.outbound_orders_eligible_for_return_grid.component';
import { FootPrintManager_outbound_orders_gridComponent } from './FootPrintManager.outbound_orders_grid.component';
import { FootPrintManager_owners_navigation_gridComponent } from './FootPrintManager.owners_navigation_grid.component';
import { FootPrintManager_ownersaccounts_gridComponent } from './FootPrintManager.ownersaccounts_grid.component';
import { FootPrintManager_ownerscarrierslookup_gridComponent } from './FootPrintManager.ownerscarrierslookup_grid.component';
import { FootPrintManager_pack_verification_instructions_gridComponent } from './FootPrintManager.pack_verification_instructions_grid.component';
import { FootPrintManager_pack_verification_shipping_container_contents_gridComponent } from './FootPrintManager.pack_verification_shipping_container_contents_grid.component';
import { FootPrintManager_pallet_classes_gridComponent } from './FootPrintManager.pallet_classes_grid.component';
import { FootPrintManager_pallet_inventory_by_class_gridComponent } from './FootPrintManager.pallet_inventory_by_class_grid.component';
import { FootPrintManager_pallet_inventory_by_project_gridComponent } from './FootPrintManager.pallet_inventory_by_project_grid.component';
import { FootPrintManager_pallet_transactions_gridComponent } from './FootPrintManager.pallet_transactions_grid.component';
import { FootPrintManager_pallet_transactions_shipment_gridComponent } from './FootPrintManager.pallet_transactions_shipment_grid.component';
import { FootPrintManager_pick_slips_gridComponent } from './FootPrintManager.pick_slips_grid.component';
import { FootPrintManager_pick_waves_expanded_gridComponent } from './FootPrintManager.pick_waves_expanded_grid.component';
import { FootPrintManager_pick_waves_gridComponent } from './FootPrintManager.pick_waves_grid.component';
import { FootPrintManager_projects_gridComponent } from './FootPrintManager.projects_grid.component';
import { FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent } from './FootPrintManager.purchase_order_bulk_receiving_by_taskId_grid.component';
import { FootPrintManager_purchase_order_children_tasks_gridComponent } from './FootPrintManager.purchase_order_children_tasks_grid.component';
import { FootPrintManager_purchase_order_lines_gridComponent } from './FootPrintManager.purchase_order_lines_grid.component';
import { FootPrintManager_purchase_order_received_inventory_gridComponent } from './FootPrintManager.purchase_order_received_inventory_grid.component';
import { FootPrintManager_purchase_order_receiving_gridComponent } from './FootPrintManager.purchase_order_receiving_grid.component';
import { FootPrintManager_purchase_order_tasks_gridComponent } from './FootPrintManager.purchase_order_tasks_grid.component';
import { FootPrintManager_reason_codes_gridComponent } from './FootPrintManager.reason_codes_grid.component';
import { FootPrintManager_replenishment_inventory_licenseplate_gridComponent } from './FootPrintManager.replenishment_inventory_licenseplate_grid.component';
import { FootPrintManager_replenishment_tasks_gridComponent } from './FootPrintManager.replenishment_tasks_grid.component';
import { FootPrintManager_return_order_lines_gridComponent } from './FootPrintManager.return_order_lines_grid.component';
import { FootPrintManager_return_orders_gridComponent } from './FootPrintManager.return_orders_grid.component';
import { FootPrintManager_return_shipping_containers_gridComponent } from './FootPrintManager.return_shipping_containers_grid.component';
import { FootPrintManager_sales_order_children_tasks_gridComponent } from './FootPrintManager.sales_order_children_tasks_grid.component';
import { FootPrintManager_sales_order_line_components_gridComponent } from './FootPrintManager.sales_order_line_components_grid.component';
import { FootPrintManager_sales_order_line_kit_gridComponent } from './FootPrintManager.sales_order_line_kit_grid.component';
import { FootPrintManager_sales_order_line_substitutions_gridComponent } from './FootPrintManager.sales_order_line_substitutions_grid.component';
import { FootPrintManager_sales_order_lines_gridComponent } from './FootPrintManager.sales_order_lines_grid.component';
import { FootPrintManager_sales_order_picking_gridComponent } from './FootPrintManager.sales_order_picking_grid.component';
import { FootPrintManager_sales_order_tasks_gridComponent } from './FootPrintManager.sales_order_tasks_grid.component';
import { FootPrintManager_scanned_values_gridComponent } from './FootPrintManager.scanned_values_grid.component';
import { FootPrintManager_serialnumbers_creation_gridComponent } from './FootPrintManager.serialnumbers_creation_grid.component';
import { FootPrintManager_serialnumbers_gridComponent } from './FootPrintManager.serialnumbers_grid.component';
import { FootPrintManager_serialnumbers_selection_gridComponent } from './FootPrintManager.serialnumbers_selection_grid.component';
import { FootPrintManager_shipping_container_tasks_child_gridComponent } from './FootPrintManager.shipping_container_tasks_child_grid.component';
import { FootPrintManager_shipping_container_tasks_gridComponent } from './FootPrintManager.shipping_container_tasks_grid.component';
import { FootPrintManager_shipping_containers_gridComponent } from './FootPrintManager.shipping_containers_grid.component';
import { FootPrintManager_shipping_containers_to_verify_gridComponent } from './FootPrintManager.shipping_containers_to_verify_grid.component';
import { FootPrintManager_shippingcontents_gridComponent } from './FootPrintManager.shippingcontents_grid.component';
import { FootPrintManager_storage_categories_gridComponent } from './FootPrintManager.storage_categories_grid.component';
import { FootPrintManager_storage_category_rules_gridComponent } from './FootPrintManager.storage_category_rules_grid.component';
import { FootPrintManager_submitted_survey_inputs_gridComponent } from './FootPrintManager.submitted_survey_inputs_grid.component';
import { FootPrintManager_submitted_surveys_gridComponent } from './FootPrintManager.submitted_surveys_grid.component';
import { FootPrintManager_support_tests_gridComponent } from './FootPrintManager.support_tests_grid.component';
import { FootPrintManager_survey_grid_input_values_gridComponent } from './FootPrintManager.survey_grid_input_values_grid.component';
import { FootPrintManager_task_assignment_gridComponent } from './FootPrintManager.task_assignment_grid.component';
import { FootPrintManager_temperature_capture_reading_points_gridComponent } from './FootPrintManager.temperature_capture_reading_points_grid.component';
import { FootPrintManager_temperature_capture_reading_types_gridComponent } from './FootPrintManager.temperature_capture_reading_types_grid.component';
import { FootPrintManager_temperature_readings_gridComponent } from './FootPrintManager.temperature_readings_grid.component';
import { FootPrintManager_transload_order_lines_gridComponent } from './FootPrintManager.transload_order_lines_grid.component';
import { FootPrintManager_transload_order_received_inventory_gridComponent } from './FootPrintManager.transload_order_received_inventory_grid.component';
import { FootPrintManager_transload_order_receiving_gridComponent } from './FootPrintManager.transload_order_receiving_grid.component';
import { FootPrintManager_transload_orders_gridComponent } from './FootPrintManager.transload_orders_grid.component';
import { FootPrintManager_transload_outbound_shipments_gridComponent } from './FootPrintManager.transload_outbound_shipments_grid.component';
import { FootPrintManager_vendorlots_selector_gridComponent } from './FootPrintManager.vendorlots_selector_grid.component';
import { FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent } from './FootPrintManager.warehouse_transfer_inbound_licenseplates_grid.component';
import { FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_gridComponent } from './FootPrintManager.warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid.component';
import { FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent } from './FootPrintManager.warehouse_transfer_inbound_order_receiving_tasks_grid.component';
import { FootPrintManager_warehouse_transfer_inbound_orderlines_gridComponent } from './FootPrintManager.warehouse_transfer_inbound_orderlines_grid.component';
import { FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent } from './FootPrintManager.warehouse_transfer_outbound_order_lines_grid.component';
import { FootPrintManager_warehouse_transfer_outbound_order_manual_allocations_gridComponent } from './FootPrintManager.warehouse_transfer_outbound_order_manual_allocations_grid.component';
import { FootPrintManager_warehouse_transfer_outbound_order_shippingcontents_gridComponent } from './FootPrintManager.warehouse_transfer_outbound_order_shippingcontents_grid.component';
import { FootPrintManager_warehouse_transfer_picking_gridComponent } from './FootPrintManager.warehouse_transfer_picking_grid.component';
import { FootPrintManager_warehouse_transfers_gridComponent } from './FootPrintManager.warehouse_transfers_grid.component';
import { FootPrintManager_warehouses_navigation_gridComponent } from './FootPrintManager.warehouses_navigation_grid.component';
import { FootPrintManager_wave_creation_gridComponent } from './FootPrintManager.wave_creation_grid.component';
import { FootPrintManager_work_class_operation_codes_gridComponent } from './FootPrintManager.work_class_operation_codes_grid.component';
import { FootPrintManager_work_class_users_gridComponent } from './FootPrintManager.work_class_users_grid.component';
import { FootPrintManager_work_classes_gridComponent } from './FootPrintManager.work_classes_grid.component';
import { FootPrintManager_work_orders_gridComponent } from './FootPrintManager.work_orders_grid.component';
import { FootPrintManager_activity_hubComponent } from './FootPrintManager.activity_hub.component';
import { FootPrintManager_audit_serial_numbers_hubComponent } from './FootPrintManager.audit_serial_numbers_hub.component';
import { FootPrintManager_billing_contracts_hubComponent } from './FootPrintManager.billing_contracts_hub.component';
import { FootPrintManager_billing_records_hubComponent } from './FootPrintManager.billing_records_hub.component';
import { FootPrintManager_blind_return_shipping_container_hubComponent } from './FootPrintManager.blind_return_shipping_container_hub.component';
import { FootPrintManager_configurations_inventory_hubComponent } from './FootPrintManager.configurations_inventory_hub.component';
import { FootPrintManager_configurations_storage_categories_hubComponent } from './FootPrintManager.configurations_storage_categories_hub.component';
import { FootPrintManager_dock_appointments_hubComponent } from './FootPrintManager.dock_appointments_hub.component';
import { FootPrintManager_dock_appointments_hubCloneComponent } from './FootPrintManager.dock_appointments_hubClone.component';
import { FootPrintManager_dock_doors_hubComponent } from './FootPrintManager.dock_doors_hub.component';
import { FootPrintManager_driver_check_out_hubComponent } from './FootPrintManager.driver_check_out_hub.component';
import { FootPrintManager_entity_import_hubComponent } from './FootPrintManager.entity_import_hub.component';
import { FootPrintManager_inbound_orders_hubComponent } from './FootPrintManager.inbound_orders_hub.component';
import { FootPrintManager_inspections_hubComponent } from './FootPrintManager.inspections_hub.component';
import { FootPrintManager_inventory_counts_hubComponent } from './FootPrintManager.inventory_counts_hub.component';
import { FootPrintManager_inventory_hubComponent } from './FootPrintManager.inventory_hub.component';
import { FootPrintManager_inventory_transfers_hubComponent } from './FootPrintManager.inventory_transfers_hub.component';
import { FootPrintManager_invoices_hubComponent } from './FootPrintManager.invoices_hub.component';
import { FootPrintManager_labor_management_hubComponent } from './FootPrintManager.labor_management_hub.component';
import { FootPrintManager_load_containers_hubComponent } from './FootPrintManager.load_containers_hub.component';
import { FootPrintManager_lots_hubComponent } from './FootPrintManager.lots_hub.component';
import { FootPrintManager_manifesting_hubComponent } from './FootPrintManager.manifesting_hub.component';
import { FootPrintManager_material_attachments_hubComponent } from './FootPrintManager.material_attachments_hub.component';
import { FootPrintManager_materials_hubComponent } from './FootPrintManager.materials_hub.component';
import { FootPrintManager_orders_attachments_hubComponent } from './FootPrintManager.orders_attachments_hub.component';
import { FootPrintManager_orders_hubComponent } from './FootPrintManager.orders_hub.component';
import { FootPrintManager_outbound_orders_hubComponent } from './FootPrintManager.outbound_orders_hub.component';
import { FootPrintManager_owners_hubComponent } from './FootPrintManager.owners_hub.component';
import { FootPrintManager_pack_verification_hubComponent } from './FootPrintManager.pack_verification_hub.component';
import { FootPrintManager_projects_hubComponent } from './FootPrintManager.projects_hub.component';
import { FootPrintManager_replenishment_hubComponent } from './FootPrintManager.replenishment_hub.component';
import { FootPrintManager_returns_hubComponent } from './FootPrintManager.returns_hub.component';
import { FootPrintManager_serialnumbers_hubComponent } from './FootPrintManager.serialnumbers_hub.component';
import { FootPrintManager_shipping_containers_hubComponent } from './FootPrintManager.shipping_containers_hub.component';
import { FootPrintManager_single_licenseplate_hubComponent } from './FootPrintManager.single_licenseplate_hub.component';
import { FootPrintManager_single_location_hubComponent } from './FootPrintManager.single_location_hub.component';
import { FootPrintManager_single_lot_hubComponent } from './FootPrintManager.single_lot_hub.component';
import { FootPrintManager_single_material_hubComponent } from './FootPrintManager.single_material_hub.component';
import { FootPrintManager_single_owner_hubComponent } from './FootPrintManager.single_owner_hub.component';
import { FootPrintManager_single_parent_licenseplate_hubComponent } from './FootPrintManager.single_parent_licenseplate_hub.component';
import { FootPrintManager_single_project_hubComponent } from './FootPrintManager.single_project_hub.component';
import { FootPrintManager_single_serialnumber_hubComponent } from './FootPrintManager.single_serialnumber_hub.component';
import { FootPrintManager_single_survey_hubComponent } from './FootPrintManager.single_survey_hub.component';
import { FootPrintManager_single_warehouse_hubComponent } from './FootPrintManager.single_warehouse_hub.component';
import { FootPrintManager_single_wave_hubComponent } from './FootPrintManager.single_wave_hub.component';
import { FootPrintManager_support_tests_hubComponent } from './FootPrintManager.support_tests_hub.component';
import { FootPrintManager_surveys_hubComponent } from './FootPrintManager.surveys_hub.component';
import { FootPrintManager_transload_orders_hubComponent } from './FootPrintManager.transload_orders_hub.component';
import { FootPrintManager_warehouse_transfers_hubComponent } from './FootPrintManager.warehouse_transfers_hub.component';
import { FootPrintManager_warehouses_hubComponent } from './FootPrintManager.warehouses_hub.component';
import { FootPrintManager_waves_hubComponent } from './FootPrintManager.waves_hub.component';
import { FootPrintManager_work_classes_hubComponent } from './FootPrintManager.work_classes_hub.component';
import { FootPrintManager_work_orders_hubComponent } from './FootPrintManager.work_orders_hub.component';
import { FootPrintManager_dock_appointments_calendarComponent } from './FootPrintManager.dock_appointments_calendar.component';
import { FootPrintManager_dock_appointment_selection_wizardComponent } from './FootPrintManager.dock_appointment_selection_wizard.component';
import { FootPrintManager_inventory_by_location_selection_wizardComponent } from './FootPrintManager.inventory_by_location_selection_wizard.component';
import { FootPrintManager_inventory_by_lot_selection_wizardComponent } from './FootPrintManager.inventory_by_lot_selection_wizard.component';
import { FootPrintManager_inventory_by_lot_single_selection_wizardComponent } from './FootPrintManager.inventory_by_lot_single_selection_wizard.component';
import { FootPrintManager_inventory_by_lp_selection_wizardComponent } from './FootPrintManager.inventory_by_lp_selection_wizard.component';
import { FootPrintManager_inventory_by_lp_single_selection_wizardComponent } from './FootPrintManager.inventory_by_lp_single_selection_wizard.component';
import { FootPrintManager_inventory_by_serialnumber_selection_wizardComponent } from './FootPrintManager.inventory_by_serialnumber_selection_wizard.component';
import { FootPrintManager_inventory_by_serialnumber_single_selection_wizardComponent } from './FootPrintManager.inventory_by_serialnumber_single_selection_wizard.component';
import { FootPrintManager_pack_verification_wizardComponent } from './FootPrintManager.pack_verification_wizard.component';
import { FootPrintManager_purchase_order_bulk_receiving_by_taskId_wizardComponent } from './FootPrintManager.purchase_order_bulk_receiving_by_taskId_wizard.component';
import { FootPrintManager_billing_record_activity_listComponent } from './FootPrintManager.billing_record_activity_list.component';
import { FootPrintManager_count_tasks_listComponent } from './FootPrintManager.count_tasks_list.component';
import { FootPrintManager_dock_appointment_activity_listComponent } from './FootPrintManager.dock_appointment_activity_list.component';
import { FootPrintManager_dock_doors_listComponent } from './FootPrintManager.dock_doors_list.component';
import { FootPrintManager_licenseplate_listComponent } from './FootPrintManager.licenseplate_list.component';
import { FootPrintManager_licenseplatecontent_listComponent } from './FootPrintManager.licenseplatecontent_list.component';
import { FootPrintManager_licenseplates_by_shipping_container_listComponent } from './FootPrintManager.licenseplates_by_shipping_container_list.component';
import { FootPrintManager_shipping_containers_listComponent } from './FootPrintManager.shipping_containers_list.component';
import { FootPrintManager_view_xml_payloadComponent } from './FootPrintManager.view_xml_payload.component';
import { FootPrintManager_commercial_invoice_contentComponent } from './FootPrintManager.commercial_invoice_content.component';
import { FootPrintManager_shipping_label_contentComponent } from './FootPrintManager.shipping_label_content.component';
import { FootPrintManager_archived_options_dd_singleComponent } from './FootPrintManager.archived_options_dd_single.component';
import { FootPrintManager_daysOfWeek_zt_singleComponent } from './FootPrintManager.daysOfWeek_zt_single.component';
import { FootPrintManager_hourly_options_dd_singleComponent } from './FootPrintManager.hourly_options_dd_single.component';
import { FootPrintManager_inventory_count_statuses_dd_alt_singleComponent } from './FootPrintManager.inventory_count_statuses_dd_alt_single.component';
import { FootPrintManager_kit_view_sales_order_lines_singleComponent } from './FootPrintManager.kit_view_sales_order_lines_single.component';
import { FootPrintManager_lots_dd_singleComponent } from './FootPrintManager.lots_dd_single.component';
import { FootPrintManager_monthsOfYear_zt_singleComponent } from './FootPrintManager.monthsOfYear_zt_single.component';
import { FootPrintManager_orderclasses_inbound_dd_singleComponent } from './FootPrintManager.orderclasses_inbound_dd_single.component';
import { FootPrintManager_orders_by_project_dd_singleComponent } from './FootPrintManager.orders_by_project_dd_single.component';
import { FootPrintManager_pallet_classes_dd_singleComponent } from './FootPrintManager.pallet_classes_dd_single.component';
import { FootPrintManager_print_order_labels_options_dd_singleComponent } from './FootPrintManager.print_order_labels_options_dd_single.component';
import { FootPrintManager_reason_code_parent_entities_dd_singleComponent } from './FootPrintManager.reason_code_parent_entities_dd_single.component';
import { FootPrintManager_reason_code_parent_entity_ids_dd_singleComponent } from './FootPrintManager.reason_code_parent_entity_ids_dd_single.component';
import { FootPrintManager_reason_codes_dd_singleComponent } from './FootPrintManager.reason_codes_dd_single.component';
import { FootPrintManager_support_tests_dd_singleComponent } from './FootPrintManager.support_tests_dd_single.component';
import { FootPrintManager_vendorlots_dd_singleComponent } from './FootPrintManager.vendorlots_dd_single.component';
import { FootPrintManager_weight_field_dd_singleComponent } from './FootPrintManager.weight_field_dd_single.component';
import { FootPrintManager_archived_options_dd_multiComponent } from './FootPrintManager.archived_options_dd_multi.component';
import { FootPrintManager_daysOfWeek_zt_multiComponent } from './FootPrintManager.daysOfWeek_zt_multi.component';
import { FootPrintManager_hourly_options_dd_multiComponent } from './FootPrintManager.hourly_options_dd_multi.component';
import { FootPrintManager_inventory_count_statuses_dd_alt_multiComponent } from './FootPrintManager.inventory_count_statuses_dd_alt_multi.component';
import { FootPrintManager_kit_view_sales_order_lines_multiComponent } from './FootPrintManager.kit_view_sales_order_lines_multi.component';
import { FootPrintManager_lots_dd_multiComponent } from './FootPrintManager.lots_dd_multi.component';
import { FootPrintManager_monthsOfYear_zt_multiComponent } from './FootPrintManager.monthsOfYear_zt_multi.component';
import { FootPrintManager_orderclasses_inbound_dd_multiComponent } from './FootPrintManager.orderclasses_inbound_dd_multi.component';
import { FootPrintManager_orders_by_project_dd_multiComponent } from './FootPrintManager.orders_by_project_dd_multi.component';
import { FootPrintManager_pallet_classes_dd_multiComponent } from './FootPrintManager.pallet_classes_dd_multi.component';
import { FootPrintManager_print_order_labels_options_dd_multiComponent } from './FootPrintManager.print_order_labels_options_dd_multi.component';
import { FootPrintManager_reason_code_parent_entities_dd_multiComponent } from './FootPrintManager.reason_code_parent_entities_dd_multi.component';
import { FootPrintManager_reason_code_parent_entity_ids_dd_multiComponent } from './FootPrintManager.reason_code_parent_entity_ids_dd_multi.component';
import { FootPrintManager_reason_codes_dd_multiComponent } from './FootPrintManager.reason_codes_dd_multi.component';
import { FootPrintManager_support_tests_dd_multiComponent } from './FootPrintManager.support_tests_dd_multi.component';
import { FootPrintManager_vendorlots_dd_multiComponent } from './FootPrintManager.vendorlots_dd_multi.component';
import { FootPrintManager_weight_field_dd_multiComponent } from './FootPrintManager.weight_field_dd_multi.component';
import { FootPrintManager_billing_record_activity_cardComponent } from './FootPrintManager.billing_record_activity_card.component';
import { FootPrintManager_count_task_cardComponent } from './FootPrintManager.count_task_card.component';
import { FootPrintManager_dock_appoinment_activity_cardComponent } from './FootPrintManager.dock_appoinment_activity_card.component';
import { FootPrintManager_dock_appointment_cardComponent } from './FootPrintManager.dock_appointment_card.component';
import { FootPrintManager_dock_door_cardComponent } from './FootPrintManager.dock_door_card.component';
import { FootPrintManager_licenseplate_cardComponent } from './FootPrintManager.licenseplate_card.component';
import { FootPrintManager_licenseplate_content_cardComponent } from './FootPrintManager.licenseplate_content_card.component';
import { FootPrintManager_licenseplate_with_content_cardComponent } from './FootPrintManager.licenseplate_with_content_card.component';
import { FootPrintManager_shipping_container_cardComponent } from './FootPrintManager.shipping_container_card.component';

import { Discussions_ShellService } from './Discussions.shell.service';
import { EasyPost_ShellService } from './EasyPost.shell.service';
import { BarcodeParsing_ShellService } from './BarcodeParsing.shell.service';
import { BarTender_ShellService } from './BarTender.shell.service';
import { Utilities_ShellService } from './Utilities.shell.service';
import { Addresses_ShellService } from './Addresses.shell.service';
import { AsnOrders_ShellService } from './AsnOrders.shell.service';
import { Attachments_ShellService } from './Attachments.shell.service';
import { Carriers_ShellService } from './Carriers.shell.service';
import { EntityImport_ShellService } from './EntityImport.shell.service';
import { Cartonization_ShellService } from './Cartonization.shell.service';
import { ExcelInventoryImport_ShellService } from './ExcelInventoryImport.shell.service';
import { Instructions_ShellService } from './Instructions.shell.service';
import { Inspections_ShellService } from './Inspections.shell.service';
import { ExcelProjectImport_ShellService } from './ExcelProjectImport.shell.service';
import { ExcelOrderImport_ShellService } from './ExcelOrderImport.shell.service';
import { InventoryTransfers_ShellService } from './InventoryTransfers.shell.service';
import { Locations_ShellService } from './Locations.shell.service';
import { Lots_ShellService } from './Lots.shell.service';
import { LoadContainers_ShellService } from './LoadContainers.shell.service';
import { LaborManagement_ShellService } from './LaborManagement.shell.service';
import { Manifesting_ShellService } from './Manifesting.shell.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Returns_ShellService } from './Returns.shell.service';
import { ShippingContainers_ShellService } from './ShippingContainers.shell.service';
import { TemperatureReadings_ShellService } from './TemperatureReadings.shell.service';
import { Surveys_ShellService } from './Surveys.shell.service';
import { WarehouseTransfers_ShellService } from './WarehouseTransfers.shell.service';
import { Waves_ShellService } from './Waves.shell.service';
import { Equipment_ShellService } from './Equipment.shell.service';
import { ExcelMaterialImport_ShellService } from './ExcelMaterialImport.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Replenishments_ShellService } from './Replenishments.shell.service';
import { PalletTransactions_ShellService } from './PalletTransactions.shell.service';
import { SerialNumbers_ShellService } from './SerialNumbers.shell.service';
import { ExcelLocationImport_ShellService } from './ExcelLocationImport.shell.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { InventoryCounts_ShellService } from './InventoryCounts.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { TransloadOrders_ShellService } from './TransloadOrders.shell.service';
import { DockAppointments_ShellService } from './DockAppointments.shell.service';
import { WorkOrders_ShellService } from './WorkOrders.shell.service';
import { PackVerification_ShellService } from './PackVerification.shell.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { PrintNode_ShellService } from './PrintNode.shell.service';
import { Materials_ShellService } from './Materials.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';

@Injectable({ providedIn: 'root' })
export class FootPrintManager_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Discussions: Discussions_ShellService,
    public EasyPost: EasyPost_ShellService,
    public BarcodeParsing: BarcodeParsing_ShellService,
    public BarTender: BarTender_ShellService,
    public Utilities: Utilities_ShellService,
    public Addresses: Addresses_ShellService,
    public AsnOrders: AsnOrders_ShellService,
    public Attachments: Attachments_ShellService,
    public Carriers: Carriers_ShellService,
    public EntityImport: EntityImport_ShellService,
    public Cartonization: Cartonization_ShellService,
    public ExcelInventoryImport: ExcelInventoryImport_ShellService,
    public Instructions: Instructions_ShellService,
    public Inspections: Inspections_ShellService,
    public ExcelProjectImport: ExcelProjectImport_ShellService,
    public ExcelOrderImport: ExcelOrderImport_ShellService,
    public InventoryTransfers: InventoryTransfers_ShellService,
    public Locations: Locations_ShellService,
    public Lots: Lots_ShellService,
    public LoadContainers: LoadContainers_ShellService,
    public LaborManagement: LaborManagement_ShellService,
    public Manifesting: Manifesting_ShellService,
    public Owners: Owners_ShellService,
    public Returns: Returns_ShellService,
    public ShippingContainers: ShippingContainers_ShellService,
    public TemperatureReadings: TemperatureReadings_ShellService,
    public Surveys: Surveys_ShellService,
    public WarehouseTransfers: WarehouseTransfers_ShellService,
    public Waves: Waves_ShellService,
    public Equipment: Equipment_ShellService,
    public ExcelMaterialImport: ExcelMaterialImport_ShellService,
    public Notifications: Notifications_ShellService,
    public Replenishments: Replenishments_ShellService,
    public PalletTransactions: PalletTransactions_ShellService,
    public SerialNumbers: SerialNumbers_ShellService,
    public ExcelLocationImport: ExcelLocationImport_ShellService,
    public Inventory: Inventory_ShellService,
    public InventoryCounts: InventoryCounts_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public TransloadOrders: TransloadOrders_ShellService,
    public DockAppointments: DockAppointments_ShellService,
    public WorkOrders: WorkOrders_ShellService,
    public PackVerification: PackVerification_ShellService,
    public Invoices: Invoices_ShellService,
    public PrintNode: PrintNode_ShellService,
    public Materials: Materials_ShellService,
    public SalesOrders: SalesOrders_ShellService,
  ) {
    super(dialog, toastr);
  }

  public FootPrintManager: FootPrintManager_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openasn_order_creation_form(inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create ASN Order',
        referenceName: 'FootPrintManager_asn_order_creation_form',
        component: FootPrintManager_asn_order_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openasn_order_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_asn_order_creation_formComponent,
      'Create ASN Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openasn_order_transfer_outstanding_options_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'ASN Order Transfer Outstanding Options',
        referenceName: 'FootPrintManager_asn_order_transfer_outstanding_options_form',
        component: FootPrintManager_asn_order_transfer_outstanding_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openasn_order_transfer_outstanding_options_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, createNewOrder?: boolean, notes?: string, copyUdfs?: boolean, ignore?: boolean, cancelCompletion?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_asn_order_transfer_outstanding_options_formComponent,
      'ASN Order Transfer Outstanding Options',
      mode,
      dialogSize
    )
  }
  public openauto_load_options_form(inParams:{ warehouseId: number, loadLocationId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Auto Load Options',
        referenceName: 'FootPrintManager_auto_load_options_form',
        component: FootPrintManager_auto_load_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openauto_load_options_formDialog(
    inParams:{ warehouseId: number, loadLocationId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, loadLocation?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_auto_load_options_formComponent,
      'Auto Load Options',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openauto_pick_options_form(inParams:{ warehouseId: number, loadLocationId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Auto Pick Options',
        referenceName: 'FootPrintManager_auto_pick_options_form',
        component: FootPrintManager_auto_pick_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openauto_pick_options_formDialog(
    inParams:{ warehouseId: number, loadLocationId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, autoLoad?: boolean, loadLocation?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_auto_pick_options_formComponent,
      'Auto Pick Options',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openauto_receive_and_putaway_form(inParams:{ orderId: number, taskIds?: number[], licenseplateIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Auto-receive and putaway',
        referenceName: 'FootPrintManager_auto_receive_and_putaway_form',
        component: FootPrintManager_auto_receive_and_putaway_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openauto_receive_and_putaway_formDialog(
    inParams:{ orderId: number, taskIds?: number[], licenseplateIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ isReceived?: boolean, reason?: string, isPutaway?: boolean, isConfirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_auto_receive_and_putaway_formComponent,
      'Auto-receive and putaway',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_cancellation_options_form(inParams:{ title?: string, parentId: number, parentEntity: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Generic Billing Cancellation ',
        referenceName: 'FootPrintManager_billing_cancellation_options_form',
        component: FootPrintManager_billing_cancellation_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_cancellation_options_formDialog(
    inParams:{ title?: string, parentId: number, parentEntity: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, reasonCodeId?: number, notes?: string }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_cancellation_options_formComponent,
      'Generic Billing Cancellation ',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_contract_creation_form(inParams:{ ownerId?: number, projectId?: number, copy?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing Contract Creation Form',
        referenceName: 'FootPrintManager_billing_contract_creation_form',
        component: FootPrintManager_billing_contract_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_contract_creation_formDialog(
    inParams:{ ownerId?: number, projectId?: number, copy?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ projectId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_contract_creation_formComponent,
      'Billing Contract Creation Form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_contract_line_creation_form(inParams:{ billingContractId?: number, strategyName?: string, statusId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing Contract Line Creation Form',
        referenceName: 'FootPrintManager_billing_contract_line_creation_form',
        component: FootPrintManager_billing_contract_line_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_contract_line_creation_formDialog(
    inParams:{ billingContractId?: number, strategyName?: string, statusId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_contract_line_creation_formComponent,
      'Billing Contract Line Creation Form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openblind_return_project_selector_form(inParams:{ gtin: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Select project',
        referenceName: 'FootPrintManager_blind_return_project_selector_form',
        component: FootPrintManager_blind_return_project_selector_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openblind_return_project_selector_formDialog(
    inParams:{ gtin: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ projectId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_blind_return_project_selector_formComponent,
      'Select project',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbulk_licenseplate_directed_move_form(inParams:{ licenseplates: { id?: number, warehouseId?: number, typeId?: number, locationId?: number }[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Move License Plates',
        referenceName: 'FootPrintManager_bulk_licenseplate_directed_move_form',
        component: FootPrintManager_bulk_licenseplate_directed_move_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbulk_licenseplate_directed_move_formDialog(
    inParams:{ licenseplates: { id?: number, warehouseId?: number, typeId?: number, locationId?: number }[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_bulk_licenseplate_directed_move_formComponent,
      'Move License Plates',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbulk_receiving_row_input_form(inParams:{ materialId: number, packagingId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Bulk Receiving',
        referenceName: 'FootPrintManager_bulk_receiving_row_input_form',
        component: FootPrintManager_bulk_receiving_row_input_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbulk_receiving_row_input_formDialog(
    inParams:{ materialId: number, packagingId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ palletCount?: number, fullPalletQuantity?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_bulk_receiving_row_input_formComponent,
      'Bulk Receiving',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencarrier_creation_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create carrier',
        referenceName: 'FootPrintManager_carrier_creation_form',
        component: FootPrintManager_carrier_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencarrier_creation_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_carrier_creation_formComponent,
      'Create carrier',
      mode,
      dialogSize
    )
  }
  public opencarrierservicetype_creation_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Carrier Service Type',
        referenceName: 'FootPrintManager_carrierservicetype_creation_form',
        component: FootPrintManager_carrierservicetype_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencarrierservicetype_creation_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_carrierservicetype_creation_formComponent,
      'Create Carrier Service Type',
      mode,
      dialogSize
    )
  }
  public opendock_appointment_assign_door_options_form(inParams:{ title?: string, dockAppointmentId: number, warehouseId: number, scheduledDockDoorId?: number, appointmentType?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock Appointment Door Assignment',
        referenceName: 'FootPrintManager_dock_appointment_assign_door_options_form',
        component: FootPrintManager_dock_appointment_assign_door_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_appointment_assign_door_options_formDialog(
    inParams:{ title?: string, dockAppointmentId: number, warehouseId: number, scheduledDockDoorId?: number, appointmentType?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, assignedDoorId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointment_assign_door_options_formComponent,
      'Dock Appointment Door Assignment',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendock_appointment_cancel_options_form(inParams:{ title?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock Appointment Cancellation',
        referenceName: 'FootPrintManager_dock_appointment_cancel_options_form',
        component: FootPrintManager_dock_appointment_cancel_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_appointment_cancel_options_formDialog(
    inParams:{ title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, reasonCodeId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointment_cancel_options_formComponent,
      'Dock Appointment Cancellation',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendock_appointment_check_in_options_form(inParams:{ title?: string, dockAppointmentId: number, warehouseId: number, scheduledDockDoorId?: number, appointmentType?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock Appointment Check In',
        referenceName: 'FootPrintManager_dock_appointment_check_in_options_form',
        component: FootPrintManager_dock_appointment_check_in_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_appointment_check_in_options_formDialog(
    inParams:{ title?: string, dockAppointmentId: number, warehouseId: number, scheduledDockDoorId?: number, appointmentType?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, scheduledDoorId?: number, assignedDoorId?: number, driverName?: string, driverLicense?: string, vehicleLicense?: string }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointment_check_in_options_formComponent,
      'Dock Appointment Check In',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendock_appointment_creation_form(inParams:{ warehouseId?: number[], lookupcode?: string, scheduledArrival?: string, scheduledDeparture?: string, dockDoor?: number, typeId?: number, ownerId?: number, projectId?: number, carrierId?: number, orderId?: number, loadContainerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Dock Appointment',
        referenceName: 'FootPrintManager_dock_appointment_creation_form',
        component: FootPrintManager_dock_appointment_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_appointment_creation_formDialog(
    inParams:{ warehouseId?: number[], lookupcode?: string, scheduledArrival?: string, scheduledDeparture?: string, dockDoor?: number, typeId?: number, ownerId?: number, projectId?: number, carrierId?: number, orderId?: number, loadContainerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointment_creation_formComponent,
      'Create Dock Appointment',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendock_door_creation_form(inParams:{ warehouseId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Dock Door',
        referenceName: 'FootPrintManager_dock_door_creation_form',
        component: FootPrintManager_dock_door_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_door_creation_formDialog(
    inParams:{ warehouseId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_door_creation_formComponent,
      'Create Dock Door',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openfinalize_audit_form(inParams:{ licensePlateId?: number, missingSerialNumberIds: number[], warehouseId: number, locationId?: number, shippingContainerId?: number, foundSerialNumberIds: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Finalize audit',
        referenceName: 'FootPrintManager_finalize_audit_form',
        component: FootPrintManager_finalize_audit_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openfinalize_audit_formDialog(
    inParams:{ licensePlateId?: number, missingSerialNumberIds: number[], warehouseId: number, locationId?: number, shippingContainerId?: number, foundSerialNumberIds: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ isConfirm: boolean, reasonCodeId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_finalize_audit_formComponent,
      'Finalize audit',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openfootprint_manager_home(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'FootPrintManager_footprint_manager_home',
        component: FootPrintManager_footprint_manager_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openfootprint_manager_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_footprint_manager_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public opengeneric_form(inParams:{ stringField1?: string, stringField2?: string, stringField3?: string, stringField4?: string, stringField5?: string, dateField1?: string, dateField2?: string, dateField3?: string, dateField4?: string, dateField5?: string, numberField1?: string, numberField2?: string, numberField3?: string, numberField4?: string, numberField5?: string, title?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'generic_form',
        referenceName: 'FootPrintManager_generic_form',
        component: FootPrintManager_generic_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opengeneric_formDialog(
    inParams:{ stringField1?: string, stringField2?: string, stringField3?: string, stringField4?: string, stringField5?: string, dateField1?: string, dateField2?: string, dateField3?: string, dateField4?: string, dateField5?: string, numberField1?: string, numberField2?: string, numberField3?: string, numberField4?: string, numberField5?: string, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ stringField1?: string, stringField2?: string, stringField3?: string, stringField4?: string, stringField5?: string, dateField1?: string, dateField2?: string, dateField3?: string, dateField4?: string, dateField5?: string, numberField1?: number, numberField2?: number, numberField3?: number, numberField4?: number, numberField5?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_generic_formComponent,
      'generic_form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openget_average_serial_weights_form(inParams:{ serial_numbers: { id?: number, lookup: string, net_weight: number, gross_weight: number }[], weight_uom_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Distribute total weight across all items',
        referenceName: 'FootPrintManager_get_average_serial_weights_form',
        component: FootPrintManager_get_average_serial_weights_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openget_average_serial_weights_formDialog(
    inParams:{ serial_numbers: { id?: number, lookup: string, net_weight: number, gross_weight: number }[], weight_uom_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ serial_numbers?: { id?: number, lookup: string, net_weight: number, gross_weight: number }[], total_net?: number, total_gross?: number, weight_uom_id?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_get_average_serial_weights_formComponent,
      'Distribute total weight across all items',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openget_licenseplate_form(inParams:{ warehouseId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Get license plate',
        referenceName: 'FootPrintManager_get_licenseplate_form',
        component: FootPrintManager_get_licenseplate_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openget_licenseplate_formDialog(
    inParams:{ warehouseId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ locationId?: number, licenseplateId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_get_licenseplate_formComponent,
      'Get license plate',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinbound_orders_update_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Update Orders',
        referenceName: 'FootPrintManager_inbound_orders_update_form',
        component: FootPrintManager_inbound_orders_update_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinbound_orders_update_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, carrierId?: number, expectedDate?: string, reference?: string }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inbound_orders_update_formComponent,
      'Update Orders',
      mode,
      dialogSize
    )
  }
  public openinspection_select_task_status_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Select task status',
        referenceName: 'FootPrintManager_inspection_select_task_status_form',
        component: FootPrintManager_inspection_select_task_status_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinspection_select_task_status_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ taskStatusId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inspection_select_task_status_formComponent,
      'Select task status',
      mode,
      dialogSize
    )
  }
  public openinspection_task_creation_form(inParams:{ warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create inspection task',
        referenceName: 'FootPrintManager_inspection_task_creation_form',
        component: FootPrintManager_inspection_task_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinspection_task_creation_formDialog(
    inParams:{ warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ taskId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inspection_task_creation_formComponent,
      'Create inspection task',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openintegration_info_by_order_id(inParams:{ order_id?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'integration_info_by_order_id',
        referenceName: 'FootPrintManager_integration_info_by_order_id',
        component: FootPrintManager_integration_info_by_order_idComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openintegration_info_by_order_idDialog(
    inParams:{ order_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_integration_info_by_order_idComponent,
      'integration_info_by_order_id',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_adjustment_form(inParams:{ licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, availablePackagedAmount: number, serialnumberId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Adjust Inventory',
        referenceName: 'FootPrintManager_inventory_adjustment_form',
        component: FootPrintManager_inventory_adjustment_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_adjustment_formDialog(
    inParams:{ licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, availablePackagedAmount: number, serialnumberId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_adjustment_formComponent,
      'Adjust Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_and_licenseplate_move_form(inParams:{ warehouseId?: number, locationId?: number, materialId?: number, vendorlotId?: number, licenseplates?: { id?: number, content?: { lotId?: number, packagingId?: number, packagedAmount?: number, availablePackagedAmount?: number }[] }[], serialNumberIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Move Inventory',
        referenceName: 'FootPrintManager_inventory_and_licenseplate_move_form',
        component: FootPrintManager_inventory_and_licenseplate_move_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_and_licenseplate_move_formDialog(
    inParams:{ warehouseId?: number, locationId?: number, materialId?: number, vendorlotId?: number, licenseplates?: { id?: number, content?: { lotId?: number, packagingId?: number, packagedAmount?: number, availablePackagedAmount?: number }[] }[], serialNumberIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_and_licenseplate_move_formComponent,
      'Move Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_creation_form(inParams:{ projectId?: number, warehouseId?: number, materialId?: number, packagingId?: number, lotId?: number, licenseplateId?: number, ownerId?: number, locationId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Inventory',
        referenceName: 'FootPrintManager_inventory_creation_form',
        component: FootPrintManager_inventory_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_creation_formDialog(
    inParams:{ projectId?: number, warehouseId?: number, materialId?: number, packagingId?: number, lotId?: number, licenseplateId?: number, ownerId?: number, locationId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_creation_formComponent,
      'Create Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_transfer_creation_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create inventory transfer',
        referenceName: 'FootPrintManager_inventory_transfer_creation_form',
        component: FootPrintManager_inventory_transfer_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinventory_transfer_creation_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_transfer_creation_formComponent,
      'Create inventory transfer',
      mode,
      dialogSize
    )
  }
  public openinventory_transfer_form(inParams:{ lotId: number, packagingId: number, licenseplateId: number, packagedAmount: number, materialId: number, availablePackagedAmount: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Transfer Inventory',
        referenceName: 'FootPrintManager_inventory_transfer_form',
        component: FootPrintManager_inventory_transfer_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_transfer_formDialog(
    inParams:{ lotId: number, packagingId: number, licenseplateId: number, packagedAmount: number, materialId: number, availablePackagedAmount: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_transfer_formComponent,
      'Transfer Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_type_selection_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory Type Selection',
        referenceName: 'FootPrintManager_inventory_type_selection_form',
        component: FootPrintManager_inventory_type_selection_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinventory_type_selection_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, inventoryTypeId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_type_selection_formComponent,
      'Inventory Type Selection',
      mode,
      dialogSize
    )
  }
  public openinvoice_creation_form(inParams:{ projectId?: number, ownerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Invoice',
        referenceName: 'FootPrintManager_invoice_creation_form',
        component: FootPrintManager_invoice_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoice_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_invoice_creation_formComponent,
      'Create Invoice',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoice_creation_options_form(inParams:{ title?: string, projectId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Invoice Creation Options',
        referenceName: 'FootPrintManager_invoice_creation_options_form',
        component: FootPrintManager_invoice_creation_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoice_creation_options_formDialog(
    inParams:{ title?: string, projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, invoiceId?: number, notes?: string }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_invoice_creation_options_formComponent,
      'Invoice Creation Options',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoice_status_change_options_form(inParams:{ title?: string, excludeStatusId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Invoice Status Change Options',
        referenceName: 'FootPrintManager_invoice_status_change_options_form',
        component: FootPrintManager_invoice_status_change_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoice_status_change_options_formDialog(
    inParams:{ title?: string, excludeStatusId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, statusId?: number, notes?: string }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_invoice_status_change_options_formComponent,
      'Invoice Status Change Options',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openload_container_create_form(inParams:{ warehouseId?: number, orderTypeId?: number, projectId?: number, ownerId?: number, loadContainerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create a Load Container',
        referenceName: 'FootPrintManager_load_container_create_form',
        component: FootPrintManager_load_container_create_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openload_container_create_formDialog(
    inParams:{ warehouseId?: number, orderTypeId?: number, projectId?: number, ownerId?: number, loadContainerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_load_container_create_formComponent,
      'Create a Load Container',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlocation_creation_form(inParams:{ warehouseId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create location',
        referenceName: 'FootPrintManager_location_creation_form',
        component: FootPrintManager_location_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlocation_creation_formDialog(
    inParams:{ warehouseId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, locationId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_location_creation_formComponent,
      'Create location',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlocation_replenishment_creation_form(inParams:{ projectId?: number, warehouseId?: number, locationId?: number, materialId?: number, ownerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create new rule',
        referenceName: 'FootPrintManager_location_replenishment_creation_form',
        component: FootPrintManager_location_replenishment_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlocation_replenishment_creation_formDialog(
    inParams:{ projectId?: number, warehouseId?: number, locationId?: number, materialId?: number, ownerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_location_replenishment_creation_formComponent,
      'Create new rule',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlot_bulk_update_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Update Lots and Vendor Lots',
        referenceName: 'FootPrintManager_lot_bulk_update_form',
        component: FootPrintManager_lot_bulk_update_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openlot_bulk_update_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, lotLookupCode?: string, vendorLookupCode?: string, expirationDate?: string, manufacturedDate?: string }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_lot_bulk_update_formComponent,
      'Update Lots and Vendor Lots',
      mode,
      dialogSize
    )
  }
  public openlot_bulk_update_status_form(inParams:{ message: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Lot status update',
        referenceName: 'FootPrintManager_lot_bulk_update_status_form',
        component: FootPrintManager_lot_bulk_update_status_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlot_bulk_update_status_formDialog(
    inParams:{ message: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ statusId?: number, reasonCodeIds?: number[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_lot_bulk_update_status_formComponent,
      'Lot status update',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlot_creation_form(inParams:{ materialId: number, lotLookupCode?: string, vendorLotLookupCode?: string, vendorLotId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Lot',
        referenceName: 'FootPrintManager_lot_creation_form',
        component: FootPrintManager_lot_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlot_creation_formDialog(
    inParams:{ materialId: number, lotLookupCode?: string, vendorLotLookupCode?: string, vendorLotId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ lotId?: number, confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_lot_creation_formComponent,
      'Create Lot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlot_creation_form_with_material(inParams:{ materialId?: number, ownerId?: number, projectId?: number, vendorLotId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Lot With Material',
        referenceName: 'FootPrintManager_lot_creation_form_with_material',
        component: FootPrintManager_lot_creation_form_with_materialComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlot_creation_form_with_materialDialog(
    inParams:{ materialId?: number, ownerId?: number, projectId?: number, vendorLotId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ lotId?: number, confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_lot_creation_form_with_materialComponent,
      'Create Lot With Material',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_copy_form(inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Copy Material',
        referenceName: 'FootPrintManager_material_copy_form',
        component: FootPrintManager_material_copy_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_copy_formDialog(
    inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, materialId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_copy_formComponent,
      'Copy Material',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_creation_form(inParams:{ projectId?: number, ownerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Material',
        referenceName: 'FootPrintManager_material_creation_form',
        component: FootPrintManager_material_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ materialId?: number, confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_creation_formComponent,
      'Create Material',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_update_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Update Material',
        referenceName: 'FootPrintManager_material_update_form',
        component: FootPrintManager_material_update_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmaterial_update_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ materialGroupId?: number, shelfLifeSpan?: number, allocationStrategyId?: number, confirm?: boolean, statusId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_update_formComponent,
      'Update Material',
      mode,
      dialogSize
    )
  }
  public openorder_completion_options_form(inParams:{ warehouseId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order Completion Options',
        referenceName: 'FootPrintManager_order_completion_options_form',
        component: FootPrintManager_order_completion_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openorder_completion_options_formDialog(
    inParams:{ warehouseId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, autoLoad?: boolean, loadLocation?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_order_completion_options_formComponent,
      'Order Completion Options',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openoutbound_orders_update_form(inParams:{ orders: { orderId?: number, shipmentId?: number }[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Update Orders',
        referenceName: 'FootPrintManager_outbound_orders_update_form',
        component: FootPrintManager_outbound_orders_update_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openoutbound_orders_update_formDialog(
    inParams:{ orders: { orderId?: number, shipmentId?: number }[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_outbound_orders_update_formComponent,
      'Update Orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openowner_creation_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Owner',
        referenceName: 'FootPrintManager_owner_creation_form',
        component: FootPrintManager_owner_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openowner_creation_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, ownerId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_owner_creation_formComponent,
      'Create Owner',
      mode,
      dialogSize
    )
  }
  public openowner_update_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Update Owner',
        referenceName: 'FootPrintManager_owner_update_form',
        component: FootPrintManager_owner_update_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openowner_update_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, ownerStatusId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_owner_update_formComponent,
      'Update Owner',
      mode,
      dialogSize
    )
  }
  public openpack_verification_failure_form(inParams:{ shippingContainerCode: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Fail verification?',
        referenceName: 'FootPrintManager_pack_verification_failure_form',
        component: FootPrintManager_pack_verification_failure_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpack_verification_failure_formDialog(
    inParams:{ shippingContainerCode: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ reasonCodeId?: number, isConfirmed?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pack_verification_failure_formComponent,
      'Fail verification?',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpallet_class_creation_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pallet class creation form',
        referenceName: 'FootPrintManager_pallet_class_creation_form',
        component: FootPrintManager_pallet_class_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openpallet_class_creation_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pallet_class_creation_formComponent,
      'Pallet class creation form',
      mode,
      dialogSize
    )
  }
  public openpallet_transaction_creation_form(inParams:{ project_id?: number, shipment_id?: number, pallet_class_id?: number, order_lookup_code?: string, pallets_in?: number, pallets_out?: number, transaction_id?: number, amount?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pallet transaction creation form',
        referenceName: 'FootPrintManager_pallet_transaction_creation_form',
        component: FootPrintManager_pallet_transaction_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpallet_transaction_creation_formDialog(
    inParams:{ project_id?: number, shipment_id?: number, pallet_class_id?: number, order_lookup_code?: string, pallets_in?: number, pallets_out?: number, transaction_id?: number, amount?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pallet_transaction_creation_formComponent,
      'Pallet transaction creation form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openprint_license_plate_labels_form(inParams:{ orderId: number, licensePlateId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Print license plate labels',
        referenceName: 'FootPrintManager_print_license_plate_labels_form',
        component: FootPrintManager_print_license_plate_labels_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openprint_license_plate_labels_formDialog(
    inParams:{ orderId: number, licensePlateId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_print_license_plate_labels_formComponent,
      'Print license plate labels',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openprint_order_labels_form(inParams:{ orderId: number, context: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Print order labels form',
        referenceName: 'FootPrintManager_print_order_labels_form',
        component: FootPrintManager_print_order_labels_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openprint_order_labels_formDialog(
    inParams:{ orderId: number, context: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_print_order_labels_formComponent,
      'Print order labels form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openproject_creation_form(inParams:{ ownerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Project',
        referenceName: 'FootPrintManager_project_creation_form',
        component: FootPrintManager_project_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openproject_creation_formDialog(
    inParams:{ ownerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, projectId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_project_creation_formComponent,
      'Create Project',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openproject_update_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Update Project',
        referenceName: 'FootPrintManager_project_update_form',
        component: FootPrintManager_project_update_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openproject_update_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, projectStatusId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_project_update_formComponent,
      'Update Project',
      mode,
      dialogSize
    )
  }
  public openpurchase_order_copy_form(inParams:{ order_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Copy purchase order',
        referenceName: 'FootPrintManager_purchase_order_copy_form',
        component: FootPrintManager_purchase_order_copy_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_copy_formDialog(
    inParams:{ order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ new_order_id?: number, confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_purchase_order_copy_formComponent,
      'Copy purchase order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_creation_form(inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Purchase Order',
        referenceName: 'FootPrintManager_purchase_order_creation_form',
        component: FootPrintManager_purchase_order_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_purchase_order_creation_formComponent,
      'Create Purchase Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreason_code_creation_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create reason code',
        referenceName: 'FootPrintManager_reason_code_creation_form',
        component: FootPrintManager_reason_code_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openreason_code_creation_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_reason_code_creation_formComponent,
      'Create reason code',
      mode,
      dialogSize
    )
  }
  public openreturn_order_creation_form(inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Return Order',
        referenceName: 'FootPrintManager_return_order_creation_form',
        component: FootPrintManager_return_order_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreturn_order_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_return_order_creation_formComponent,
      'Create Return Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_cancellation_options_form(inParams:{ title?: string, parentId: number, parentEntity: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Generic Sales Order Cancellation ',
        referenceName: 'FootPrintManager_sales_order_cancellation_options_form',
        component: FootPrintManager_sales_order_cancellation_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_cancellation_options_formDialog(
    inParams:{ title?: string, parentId: number, parentEntity: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, reasonCodeId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_cancellation_options_formComponent,
      'Generic Sales Order Cancellation ',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_copy_form(inParams:{ order_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Copy sales order',
        referenceName: 'FootPrintManager_sales_order_copy_form',
        component: FootPrintManager_sales_order_copy_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_copy_formDialog(
    inParams:{ order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ new_order_id?: number, confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_copy_formComponent,
      'Copy sales order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_creation_form(inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Sales Order',
        referenceName: 'FootPrintManager_sales_order_creation_form',
        component: FootPrintManager_sales_order_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_creation_formComponent,
      'Create Sales Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_pick_task_cancellation_options_form(inParams:{ title?: string, parentId: number, parentEntity: string, cancelOrderRequest?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Generic Sales Order Pick Task Cancellation ',
        referenceName: 'FootPrintManager_sales_order_pick_task_cancellation_options_form',
        component: FootPrintManager_sales_order_pick_task_cancellation_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_pick_task_cancellation_options_formDialog(
    inParams:{ title?: string, parentId: number, parentEntity: string, cancelOrderRequest?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, reasonCodeId?: number, cancelOrderConfirmed?: boolean, cancelOrderReasonCodeId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_pick_task_cancellation_options_formComponent,
      'Generic Sales Order Pick Task Cancellation ',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_orders_processing_options_form(inParams:{ isMultiShipment: boolean, waveOptionDefaultCreate: boolean, expand_order_line_kits?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Processing options',
        referenceName: 'FootPrintManager_sales_orders_processing_options_form',
        component: FootPrintManager_sales_orders_processing_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_orders_processing_options_formDialog(
    inParams:{ isMultiShipment: boolean, waveOptionDefaultCreate: boolean, expand_order_line_kits?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, waveOptions?: { isCreateWave?: boolean, isProcessWave?: boolean, isReleaseWave?: boolean, priority?: number, description?: string, abortIfErrors?: boolean, groupOnWave?: boolean, isCreateManualAllocationTasks?: boolean }, processingStrategyWorkflowId?: string, expand_order_line_kits_request?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_orders_processing_options_formComponent,
      'Processing options',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openselect_user_form(inParams:{ operationCodeIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Select user',
        referenceName: 'FootPrintManager_select_user_form',
        component: FootPrintManager_select_user_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openselect_user_formDialog(
    inParams:{ operationCodeIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ user?: { id?: string, username?: string } }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_select_user_formComponent,
      'Select user',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentemperature_capture_form(inParams:{ shipmentId?: number, shipmentLineId?: number, licensePlateId?: number, loadContainerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Temperature capture form',
        referenceName: 'FootPrintManager_temperature_capture_form',
        component: FootPrintManager_temperature_capture_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentemperature_capture_formDialog(
    inParams:{ shipmentId?: number, shipmentLineId?: number, licensePlateId?: number, loadContainerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_temperature_capture_formComponent,
      'Temperature capture form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransfer_licenseplate_form(inParams:{ licenseplateIds?: number[], sourceShipmentId: number, targetShipmentId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'transfer_licenseplate_form',
        referenceName: 'FootPrintManager_transfer_licenseplate_form',
        component: FootPrintManager_transfer_licenseplate_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransfer_licenseplate_formDialog(
    inParams:{ licenseplateIds?: number[], sourceShipmentId: number, targetShipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, auto_pick?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_transfer_licenseplate_formComponent,
      'transfer_licenseplate_form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransfer_transload_licenseplate_form(inParams:{ licenseplate_id: number, transload_id: number, current_outbound_shipment_id?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Transfer transload licenseplate',
        referenceName: 'FootPrintManager_transfer_transload_licenseplate_form',
        component: FootPrintManager_transfer_transload_licenseplate_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransfer_transload_licenseplate_formDialog(
    inParams:{ licenseplate_id: number, transload_id: number, current_outbound_shipment_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_transfer_transload_licenseplate_formComponent,
      'Transfer transload licenseplate',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransload_order_creation_form(inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create transload order',
        referenceName: 'FootPrintManager_transload_order_creation_form',
        component: FootPrintManager_transload_order_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransload_order_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_transload_order_creation_formComponent,
      'Create transload order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openunloading_form(inParams:{ licenseplateId: number, shipmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Unloading Inventory',
        referenceName: 'FootPrintManager_unloading_form',
        component: FootPrintManager_unloading_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openunloading_formDialog(
    inParams:{ licenseplateId: number, shipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_unloading_formComponent,
      'Unloading Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openunpicking_form(inParams:{ licenseplateId: number, locationId?: number, inventory?: { lotId: number, packagingId: number, packagedAmount: number, shipmentLineId: number, unpickPackagedAmount?: number, serialNumberIds?: number[] } }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Unpick Inventory',
        referenceName: 'FootPrintManager_unpicking_form',
        component: FootPrintManager_unpicking_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openunpicking_formDialog(
    inParams:{ licenseplateId: number, locationId?: number, inventory?: { lotId: number, packagingId: number, packagedAmount: number, shipmentLineId: number, unpickPackagedAmount?: number, serialNumberIds?: number[] } }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_unpicking_formComponent,
      'Unpick Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openunreceiving_all_options_form(inParams:{ title?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Unreceive All Inventory',
        referenceName: 'FootPrintManager_unreceiving_all_options_form',
        component: FootPrintManager_unreceiving_all_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openunreceiving_all_options_formDialog(
    inParams:{ title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, reasonCodeId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_unreceiving_all_options_formComponent,
      'Unreceive All Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openunreceiving_single_taskId_form(inParams:{ licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, taskId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Unreceive Inventory',
        referenceName: 'FootPrintManager_unreceiving_single_taskId_form',
        component: FootPrintManager_unreceiving_single_taskId_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openunreceiving_single_taskId_formDialog(
    inParams:{ licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, taskId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_unreceiving_single_taskId_formComponent,
      'Unreceive Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openvendorlot_creation_form(inParams:{ materialId: number, vendorLookupcode?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Vendor Lot',
        referenceName: 'FootPrintManager_vendorlot_creation_form',
        component: FootPrintManager_vendorlot_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openvendorlot_creation_formDialog(
    inParams:{ materialId: number, vendorLookupcode?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ vendorLotId?: number, confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_vendorlot_creation_formComponent,
      'Create Vendor Lot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_creation_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create warehouse',
        referenceName: 'FootPrintManager_warehouse_creation_form',
        component: FootPrintManager_warehouse_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwarehouse_creation_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, warehouseId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_creation_formComponent,
      'Create warehouse',
      mode,
      dialogSize
    )
  }
  public openwarehouse_transfer_creation_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create warehouse transfer',
        referenceName: 'FootPrintManager_warehouse_transfer_creation_form',
        component: FootPrintManager_warehouse_transfer_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwarehouse_transfer_creation_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_creation_formComponent,
      'Create warehouse transfer',
      mode,
      dialogSize
    )
  }
  public openwave_update_form(inParams:{ waveId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Wave update form',
        referenceName: 'FootPrintManager_wave_update_form',
        component: FootPrintManager_wave_update_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwave_update_formDialog(
    inParams:{ waveId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_wave_update_formComponent,
      'Wave update form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwork_class_creation_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create work class',
        referenceName: 'FootPrintManager_work_class_creation_form',
        component: FootPrintManager_work_class_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwork_class_creation_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ workClassId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_work_class_creation_formComponent,
      'Create work class',
      mode,
      dialogSize
    )
  }
  public openwork_order_creation_form(inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Work Order',
        referenceName: 'FootPrintManager_work_order_creation_form',
        component: FootPrintManager_work_order_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwork_order_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_work_order_creation_formComponent,
      'Create Work Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openasn_order_editor(inParams:{ order_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit ASN Order',
        referenceName: 'FootPrintManager_asn_order_editor',
        component: FootPrintManager_asn_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openasn_order_editorDialog(
    inParams:{ order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_asn_order_editorComponent,
      'Edit ASN Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_contract_editor(inParams:{ contractId: number, edit?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Billing Contract',
        referenceName: 'FootPrintManager_billing_contract_editor',
        component: FootPrintManager_billing_contract_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_contract_editorDialog(
    inParams:{ contractId: number, edit?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_contract_editorComponent,
      'Edit Billing Contract',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_contract_line_editor(inParams:{ billingContractLineId: number, edit: boolean, operationCodeId?: number, billingAggregationStrategy?: string, statusId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing Contract Line Editor',
        referenceName: 'FootPrintManager_billing_contract_line_editor',
        component: FootPrintManager_billing_contract_line_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_contract_line_editorDialog(
    inParams:{ billingContractLineId: number, edit: boolean, operationCodeId?: number, billingAggregationStrategy?: string, statusId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ updated?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_contract_line_editorComponent,
      'Billing Contract Line Editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_record_editor(inParams:{ billingTaskId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Billing Record',
        referenceName: 'FootPrintManager_billing_record_editor',
        component: FootPrintManager_billing_record_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_record_editorDialog(
    inParams:{ billingTaskId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_record_editorComponent,
      'Edit Billing Record',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencarrier_editor(inParams:{ carrierId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Carrier',
        referenceName: 'FootPrintManager_carrier_editor',
        component: FootPrintManager_carrier_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencarrier_editorDialog(
    inParams:{ carrierId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_carrier_editorComponent,
      'Edit Carrier',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencarrierservicetype_editor(inParams:{ carrierservicetypeId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Carrier Service Type',
        referenceName: 'FootPrintManager_carrierservicetype_editor',
        component: FootPrintManager_carrierservicetype_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencarrierservicetype_editorDialog(
    inParams:{ carrierservicetypeId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_carrierservicetype_editorComponent,
      'Edit Carrier Service Type',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencount_task_editor(inParams:{ taskId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'count_task_editor',
        referenceName: 'FootPrintManager_count_task_editor',
        component: FootPrintManager_count_task_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencount_task_editorDialog(
    inParams:{ taskId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_count_task_editorComponent,
      'count_task_editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendock_appointment_editor(inParams:{ dockAppointmentId: number, orderId?: number, loadContainerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock Appointment Editor',
        referenceName: 'FootPrintManager_dock_appointment_editor',
        component: FootPrintManager_dock_appointment_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_appointment_editorDialog(
    inParams:{ dockAppointmentId: number, orderId?: number, loadContainerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointment_editorComponent,
      'Dock Appointment Editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendock_door_editor(inParams:{ locationId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Dock Door',
        referenceName: 'FootPrintManager_dock_door_editor',
        component: FootPrintManager_dock_door_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_door_editorDialog(
    inParams:{ locationId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_door_editorComponent,
      'Edit Dock Door',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinspection_task_editor(inParams:{ taskId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inspection task',
        referenceName: 'FootPrintManager_inspection_task_editor',
        component: FootPrintManager_inspection_task_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinspection_task_editorDialog(
    inParams:{ taskId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inspection_task_editorComponent,
      'Inspection task',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_count_editor(inParams:{ inventoryCountId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'inventory_count_editor',
        referenceName: 'FootPrintManager_inventory_count_editor',
        component: FootPrintManager_inventory_count_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_count_editorDialog(
    inParams:{ inventoryCountId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_count_editorComponent,
      'inventory_count_editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_transfer_editor(inParams:{ inventoryTransferId: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory transfer',
        referenceName: 'FootPrintManager_inventory_transfer_editor',
        component: FootPrintManager_inventory_transfer_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_transfer_editorDialog(
    inParams:{ inventoryTransferId: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_transfer_editorComponent,
      'Inventory transfer',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoice_editor(inParams:{ invoiceId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Invoice',
        referenceName: 'FootPrintManager_invoice_editor',
        component: FootPrintManager_invoice_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoice_editorDialog(
    inParams:{ invoiceId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_invoice_editorComponent,
      'Edit Invoice',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlicenseplate_editor(inParams:{ licenseplateId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Licenseplate',
        referenceName: 'FootPrintManager_licenseplate_editor',
        component: FootPrintManager_licenseplate_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlicenseplate_editorDialog(
    inParams:{ licenseplateId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_licenseplate_editorComponent,
      'Edit Licenseplate',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openload_container_add_orders(inParams:{ loadContainerId?: number, warehouseId?: number, orderTypeId?: number, projectId?: number, ownerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Load Container Add Orders',
        referenceName: 'FootPrintManager_load_container_add_orders',
        component: FootPrintManager_load_container_add_ordersComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openload_container_add_ordersDialog(
    inParams:{ loadContainerId?: number, warehouseId?: number, orderTypeId?: number, projectId?: number, ownerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_load_container_add_ordersComponent,
      'Load Container Add Orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openload_container_editor(inParams:{ loadContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Load Container Editor',
        referenceName: 'FootPrintManager_load_container_editor',
        component: FootPrintManager_load_container_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openload_container_editorDialog(
    inParams:{ loadContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_load_container_editorComponent,
      'Load Container Editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlocation_editor(inParams:{ locationId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Location',
        referenceName: 'FootPrintManager_location_editor',
        component: FootPrintManager_location_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlocation_editorDialog(
    inParams:{ locationId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_location_editorComponent,
      'Edit Location',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlocation_replenishment_editor(inParams:{ locationReplenishmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Rule',
        referenceName: 'FootPrintManager_location_replenishment_editor',
        component: FootPrintManager_location_replenishment_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlocation_replenishment_editorDialog(
    inParams:{ locationReplenishmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_location_replenishment_editorComponent,
      'Edit Rule',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlot_editor(inParams:{ lotId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Lot',
        referenceName: 'FootPrintManager_lot_editor',
        component: FootPrintManager_lot_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlot_editorDialog(
    inParams:{ lotId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_lot_editorComponent,
      'Edit Lot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_editor(inParams:{ materialId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Material',
        referenceName: 'FootPrintManager_material_editor',
        component: FootPrintManager_material_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_editorDialog(
    inParams:{ materialId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_editorComponent,
      'Edit Material',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_kit_editor(inParams:{ material_id: number, project_id: number, packaging_id?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'material_kit_editor',
        referenceName: 'FootPrintManager_material_kit_editor',
        component: FootPrintManager_material_kit_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_kit_editorDialog(
    inParams:{ material_id: number, project_id: number, packaging_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_kit_editorComponent,
      'material_kit_editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_substitutions_editor(inParams:{ material_id: number, packaging_id: number, project_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Material Substitutions',
        referenceName: 'FootPrintManager_material_substitutions_editor',
        component: FootPrintManager_material_substitutions_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_substitutions_editorDialog(
    inParams:{ material_id: number, packaging_id: number, project_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_substitutions_editorComponent,
      'Material Substitutions',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openowner_editor(inParams:{ ownerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Owner',
        referenceName: 'FootPrintManager_owner_editor',
        component: FootPrintManager_owner_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openowner_editorDialog(
    inParams:{ ownerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_owner_editorComponent,
      'Edit Owner',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpack_verification_completion_editor(inParams:{ shippingContainerId: number, startVerificationTaskId: number, instructions?: { entity?: string, code?: string, instruction?: string, isCompleted?: boolean }[], scannedValues?: { scannedValue: string, lotId: number, packagingId: number }[], properties?: { containerType?: string, dimensions?: string, weight?: string }, targetLocationId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Complete verification',
        referenceName: 'FootPrintManager_pack_verification_completion_editor',
        component: FootPrintManager_pack_verification_completion_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpack_verification_completion_editorDialog(
    inParams:{ shippingContainerId: number, startVerificationTaskId: number, instructions?: { entity?: string, code?: string, instruction?: string, isCompleted?: boolean }[], scannedValues?: { scannedValue: string, lotId: number, packagingId: number }[], properties?: { containerType?: string, dimensions?: string, weight?: string }, targetLocationId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pack_verification_completion_editorComponent,
      'Complete verification',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpack_verification_shipping_container_properties_editor(inParams:{ shippingContainerId: number, startVerificationTaskId: number, targetLocationId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Shipping container properties',
        referenceName: 'FootPrintManager_pack_verification_shipping_container_properties_editor',
        component: FootPrintManager_pack_verification_shipping_container_properties_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpack_verification_shipping_container_properties_editorDialog(
    inParams:{ shippingContainerId: number, startVerificationTaskId: number, targetLocationId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pack_verification_shipping_container_properties_editorComponent,
      'Shipping container properties',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpack_verification_verify_container_editor(inParams:{ shippingContainerId: number, startVerificationTaskId: number, isBlind?: boolean, targetLocationId?: number, isReadOnly?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Verify Container',
        referenceName: 'FootPrintManager_pack_verification_verify_container_editor',
        component: FootPrintManager_pack_verification_verify_container_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpack_verification_verify_container_editorDialog(
    inParams:{ shippingContainerId: number, startVerificationTaskId: number, isBlind?: boolean, targetLocationId?: number, isReadOnly?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ scannedValues?: { scannedValue?: string, materialId?: number, serialNumberId?: number, isVerified?: boolean, reasons?: string[], lotId?: number, packagingId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pack_verification_verify_container_editorComponent,
      'Verify Container',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openproject_editor(inParams:{ projectId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Project',
        referenceName: 'FootPrintManager_project_editor',
        component: FootPrintManager_project_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openproject_editorDialog(
    inParams:{ projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_project_editorComponent,
      'Edit Project',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Purchase Order',
        referenceName: 'FootPrintManager_purchase_order_editor',
        component: FootPrintManager_purchase_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_purchase_order_editorComponent,
      'Edit Purchase Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreason_code_editor(inParams:{ reasonCodeId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'reason_code_editor',
        referenceName: 'FootPrintManager_reason_code_editor',
        component: FootPrintManager_reason_code_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreason_code_editorDialog(
    inParams:{ reasonCodeId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_reason_code_editorComponent,
      'reason_code_editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreturn_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Return Order',
        referenceName: 'FootPrintManager_return_order_editor',
        component: FootPrintManager_return_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreturn_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_return_order_editorComponent,
      'Edit Return Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Sales Order',
        referenceName: 'FootPrintManager_sales_order_editor',
        component: FootPrintManager_sales_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_editorComponent,
      'Edit Sales Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_line_kit_editor(inParams:{ material_id: number, project_id: number, packaging_id: number, line_number: number, line_status_id: number, packaged_amount: number, order_id: number, shipment_id: number, warehouse_id: number, order_status_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Sale order line kit editor',
        referenceName: 'FootPrintManager_sales_order_line_kit_editor',
        component: FootPrintManager_sales_order_line_kit_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_line_kit_editorDialog(
    inParams:{ material_id: number, project_id: number, packaging_id: number, line_number: number, line_status_id: number, packaged_amount: number, order_id: number, shipment_id: number, warehouse_id: number, order_status_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_line_kit_editorComponent,
      'Sale order line kit editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openserialnumber_editor(inParams:{ serialnumberId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Serialnumber',
        referenceName: 'FootPrintManager_serialnumber_editor',
        component: FootPrintManager_serialnumber_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openserialnumber_editorDialog(
    inParams:{ serialnumberId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_serialnumber_editorComponent,
      'Edit Serialnumber',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshipping_container_editor(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Container',
        referenceName: 'FootPrintManager_shipping_container_editor',
        component: FootPrintManager_shipping_container_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshipping_container_editorDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_shipping_container_editorComponent,
      'Container',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentask_assignment_editor(inParams:{ taskId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Task',
        referenceName: 'FootPrintManager_task_assignment_editor',
        component: FootPrintManager_task_assignment_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentask_assignment_editorDialog(
    inParams:{ taskId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ isConfirmed?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_task_assignment_editorComponent,
      'Task',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransload_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Transload Order',
        referenceName: 'FootPrintManager_transload_order_editor',
        component: FootPrintManager_transload_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransload_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_transload_order_editorComponent,
      'Edit Transload Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_editor(inParams:{ warehouseId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Warehouse',
        referenceName: 'FootPrintManager_warehouse_editor',
        component: FootPrintManager_warehouse_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_editorDialog(
    inParams:{ warehouseId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_editorComponent,
      'Edit Warehouse',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfer_editor(inParams:{ warehouseTransferId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'warehouse_transfer_editor',
        referenceName: 'FootPrintManager_warehouse_transfer_editor',
        component: FootPrintManager_warehouse_transfer_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfer_editorDialog(
    inParams:{ warehouseTransferId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_editorComponent,
      'warehouse_transfer_editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfer_inbound_order_editor(inParams:{ orderId: number, warehouseTransferId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Inbound Order',
        referenceName: 'FootPrintManager_warehouse_transfer_inbound_order_editor',
        component: FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfer_inbound_order_editorDialog(
    inParams:{ orderId: number, warehouseTransferId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_inbound_order_editorComponent,
      'Edit Inbound Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfer_outbound_order_editor(inParams:{ orderId: number, warehouseTransferId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit outbound order',
        referenceName: 'FootPrintManager_warehouse_transfer_outbound_order_editor',
        component: FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfer_outbound_order_editorDialog(
    inParams:{ orderId: number, warehouseTransferId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_outbound_order_editorComponent,
      'Edit outbound order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwave_editor(inParams:{ waveId: number, projectId?: number, ownerId?: number, warehouseId?: number, showCreate?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Wave editor',
        referenceName: 'FootPrintManager_wave_editor',
        component: FootPrintManager_wave_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwave_editorDialog(
    inParams:{ waveId: number, projectId?: number, ownerId?: number, warehouseId?: number, showCreate?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_wave_editorComponent,
      'Wave editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwork_class_editor(inParams:{ workClassId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Work class',
        referenceName: 'FootPrintManager_work_class_editor',
        component: FootPrintManager_work_class_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwork_class_editorDialog(
    inParams:{ workClassId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ isDeleted?: boolean, isConfirmed?: boolean, promises?: any[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_work_class_editorComponent,
      'Work class',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwork_order_editor(inParams:{ workOrderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Work Order',
        referenceName: 'FootPrintManager_work_order_editor',
        component: FootPrintManager_work_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwork_order_editorDialog(
    inParams:{ workOrderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_work_order_editorComponent,
      'Edit Work Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openaccessorial_tasks_grid(inParams:{ workOrderId?: number, projectId?: number, entityStatusId: number, orderId?: number, warehouseId: number, shippingContainerId?: number, showAllOpCodes?: boolean, shipmentId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Accessorials',
        referenceName: 'FootPrintManager_accessorial_tasks_grid',
        component: FootPrintManager_accessorial_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openaccessorial_tasks_gridDialog(
    inParams:{ workOrderId?: number, projectId?: number, entityStatusId: number, orderId?: number, warehouseId: number, shippingContainerId?: number, showAllOpCodes?: boolean, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_accessorial_tasks_gridComponent,
      'Accessorials',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openaccounts_grid(inParams:{ ownerId: number, accountTypeId: number, typeIds: number[], allowSelection: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Addresses',
        referenceName: 'FootPrintManager_accounts_grid',
        component: FootPrintManager_accounts_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openaccounts_gridDialog(
    inParams:{ ownerId: number, accountTypeId: number, typeIds: number[], allowSelection: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ accountId?: number, contactId?: number, addressId?: number, confirm?: boolean, ownerId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_accounts_gridComponent,
      'Addresses',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openactivity_grid(inParams:{ project_ids?: number[], owner_ids?: number[], warehouse_ids?: number[], operation_codes?: number[], status_ids?: number[], location_id?: number, seven_days_before_flag?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Activity Grid',
        referenceName: 'FootPrintManager_activity_grid',
        component: FootPrintManager_activity_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openactivity_gridDialog(
    inParams:{ project_ids?: number[], owner_ids?: number[], warehouse_ids?: number[], operation_codes?: number[], status_ids?: number[], location_id?: number, seven_days_before_flag?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_activity_gridComponent,
      'Activity Grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openasn_order_children_tasks_grid(inParams:{ taskId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Asn order children tasks',
        referenceName: 'FootPrintManager_asn_order_children_tasks_grid',
        component: FootPrintManager_asn_order_children_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openasn_order_children_tasks_gridDialog(
    inParams:{ taskId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_asn_order_children_tasks_gridComponent,
      'Asn order children tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openasn_order_licenseplates_grid(inParams:{ order_id: number, order_status_id: number, project_id: number, can_unreceive?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'License plates',
        referenceName: 'FootPrintManager_asn_order_licenseplates_grid',
        component: FootPrintManager_asn_order_licenseplates_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openasn_order_licenseplates_gridDialog(
    inParams:{ order_id: number, order_status_id: number, project_id: number, can_unreceive?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_asn_order_licenseplates_gridComponent,
      'License plates',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openasn_order_licenseplates_receiving_tasks_grid(inParams:{ licenseplateId: number, orderId: number, projectId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Receiving tasks',
        referenceName: 'FootPrintManager_asn_order_licenseplates_receiving_tasks_grid',
        component: FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openasn_order_licenseplates_receiving_tasks_gridDialog(
    inParams:{ licenseplateId: number, orderId: number, projectId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent,
      'Receiving tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openasn_order_receiving_tasks_grid(inParams:{ orderId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Receiving tasks',
        referenceName: 'FootPrintManager_asn_order_receiving_tasks_grid',
        component: FootPrintManager_asn_order_receiving_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openasn_order_receiving_tasks_gridDialog(
    inParams:{ orderId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_asn_order_receiving_tasks_gridComponent,
      'Receiving tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openasn_order_tasks_grid(inParams:{ orderId: number, shipmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Asn order tasks',
        referenceName: 'FootPrintManager_asn_order_tasks_grid',
        component: FootPrintManager_asn_order_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openasn_order_tasks_gridDialog(
    inParams:{ orderId: number, shipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_asn_order_tasks_gridComponent,
      'Asn order tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openasn_orderlines_grid(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order lines',
        referenceName: 'FootPrintManager_asn_orderlines_grid',
        component: FootPrintManager_asn_orderlines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openasn_orderlines_gridDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_asn_orderlines_gridComponent,
      'Order lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openassigned_orders_on_wave_grid(inParams:{ waveId: number, waveStatusId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Assigned orders',
        referenceName: 'FootPrintManager_assigned_orders_on_wave_grid',
        component: FootPrintManager_assigned_orders_on_wave_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openassigned_orders_on_wave_gridDialog(
    inParams:{ waveId: number, waveStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ refresh?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_assigned_orders_on_wave_gridComponent,
      'Assigned orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openaudit_serial_numbers_grid(inParams:{ scannedBarcodes: { barcode: string, serialNumber: string, isValid: boolean, serialNumberId: number }[], watchList: { barcode: string, serialNumber: string }[], context?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Audit serial numbers',
        referenceName: 'FootPrintManager_audit_serial_numbers_grid',
        component: FootPrintManager_audit_serial_numbers_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openaudit_serial_numbers_gridDialog(
    inParams:{ scannedBarcodes: { barcode: string, serialNumber: string, isValid: boolean, serialNumberId: number }[], watchList: { barcode: string, serialNumber: string }[], context?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_audit_serial_numbers_gridComponent,
      'Audit serial numbers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openavailable_inventory_grid(inParams:{ materialId: number, warehouseId: number, recommendedAmount?: number, taskId?: number, lotId?: number, vendorLotId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Available inventory',
        referenceName: 'FootPrintManager_available_inventory_grid',
        component: FootPrintManager_available_inventory_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openavailable_inventory_gridDialog(
    inParams:{ materialId: number, warehouseId: number, recommendedAmount?: number, taskId?: number, lotId?: number, vendorLotId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_available_inventory_gridComponent,
      'Available inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_contract_lines_grid(inParams:{ contractId: number, strategyName?: string, edit?: boolean, hide?: string[], statusId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing contract lines',
        referenceName: 'FootPrintManager_billing_contract_lines_grid',
        component: FootPrintManager_billing_contract_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_contract_lines_gridDialog(
    inParams:{ contractId: number, strategyName?: string, edit?: boolean, hide?: string[], statusId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ updated?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_contract_lines_gridComponent,
      'Billing contract lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_contracts_grid(inParams:{ ownerId?: number, projectId?: number, statusIds?: number[], endDate?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing contracts',
        referenceName: 'FootPrintManager_billing_contracts_grid',
        component: FootPrintManager_billing_contracts_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_contracts_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, statusIds?: number[], endDate?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_contracts_gridComponent,
      'Billing contracts',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_projects_grid(inParams:{ billingContractLineId: number, edit: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing projects grid',
        referenceName: 'FootPrintManager_billing_projects_grid',
        component: FootPrintManager_billing_projects_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_projects_gridDialog(
    inParams:{ billingContractLineId: number, edit: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_projects_gridComponent,
      'Billing projects grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_rates_grid(inParams:{ billingContractLineId?: number, edit: boolean, typeId: number, accessorialTemplateLineId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing rates',
        referenceName: 'FootPrintManager_billing_rates_grid',
        component: FootPrintManager_billing_rates_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_rates_gridDialog(
    inParams:{ billingContractLineId?: number, edit: boolean, typeId: number, accessorialTemplateLineId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_rates_gridComponent,
      'Billing rates',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_records_by_invoiceline_grid(inParams:{ invoiceLineId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing records by invoice line',
        referenceName: 'FootPrintManager_billing_records_by_invoiceline_grid',
        component: FootPrintManager_billing_records_by_invoiceline_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_records_by_invoiceline_gridDialog(
    inParams:{ invoiceLineId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_records_by_invoiceline_gridComponent,
      'Billing records by invoice line',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_records_grid(inParams:{ ownerId?: number, projectId?: number, materialIds?: number[], warehouseIds?: number[], orderIds?: number[], includeCancelled?: boolean, includeInvoiced?: boolean, shipmentIds?: number[], workOrderId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing records',
        referenceName: 'FootPrintManager_billing_records_grid',
        component: FootPrintManager_billing_records_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_records_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, materialIds?: number[], warehouseIds?: number[], orderIds?: number[], includeCancelled?: boolean, includeInvoiced?: boolean, shipmentIds?: number[], workOrderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_records_gridComponent,
      'Billing records',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_supplemental_fees_grid(inParams:{ billingContractLineId: number, edit: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing supplemental fees grid',
        referenceName: 'FootPrintManager_billing_supplemental_fees_grid',
        component: FootPrintManager_billing_supplemental_fees_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_supplemental_fees_gridDialog(
    inParams:{ billingContractLineId: number, edit: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_supplemental_fees_gridComponent,
      'Billing supplemental fees grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openblind_shipping_container_return_tasks_grid(inParams:{ warehouseId?: number, locationId?: number, sourceShippingContainerId: number, sourceLicensePlateId?: number, inputScan?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Return tasks',
        referenceName: 'FootPrintManager_blind_shipping_container_return_tasks_grid',
        component: FootPrintManager_blind_shipping_container_return_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openblind_shipping_container_return_tasks_gridDialog(
    inParams:{ warehouseId?: number, locationId?: number, sourceShippingContainerId: number, sourceLicensePlateId?: number, inputScan?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_blind_shipping_container_return_tasks_gridComponent,
      'Return tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencarriers_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Carriers',
        referenceName: 'FootPrintManager_carriers_grid',
        component: FootPrintManager_carriers_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencarriers_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_carriers_gridComponent,
      'Carriers',
      mode,
      dialogSize
    )
  }
  public opencarrierservices_grid(inParams:{ carrierId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Carrier services',
        referenceName: 'FootPrintManager_carrierservices_grid',
        component: FootPrintManager_carrierservices_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencarrierservices_gridDialog(
    inParams:{ carrierId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_carrierservices_gridComponent,
      'Carrier services',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencarrierservicetypes_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Service types',
        referenceName: 'FootPrintManager_carrierservicetypes_grid',
        component: FootPrintManager_carrierservicetypes_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencarrierservicetypes_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_carrierservicetypes_gridComponent,
      'Service types',
      mode,
      dialogSize
    )
  }
  public openchild_serialnumbers_grid(inParams:{ chainHead: number, archived: boolean, fullTextSearch?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Child serial numbers',
        referenceName: 'FootPrintManager_child_serialnumbers_grid',
        component: FootPrintManager_child_serialnumbers_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openchild_serialnumbers_gridDialog(
    inParams:{ chainHead: number, archived: boolean, fullTextSearch?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_child_serialnumbers_gridComponent,
      'Child serial numbers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencontact_addresses_grid(inParams:{ entity: string, entityId: number, typeIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Addresses',
        referenceName: 'FootPrintManager_contact_addresses_grid',
        component: FootPrintManager_contact_addresses_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencontact_addresses_gridDialog(
    inParams:{ entity: string, entityId: number, typeIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_contact_addresses_gridComponent,
      'Addresses',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencontainer_type_assignment_grid(inParams:{ ownerId?: number, projectId?: number, materialId?: number, enabled?: boolean, assignmentLevel?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Container assignment grid',
        referenceName: 'FootPrintManager_container_type_assignment_grid',
        component: FootPrintManager_container_type_assignment_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencontainer_type_assignment_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, materialId?: number, enabled?: boolean, assignmentLevel?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_container_type_assignment_gridComponent,
      'Container assignment grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencontainer_types_grid(inParams:{ ownerId?: number, projectId?: number, materialId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Container types',
        referenceName: 'FootPrintManager_container_types_grid',
        component: FootPrintManager_container_types_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencontainer_types_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, materialId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_container_types_gridComponent,
      'Container types',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencount_tasks_grid(inParams:{ inventoryCountId?: number, allowEdit?: boolean, statusIds?: number[], warehouseIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Count tasks',
        referenceName: 'FootPrintManager_count_tasks_grid',
        component: FootPrintManager_count_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencount_tasks_gridDialog(
    inParams:{ inventoryCountId?: number, allowEdit?: boolean, statusIds?: number[], warehouseIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_count_tasks_gridComponent,
      'Count tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencount_tasks_no_filter_grid(inParams:{ allowEdit?: boolean, statusIds?: number[], warehouseIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Count tasks',
        referenceName: 'FootPrintManager_count_tasks_no_filter_grid',
        component: FootPrintManager_count_tasks_no_filter_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencount_tasks_no_filter_gridDialog(
    inParams:{ allowEdit?: boolean, statusIds?: number[], warehouseIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_count_tasks_no_filter_gridComponent,
      'Count tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustomer_return_shipping_containers_grid(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Shipping containers',
        referenceName: 'FootPrintManager_customer_return_shipping_containers_grid',
        component: FootPrintManager_customer_return_shipping_containers_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustomer_return_shipping_containers_gridDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_customer_return_shipping_containers_gridComponent,
      'Shipping containers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendock_appointment_types_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock appointment types',
        referenceName: 'FootPrintManager_dock_appointment_types_grid',
        component: FootPrintManager_dock_appointment_types_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opendock_appointment_types_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointment_types_gridComponent,
      'Dock appointment types',
      mode,
      dialogSize
    )
  }
  public opendock_appointments_dashboard_grid(inParams:{ ownerId?: number, projectId?: number, warehouseIds: number[], typeIds?: number[], statusIds?: number[], fromDate?: string, toDate?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock appointments dashboard',
        referenceName: 'FootPrintManager_dock_appointments_dashboard_grid',
        component: FootPrintManager_dock_appointments_dashboard_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_appointments_dashboard_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseIds: number[], typeIds?: number[], statusIds?: number[], fromDate?: string, toDate?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointments_dashboard_gridComponent,
      'Dock appointments dashboard',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendock_appointments_grid(inParams:{ typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds: number[], fromDate?: string, toDate?: string, dockDoorIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock appointments',
        referenceName: 'FootPrintManager_dock_appointments_grid',
        component: FootPrintManager_dock_appointments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_appointments_gridDialog(
    inParams:{ typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds: number[], fromDate?: string, toDate?: string, dockDoorIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointments_gridComponent,
      'Dock appointments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendock_appointments_selection_grid(inParams:{ typeIds?: number[], carrierId?: number, statusIds?: number[], warehouseId?: number, scheduledProjectId?: number, excludedDockAppointmentIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock appointments',
        referenceName: 'FootPrintManager_dock_appointments_selection_grid',
        component: FootPrintManager_dock_appointments_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_appointments_selection_gridDialog(
    inParams:{ typeIds?: number[], carrierId?: number, statusIds?: number[], warehouseId?: number, scheduledProjectId?: number, excludedDockAppointmentIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ dockAppointmentId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointments_selection_gridComponent,
      'Dock appointments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openentity_attachments_grid(inParams:{ entityType: string, entityKeys: { name?: string, value?: any }[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Attachments',
        referenceName: 'FootPrintManager_entity_attachments_grid',
        component: FootPrintManager_entity_attachments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openentity_attachments_gridDialog(
    inParams:{ entityType: string, entityKeys: { name?: string, value?: any }[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_entity_attachments_gridComponent,
      'Attachments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openentity_import_requests_grid(inParams:{ statusCodes?: string[], projects?: string[], warehouses?: string[], singleWarehouse?: string, singleProjectName?: string, singleProjectLookupCode?: string, requestId?: string, projectsExact?: string[], warehousesExact?: string[], dateFrom?: string, dateTo?: string, dateType?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Entity imports',
        referenceName: 'FootPrintManager_entity_import_requests_grid',
        component: FootPrintManager_entity_import_requests_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openentity_import_requests_gridDialog(
    inParams:{ statusCodes?: string[], projects?: string[], warehouses?: string[], singleWarehouse?: string, singleProjectName?: string, singleProjectLookupCode?: string, requestId?: string, projectsExact?: string[], warehousesExact?: string[], dateFrom?: string, dateTo?: string, dateType?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_entity_import_requests_gridComponent,
      'Entity imports',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openentity_user_defined_field_values_grid(inParams:{ entityType: string, entityKeys: { name?: string, value?: any }[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Custom fields',
        referenceName: 'FootPrintManager_entity_user_defined_field_values_grid',
        component: FootPrintManager_entity_user_defined_field_values_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openentity_user_defined_field_values_gridDialog(
    inParams:{ entityType: string, entityKeys: { name?: string, value?: any }[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_entity_user_defined_field_values_gridComponent,
      'Custom fields',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openfield_selector_grid(inParams:{ Columns: { Key?: string, Name?: string, IsSelected?: boolean }[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Select fields',
        referenceName: 'FootPrintManager_field_selector_grid',
        component: FootPrintManager_field_selector_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openfield_selector_gridDialog(
    inParams:{ Columns: { Key?: string, Name?: string, IsSelected?: boolean }[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ Columns?: { Key?: string, Name?: string, IsSelected?: boolean }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_field_selector_gridComponent,
      'Select fields',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinbound_orders_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], orderClassIds?: number[], reference?: string, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inbound orders',
        referenceName: 'FootPrintManager_inbound_orders_grid',
        component: FootPrintManager_inbound_orders_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinbound_orders_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], orderClassIds?: number[], reference?: string, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inbound_orders_gridComponent,
      'Inbound orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinspection_operation_codes_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Operation codes',
        referenceName: 'FootPrintManager_inspection_operation_codes_grid',
        component: FootPrintManager_inspection_operation_codes_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinspection_operation_codes_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inspection_operation_codes_gridComponent,
      'Operation codes',
      mode,
      dialogSize
    )
  }
  public openinspection_tasks_grid(inParams:{ warehouseId: number, operationCodeIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inspection tasks',
        referenceName: 'FootPrintManager_inspection_tasks_grid',
        component: FootPrintManager_inspection_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinspection_tasks_gridDialog(
    inParams:{ warehouseId: number, operationCodeIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inspection_tasks_gridComponent,
      'Inspection tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinstructions_grid(inParams:{ entityName: string, enabled: boolean, typeIds?: number[], entityKey1: number, entityKey2?: number, entityKey3?: number, entityKey4?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Instructions',
        referenceName: 'FootPrintManager_instructions_grid',
        component: FootPrintManager_instructions_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinstructions_gridDialog(
    inParams:{ entityName: string, enabled: boolean, typeIds?: number[], entityKey1: number, entityKey2?: number, entityKey3?: number, entityKey4?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_instructions_gridComponent,
      'Instructions',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_by_licenseplate_selection_grid(inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by lp',
        referenceName: 'FootPrintManager_inventory_by_licenseplate_selection_grid',
        component: FootPrintManager_inventory_by_licenseplate_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_by_licenseplate_selection_gridDialog(
    inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, licenseplateId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_licenseplate_selection_gridComponent,
      'Inventory by lp',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_by_licenseplate_single_selection_grid(inParams:{ warehouseId: number, materialId: number, packagingId: number, lotId?: number, projectId: number, packagedAmountRequest: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by lp single',
        referenceName: 'FootPrintManager_inventory_by_licenseplate_single_selection_grid',
        component: FootPrintManager_inventory_by_licenseplate_single_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_by_licenseplate_single_selection_gridDialog(
    inParams:{ warehouseId: number, materialId: number, packagingId: number, lotId?: number, projectId: number, packagedAmountRequest: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, licenseplateId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_licenseplate_single_selection_gridComponent,
      'Inventory by lp single',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_by_location_selection_grid(inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by location',
        referenceName: 'FootPrintManager_inventory_by_location_selection_grid',
        component: FootPrintManager_inventory_by_location_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_by_location_selection_gridDialog(
    inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, locationId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_location_selection_gridComponent,
      'Inventory by location',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_by_lot_selection_grid(inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by lot',
        referenceName: 'FootPrintManager_inventory_by_lot_selection_grid',
        component: FootPrintManager_inventory_by_lot_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_by_lot_selection_gridDialog(
    inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, packagingId?: number, packagedAmount?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_lot_selection_gridComponent,
      'Inventory by lot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_by_lot_single_selection_grid(inParams:{ warehouseId: number, materialId: number, packagingId: number, projectId: number, packagedAmountRequest: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by lot single',
        referenceName: 'FootPrintManager_inventory_by_lot_single_selection_grid',
        component: FootPrintManager_inventory_by_lot_single_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_by_lot_single_selection_gridDialog(
    inParams:{ warehouseId: number, materialId: number, packagingId: number, projectId: number, packagedAmountRequest: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_lot_single_selection_gridComponent,
      'Inventory by lot single',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_by_serialnumber_selection_grid(inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by serialnumber',
        referenceName: 'FootPrintManager_inventory_by_serialnumber_selection_grid',
        component: FootPrintManager_inventory_by_serialnumber_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_by_serialnumber_selection_gridDialog(
    inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { serial_number_id?: number, lookup_code?: string, chain_head?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_serialnumber_selection_gridComponent,
      'Inventory by serialnumber',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_by_serialnumber_single_selection_grid(inParams:{ warehouseId: number, materialId: number, packagingId: number, lotId?: number, projectId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by sn single',
        referenceName: 'FootPrintManager_inventory_by_serialnumber_single_selection_grid',
        component: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_by_serialnumber_single_selection_gridDialog(
    inParams:{ warehouseId: number, materialId: number, packagingId: number, lotId?: number, projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { serialnumberId?: number, lotId?: number, vendorLotId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent,
      'Inventory by sn single',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_counts_grid(inParams:{ warehouseIds?: number[], statusIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory counts',
        referenceName: 'FootPrintManager_inventory_counts_grid',
        component: FootPrintManager_inventory_counts_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_counts_gridDialog(
    inParams:{ warehouseIds?: number[], statusIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_counts_gridComponent,
      'Inventory counts',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_licenseplate_grid(inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by LP',
        referenceName: 'FootPrintManager_inventory_licenseplate_grid',
        component: FootPrintManager_inventory_licenseplate_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_licenseplate_gridDialog(
    inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_licenseplate_gridComponent,
      'Inventory by LP',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_licenseplate_snapshot_grid(inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory licenseplate snapshot',
        referenceName: 'FootPrintManager_inventory_licenseplate_snapshot_grid',
        component: FootPrintManager_inventory_licenseplate_snapshot_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_licenseplate_snapshot_gridDialog(
    inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_licenseplate_snapshot_gridComponent,
      'Inventory licenseplate snapshot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_location_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], lotId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory',
        referenceName: 'FootPrintManager_inventory_location_grid',
        component: FootPrintManager_inventory_location_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_location_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], lotId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_location_gridComponent,
      'Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_lot_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by lot',
        referenceName: 'FootPrintManager_inventory_lot_grid',
        component: FootPrintManager_inventory_lot_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_lot_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_lot_gridComponent,
      'Inventory by lot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_lot_snapshot_grid(inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory lot snapshot',
        referenceName: 'FootPrintManager_inventory_lot_snapshot_grid',
        component: FootPrintManager_inventory_lot_snapshot_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_lot_snapshot_gridDialog(
    inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_lot_snapshot_gridComponent,
      'Inventory lot snapshot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_material_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by material',
        referenceName: 'FootPrintManager_inventory_material_grid',
        component: FootPrintManager_inventory_material_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_material_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_material_gridComponent,
      'Inventory by material',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_material_snapshot_grid(inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory material snapshot',
        referenceName: 'FootPrintManager_inventory_material_snapshot_grid',
        component: FootPrintManager_inventory_material_snapshot_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_material_snapshot_gridDialog(
    inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_material_snapshot_gridComponent,
      'Inventory material snapshot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_measurement_units_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Packages',
        referenceName: 'FootPrintManager_inventory_measurement_units_grid',
        component: FootPrintManager_inventory_measurement_units_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinventory_measurement_units_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_measurement_units_gridComponent,
      'Packages',
      mode,
      dialogSize
    )
  }
  public openinventory_parent_licenseplate_grid(inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number[], materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by parent lp',
        referenceName: 'FootPrintManager_inventory_parent_licenseplate_grid',
        component: FootPrintManager_inventory_parent_licenseplate_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_parent_licenseplate_gridDialog(
    inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number[], materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_parent_licenseplate_gridComponent,
      'Inventory by parent lp',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_statuses_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory statuses',
        referenceName: 'FootPrintManager_inventory_statuses_grid',
        component: FootPrintManager_inventory_statuses_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinventory_statuses_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_statuses_gridComponent,
      'Inventory statuses',
      mode,
      dialogSize
    )
  }
  public openinventory_tasks_grid(inParams:{ lotId?: number, materialIds?: number[], projectId?: number, licenseplateId?: number, locationId?: number, ownerId?: number, warehouseIds?: number[], materialId?: number, serialnumberId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory tasks',
        referenceName: 'FootPrintManager_inventory_tasks_grid',
        component: FootPrintManager_inventory_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_tasks_gridDialog(
    inParams:{ lotId?: number, materialIds?: number[], projectId?: number, licenseplateId?: number, locationId?: number, ownerId?: number, warehouseIds?: number[], materialId?: number, serialnumberId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_tasks_gridComponent,
      'Inventory tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_transfer_lines_grid(inParams:{ inventoryTransferId: number, sourceProjectId?: number, targetProjectId?: number, warehouseId?: number, can_update?: boolean, can_delete?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory transfer lines',
        referenceName: 'FootPrintManager_inventory_transfer_lines_grid',
        component: FootPrintManager_inventory_transfer_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_transfer_lines_gridDialog(
    inParams:{ inventoryTransferId: number, sourceProjectId?: number, targetProjectId?: number, warehouseId?: number, can_update?: boolean, can_delete?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_transfer_lines_gridComponent,
      'Inventory transfer lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_transfers_grid(inParams:{ warehouses?: number[], sourceProjectId?: number, targetProjectId?: number, statusIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory transfers',
        referenceName: 'FootPrintManager_inventory_transfers_grid',
        component: FootPrintManager_inventory_transfers_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_transfers_gridDialog(
    inParams:{ warehouses?: number[], sourceProjectId?: number, targetProjectId?: number, statusIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_transfers_gridComponent,
      'Inventory transfers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoice_lines_grid(inParams:{ invoiceId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Invoice lines',
        referenceName: 'FootPrintManager_invoice_lines_grid',
        component: FootPrintManager_invoice_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoice_lines_gridDialog(
    inParams:{ invoiceId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_invoice_lines_gridComponent,
      'Invoice lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoices_grid(inParams:{ ownerId?: number, projectId?: number, statusIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Invoices',
        referenceName: 'FootPrintManager_invoices_grid',
        component: FootPrintManager_invoices_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoices_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, statusIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_invoices_gridComponent,
      'Invoices',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlicenseplate_grid(inParams:{ locationId: number, lotId: number, packagedId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Select a licenseplate',
        referenceName: 'FootPrintManager_licenseplate_grid',
        component: FootPrintManager_licenseplate_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlicenseplate_gridDialog(
    inParams:{ locationId: number, lotId: number, packagedId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, licenseplateId?: number, packagedAmount?: number, packagingId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_licenseplate_gridComponent,
      'Select a licenseplate',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openload_container_orders_grid(inParams:{ loadContainerId: number, edit?: boolean, warehouseId?: number, orderTypeId?: number, status_id?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Orders by load container',
        referenceName: 'FootPrintManager_load_container_orders_grid',
        component: FootPrintManager_load_container_orders_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openload_container_orders_gridDialog(
    inParams:{ loadContainerId: number, edit?: boolean, warehouseId?: number, orderTypeId?: number, status_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ warehouseId?: number, orderTypeId?: number, ownerId?: number, projectId?: number, carrierId?: number, createdCount?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_load_container_orders_gridComponent,
      'Orders by load container',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openload_container_unassigned_orders_grid(inParams:{ ownerId?: number, projectId?: number, statusIds?: number[], orderTypeId?: number, warehouseId?: number, loadContainerId?: number, lookupCode?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Unassigned orders for load containers',
        referenceName: 'FootPrintManager_load_container_unassigned_orders_grid',
        component: FootPrintManager_load_container_unassigned_orders_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openload_container_unassigned_orders_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, statusIds?: number[], orderTypeId?: number, warehouseId?: number, loadContainerId?: number, lookupCode?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_load_container_unassigned_orders_gridComponent,
      'Unassigned orders for load containers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openload_containers_grid(inParams:{ statusIds?: number[], ownerId?: number, orderTypeIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Load containers',
        referenceName: 'FootPrintManager_load_containers_grid',
        component: FootPrintManager_load_containers_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openload_containers_gridDialog(
    inParams:{ statusIds?: number[], ownerId?: number, orderTypeIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_load_containers_gridComponent,
      'Load containers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlocation_projects_grid(inParams:{ locationId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Location project assignments',
        referenceName: 'FootPrintManager_location_projects_grid',
        component: FootPrintManager_location_projects_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlocation_projects_gridDialog(
    inParams:{ locationId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_location_projects_gridComponent,
      'Location project assignments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlocations_grid(inParams:{ warehouseId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Locations',
        referenceName: 'FootPrintManager_locations_grid',
        component: FootPrintManager_locations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlocations_gridDialog(
    inParams:{ warehouseId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_locations_gridComponent,
      'Locations',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlocations_navigational_grid(inParams:{ warehouseId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Locations',
        referenceName: 'FootPrintManager_locations_navigational_grid',
        component: FootPrintManager_locations_navigational_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlocations_navigational_gridDialog(
    inParams:{ warehouseId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_locations_navigational_gridComponent,
      'Locations',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlocations_replenishments_grid(inParams:{ materialId?: number[], warehouseIds?: number[], locationId?: number[], projectId?: number, ownerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Replenishments',
        referenceName: 'FootPrintManager_locations_replenishments_grid',
        component: FootPrintManager_locations_replenishments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlocations_replenishments_gridDialog(
    inParams:{ materialId?: number[], warehouseIds?: number[], locationId?: number[], projectId?: number, ownerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_locations_replenishments_gridComponent,
      'Replenishments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlocations_selector_grid(inParams:{ warehouseId: number, selectedLocationIds?: number[], isExclude?: boolean, allowEdit: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Location selector',
        referenceName: 'FootPrintManager_locations_selector_grid',
        component: FootPrintManager_locations_selector_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlocations_selector_gridDialog(
    inParams:{ warehouseId: number, selectedLocationIds?: number[], isExclude?: boolean, allowEdit: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ selectedLocationIds?: number[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_locations_selector_gridComponent,
      'Location selector',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlots_grid(inParams:{ ownerIds?: number[], projectIds?: number[], materialIds?: number[], statusIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Lots grid',
        referenceName: 'FootPrintManager_lots_grid',
        component: FootPrintManager_lots_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlots_gridDialog(
    inParams:{ ownerIds?: number[], projectIds?: number[], materialIds?: number[], statusIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_lots_gridComponent,
      'Lots grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlots_grid_flat(inParams:{ ownerIds?: number[], projectIds?: number[], materialIds?: number[], statusIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Lots grid from flat data source',
        referenceName: 'FootPrintManager_lots_grid_flat',
        component: FootPrintManager_lots_grid_flatComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlots_grid_flatDialog(
    inParams:{ ownerIds?: number[], projectIds?: number[], materialIds?: number[], statusIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_lots_grid_flatComponent,
      'Lots grid from flat data source',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlots_udf_grid(inParams:{ projectId?: number, ownerId?: number, materialIds?: number[], lotId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Lots udf',
        referenceName: 'FootPrintManager_lots_udf_grid',
        component: FootPrintManager_lots_udf_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlots_udf_gridDialog(
    inParams:{ projectId?: number, ownerId?: number, materialIds?: number[], lotId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_lots_udf_gridComponent,
      'Lots udf',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanifesting_shipments_grid(inParams:{ shipmentIds: number[], printerId?: number, length?: number, width?: number, height?: number, weight?: number, utilizeShipmentContainer?: boolean, labelSize?: number, contentsExplanation?: string, containerCount?: number, printerIdPackinglist?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Shipments',
        referenceName: 'FootPrintManager_manifesting_shipments_grid',
        component: FootPrintManager_manifesting_shipments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanifesting_shipments_gridDialog(
    inParams:{ shipmentIds: number[], printerId?: number, length?: number, width?: number, height?: number, weight?: number, utilizeShipmentContainer?: boolean, labelSize?: number, contentsExplanation?: string, containerCount?: number, printerIdPackinglist?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_manifesting_shipments_gridComponent,
      'Shipments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanual_allocations_grid(inParams:{ waveId?: number, orderId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Manual allocations',
        referenceName: 'FootPrintManager_manual_allocations_grid',
        component: FootPrintManager_manual_allocations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanual_allocations_gridDialog(
    inParams:{ waveId?: number, orderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_manual_allocations_gridComponent,
      'Manual allocations',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_components_grid(inParams:{ material_id: number, packaging_id: number, project_id: number, suppress_new_row: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Material components',
        referenceName: 'FootPrintManager_material_components_grid',
        component: FootPrintManager_material_components_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_components_gridDialog(
    inParams:{ material_id: number, packaging_id: number, project_id: number, suppress_new_row: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_components_gridComponent,
      'Material components',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_kits_grid(inParams:{ material_id: number, project_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Material kits',
        referenceName: 'FootPrintManager_material_kits_grid',
        component: FootPrintManager_material_kits_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_kits_gridDialog(
    inParams:{ material_id: number, project_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_kits_gridComponent,
      'Material kits',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_packagings_by_project_grid(inParams:{ materialIds?: number[], ownerId?: number, projectId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Packagings by project',
        referenceName: 'FootPrintManager_material_packagings_by_project_grid',
        component: FootPrintManager_material_packagings_by_project_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_packagings_by_project_gridDialog(
    inParams:{ materialIds?: number[], ownerId?: number, projectId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_packagings_by_project_gridComponent,
      'Packagings by project',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_packagings_grid(inParams:{ materialId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Packagings',
        referenceName: 'FootPrintManager_material_packagings_grid',
        component: FootPrintManager_material_packagings_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_packagings_gridDialog(
    inParams:{ materialId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_packagings_gridComponent,
      'Packagings',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_substitutions_grid(inParams:{ material_id: number, packaging_id: number, project_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Material substitutions',
        referenceName: 'FootPrintManager_material_substitutions_grid',
        component: FootPrintManager_material_substitutions_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_substitutions_gridDialog(
    inParams:{ material_id: number, packaging_id: number, project_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_substitutions_gridComponent,
      'Material substitutions',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterials_by_base_packaging_grid(inParams:{ projectId?: number, ownerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Materials',
        referenceName: 'FootPrintManager_materials_by_base_packaging_grid',
        component: FootPrintManager_materials_by_base_packaging_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterials_by_base_packaging_gridDialog(
    inParams:{ projectId?: number, ownerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_materials_by_base_packaging_gridComponent,
      'Materials',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterials_navigational_grid(inParams:{ ownerId?: number, projectId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Materials',
        referenceName: 'FootPrintManager_materials_navigational_grid',
        component: FootPrintManager_materials_navigational_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterials_navigational_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_materials_navigational_gridComponent,
      'Materials',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterials_selector_grid(inParams:{ selectedMaterialIds?: number[], isExclude?: boolean, allowEdit: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Material selector',
        referenceName: 'FootPrintManager_materials_selector_grid',
        component: FootPrintManager_materials_selector_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterials_selector_gridDialog(
    inParams:{ selectedMaterialIds?: number[], isExclude?: boolean, allowEdit: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ selectedMaterialIds?: number[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_materials_selector_gridComponent,
      'Material selector',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterials_udf_grid(inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Materials advanced',
        referenceName: 'FootPrintManager_materials_udf_grid',
        component: FootPrintManager_materials_udf_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterials_udf_gridDialog(
    inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_materials_udf_gridComponent,
      'Materials advanced',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openorderaddresses_grid(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order addresses',
        referenceName: 'FootPrintManager_orderaddresses_grid',
        component: FootPrintManager_orderaddresses_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openorderaddresses_gridDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_orderaddresses_gridComponent,
      'Order addresses',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openoutbound_orders_eligible_for_return_grid(inParams:{ warehouseIds?: number[], ownerId?: number, projectId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Completed outbound orders',
        referenceName: 'FootPrintManager_outbound_orders_eligible_for_return_grid',
        component: FootPrintManager_outbound_orders_eligible_for_return_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openoutbound_orders_eligible_for_return_gridDialog(
    inParams:{ warehouseIds?: number[], ownerId?: number, projectId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ orderIds?: number[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_outbound_orders_eligible_for_return_gridComponent,
      'Completed outbound orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openoutbound_orders_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, materialIds?: number[], waveId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Outbound orders',
        referenceName: 'FootPrintManager_outbound_orders_grid',
        component: FootPrintManager_outbound_orders_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openoutbound_orders_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, materialIds?: number[], waveId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_outbound_orders_gridComponent,
      'Outbound orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openowners_navigation_grid(inParams:{ ownerStatusId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Owners',
        referenceName: 'FootPrintManager_owners_navigation_grid',
        component: FootPrintManager_owners_navigation_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openowners_navigation_gridDialog(
    inParams:{ ownerStatusId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_owners_navigation_gridComponent,
      'Owners',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openownersaccounts_grid(inParams:{ ownerId: number, accountTypeId: number, typeIds: number[], allowSelection: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Addresses',
        referenceName: 'FootPrintManager_ownersaccounts_grid',
        component: FootPrintManager_ownersaccounts_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openownersaccounts_gridDialog(
    inParams:{ ownerId: number, accountTypeId: number, typeIds: number[], allowSelection: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ accountId?: number, contactId?: number, addressId?: number, confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_ownersaccounts_gridComponent,
      'Addresses',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openownerscarrierslookup_grid(inParams:{ ownerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Owner carriers lookup',
        referenceName: 'FootPrintManager_ownerscarrierslookup_grid',
        component: FootPrintManager_ownerscarrierslookup_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openownerscarrierslookup_gridDialog(
    inParams:{ ownerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_ownerscarrierslookup_gridComponent,
      'Owner carriers lookup',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpack_verification_instructions_grid(inParams:{ shippingContainerId: number, startVerificationTaskId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Instructions',
        referenceName: 'FootPrintManager_pack_verification_instructions_grid',
        component: FootPrintManager_pack_verification_instructions_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpack_verification_instructions_gridDialog(
    inParams:{ shippingContainerId: number, startVerificationTaskId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pack_verification_instructions_gridComponent,
      'Instructions',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpack_verification_shipping_container_contents_grid(inParams:{ shippingContainerId: number, canAddManually?: boolean, startVerificationTaskId: number, isReadOnly?: boolean, scannedValues: { scannedValue?: string, lotId?: number, packagingId?: number }[], isBlind?: boolean, expectedSerialNumbers?: { lookupCode: string, lotId: number, packagingId: number, id: number, licensePlateId: number }[], locationId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Container contents',
        referenceName: 'FootPrintManager_pack_verification_shipping_container_contents_grid',
        component: FootPrintManager_pack_verification_shipping_container_contents_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpack_verification_shipping_container_contents_gridDialog(
    inParams:{ shippingContainerId: number, canAddManually?: boolean, startVerificationTaskId: number, isReadOnly?: boolean, scannedValues: { scannedValue?: string, lotId?: number, packagingId?: number }[], isBlind?: boolean, expectedSerialNumbers?: { lookupCode: string, lotId: number, packagingId: number, id: number, licensePlateId: number }[], locationId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pack_verification_shipping_container_contents_gridComponent,
      'Container contents',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpallet_classes_grid(inParams:{ pallet_class_id?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pallet classes grid',
        referenceName: 'FootPrintManager_pallet_classes_grid',
        component: FootPrintManager_pallet_classes_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpallet_classes_gridDialog(
    inParams:{ pallet_class_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pallet_classes_gridComponent,
      'Pallet classes grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpallet_inventory_by_class_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pallet inventory by class grid',
        referenceName: 'FootPrintManager_pallet_inventory_by_class_grid',
        component: FootPrintManager_pallet_inventory_by_class_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openpallet_inventory_by_class_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pallet_inventory_by_class_gridComponent,
      'Pallet inventory by class grid',
      mode,
      dialogSize
    )
  }
  public openpallet_inventory_by_project_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'pallet_inventory_by_project_grid',
        referenceName: 'FootPrintManager_pallet_inventory_by_project_grid',
        component: FootPrintManager_pallet_inventory_by_project_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openpallet_inventory_by_project_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pallet_inventory_by_project_gridComponent,
      'pallet_inventory_by_project_grid',
      mode,
      dialogSize
    )
  }
  public openpallet_transactions_grid(inParams:{ project_id?: number, pallet_class_id?: number, project_lookup_code?: string, pallet_class_name?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pallet transactions grid',
        referenceName: 'FootPrintManager_pallet_transactions_grid',
        component: FootPrintManager_pallet_transactions_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpallet_transactions_gridDialog(
    inParams:{ project_id?: number, pallet_class_id?: number, project_lookup_code?: string, pallet_class_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pallet_transactions_gridComponent,
      'Pallet transactions grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpallet_transactions_shipment_grid(inParams:{ project_id?: number, shipment_id?: number, order_lookup_code?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pallet transactions shipment grid',
        referenceName: 'FootPrintManager_pallet_transactions_shipment_grid',
        component: FootPrintManager_pallet_transactions_shipment_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpallet_transactions_shipment_gridDialog(
    inParams:{ project_id?: number, shipment_id?: number, order_lookup_code?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pallet_transactions_shipment_gridComponent,
      'Pallet transactions shipment grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpick_slips_grid(inParams:{ waveId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pick slips',
        referenceName: 'FootPrintManager_pick_slips_grid',
        component: FootPrintManager_pick_slips_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpick_slips_gridDialog(
    inParams:{ waveId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pick_slips_gridComponent,
      'Pick slips',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpick_waves_expanded_grid(inParams:{ WaveId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pick waves expanded',
        referenceName: 'FootPrintManager_pick_waves_expanded_grid',
        component: FootPrintManager_pick_waves_expanded_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpick_waves_expanded_gridDialog(
    inParams:{ WaveId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pick_waves_expanded_gridComponent,
      'Pick waves expanded',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpick_waves_grid(inParams:{ ownerId?: number[], projectId?: number[], warehouseId?: number[], statusIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pick waves',
        referenceName: 'FootPrintManager_pick_waves_grid',
        component: FootPrintManager_pick_waves_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpick_waves_gridDialog(
    inParams:{ ownerId?: number[], projectId?: number[], warehouseId?: number[], statusIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pick_waves_gridComponent,
      'Pick waves',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openprojects_grid(inParams:{ projectStatusId?: number, ownerId?: number, projectIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Projects',
        referenceName: 'FootPrintManager_projects_grid',
        component: FootPrintManager_projects_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openprojects_gridDialog(
    inParams:{ projectStatusId?: number, ownerId?: number, projectIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_projects_gridComponent,
      'Projects',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_bulk_receiving_by_taskId_grid(inParams:{ taskId: number, palletCount: number, fullPalletQuantity?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Bulk receiving task',
        referenceName: 'FootPrintManager_purchase_order_bulk_receiving_by_taskId_grid',
        component: FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_bulk_receiving_by_taskId_gridDialog(
    inParams:{ taskId: number, palletCount: number, fullPalletQuantity?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent,
      'Bulk receiving task',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_children_tasks_grid(inParams:{ taskId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Purchase order children tasks',
        referenceName: 'FootPrintManager_purchase_order_children_tasks_grid',
        component: FootPrintManager_purchase_order_children_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_children_tasks_gridDialog(
    inParams:{ taskId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_purchase_order_children_tasks_gridComponent,
      'Purchase order children tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order lines',
        referenceName: 'FootPrintManager_purchase_order_lines_grid',
        component: FootPrintManager_purchase_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_purchase_order_lines_gridComponent,
      'Order lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_received_inventory_grid(inParams:{ orderId: number, shipmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Received inventory',
        referenceName: 'FootPrintManager_purchase_order_received_inventory_grid',
        component: FootPrintManager_purchase_order_received_inventory_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_received_inventory_gridDialog(
    inParams:{ orderId: number, shipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_purchase_order_received_inventory_gridComponent,
      'Received inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_receiving_grid(inParams:{ orderId: number, shipmentId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Receiving tasks',
        referenceName: 'FootPrintManager_purchase_order_receiving_grid',
        component: FootPrintManager_purchase_order_receiving_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_receiving_gridDialog(
    inParams:{ orderId: number, shipmentId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_purchase_order_receiving_gridComponent,
      'Receiving tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_tasks_grid(inParams:{ orderId: number, shipmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Purchase order tasks',
        referenceName: 'FootPrintManager_purchase_order_tasks_grid',
        component: FootPrintManager_purchase_order_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_tasks_gridDialog(
    inParams:{ orderId: number, shipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_purchase_order_tasks_gridComponent,
      'Purchase order tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreason_codes_grid(inParams:{ parentEntity?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Reason codes',
        referenceName: 'FootPrintManager_reason_codes_grid',
        component: FootPrintManager_reason_codes_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreason_codes_gridDialog(
    inParams:{ parentEntity?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_reason_codes_gridComponent,
      'Reason codes',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreplenishment_inventory_licenseplate_grid(inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], materialIds?: number[], locationIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by lp',
        referenceName: 'FootPrintManager_replenishment_inventory_licenseplate_grid',
        component: FootPrintManager_replenishment_inventory_licenseplate_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreplenishment_inventory_licenseplate_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], materialIds?: number[], locationIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_replenishment_inventory_licenseplate_gridComponent,
      'Inventory by lp',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreplenishment_tasks_grid(inParams:{ materialIds?: number[], projectId?: number, locationIds?: number[], ownerId?: number, warehouseIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Replenishment tasks',
        referenceName: 'FootPrintManager_replenishment_tasks_grid',
        component: FootPrintManager_replenishment_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreplenishment_tasks_gridDialog(
    inParams:{ materialIds?: number[], projectId?: number, locationIds?: number[], ownerId?: number, warehouseIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_replenishment_tasks_gridComponent,
      'Replenishment tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreturn_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order lines',
        referenceName: 'FootPrintManager_return_order_lines_grid',
        component: FootPrintManager_return_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreturn_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_return_order_lines_gridComponent,
      'Order lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreturn_orders_grid(inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], statusIds?: number[], orderClassIds?: number[], reference?: string, blindShippingContainerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Return orders',
        referenceName: 'FootPrintManager_return_orders_grid',
        component: FootPrintManager_return_orders_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreturn_orders_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], statusIds?: number[], orderClassIds?: number[], reference?: string, blindShippingContainerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_return_orders_gridComponent,
      'Return orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreturn_shipping_containers_grid(inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], orderStatusIds?: number[], orderClassIds?: number[], reference?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Return shipping containers',
        referenceName: 'FootPrintManager_return_shipping_containers_grid',
        component: FootPrintManager_return_shipping_containers_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreturn_shipping_containers_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], orderStatusIds?: number[], orderClassIds?: number[], reference?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_return_shipping_containers_gridComponent,
      'Return shipping containers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_children_tasks_grid(inParams:{ taskId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Sales order children tasks',
        referenceName: 'FootPrintManager_sales_order_children_tasks_grid',
        component: FootPrintManager_sales_order_children_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_children_tasks_gridDialog(
    inParams:{ taskId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_children_tasks_gridComponent,
      'Sales order children tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_line_components_grid(inParams:{ order_id: number, shipment_id: number, project_id: number, order_status_id: number, warehouse_id: number, line_number: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Sales order line kit components',
        referenceName: 'FootPrintManager_sales_order_line_components_grid',
        component: FootPrintManager_sales_order_line_components_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_line_components_gridDialog(
    inParams:{ order_id: number, shipment_id: number, project_id: number, order_status_id: number, warehouse_id: number, line_number: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_line_components_gridComponent,
      'Sales order line kit components',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_line_kit_grid(inParams:{ material_id: number, packaging_id: number, project_id: number, order_id: number, line_number: number, line_status_id: number, packaged_amount: number, warehouse_id: number, shipment_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Sales order line kit management',
        referenceName: 'FootPrintManager_sales_order_line_kit_grid',
        component: FootPrintManager_sales_order_line_kit_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_line_kit_gridDialog(
    inParams:{ material_id: number, packaging_id: number, project_id: number, order_id: number, line_number: number, line_status_id: number, packaged_amount: number, warehouse_id: number, shipment_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_line_kit_gridComponent,
      'Sales order line kit management',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_line_substitutions_grid(inParams:{ material_id: number, packaging_id: number, warehouse_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Sales order line substitutions management',
        referenceName: 'FootPrintManager_sales_order_line_substitutions_grid',
        component: FootPrintManager_sales_order_line_substitutions_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_line_substitutions_gridDialog(
    inParams:{ material_id: number, packaging_id: number, warehouse_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ material_id?: number, packaging_id?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_line_substitutions_gridComponent,
      'Sales order line substitutions management',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order lines',
        referenceName: 'FootPrintManager_sales_order_lines_grid',
        component: FootPrintManager_sales_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_lines_gridComponent,
      'Order lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_picking_grid(inParams:{ orderId?: number, shipmentId?: number, waveId?: number, orderStatusId?: number, waveStatusId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Picking tasks',
        referenceName: 'FootPrintManager_sales_order_picking_grid',
        component: FootPrintManager_sales_order_picking_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_picking_gridDialog(
    inParams:{ orderId?: number, shipmentId?: number, waveId?: number, orderStatusId?: number, waveStatusId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_picking_gridComponent,
      'Picking tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_tasks_grid(inParams:{ orderId?: number, shipmentId?: number, waveId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Sales Order Tasks',
        referenceName: 'FootPrintManager_sales_order_tasks_grid',
        component: FootPrintManager_sales_order_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_tasks_gridDialog(
    inParams:{ orderId?: number, shipmentId?: number, waveId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_sales_order_tasks_gridComponent,
      'Sales Order Tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openscanned_values_grid(inParams:{ scannedValues: { Value?: string, Note?: string }[], expectedValues?: string[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Scanned values',
        referenceName: 'FootPrintManager_scanned_values_grid',
        component: FootPrintManager_scanned_values_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openscanned_values_gridDialog(
    inParams:{ scannedValues: { Value?: string, Note?: string }[], expectedValues?: string[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_scanned_values_gridComponent,
      'Scanned values',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openserialnumbers_creation_grid(inParams:{ materialId: number, packagingId: number, packagedAmount: number, averageGross?: number, averageNet?: number, weightUomId?: number, averageLength?: number, averageWidth?: number, averageHeight?: number, dimensionUomId?: number, lotId?: number, licensePlateId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create serial numbers',
        referenceName: 'FootPrintManager_serialnumbers_creation_grid',
        component: FootPrintManager_serialnumbers_creation_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openserialnumbers_creation_gridDialog(
    inParams:{ materialId: number, packagingId: number, packagedAmount: number, averageGross?: number, averageNet?: number, weightUomId?: number, averageLength?: number, averageWidth?: number, averageHeight?: number, dimensionUomId?: number, lotId?: number, licensePlateId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ serialNumbers?: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[], confirm?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_serialnumbers_creation_gridComponent,
      'Create serial numbers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openserialnumbers_grid(inParams:{ licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, search?: string, packagingId?: number, orderId?: number, orderLineNumber?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Serial Numbers',
        referenceName: 'FootPrintManager_serialnumbers_grid',
        component: FootPrintManager_serialnumbers_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openserialnumbers_gridDialog(
    inParams:{ licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, search?: string, packagingId?: number, orderId?: number, orderLineNumber?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_serialnumbers_gridComponent,
      'Serial Numbers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openserialnumbers_selection_grid(inParams:{ lotId: number, licenseplateId: number, packagingId: number, packagedAmountRequest: number, serialNumberIds?: number[], filterSerialNumberIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Select serial numbers',
        referenceName: 'FootPrintManager_serialnumbers_selection_grid',
        component: FootPrintManager_serialnumbers_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openserialnumbers_selection_gridDialog(
    inParams:{ lotId: number, licenseplateId: number, packagingId: number, packagedAmountRequest: number, serialNumberIds?: number[], filterSerialNumberIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ SerialNumberLookupCodes?: string[], confirm?: boolean, SerialNumberIds?: number[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_serialnumbers_selection_gridComponent,
      'Select serial numbers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshipping_container_tasks_child_grid(inParams:{ shippingContainerId: number, chainHead?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Shipping container child tasks',
        referenceName: 'FootPrintManager_shipping_container_tasks_child_grid',
        component: FootPrintManager_shipping_container_tasks_child_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshipping_container_tasks_child_gridDialog(
    inParams:{ shippingContainerId: number, chainHead?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_shipping_container_tasks_child_gridComponent,
      'Shipping container child tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshipping_container_tasks_grid(inParams:{ shippingContainerId: number, chainHead?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Shipping container tasks',
        referenceName: 'FootPrintManager_shipping_container_tasks_grid',
        component: FootPrintManager_shipping_container_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshipping_container_tasks_gridDialog(
    inParams:{ shippingContainerId: number, chainHead?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_shipping_container_tasks_gridComponent,
      'Shipping container tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshipping_containers_grid(inParams:{ ownerIds?: number[], projectIds?: number[], warehouseIds?: number[], orderTypeIds?: number[], shipmentStatuses?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Shipping containers grid',
        referenceName: 'FootPrintManager_shipping_containers_grid',
        component: FootPrintManager_shipping_containers_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshipping_containers_gridDialog(
    inParams:{ ownerIds?: number[], projectIds?: number[], warehouseIds?: number[], orderTypeIds?: number[], shipmentStatuses?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_shipping_containers_gridComponent,
      'Shipping containers grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshipping_containers_to_verify_grid(inParams:{ warehouseId: number, locationId?: number, shippingContainerCode?: string, isFailed: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Containers to verify',
        referenceName: 'FootPrintManager_shipping_containers_to_verify_grid',
        component: FootPrintManager_shipping_containers_to_verify_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshipping_containers_to_verify_gridDialog(
    inParams:{ warehouseId: number, locationId?: number, shippingContainerCode?: string, isFailed: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_shipping_containers_to_verify_gridComponent,
      'Containers to verify',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshippingcontents_grid(inParams:{ orderId: number, shipmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Shipping contents',
        referenceName: 'FootPrintManager_shippingcontents_grid',
        component: FootPrintManager_shippingcontents_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshippingcontents_gridDialog(
    inParams:{ orderId: number, shipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_shippingcontents_gridComponent,
      'Shipping contents',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openstorage_categories_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Storage categories',
        referenceName: 'FootPrintManager_storage_categories_grid',
        component: FootPrintManager_storage_categories_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openstorage_categories_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_storage_categories_gridComponent,
      'Storage categories',
      mode,
      dialogSize
    )
  }
  public openstorage_category_rules_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Storage category rules',
        referenceName: 'FootPrintManager_storage_category_rules_grid',
        component: FootPrintManager_storage_category_rules_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openstorage_category_rules_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_storage_category_rules_gridComponent,
      'Storage category rules',
      mode,
      dialogSize
    )
  }
  public opensubmitted_survey_inputs_grid(inParams:{ surveyId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Survey inputs',
        referenceName: 'FootPrintManager_submitted_survey_inputs_grid',
        component: FootPrintManager_submitted_survey_inputs_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensubmitted_survey_inputs_gridDialog(
    inParams:{ surveyId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_submitted_survey_inputs_gridComponent,
      'Survey inputs',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensubmitted_surveys_grid(inParams:{ entities?: { type?: string, ids?: number[] }[], operationContextTypes?: string[], warehouseIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Submitted surveys',
        referenceName: 'FootPrintManager_submitted_surveys_grid',
        component: FootPrintManager_submitted_surveys_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensubmitted_surveys_gridDialog(
    inParams:{ entities?: { type?: string, ids?: number[] }[], operationContextTypes?: string[], warehouseIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_submitted_surveys_gridComponent,
      'Submitted surveys',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensupport_tests_grid(inParams:{ test_ids?: number[], test_runs?: number, rows_override?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Support tests grid',
        referenceName: 'FootPrintManager_support_tests_grid',
        component: FootPrintManager_support_tests_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensupport_tests_gridDialog(
    inParams:{ test_ids?: number[], test_runs?: number, rows_override?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_support_tests_gridComponent,
      'Support tests grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensurvey_grid_input_values_grid(inParams:{ values: { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string }[], headers?: { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string } }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Grid inputs',
        referenceName: 'FootPrintManager_survey_grid_input_values_grid',
        component: FootPrintManager_survey_grid_input_values_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensurvey_grid_input_values_gridDialog(
    inParams:{ values: { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string }[], headers?: { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string } }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_survey_grid_input_values_gridComponent,
      'Grid inputs',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentask_assignment_grid(inParams:{ warehouseIds?: number[], workClassIds?: number[], fromDate?: string, toDate?: string, projectId?: number, ownerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Task assignment',
        referenceName: 'FootPrintManager_task_assignment_grid',
        component: FootPrintManager_task_assignment_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentask_assignment_gridDialog(
    inParams:{ warehouseIds?: number[], workClassIds?: number[], fromDate?: string, toDate?: string, projectId?: number, ownerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_task_assignment_gridComponent,
      'Task assignment',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentemperature_capture_reading_points_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Temperature capture reading points',
        referenceName: 'FootPrintManager_temperature_capture_reading_points_grid',
        component: FootPrintManager_temperature_capture_reading_points_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opentemperature_capture_reading_points_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_temperature_capture_reading_points_gridComponent,
      'Temperature capture reading points',
      mode,
      dialogSize
    )
  }
  public opentemperature_capture_reading_types_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Temperature capture reading types',
        referenceName: 'FootPrintManager_temperature_capture_reading_types_grid',
        component: FootPrintManager_temperature_capture_reading_types_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opentemperature_capture_reading_types_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_temperature_capture_reading_types_gridComponent,
      'Temperature capture reading types',
      mode,
      dialogSize
    )
  }
  public opentemperature_readings_grid(inParams:{ loadContainerId?: number, shipmentId?: number, licensePlateId?: number, orderId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Temperature readings grid',
        referenceName: 'FootPrintManager_temperature_readings_grid',
        component: FootPrintManager_temperature_readings_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentemperature_readings_gridDialog(
    inParams:{ loadContainerId?: number, shipmentId?: number, licensePlateId?: number, orderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_temperature_readings_gridComponent,
      'Temperature readings grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransload_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order lines',
        referenceName: 'FootPrintManager_transload_order_lines_grid',
        component: FootPrintManager_transload_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransload_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_transload_order_lines_gridComponent,
      'Order lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransload_order_received_inventory_grid(inParams:{ orderId: number, shipmentId: number, transload_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Received inventory',
        referenceName: 'FootPrintManager_transload_order_received_inventory_grid',
        component: FootPrintManager_transload_order_received_inventory_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransload_order_received_inventory_gridDialog(
    inParams:{ orderId: number, shipmentId: number, transload_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_transload_order_received_inventory_gridComponent,
      'Received inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransload_order_receiving_grid(inParams:{ orderId: number, shipmentId: number, orderStatusId: number, transload_id: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Receiving tasks',
        referenceName: 'FootPrintManager_transload_order_receiving_grid',
        component: FootPrintManager_transload_order_receiving_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransload_order_receiving_gridDialog(
    inParams:{ orderId: number, shipmentId: number, orderStatusId: number, transload_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_transload_order_receiving_gridComponent,
      'Receiving tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransload_orders_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Transload orders',
        referenceName: 'FootPrintManager_transload_orders_grid',
        component: FootPrintManager_transload_orders_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransload_orders_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_transload_orders_gridComponent,
      'Transload orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransload_outbound_shipments_grid(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Transload outbound shipments',
        referenceName: 'FootPrintManager_transload_outbound_shipments_grid',
        component: FootPrintManager_transload_outbound_shipments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransload_outbound_shipments_gridDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_transload_outbound_shipments_gridComponent,
      'Transload outbound shipments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openvendorlots_selector_grid(inParams:{ selectedVendorlotIds?: number[], isExclude?: boolean, allowEdit: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Vendor lot selector',
        referenceName: 'FootPrintManager_vendorlots_selector_grid',
        component: FootPrintManager_vendorlots_selector_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openvendorlots_selector_gridDialog(
    inParams:{ selectedVendorlotIds?: number[], isExclude?: boolean, allowEdit: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ selectedVendorlotIds?: number[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_vendorlots_selector_gridComponent,
      'Vendor lot selector',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfer_inbound_licenseplates_grid(inParams:{ orderId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'License plates',
        referenceName: 'FootPrintManager_warehouse_transfer_inbound_licenseplates_grid',
        component: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfer_inbound_licenseplates_gridDialog(
    inParams:{ orderId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent,
      'License plates',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid(inParams:{ licenseplateId: number, orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Receiving tasks',
        referenceName: 'FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid',
        component: FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfer_inbound_order_licenseplates_receiving_tasks_gridDialog(
    inParams:{ licenseplateId: number, orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_gridComponent,
      'Receiving tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfer_inbound_order_receiving_tasks_grid(inParams:{ orderId: number, readOnly?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Receiving tasks',
        referenceName: 'FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_grid',
        component: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfer_inbound_order_receiving_tasks_gridDialog(
    inParams:{ orderId: number, readOnly?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent,
      'Receiving tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfer_inbound_orderlines_grid(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order lines',
        referenceName: 'FootPrintManager_warehouse_transfer_inbound_orderlines_grid',
        component: FootPrintManager_warehouse_transfer_inbound_orderlines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfer_inbound_orderlines_gridDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_inbound_orderlines_gridComponent,
      'Order lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfer_outbound_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, warehouseTransferId: number, readOnly?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Order lines',
        referenceName: 'FootPrintManager_warehouse_transfer_outbound_order_lines_grid',
        component: FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfer_outbound_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, warehouseTransferId: number, readOnly?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent,
      'Order lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfer_outbound_order_manual_allocations_grid(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Manual allocations',
        referenceName: 'FootPrintManager_warehouse_transfer_outbound_order_manual_allocations_grid',
        component: FootPrintManager_warehouse_transfer_outbound_order_manual_allocations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfer_outbound_order_manual_allocations_gridDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_outbound_order_manual_allocations_gridComponent,
      'Manual allocations',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfer_outbound_order_shippingcontents_grid(inParams:{ orderId: number, shipmentId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Shipping contents',
        referenceName: 'FootPrintManager_warehouse_transfer_outbound_order_shippingcontents_grid',
        component: FootPrintManager_warehouse_transfer_outbound_order_shippingcontents_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfer_outbound_order_shippingcontents_gridDialog(
    inParams:{ orderId: number, shipmentId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_outbound_order_shippingcontents_gridComponent,
      'Shipping contents',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfer_picking_grid(inParams:{ orderId?: number, shipmentId?: number, waveId?: number, orderStatusId?: number, waveStatusId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Picking tasks',
        referenceName: 'FootPrintManager_warehouse_transfer_picking_grid',
        component: FootPrintManager_warehouse_transfer_picking_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfer_picking_gridDialog(
    inParams:{ orderId?: number, shipmentId?: number, waveId?: number, orderStatusId?: number, waveStatusId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfer_picking_gridComponent,
      'Picking tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouse_transfers_grid(inParams:{ warehouseIds?: number[], statusIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Warehouse transfers',
        referenceName: 'FootPrintManager_warehouse_transfers_grid',
        component: FootPrintManager_warehouse_transfers_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouse_transfers_gridDialog(
    inParams:{ warehouseIds?: number[], statusIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfers_gridComponent,
      'Warehouse transfers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwarehouses_navigation_grid(inParams:{ warehouseId?: number, timezone?: string[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Warehouses',
        referenceName: 'FootPrintManager_warehouses_navigation_grid',
        component: FootPrintManager_warehouses_navigation_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwarehouses_navigation_gridDialog(
    inParams:{ warehouseId?: number, timezone?: string[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouses_navigation_gridComponent,
      'Warehouses',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwave_creation_grid(inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], waveId?: number, showCreate?: boolean, carrierId?: number, carrierServiceId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create wave',
        referenceName: 'FootPrintManager_wave_creation_grid',
        component: FootPrintManager_wave_creation_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwave_creation_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], waveId?: number, showCreate?: boolean, carrierId?: number, carrierServiceId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_wave_creation_gridComponent,
      'Create wave',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwork_class_operation_codes_grid(inParams:{ workClassId: number, operationCodes?: any }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Operation codes',
        referenceName: 'FootPrintManager_work_class_operation_codes_grid',
        component: FootPrintManager_work_class_operation_codes_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwork_class_operation_codes_gridDialog(
    inParams:{ workClassId: number, operationCodes?: any }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ operationCodes?: { id?: number, priority?: number, name?: string }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_work_class_operation_codes_gridComponent,
      'Operation codes',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwork_class_users_grid(inParams:{ users?: any[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Work class users',
        referenceName: 'FootPrintManager_work_class_users_grid',
        component: FootPrintManager_work_class_users_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwork_class_users_gridDialog(
    inParams:{ users?: any[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ users?: { id?: string, displayName?: string }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_work_class_users_gridComponent,
      'Work class users',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openwork_classes_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Work classes',
        referenceName: 'FootPrintManager_work_classes_grid',
        component: FootPrintManager_work_classes_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwork_classes_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_work_classes_gridComponent,
      'Work classes',
      mode,
      dialogSize
    )
  }
  public openwork_orders_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, statusIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Work orders',
        referenceName: 'FootPrintManager_work_orders_grid',
        component: FootPrintManager_work_orders_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwork_orders_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, statusIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_work_orders_gridComponent,
      'Work orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openactivity_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Activity Hub',
        referenceName: 'FootPrintManager_activity_hub',
        component: FootPrintManager_activity_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openactivity_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_activity_hubComponent,
      'Activity Hub',
      mode,
      dialogSize
    )
  }
  public openaudit_serial_numbers_hub(inParams:{ licensePlateId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Audit serial numbers',
        referenceName: 'FootPrintManager_audit_serial_numbers_hub',
        component: FootPrintManager_audit_serial_numbers_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openaudit_serial_numbers_hubDialog(
    inParams:{ licensePlateId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_audit_serial_numbers_hubComponent,
      'Audit serial numbers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_contracts_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing Contracts Hub',
        referenceName: 'FootPrintManager_billing_contracts_hub',
        component: FootPrintManager_billing_contracts_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openbilling_contracts_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_contracts_hubComponent,
      'Billing Contracts Hub',
      mode,
      dialogSize
    )
  }
  public openbilling_records_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing Records Hub',
        referenceName: 'FootPrintManager_billing_records_hub',
        component: FootPrintManager_billing_records_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openbilling_records_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_records_hubComponent,
      'Billing Records Hub',
      mode,
      dialogSize
    )
  }
  public openblind_return_shipping_container_hub(inParams:{ shippingContainerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Blind return',
        referenceName: 'FootPrintManager_blind_return_shipping_container_hub',
        component: FootPrintManager_blind_return_shipping_container_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openblind_return_shipping_container_hubDialog(
    inParams:{ shippingContainerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_blind_return_shipping_container_hubComponent,
      'Blind return',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openconfigurations_inventory_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory configurations',
        referenceName: 'FootPrintManager_configurations_inventory_hub',
        component: FootPrintManager_configurations_inventory_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openconfigurations_inventory_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_configurations_inventory_hubComponent,
      'Inventory configurations',
      mode,
      dialogSize
    )
  }
  public openconfigurations_storage_categories_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Storage Configurations',
        referenceName: 'FootPrintManager_configurations_storage_categories_hub',
        component: FootPrintManager_configurations_storage_categories_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openconfigurations_storage_categories_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_configurations_storage_categories_hubComponent,
      'Storage Configurations',
      mode,
      dialogSize
    )
  }
  public opendock_appointments_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock Appointments Hub',
        referenceName: 'FootPrintManager_dock_appointments_hub',
        component: FootPrintManager_dock_appointments_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opendock_appointments_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointments_hubComponent,
      'Dock Appointments Hub',
      mode,
      dialogSize
    )
  }
  public opendock_appointments_hubClone(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock Appointments Hub - Clone',
        referenceName: 'FootPrintManager_dock_appointments_hubClone',
        component: FootPrintManager_dock_appointments_hubCloneComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opendock_appointments_hubCloneDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointments_hubCloneComponent,
      'Dock Appointments Hub - Clone',
      mode,
      dialogSize
    )
  }
  public opendock_doors_hub(inParams:{ warehouseIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock Doors Hub',
        referenceName: 'FootPrintManager_dock_doors_hub',
        component: FootPrintManager_dock_doors_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_doors_hubDialog(
    inParams:{ warehouseIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_doors_hubComponent,
      'Dock Doors Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendriver_check_out_hub(inParams:{ orderId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Driver check out hub',
        referenceName: 'FootPrintManager_driver_check_out_hub',
        component: FootPrintManager_driver_check_out_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendriver_check_out_hubDialog(
    inParams:{ orderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_driver_check_out_hubComponent,
      'Driver check out hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openentity_import_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Entity Import Hub',
        referenceName: 'FootPrintManager_entity_import_hub',
        component: FootPrintManager_entity_import_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openentity_import_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_entity_import_hubComponent,
      'Entity Import Hub',
      mode,
      dialogSize
    )
  }
  public openinbound_orders_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inbound hub',
        referenceName: 'FootPrintManager_inbound_orders_hub',
        component: FootPrintManager_inbound_orders_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinbound_orders_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inbound_orders_hubComponent,
      'Inbound hub',
      mode,
      dialogSize
    )
  }
  public openinspections_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inspections hub',
        referenceName: 'FootPrintManager_inspections_hub',
        component: FootPrintManager_inspections_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinspections_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inspections_hubComponent,
      'Inspections hub',
      mode,
      dialogSize
    )
  }
  public openinventory_counts_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory Counts',
        referenceName: 'FootPrintManager_inventory_counts_hub',
        component: FootPrintManager_inventory_counts_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinventory_counts_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_counts_hubComponent,
      'Inventory Counts',
      mode,
      dialogSize
    )
  }
  public openinventory_hub(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory Hub',
        referenceName: 'FootPrintManager_inventory_hub',
        component: FootPrintManager_inventory_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_hubDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_hubComponent,
      'Inventory Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinventory_transfers_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory transfers hub',
        referenceName: 'FootPrintManager_inventory_transfers_hub',
        component: FootPrintManager_inventory_transfers_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinventory_transfers_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_inventory_transfers_hubComponent,
      'Inventory transfers hub',
      mode,
      dialogSize
    )
  }
  public openinvoices_hub(inParams:{ ownerId?: number, projectId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Invoices Hub',
        referenceName: 'FootPrintManager_invoices_hub',
        component: FootPrintManager_invoices_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoices_hubDialog(
    inParams:{ ownerId?: number, projectId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_invoices_hubComponent,
      'Invoices Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlabor_management_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Labor management',
        referenceName: 'FootPrintManager_labor_management_hub',
        component: FootPrintManager_labor_management_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openlabor_management_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_labor_management_hubComponent,
      'Labor management',
      mode,
      dialogSize
    )
  }
  public openload_containers_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Load Containers Hub',
        referenceName: 'FootPrintManager_load_containers_hub',
        component: FootPrintManager_load_containers_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openload_containers_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_load_containers_hubComponent,
      'Load Containers Hub',
      mode,
      dialogSize
    )
  }
  public openlots_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Lots Hub',
        referenceName: 'FootPrintManager_lots_hub',
        component: FootPrintManager_lots_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openlots_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_lots_hubComponent,
      'Lots Hub',
      mode,
      dialogSize
    )
  }
  public openmanifesting_hub(inParams:{ shipmentIds: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Manifesting Hub',
        referenceName: 'FootPrintManager_manifesting_hub',
        component: FootPrintManager_manifesting_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanifesting_hubDialog(
    inParams:{ shipmentIds: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_manifesting_hubComponent,
      'Manifesting Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_attachments_hub(inParams:{ materialId: number, packagingId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Material attachments',
        referenceName: 'FootPrintManager_material_attachments_hub',
        component: FootPrintManager_material_attachments_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_attachments_hubDialog(
    inParams:{ materialId: number, packagingId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_material_attachments_hubComponent,
      'Material attachments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterials_hub(inParams:{ ownerId?: number, projectId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Materials Hub',
        referenceName: 'FootPrintManager_materials_hub',
        component: FootPrintManager_materials_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterials_hubDialog(
    inParams:{ ownerId?: number, projectId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_materials_hubComponent,
      'Materials Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openorders_attachments_hub(inParams:{ orderId?: number, shipmentId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'orders_attachments_hub',
        referenceName: 'FootPrintManager_orders_attachments_hub',
        component: FootPrintManager_orders_attachments_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openorders_attachments_hubDialog(
    inParams:{ orderId?: number, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_orders_attachments_hubComponent,
      'orders_attachments_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openorders_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Orders Hub',
        referenceName: 'FootPrintManager_orders_hub',
        component: FootPrintManager_orders_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openorders_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_orders_hubComponent,
      'Orders Hub',
      mode,
      dialogSize
    )
  }
  public openoutbound_orders_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Outbound Orders Hub',
        referenceName: 'FootPrintManager_outbound_orders_hub',
        component: FootPrintManager_outbound_orders_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openoutbound_orders_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_outbound_orders_hubComponent,
      'Outbound Orders Hub',
      mode,
      dialogSize
    )
  }
  public openowners_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Owners Hub',
        referenceName: 'FootPrintManager_owners_hub',
        component: FootPrintManager_owners_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openowners_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_owners_hubComponent,
      'Owners Hub',
      mode,
      dialogSize
    )
  }
  public openpack_verification_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pack verification hub',
        referenceName: 'FootPrintManager_pack_verification_hub',
        component: FootPrintManager_pack_verification_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openpack_verification_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_pack_verification_hubComponent,
      'Pack verification hub',
      mode,
      dialogSize
    )
  }
  public openprojects_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Projects Hub',
        referenceName: 'FootPrintManager_projects_hub',
        component: FootPrintManager_projects_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openprojects_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_projects_hubComponent,
      'Projects Hub',
      mode,
      dialogSize
    )
  }
  public openreplenishment_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Replenishment Hub',
        referenceName: 'FootPrintManager_replenishment_hub',
        component: FootPrintManager_replenishment_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openreplenishment_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_replenishment_hubComponent,
      'Replenishment Hub',
      mode,
      dialogSize
    )
  }
  public openreturns_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Returns hub',
        referenceName: 'FootPrintManager_returns_hub',
        component: FootPrintManager_returns_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openreturns_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_returns_hubComponent,
      'Returns hub',
      mode,
      dialogSize
    )
  }
  public openserialnumbers_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Serialnumbers Hub',
        referenceName: 'FootPrintManager_serialnumbers_hub',
        component: FootPrintManager_serialnumbers_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openserialnumbers_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_serialnumbers_hubComponent,
      'Serialnumbers Hub',
      mode,
      dialogSize
    )
  }
  public openshipping_containers_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Shipping containers hub',
        referenceName: 'FootPrintManager_shipping_containers_hub',
        component: FootPrintManager_shipping_containers_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openshipping_containers_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_shipping_containers_hubComponent,
      'Shipping containers hub',
      mode,
      dialogSize
    )
  }
  public opensingle_licenseplate_hub(inParams:{ licenseplateId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single Licenseplate Hub',
        referenceName: 'FootPrintManager_single_licenseplate_hub',
        component: FootPrintManager_single_licenseplate_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_licenseplate_hubDialog(
    inParams:{ licenseplateId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_single_licenseplate_hubComponent,
      'Single Licenseplate Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_location_hub(inParams:{ locationId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single Location Hub',
        referenceName: 'FootPrintManager_single_location_hub',
        component: FootPrintManager_single_location_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_location_hubDialog(
    inParams:{ locationId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_single_location_hubComponent,
      'Single Location Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_lot_hub(inParams:{ lotId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single Lot Hub',
        referenceName: 'FootPrintManager_single_lot_hub',
        component: FootPrintManager_single_lot_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_lot_hubDialog(
    inParams:{ lotId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_single_lot_hubComponent,
      'Single Lot Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_material_hub(inParams:{ materialId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single Material Hub',
        referenceName: 'FootPrintManager_single_material_hub',
        component: FootPrintManager_single_material_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_material_hubDialog(
    inParams:{ materialId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_single_material_hubComponent,
      'Single Material Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_owner_hub(inParams:{ ownerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single Owner Hub',
        referenceName: 'FootPrintManager_single_owner_hub',
        component: FootPrintManager_single_owner_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_owner_hubDialog(
    inParams:{ ownerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_single_owner_hubComponent,
      'Single Owner Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_parent_licenseplate_hub(inParams:{ licenseplateId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single Parent Licenseplate Hub',
        referenceName: 'FootPrintManager_single_parent_licenseplate_hub',
        component: FootPrintManager_single_parent_licenseplate_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_parent_licenseplate_hubDialog(
    inParams:{ licenseplateId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_single_parent_licenseplate_hubComponent,
      'Single Parent Licenseplate Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_project_hub(inParams:{ projectId: number, ownerId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single Project Hub',
        referenceName: 'FootPrintManager_single_project_hub',
        component: FootPrintManager_single_project_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_project_hubDialog(
    inParams:{ projectId: number, ownerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_single_project_hubComponent,
      'Single Project Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_serialnumber_hub(inParams:{ serialnumberId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single Serialnumber Hub',
        referenceName: 'FootPrintManager_single_serialnumber_hub',
        component: FootPrintManager_single_serialnumber_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_serialnumber_hubDialog(
    inParams:{ serialnumberId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_single_serialnumber_hubComponent,
      'Single Serialnumber Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_survey_hub(inParams:{ surveyId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Submitted survey',
        referenceName: 'FootPrintManager_single_survey_hub',
        component: FootPrintManager_single_survey_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_survey_hubDialog(
    inParams:{ surveyId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_single_survey_hubComponent,
      'Submitted survey',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_warehouse_hub(inParams:{ warehouseId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single Warehouse Hub',
        referenceName: 'FootPrintManager_single_warehouse_hub',
        component: FootPrintManager_single_warehouse_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_warehouse_hubDialog(
    inParams:{ warehouseId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_single_warehouse_hubComponent,
      'Single Warehouse Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensingle_wave_hub(inParams:{ waveId: number, waveDescription?: string, waveStatus?: string, ownerId?: number, projectId?: number, warehouseIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Single wave',
        referenceName: 'FootPrintManager_single_wave_hub',
        component: FootPrintManager_single_wave_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensingle_wave_hubDialog(
    inParams:{ waveId: number, waveDescription?: string, waveStatus?: string, ownerId?: number, projectId?: number, warehouseIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_single_wave_hubComponent,
      'Single wave',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensupport_tests_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Support Tests Hub',
        referenceName: 'FootPrintManager_support_tests_hub',
        component: FootPrintManager_support_tests_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opensupport_tests_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_support_tests_hubComponent,
      'Support Tests Hub',
      mode,
      dialogSize
    )
  }
  public opensurveys_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Surveys hub',
        referenceName: 'FootPrintManager_surveys_hub',
        component: FootPrintManager_surveys_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opensurveys_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_surveys_hubComponent,
      'Surveys hub',
      mode,
      dialogSize
    )
  }
  public opentransload_orders_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Transload Orders Hub',
        referenceName: 'FootPrintManager_transload_orders_hub',
        component: FootPrintManager_transload_orders_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opentransload_orders_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_transload_orders_hubComponent,
      'Transload Orders Hub',
      mode,
      dialogSize
    )
  }
  public openwarehouse_transfers_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Warehouse Transfers',
        referenceName: 'FootPrintManager_warehouse_transfers_hub',
        component: FootPrintManager_warehouse_transfers_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwarehouse_transfers_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouse_transfers_hubComponent,
      'Warehouse Transfers',
      mode,
      dialogSize
    )
  }
  public openwarehouses_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Warehouses Hub',
        referenceName: 'FootPrintManager_warehouses_hub',
        component: FootPrintManager_warehouses_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwarehouses_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_warehouses_hubComponent,
      'Warehouses Hub',
      mode,
      dialogSize
    )
  }
  public openwaves_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Waves hub',
        referenceName: 'FootPrintManager_waves_hub',
        component: FootPrintManager_waves_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwaves_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_waves_hubComponent,
      'Waves hub',
      mode,
      dialogSize
    )
  }
  public openwork_classes_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Manage work classes',
        referenceName: 'FootPrintManager_work_classes_hub',
        component: FootPrintManager_work_classes_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwork_classes_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_work_classes_hubComponent,
      'Manage work classes',
      mode,
      dialogSize
    )
  }
  public openwork_orders_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Work Orders Hub',
        referenceName: 'FootPrintManager_work_orders_hub',
        component: FootPrintManager_work_orders_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwork_orders_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_work_orders_hubComponent,
      'Work Orders Hub',
      mode,
      dialogSize
    )
  }
  public opendock_appointments_calendar(inParams:{ warehouseIds: number[], typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], dockDoorIds?: number[], viewDate?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock appointments',
        referenceName: 'FootPrintManager_dock_appointments_calendar',
        component: FootPrintManager_dock_appointments_calendarComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_appointments_calendarDialog(
    inParams:{ warehouseIds: number[], typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], dockDoorIds?: number[], viewDate?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ filters?: { viewDate?: string, dockDoorIds?: number[] } }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointments_calendarComponent,
      'Dock appointments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public opendock_appointment_selection_wizardDialog(
    inParams:{ warehouseId?: number, dockDoor?: number, carrierId?: number, orderId?: number, scheduledProjectId?: number, excludedDockAppointmentIds?: number[], typeIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean, dockAppointmentId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintManager_dock_appointment_selection_wizardComponent,
      'Dock Appointment Selection',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public openinventory_by_location_selection_wizardDialog(
    inParams:{ warehouseId: number, projectId: number, materialId?: number, packagingId?: number, lotId?: number, packagedAmountRequest?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, locationId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_location_selection_wizardComponent,
      'Inventory by Location Selection Wizard',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public openinventory_by_lot_selection_wizardDialog(
    inParams:{ warehouseId: number, projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, licenseplateId?: number, packagingId?: number, packagedAmount?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_lot_selection_wizardComponent,
      'Inventory By Lot Selection Wizard',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public openinventory_by_lot_single_selection_wizardDialog(
    inParams:{ warehouseId: number, projectId: number, materialId: number, packagingId: number, packagedAmountRequest: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, licenseplateId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_lot_single_selection_wizardComponent,
      'Inventory By Lot Single Selection Wizard',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public openinventory_by_lp_selection_wizardDialog(
    inParams:{ warehouseId: number, projectId: number, materialId?: number, packagingId?: number, lotId?: number, packagedAmountRequest?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, licensePlateId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_lp_selection_wizardComponent,
      'Inventory by LP Selection Wizard',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public openinventory_by_lp_single_selection_wizardDialog(
    inParams:{ warehouseId: number, projectId: number, materialId: number, packagingId: number, lotId?: number, packagedAmountRequest: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, licensePlateId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_lp_single_selection_wizardComponent,
      'Inventory by LP Single Selection Wizard',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public openinventory_by_serialnumber_selection_wizardDialog(
    inParams:{ warehouseId: number, projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { serialnumberId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_serialnumber_selection_wizardComponent,
      'Inventory By Serialnumber Selection Wizard',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public openinventory_by_serialnumber_single_selection_wizardDialog(
    inParams:{ warehouseId: number, projectId: number, materialId: number, packagingId: number, lotId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { serialnumberId?: number, lotId?: number, vendorLotId?: number }[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintManager_inventory_by_serialnumber_single_selection_wizardComponent,
      'Inventory By SN Single Selection Wizard',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public openpack_verification_wizardDialog(
    inParams:{ shippingContainerId: number, startVerificationTaskId?: number, targetLocationId?: number, isBlind?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintManager_pack_verification_wizardComponent,
      'Pack verification',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public openpurchase_order_bulk_receiving_by_taskId_wizardDialog(
    inParams:{ taskId: number, materialId: number, packagingId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootPrintManager_purchase_order_bulk_receiving_by_taskId_wizardComponent,
      'Bulk Receiving',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbilling_record_activity_list(inParams:{ billingTaskId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Billing record activity',
        referenceName: 'FootPrintManager_billing_record_activity_list',
        component: FootPrintManager_billing_record_activity_listComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbilling_record_activity_listDialog(
    inParams:{ billingTaskId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_billing_record_activity_listComponent,
      'Billing record activity',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencount_tasks_list(inParams:{ countTaskId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Count tasks',
        referenceName: 'FootPrintManager_count_tasks_list',
        component: FootPrintManager_count_tasks_listComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencount_tasks_listDialog(
    inParams:{ countTaskId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_count_tasks_listComponent,
      'Count tasks',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendock_appointment_activity_list(inParams:{ dockAppointmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock appointment activity list',
        referenceName: 'FootPrintManager_dock_appointment_activity_list',
        component: FootPrintManager_dock_appointment_activity_listComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_appointment_activity_listDialog(
    inParams:{ dockAppointmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_appointment_activity_listComponent,
      'Dock appointment activity list',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opendock_doors_list(inParams:{ warehouseIds?: number[], appointmentType?: string, active?: boolean }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Dock doors',
        referenceName: 'FootPrintManager_dock_doors_list',
        component: FootPrintManager_dock_doors_listComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opendock_doors_listDialog(
    inParams:{ warehouseIds?: number[], appointmentType?: string, active?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_dock_doors_listComponent,
      'Dock doors',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlicenseplate_list(inParams:{ locationId?: number, materialId?: number, projectId?: number, lotId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'License plates',
        referenceName: 'FootPrintManager_licenseplate_list',
        component: FootPrintManager_licenseplate_listComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlicenseplate_listDialog(
    inParams:{ locationId?: number, materialId?: number, projectId?: number, lotId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_licenseplate_listComponent,
      'License plates',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlicenseplatecontent_list(inParams:{ licenseplateId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'License plate content',
        referenceName: 'FootPrintManager_licenseplatecontent_list',
        component: FootPrintManager_licenseplatecontent_listComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlicenseplatecontent_listDialog(
    inParams:{ licenseplateId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_licenseplatecontent_listComponent,
      'License plate content',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlicenseplates_by_shipping_container_list(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'License plates by shipping container',
        referenceName: 'FootPrintManager_licenseplates_by_shipping_container_list',
        component: FootPrintManager_licenseplates_by_shipping_container_listComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlicenseplates_by_shipping_container_listDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_licenseplates_by_shipping_container_listComponent,
      'License plates by shipping container',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshipping_containers_list(inParams:{ shipmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Shipping containers',
        referenceName: 'FootPrintManager_shipping_containers_list',
        component: FootPrintManager_shipping_containers_listComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshipping_containers_listDialog(
    inParams:{ shipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_shipping_containers_listComponent,
      'Shipping containers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_xml_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'view_xml_payload',
        referenceName: 'FootPrintManager_view_xml_payload',
        component: FootPrintManager_view_xml_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_xml_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_view_xml_payloadComponent,
      'view_xml_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencommercial_invoice_content(inParams:{ url: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Commercial invoice',
        referenceName: 'FootPrintManager_commercial_invoice_content',
        component: FootPrintManager_commercial_invoice_contentComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencommercial_invoice_contentDialog(
    inParams:{ url: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_commercial_invoice_contentComponent,
      'Commercial invoice',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshipping_label_content(inParams:{ url: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Shipping label',
        referenceName: 'FootPrintManager_shipping_label_content',
        component: FootPrintManager_shipping_label_contentComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshipping_label_contentDialog(
    inParams:{ url: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootPrintManager_shipping_label_contentComponent,
      'Shipping label',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'FootPrintManager_asn_order_creation_form') {
      const title = 'Create ASN Order';
      const component = FootPrintManager_asn_order_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number, warehouseId?: number } = { projectId: null, ownerId: null, warehouseId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_asn_order_transfer_outstanding_options_form') {
      const title = 'ASN Order Transfer Outstanding Options';
      const component = FootPrintManager_asn_order_transfer_outstanding_options_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_auto_load_options_form') {
      const title = 'Auto Load Options';
      const component = FootPrintManager_auto_load_options_formComponent;
      const inParams:{ warehouseId: number, loadLocationId?: number } = { warehouseId: null, loadLocationId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('loadLocationId'))) {
        const paramValueString = params.get('loadLocationId');
        inParams.loadLocationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_auto_pick_options_form') {
      const title = 'Auto Pick Options';
      const component = FootPrintManager_auto_pick_options_formComponent;
      const inParams:{ warehouseId: number, loadLocationId?: number } = { warehouseId: null, loadLocationId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('loadLocationId'))) {
        const paramValueString = params.get('loadLocationId');
        inParams.loadLocationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_auto_receive_and_putaway_form') {
      const title = 'Auto-receive and putaway';
      const component = FootPrintManager_auto_receive_and_putaway_formComponent;
      const inParams:{ orderId: number, taskIds?: number[], licenseplateIds?: number[] } = { orderId: null, taskIds: [], licenseplateIds: [] };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('taskIds'))) {
        const paramValueString = params.get('taskIds');
        inParams.taskIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('licenseplateIds'))) {
        const paramValueString = params.get('licenseplateIds');
        inParams.licenseplateIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_cancellation_options_form') {
      const title = 'Generic Billing Cancellation ';
      const component = FootPrintManager_billing_cancellation_options_formComponent;
      const inParams:{ title?: string, parentId: number, parentEntity: string } = { title: null, parentId: null, parentEntity: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_contract_creation_form') {
      const title = 'Billing Contract Creation Form';
      const component = FootPrintManager_billing_contract_creation_formComponent;
      const inParams:{ ownerId?: number, projectId?: number, copy?: boolean } = { ownerId: null, projectId: null, copy: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copy'))) {
        const paramValueString = params.get('copy');
        inParams.copy = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_contract_line_creation_form') {
      const title = 'Billing Contract Line Creation Form';
      const component = FootPrintManager_billing_contract_line_creation_formComponent;
      const inParams:{ billingContractId?: number, strategyName?: string, statusId?: number } = { billingContractId: null, strategyName: null, statusId: null };
      if (!isNil(params.get('billingContractId'))) {
        const paramValueString = params.get('billingContractId');
        inParams.billingContractId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('strategyName'))) {
        const paramValueString = params.get('strategyName');
        // TODO: date
        inParams.strategyName = paramValueString;
              }
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_blind_return_project_selector_form') {
      const title = 'Select project';
      const component = FootPrintManager_blind_return_project_selector_formComponent;
      const inParams:{ gtin: string } = { gtin: null };
      if (!isNil(params.get('gtin'))) {
        const paramValueString = params.get('gtin');
        // TODO: date
        inParams.gtin = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_bulk_licenseplate_directed_move_form') {
      const title = 'Move License Plates';
      const component = FootPrintManager_bulk_licenseplate_directed_move_formComponent;
      const inParams:{ licenseplates: { id?: number, warehouseId?: number, typeId?: number, locationId?: number }[] } = { licenseplates: [] };
      if (!isNil(params.get('licenseplates'))) {
        const paramValueString = params.get('licenseplates');
        inParams.licenseplates = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_bulk_receiving_row_input_form') {
      const title = 'Bulk Receiving';
      const component = FootPrintManager_bulk_receiving_row_input_formComponent;
      const inParams:{ materialId: number, packagingId: number } = { materialId: null, packagingId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_carrier_creation_form') {
      const title = 'Create carrier';
      const component = FootPrintManager_carrier_creation_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_carrierservicetype_creation_form') {
      const title = 'Create Carrier Service Type';
      const component = FootPrintManager_carrierservicetype_creation_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointment_assign_door_options_form') {
      const title = 'Dock Appointment Door Assignment';
      const component = FootPrintManager_dock_appointment_assign_door_options_formComponent;
      const inParams:{ title?: string, dockAppointmentId: number, warehouseId: number, scheduledDockDoorId?: number, appointmentType?: string } = { title: null, dockAppointmentId: null, warehouseId: null, scheduledDockDoorId: null, appointmentType: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }
      if (!isNil(params.get('dockAppointmentId'))) {
        const paramValueString = params.get('dockAppointmentId');
        inParams.dockAppointmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('scheduledDockDoorId'))) {
        const paramValueString = params.get('scheduledDockDoorId');
        inParams.scheduledDockDoorId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('appointmentType'))) {
        const paramValueString = params.get('appointmentType');
        // TODO: date
        inParams.appointmentType = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointment_cancel_options_form') {
      const title = 'Dock Appointment Cancellation';
      const component = FootPrintManager_dock_appointment_cancel_options_formComponent;
      const inParams:{ title?: string } = { title: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointment_check_in_options_form') {
      const title = 'Dock Appointment Check In';
      const component = FootPrintManager_dock_appointment_check_in_options_formComponent;
      const inParams:{ title?: string, dockAppointmentId: number, warehouseId: number, scheduledDockDoorId?: number, appointmentType?: string } = { title: null, dockAppointmentId: null, warehouseId: null, scheduledDockDoorId: null, appointmentType: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }
      if (!isNil(params.get('dockAppointmentId'))) {
        const paramValueString = params.get('dockAppointmentId');
        inParams.dockAppointmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('scheduledDockDoorId'))) {
        const paramValueString = params.get('scheduledDockDoorId');
        inParams.scheduledDockDoorId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('appointmentType'))) {
        const paramValueString = params.get('appointmentType');
        // TODO: date
        inParams.appointmentType = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointment_creation_form') {
      const title = 'Create Dock Appointment';
      const component = FootPrintManager_dock_appointment_creation_formComponent;
      const inParams:{ warehouseId?: number[], lookupcode?: string, scheduledArrival?: string, scheduledDeparture?: string, dockDoor?: number, typeId?: number, ownerId?: number, projectId?: number, carrierId?: number, orderId?: number, loadContainerId?: number } = { warehouseId: [], lookupcode: null, scheduledArrival: null, scheduledDeparture: null, dockDoor: null, typeId: null, ownerId: null, projectId: null, carrierId: null, orderId: null, loadContainerId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lookupcode'))) {
        const paramValueString = params.get('lookupcode');
        // TODO: date
        inParams.lookupcode = paramValueString;
              }
      if (!isNil(params.get('scheduledArrival'))) {
        const paramValueString = params.get('scheduledArrival');
        // TODO: date
        inParams.scheduledArrival = paramValueString;
              }
      if (!isNil(params.get('scheduledDeparture'))) {
        const paramValueString = params.get('scheduledDeparture');
        // TODO: date
        inParams.scheduledDeparture = paramValueString;
              }
      if (!isNil(params.get('dockDoor'))) {
        const paramValueString = params.get('dockDoor');
        inParams.dockDoor = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_door_creation_form') {
      const title = 'Create Dock Door';
      const component = FootPrintManager_dock_door_creation_formComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_finalize_audit_form') {
      const title = 'Finalize audit';
      const component = FootPrintManager_finalize_audit_formComponent;
      const inParams:{ licensePlateId?: number, missingSerialNumberIds: number[], warehouseId: number, locationId?: number, shippingContainerId?: number, foundSerialNumberIds: number[] } = { licensePlateId: null, missingSerialNumberIds: [], warehouseId: null, locationId: null, shippingContainerId: null, foundSerialNumberIds: [] };
      if (!isNil(params.get('licensePlateId'))) {
        const paramValueString = params.get('licensePlateId');
        inParams.licensePlateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('missingSerialNumberIds'))) {
        const paramValueString = params.get('missingSerialNumberIds');
        inParams.missingSerialNumberIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('foundSerialNumberIds'))) {
        const paramValueString = params.get('foundSerialNumberIds');
        inParams.foundSerialNumberIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_footprint_manager_home') {
      const title = 'Home';
      const component = FootPrintManager_footprint_manager_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_generic_form') {
      const title = 'generic_form';
      const component = FootPrintManager_generic_formComponent;
      const inParams:{ stringField1?: string, stringField2?: string, stringField3?: string, stringField4?: string, stringField5?: string, dateField1?: string, dateField2?: string, dateField3?: string, dateField4?: string, dateField5?: string, numberField1?: string, numberField2?: string, numberField3?: string, numberField4?: string, numberField5?: string, title?: string } = { stringField1: null, stringField2: null, stringField3: null, stringField4: null, stringField5: null, dateField1: null, dateField2: null, dateField3: null, dateField4: null, dateField5: null, numberField1: null, numberField2: null, numberField3: null, numberField4: null, numberField5: null, title: null };
      if (!isNil(params.get('stringField1'))) {
        const paramValueString = params.get('stringField1');
        // TODO: date
        inParams.stringField1 = paramValueString;
              }
      if (!isNil(params.get('stringField2'))) {
        const paramValueString = params.get('stringField2');
        // TODO: date
        inParams.stringField2 = paramValueString;
              }
      if (!isNil(params.get('stringField3'))) {
        const paramValueString = params.get('stringField3');
        // TODO: date
        inParams.stringField3 = paramValueString;
              }
      if (!isNil(params.get('stringField4'))) {
        const paramValueString = params.get('stringField4');
        // TODO: date
        inParams.stringField4 = paramValueString;
              }
      if (!isNil(params.get('stringField5'))) {
        const paramValueString = params.get('stringField5');
        // TODO: date
        inParams.stringField5 = paramValueString;
              }
      if (!isNil(params.get('dateField1'))) {
        const paramValueString = params.get('dateField1');
        // TODO: date
        inParams.dateField1 = paramValueString;
              }
      if (!isNil(params.get('dateField2'))) {
        const paramValueString = params.get('dateField2');
        // TODO: date
        inParams.dateField2 = paramValueString;
              }
      if (!isNil(params.get('dateField3'))) {
        const paramValueString = params.get('dateField3');
        // TODO: date
        inParams.dateField3 = paramValueString;
              }
      if (!isNil(params.get('dateField4'))) {
        const paramValueString = params.get('dateField4');
        // TODO: date
        inParams.dateField4 = paramValueString;
              }
      if (!isNil(params.get('dateField5'))) {
        const paramValueString = params.get('dateField5');
        // TODO: date
        inParams.dateField5 = paramValueString;
              }
      if (!isNil(params.get('numberField1'))) {
        const paramValueString = params.get('numberField1');
        // TODO: date
        inParams.numberField1 = paramValueString;
              }
      if (!isNil(params.get('numberField2'))) {
        const paramValueString = params.get('numberField2');
        // TODO: date
        inParams.numberField2 = paramValueString;
              }
      if (!isNil(params.get('numberField3'))) {
        const paramValueString = params.get('numberField3');
        // TODO: date
        inParams.numberField3 = paramValueString;
              }
      if (!isNil(params.get('numberField4'))) {
        const paramValueString = params.get('numberField4');
        // TODO: date
        inParams.numberField4 = paramValueString;
              }
      if (!isNil(params.get('numberField5'))) {
        const paramValueString = params.get('numberField5');
        // TODO: date
        inParams.numberField5 = paramValueString;
              }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_get_average_serial_weights_form') {
      const title = 'Distribute total weight across all items';
      const component = FootPrintManager_get_average_serial_weights_formComponent;
      const inParams:{ serial_numbers: { id?: number, lookup: string, net_weight: number, gross_weight: number }[], weight_uom_id: number } = { serial_numbers: [], weight_uom_id: null };
      if (!isNil(params.get('serial_numbers'))) {
        const paramValueString = params.get('serial_numbers');
        inParams.serial_numbers = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('weight_uom_id'))) {
        const paramValueString = params.get('weight_uom_id');
        inParams.weight_uom_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_get_licenseplate_form') {
      const title = 'Get license plate';
      const component = FootPrintManager_get_licenseplate_formComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inbound_orders_update_form') {
      const title = 'Update Orders';
      const component = FootPrintManager_inbound_orders_update_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inspection_select_task_status_form') {
      const title = 'Select task status';
      const component = FootPrintManager_inspection_select_task_status_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inspection_task_creation_form') {
      const title = 'Create inspection task';
      const component = FootPrintManager_inspection_task_creation_formComponent;
      const inParams:{ warehouseId?: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_integration_info_by_order_id') {
      const title = 'integration_info_by_order_id';
      const component = FootPrintManager_integration_info_by_order_idComponent;
      const inParams:{ order_id?: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_adjustment_form') {
      const title = 'Adjust Inventory';
      const component = FootPrintManager_inventory_adjustment_formComponent;
      const inParams:{ licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, availablePackagedAmount: number, serialnumberId?: number } = { licenseplateId: null, lotId: null, packagingId: null, packagedAmount: null, availablePackagedAmount: null, serialnumberId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmount'))) {
        const paramValueString = params.get('packagedAmount');
        inParams.packagedAmount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('availablePackagedAmount'))) {
        const paramValueString = params.get('availablePackagedAmount');
        inParams.availablePackagedAmount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('serialnumberId'))) {
        const paramValueString = params.get('serialnumberId');
        inParams.serialnumberId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_and_licenseplate_move_form') {
      const title = 'Move Inventory';
      const component = FootPrintManager_inventory_and_licenseplate_move_formComponent;
      const inParams:{ warehouseId?: number, locationId?: number, materialId?: number, vendorlotId?: number, licenseplates?: { id?: number, content?: { lotId?: number, packagingId?: number, packagedAmount?: number, availablePackagedAmount?: number }[] }[], serialNumberIds?: number[] } = { warehouseId: null, locationId: null, materialId: null, vendorlotId: null, licenseplates: [], serialNumberIds: [] };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorlotId'))) {
        const paramValueString = params.get('vendorlotId');
        inParams.vendorlotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licenseplates'))) {
        const paramValueString = params.get('licenseplates');
        inParams.licenseplates = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('serialNumberIds'))) {
        const paramValueString = params.get('serialNumberIds');
        inParams.serialNumberIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_creation_form') {
      const title = 'Create Inventory';
      const component = FootPrintManager_inventory_creation_formComponent;
      const inParams:{ projectId?: number, warehouseId?: number, materialId?: number, packagingId?: number, lotId?: number, licenseplateId?: number, ownerId?: number, locationId?: number } = { projectId: null, warehouseId: null, materialId: null, packagingId: null, lotId: null, licenseplateId: null, ownerId: null, locationId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_transfer_creation_form') {
      const title = 'Create inventory transfer';
      const component = FootPrintManager_inventory_transfer_creation_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_transfer_form') {
      const title = 'Transfer Inventory';
      const component = FootPrintManager_inventory_transfer_formComponent;
      const inParams:{ lotId: number, packagingId: number, licenseplateId: number, packagedAmount: number, materialId: number, availablePackagedAmount: number } = { lotId: null, packagingId: null, licenseplateId: null, packagedAmount: null, materialId: null, availablePackagedAmount: null };
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmount'))) {
        const paramValueString = params.get('packagedAmount');
        inParams.packagedAmount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('availablePackagedAmount'))) {
        const paramValueString = params.get('availablePackagedAmount');
        inParams.availablePackagedAmount = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_type_selection_form') {
      const title = 'Inventory Type Selection';
      const component = FootPrintManager_inventory_type_selection_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_invoice_creation_form') {
      const title = 'Create Invoice';
      const component = FootPrintManager_invoice_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number } = { projectId: null, ownerId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_invoice_creation_options_form') {
      const title = 'Invoice Creation Options';
      const component = FootPrintManager_invoice_creation_options_formComponent;
      const inParams:{ title?: string, projectId: number } = { title: null, projectId: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_invoice_status_change_options_form') {
      const title = 'Invoice Status Change Options';
      const component = FootPrintManager_invoice_status_change_options_formComponent;
      const inParams:{ title?: string, excludeStatusId?: number } = { title: null, excludeStatusId: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }
      if (!isNil(params.get('excludeStatusId'))) {
        const paramValueString = params.get('excludeStatusId');
        inParams.excludeStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_load_container_create_form') {
      const title = 'Create a Load Container';
      const component = FootPrintManager_load_container_create_formComponent;
      const inParams:{ warehouseId?: number, orderTypeId?: number, projectId?: number, ownerId?: number, loadContainerId?: number } = { warehouseId: null, orderTypeId: null, projectId: null, ownerId: null, loadContainerId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderTypeId'))) {
        const paramValueString = params.get('orderTypeId');
        inParams.orderTypeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_location_creation_form') {
      const title = 'Create location';
      const component = FootPrintManager_location_creation_formComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_location_replenishment_creation_form') {
      const title = 'Create new rule';
      const component = FootPrintManager_location_replenishment_creation_formComponent;
      const inParams:{ projectId?: number, warehouseId?: number, locationId?: number, materialId?: number, ownerId?: number } = { projectId: null, warehouseId: null, locationId: null, materialId: null, ownerId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_lot_bulk_update_form') {
      const title = 'Update Lots and Vendor Lots';
      const component = FootPrintManager_lot_bulk_update_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_lot_bulk_update_status_form') {
      const title = 'Lot status update';
      const component = FootPrintManager_lot_bulk_update_status_formComponent;
      const inParams:{ message: string } = { message: null };
      if (!isNil(params.get('message'))) {
        const paramValueString = params.get('message');
        // TODO: date
        inParams.message = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_lot_creation_form') {
      const title = 'Create Lot';
      const component = FootPrintManager_lot_creation_formComponent;
      const inParams:{ materialId: number, lotLookupCode?: string, vendorLotLookupCode?: string, vendorLotId?: number } = { materialId: null, lotLookupCode: null, vendorLotLookupCode: null, vendorLotId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotLookupCode'))) {
        const paramValueString = params.get('lotLookupCode');
        // TODO: date
        inParams.lotLookupCode = paramValueString;
              }
      if (!isNil(params.get('vendorLotLookupCode'))) {
        const paramValueString = params.get('vendorLotLookupCode');
        // TODO: date
        inParams.vendorLotLookupCode = paramValueString;
              }
      if (!isNil(params.get('vendorLotId'))) {
        const paramValueString = params.get('vendorLotId');
        inParams.vendorLotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_lot_creation_form_with_material') {
      const title = 'Create Lot With Material';
      const component = FootPrintManager_lot_creation_form_with_materialComponent;
      const inParams:{ materialId?: number, ownerId?: number, projectId?: number, vendorLotId?: number } = { materialId: null, ownerId: null, projectId: null, vendorLotId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotId'))) {
        const paramValueString = params.get('vendorLotId');
        inParams.vendorLotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_copy_form') {
      const title = 'Copy Material';
      const component = FootPrintManager_material_copy_formComponent;
      const inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] } = { projectId: null, ownerId: null, materialIds: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_creation_form') {
      const title = 'Create Material';
      const component = FootPrintManager_material_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number } = { projectId: null, ownerId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_update_form') {
      const title = 'Update Material';
      const component = FootPrintManager_material_update_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_order_completion_options_form') {
      const title = 'Order Completion Options';
      const component = FootPrintManager_order_completion_options_formComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_outbound_orders_update_form') {
      const title = 'Update Orders';
      const component = FootPrintManager_outbound_orders_update_formComponent;
      const inParams:{ orders: { orderId?: number, shipmentId?: number }[] } = { orders: [] };
      if (!isNil(params.get('orders'))) {
        const paramValueString = params.get('orders');
        inParams.orders = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_owner_creation_form') {
      const title = 'Create Owner';
      const component = FootPrintManager_owner_creation_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_owner_update_form') {
      const title = 'Update Owner';
      const component = FootPrintManager_owner_update_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pack_verification_failure_form') {
      const title = 'Fail verification?';
      const component = FootPrintManager_pack_verification_failure_formComponent;
      const inParams:{ shippingContainerCode: string } = { shippingContainerCode: null };
      if (!isNil(params.get('shippingContainerCode'))) {
        const paramValueString = params.get('shippingContainerCode');
        // TODO: date
        inParams.shippingContainerCode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pallet_class_creation_form') {
      const title = 'Pallet class creation form';
      const component = FootPrintManager_pallet_class_creation_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pallet_transaction_creation_form') {
      const title = 'Pallet transaction creation form';
      const component = FootPrintManager_pallet_transaction_creation_formComponent;
      const inParams:{ project_id?: number, shipment_id?: number, pallet_class_id?: number, order_lookup_code?: string, pallets_in?: number, pallets_out?: number, transaction_id?: number, amount?: number } = { project_id: null, shipment_id: null, pallet_class_id: null, order_lookup_code: null, pallets_in: null, pallets_out: null, transaction_id: null, amount: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipment_id'))) {
        const paramValueString = params.get('shipment_id');
        inParams.shipment_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('pallet_class_id'))) {
        const paramValueString = params.get('pallet_class_id');
        inParams.pallet_class_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_lookup_code'))) {
        const paramValueString = params.get('order_lookup_code');
        // TODO: date
        inParams.order_lookup_code = paramValueString;
              }
      if (!isNil(params.get('pallets_in'))) {
        const paramValueString = params.get('pallets_in');
        inParams.pallets_in = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('pallets_out'))) {
        const paramValueString = params.get('pallets_out');
        inParams.pallets_out = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        inParams.transaction_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('amount'))) {
        const paramValueString = params.get('amount');
        inParams.amount = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_print_license_plate_labels_form') {
      const title = 'Print license plate labels';
      const component = FootPrintManager_print_license_plate_labels_formComponent;
      const inParams:{ orderId: number, licensePlateId?: number } = { orderId: null, licensePlateId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licensePlateId'))) {
        const paramValueString = params.get('licensePlateId');
        inParams.licensePlateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_print_order_labels_form') {
      const title = 'Print order labels form';
      const component = FootPrintManager_print_order_labels_formComponent;
      const inParams:{ orderId: number, context: string } = { orderId: null, context: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('context'))) {
        const paramValueString = params.get('context');
        // TODO: date
        inParams.context = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_project_creation_form') {
      const title = 'Create Project';
      const component = FootPrintManager_project_creation_formComponent;
      const inParams:{ ownerId: number } = { ownerId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_project_update_form') {
      const title = 'Update Project';
      const component = FootPrintManager_project_update_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_purchase_order_copy_form') {
      const title = 'Copy purchase order';
      const component = FootPrintManager_purchase_order_copy_formComponent;
      const inParams:{ order_id: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_purchase_order_creation_form') {
      const title = 'Create Purchase Order';
      const component = FootPrintManager_purchase_order_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number, warehouseId?: number } = { projectId: null, ownerId: null, warehouseId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_reason_code_creation_form') {
      const title = 'Create reason code';
      const component = FootPrintManager_reason_code_creation_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_return_order_creation_form') {
      const title = 'Create Return Order';
      const component = FootPrintManager_return_order_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number, warehouseId?: number } = { projectId: null, ownerId: null, warehouseId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_cancellation_options_form') {
      const title = 'Generic Sales Order Cancellation ';
      const component = FootPrintManager_sales_order_cancellation_options_formComponent;
      const inParams:{ title?: string, parentId: number, parentEntity: string } = { title: null, parentId: null, parentEntity: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_copy_form') {
      const title = 'Copy sales order';
      const component = FootPrintManager_sales_order_copy_formComponent;
      const inParams:{ order_id: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_creation_form') {
      const title = 'Create Sales Order';
      const component = FootPrintManager_sales_order_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number, warehouseId?: number } = { projectId: null, ownerId: null, warehouseId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_pick_task_cancellation_options_form') {
      const title = 'Generic Sales Order Pick Task Cancellation ';
      const component = FootPrintManager_sales_order_pick_task_cancellation_options_formComponent;
      const inParams:{ title?: string, parentId: number, parentEntity: string, cancelOrderRequest?: boolean } = { title: null, parentId: null, parentEntity: null, cancelOrderRequest: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }
      if (!isNil(params.get('cancelOrderRequest'))) {
        const paramValueString = params.get('cancelOrderRequest');
        inParams.cancelOrderRequest = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_orders_processing_options_form') {
      const title = 'Processing options';
      const component = FootPrintManager_sales_orders_processing_options_formComponent;
      const inParams:{ isMultiShipment: boolean, waveOptionDefaultCreate: boolean, expand_order_line_kits?: boolean } = { isMultiShipment: null, waveOptionDefaultCreate: null, expand_order_line_kits: null };
      if (!isNil(params.get('isMultiShipment'))) {
        const paramValueString = params.get('isMultiShipment');
        inParams.isMultiShipment = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('waveOptionDefaultCreate'))) {
        const paramValueString = params.get('waveOptionDefaultCreate');
        inParams.waveOptionDefaultCreate = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('expand_order_line_kits'))) {
        const paramValueString = params.get('expand_order_line_kits');
        inParams.expand_order_line_kits = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_select_user_form') {
      const title = 'Select user';
      const component = FootPrintManager_select_user_formComponent;
      const inParams:{ operationCodeIds?: number[] } = { operationCodeIds: [] };
      if (!isNil(params.get('operationCodeIds'))) {
        const paramValueString = params.get('operationCodeIds');
        inParams.operationCodeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_temperature_capture_form') {
      const title = 'Temperature capture form';
      const component = FootPrintManager_temperature_capture_formComponent;
      const inParams:{ shipmentId?: number, shipmentLineId?: number, licensePlateId?: number, loadContainerId?: number } = { shipmentId: null, shipmentLineId: null, licensePlateId: null, loadContainerId: null };
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentLineId'))) {
        const paramValueString = params.get('shipmentLineId');
        inParams.shipmentLineId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licensePlateId'))) {
        const paramValueString = params.get('licensePlateId');
        inParams.licensePlateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_transfer_licenseplate_form') {
      const title = 'transfer_licenseplate_form';
      const component = FootPrintManager_transfer_licenseplate_formComponent;
      const inParams:{ licenseplateIds?: number[], sourceShipmentId: number, targetShipmentId?: number } = { licenseplateIds: [], sourceShipmentId: null, targetShipmentId: null };
      if (!isNil(params.get('licenseplateIds'))) {
        const paramValueString = params.get('licenseplateIds');
        inParams.licenseplateIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('sourceShipmentId'))) {
        const paramValueString = params.get('sourceShipmentId');
        inParams.sourceShipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('targetShipmentId'))) {
        const paramValueString = params.get('targetShipmentId');
        inParams.targetShipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_transfer_transload_licenseplate_form') {
      const title = 'Transfer transload licenseplate';
      const component = FootPrintManager_transfer_transload_licenseplate_formComponent;
      const inParams:{ licenseplate_id: number, transload_id: number, current_outbound_shipment_id?: number } = { licenseplate_id: null, transload_id: null, current_outbound_shipment_id: null };
      if (!isNil(params.get('licenseplate_id'))) {
        const paramValueString = params.get('licenseplate_id');
        inParams.licenseplate_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('transload_id'))) {
        const paramValueString = params.get('transload_id');
        inParams.transload_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('current_outbound_shipment_id'))) {
        const paramValueString = params.get('current_outbound_shipment_id');
        inParams.current_outbound_shipment_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_transload_order_creation_form') {
      const title = 'Create transload order';
      const component = FootPrintManager_transload_order_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number, warehouseId?: number } = { projectId: null, ownerId: null, warehouseId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_unloading_form') {
      const title = 'Unloading Inventory';
      const component = FootPrintManager_unloading_formComponent;
      const inParams:{ licenseplateId: number, shipmentId: number } = { licenseplateId: null, shipmentId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_unpicking_form') {
      const title = 'Unpick Inventory';
      const component = FootPrintManager_unpicking_formComponent;
      const inParams:{ licenseplateId: number, locationId?: number, inventory?: { lotId: number, packagingId: number, packagedAmount: number, shipmentLineId: number, unpickPackagedAmount?: number, serialNumberIds?: number[] } } = { licenseplateId: null, locationId: null, inventory: { lotId: null, packagingId: null, packagedAmount: null, shipmentLineId: null, unpickPackagedAmount: null, serialNumberIds: [] } };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('inventory'))) {
        const paramValueString = params.get('inventory');
        inParams.inventory = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_unreceiving_all_options_form') {
      const title = 'Unreceive All Inventory';
      const component = FootPrintManager_unreceiving_all_options_formComponent;
      const inParams:{ title?: string } = { title: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_unreceiving_single_taskId_form') {
      const title = 'Unreceive Inventory';
      const component = FootPrintManager_unreceiving_single_taskId_formComponent;
      const inParams:{ licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, taskId: number } = { licenseplateId: null, lotId: null, packagingId: null, packagedAmount: null, taskId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmount'))) {
        const paramValueString = params.get('packagedAmount');
        inParams.packagedAmount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('taskId'))) {
        const paramValueString = params.get('taskId');
        inParams.taskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_vendorlot_creation_form') {
      const title = 'Create Vendor Lot';
      const component = FootPrintManager_vendorlot_creation_formComponent;
      const inParams:{ materialId: number, vendorLookupcode?: string } = { materialId: null, vendorLookupcode: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLookupcode'))) {
        const paramValueString = params.get('vendorLookupcode');
        // TODO: date
        inParams.vendorLookupcode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_creation_form') {
      const title = 'Create warehouse';
      const component = FootPrintManager_warehouse_creation_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_creation_form') {
      const title = 'Create warehouse transfer';
      const component = FootPrintManager_warehouse_transfer_creation_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_wave_update_form') {
      const title = 'Wave update form';
      const component = FootPrintManager_wave_update_formComponent;
      const inParams:{ waveId: number } = { waveId: null };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_work_class_creation_form') {
      const title = 'Create work class';
      const component = FootPrintManager_work_class_creation_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_work_order_creation_form') {
      const title = 'Create Work Order';
      const component = FootPrintManager_work_order_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number, warehouseId?: number } = { projectId: null, ownerId: null, warehouseId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_asn_order_editor') {
      const title = 'Edit ASN Order';
      const component = FootPrintManager_asn_order_editorComponent;
      const inParams:{ order_id: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_contract_editor') {
      const title = 'Edit Billing Contract';
      const component = FootPrintManager_billing_contract_editorComponent;
      const inParams:{ contractId: number, edit?: boolean } = { contractId: null, edit: null };
      if (!isNil(params.get('contractId'))) {
        const paramValueString = params.get('contractId');
        inParams.contractId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('edit'))) {
        const paramValueString = params.get('edit');
        inParams.edit = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_contract_line_editor') {
      const title = 'Billing Contract Line Editor';
      const component = FootPrintManager_billing_contract_line_editorComponent;
      const inParams:{ billingContractLineId: number, edit: boolean, operationCodeId?: number, billingAggregationStrategy?: string, statusId?: number } = { billingContractLineId: null, edit: null, operationCodeId: null, billingAggregationStrategy: null, statusId: null };
      if (!isNil(params.get('billingContractLineId'))) {
        const paramValueString = params.get('billingContractLineId');
        inParams.billingContractLineId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('edit'))) {
        const paramValueString = params.get('edit');
        inParams.edit = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('operationCodeId'))) {
        const paramValueString = params.get('operationCodeId');
        inParams.operationCodeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('billingAggregationStrategy'))) {
        const paramValueString = params.get('billingAggregationStrategy');
        // TODO: date
        inParams.billingAggregationStrategy = paramValueString;
              }
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_record_editor') {
      const title = 'Edit Billing Record';
      const component = FootPrintManager_billing_record_editorComponent;
      const inParams:{ billingTaskId: number } = { billingTaskId: null };
      if (!isNil(params.get('billingTaskId'))) {
        const paramValueString = params.get('billingTaskId');
        inParams.billingTaskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_carrier_editor') {
      const title = 'Edit Carrier';
      const component = FootPrintManager_carrier_editorComponent;
      const inParams:{ carrierId: number } = { carrierId: null };
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_carrierservicetype_editor') {
      const title = 'Edit Carrier Service Type';
      const component = FootPrintManager_carrierservicetype_editorComponent;
      const inParams:{ carrierservicetypeId: number } = { carrierservicetypeId: null };
      if (!isNil(params.get('carrierservicetypeId'))) {
        const paramValueString = params.get('carrierservicetypeId');
        inParams.carrierservicetypeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_count_task_editor') {
      const title = 'count_task_editor';
      const component = FootPrintManager_count_task_editorComponent;
      const inParams:{ taskId: number } = { taskId: null };
      if (!isNil(params.get('taskId'))) {
        const paramValueString = params.get('taskId');
        inParams.taskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointment_editor') {
      const title = 'Dock Appointment Editor';
      const component = FootPrintManager_dock_appointment_editorComponent;
      const inParams:{ dockAppointmentId: number, orderId?: number, loadContainerId?: number } = { dockAppointmentId: null, orderId: null, loadContainerId: null };
      if (!isNil(params.get('dockAppointmentId'))) {
        const paramValueString = params.get('dockAppointmentId');
        inParams.dockAppointmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_door_editor') {
      const title = 'Edit Dock Door';
      const component = FootPrintManager_dock_door_editorComponent;
      const inParams:{ locationId: number } = { locationId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inspection_task_editor') {
      const title = 'Inspection task';
      const component = FootPrintManager_inspection_task_editorComponent;
      const inParams:{ taskId: number } = { taskId: null };
      if (!isNil(params.get('taskId'))) {
        const paramValueString = params.get('taskId');
        inParams.taskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_count_editor') {
      const title = 'inventory_count_editor';
      const component = FootPrintManager_inventory_count_editorComponent;
      const inParams:{ inventoryCountId: number } = { inventoryCountId: null };
      if (!isNil(params.get('inventoryCountId'))) {
        const paramValueString = params.get('inventoryCountId');
        inParams.inventoryCountId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_transfer_editor') {
      const title = 'Inventory transfer';
      const component = FootPrintManager_inventory_transfer_editorComponent;
      const inParams:{ inventoryTransferId: number, warehouseId?: number } = { inventoryTransferId: null, warehouseId: null };
      if (!isNil(params.get('inventoryTransferId'))) {
        const paramValueString = params.get('inventoryTransferId');
        inParams.inventoryTransferId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_invoice_editor') {
      const title = 'Edit Invoice';
      const component = FootPrintManager_invoice_editorComponent;
      const inParams:{ invoiceId: number } = { invoiceId: null };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_licenseplate_editor') {
      const title = 'Edit Licenseplate';
      const component = FootPrintManager_licenseplate_editorComponent;
      const inParams:{ licenseplateId: number } = { licenseplateId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_load_container_add_orders') {
      const title = 'Load Container Add Orders';
      const component = FootPrintManager_load_container_add_ordersComponent;
      const inParams:{ loadContainerId?: number, warehouseId?: number, orderTypeId?: number, projectId?: number, ownerId?: number } = { loadContainerId: null, warehouseId: null, orderTypeId: null, projectId: null, ownerId: null };
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderTypeId'))) {
        const paramValueString = params.get('orderTypeId');
        inParams.orderTypeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_load_container_editor') {
      const title = 'Load Container Editor';
      const component = FootPrintManager_load_container_editorComponent;
      const inParams:{ loadContainerId: number } = { loadContainerId: null };
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_location_editor') {
      const title = 'Edit Location';
      const component = FootPrintManager_location_editorComponent;
      const inParams:{ locationId: number } = { locationId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_location_replenishment_editor') {
      const title = 'Edit Rule';
      const component = FootPrintManager_location_replenishment_editorComponent;
      const inParams:{ locationReplenishmentId: number } = { locationReplenishmentId: null };
      if (!isNil(params.get('locationReplenishmentId'))) {
        const paramValueString = params.get('locationReplenishmentId');
        inParams.locationReplenishmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_lot_editor') {
      const title = 'Edit Lot';
      const component = FootPrintManager_lot_editorComponent;
      const inParams:{ lotId: number } = { lotId: null };
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_editor') {
      const title = 'Edit Material';
      const component = FootPrintManager_material_editorComponent;
      const inParams:{ materialId: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_kit_editor') {
      const title = 'material_kit_editor';
      const component = FootPrintManager_material_kit_editorComponent;
      const inParams:{ material_id: number, project_id: number, packaging_id?: number } = { material_id: null, project_id: null, packaging_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packaging_id'))) {
        const paramValueString = params.get('packaging_id');
        inParams.packaging_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_substitutions_editor') {
      const title = 'Material Substitutions';
      const component = FootPrintManager_material_substitutions_editorComponent;
      const inParams:{ material_id: number, packaging_id: number, project_id: number } = { material_id: null, packaging_id: null, project_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packaging_id'))) {
        const paramValueString = params.get('packaging_id');
        inParams.packaging_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_owner_editor') {
      const title = 'Edit Owner';
      const component = FootPrintManager_owner_editorComponent;
      const inParams:{ ownerId: number } = { ownerId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pack_verification_completion_editor') {
      const title = 'Complete verification';
      const component = FootPrintManager_pack_verification_completion_editorComponent;
      const inParams:{ shippingContainerId: number, startVerificationTaskId: number, instructions?: { entity?: string, code?: string, instruction?: string, isCompleted?: boolean }[], scannedValues?: { scannedValue: string, lotId: number, packagingId: number }[], properties?: { containerType?: string, dimensions?: string, weight?: string }, targetLocationId: number } = { shippingContainerId: null, startVerificationTaskId: null, instructions: [], scannedValues: [], properties: { containerType: null, dimensions: null, weight: null }, targetLocationId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('startVerificationTaskId'))) {
        const paramValueString = params.get('startVerificationTaskId');
        inParams.startVerificationTaskId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('instructions'))) {
        const paramValueString = params.get('instructions');
        inParams.instructions = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('scannedValues'))) {
        const paramValueString = params.get('scannedValues');
        inParams.scannedValues = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('properties'))) {
        const paramValueString = params.get('properties');
        inParams.properties = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('targetLocationId'))) {
        const paramValueString = params.get('targetLocationId');
        inParams.targetLocationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pack_verification_shipping_container_properties_editor') {
      const title = 'Shipping container properties';
      const component = FootPrintManager_pack_verification_shipping_container_properties_editorComponent;
      const inParams:{ shippingContainerId: number, startVerificationTaskId: number, targetLocationId?: number } = { shippingContainerId: null, startVerificationTaskId: null, targetLocationId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('startVerificationTaskId'))) {
        const paramValueString = params.get('startVerificationTaskId');
        inParams.startVerificationTaskId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('targetLocationId'))) {
        const paramValueString = params.get('targetLocationId');
        inParams.targetLocationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pack_verification_verify_container_editor') {
      const title = 'Verify Container';
      const component = FootPrintManager_pack_verification_verify_container_editorComponent;
      const inParams:{ shippingContainerId: number, startVerificationTaskId: number, isBlind?: boolean, targetLocationId?: number, isReadOnly?: boolean } = { shippingContainerId: null, startVerificationTaskId: null, isBlind: null, targetLocationId: null, isReadOnly: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('startVerificationTaskId'))) {
        const paramValueString = params.get('startVerificationTaskId');
        inParams.startVerificationTaskId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('isBlind'))) {
        const paramValueString = params.get('isBlind');
        inParams.isBlind = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('targetLocationId'))) {
        const paramValueString = params.get('targetLocationId');
        inParams.targetLocationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('isReadOnly'))) {
        const paramValueString = params.get('isReadOnly');
        inParams.isReadOnly = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_project_editor') {
      const title = 'Edit Project';
      const component = FootPrintManager_project_editorComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_purchase_order_editor') {
      const title = 'Edit Purchase Order';
      const component = FootPrintManager_purchase_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_reason_code_editor') {
      const title = 'reason_code_editor';
      const component = FootPrintManager_reason_code_editorComponent;
      const inParams:{ reasonCodeId: number } = { reasonCodeId: null };
      if (!isNil(params.get('reasonCodeId'))) {
        const paramValueString = params.get('reasonCodeId');
        inParams.reasonCodeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_return_order_editor') {
      const title = 'Edit Return Order';
      const component = FootPrintManager_return_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_editor') {
      const title = 'Edit Sales Order';
      const component = FootPrintManager_sales_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_line_kit_editor') {
      const title = 'Sale order line kit editor';
      const component = FootPrintManager_sales_order_line_kit_editorComponent;
      const inParams:{ material_id: number, project_id: number, packaging_id: number, line_number: number, line_status_id: number, packaged_amount: number, order_id: number, shipment_id: number, warehouse_id: number, order_status_id: number } = { material_id: null, project_id: null, packaging_id: null, line_number: null, line_status_id: null, packaged_amount: null, order_id: null, shipment_id: null, warehouse_id: null, order_status_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packaging_id'))) {
        const paramValueString = params.get('packaging_id');
        inParams.packaging_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('line_number'))) {
        const paramValueString = params.get('line_number');
        inParams.line_number = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('line_status_id'))) {
        const paramValueString = params.get('line_status_id');
        inParams.line_status_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packaged_amount'))) {
        const paramValueString = params.get('packaged_amount');
        inParams.packaged_amount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipment_id'))) {
        const paramValueString = params.get('shipment_id');
        inParams.shipment_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_status_id'))) {
        const paramValueString = params.get('order_status_id');
        inParams.order_status_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_serialnumber_editor') {
      const title = 'Edit Serialnumber';
      const component = FootPrintManager_serialnumber_editorComponent;
      const inParams:{ serialnumberId: number } = { serialnumberId: null };
      if (!isNil(params.get('serialnumberId'))) {
        const paramValueString = params.get('serialnumberId');
        inParams.serialnumberId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_shipping_container_editor') {
      const title = 'Container';
      const component = FootPrintManager_shipping_container_editorComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_task_assignment_editor') {
      const title = 'Task';
      const component = FootPrintManager_task_assignment_editorComponent;
      const inParams:{ taskId: number } = { taskId: null };
      if (!isNil(params.get('taskId'))) {
        const paramValueString = params.get('taskId');
        inParams.taskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_transload_order_editor') {
      const title = 'Edit Transload Order';
      const component = FootPrintManager_transload_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_editor') {
      const title = 'Edit Warehouse';
      const component = FootPrintManager_warehouse_editorComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_editor') {
      const title = 'warehouse_transfer_editor';
      const component = FootPrintManager_warehouse_transfer_editorComponent;
      const inParams:{ warehouseTransferId: number } = { warehouseTransferId: null };
      if (!isNil(params.get('warehouseTransferId'))) {
        const paramValueString = params.get('warehouseTransferId');
        inParams.warehouseTransferId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_inbound_order_editor') {
      const title = 'Edit Inbound Order';
      const component = FootPrintManager_warehouse_transfer_inbound_order_editorComponent;
      const inParams:{ orderId: number, warehouseTransferId: number } = { orderId: null, warehouseTransferId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseTransferId'))) {
        const paramValueString = params.get('warehouseTransferId');
        inParams.warehouseTransferId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_outbound_order_editor') {
      const title = 'Edit outbound order';
      const component = FootPrintManager_warehouse_transfer_outbound_order_editorComponent;
      const inParams:{ orderId: number, warehouseTransferId: number } = { orderId: null, warehouseTransferId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseTransferId'))) {
        const paramValueString = params.get('warehouseTransferId');
        inParams.warehouseTransferId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_wave_editor') {
      const title = 'Wave editor';
      const component = FootPrintManager_wave_editorComponent;
      const inParams:{ waveId: number, projectId?: number, ownerId?: number, warehouseId?: number, showCreate?: boolean } = { waveId: null, projectId: null, ownerId: null, warehouseId: null, showCreate: null };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('showCreate'))) {
        const paramValueString = params.get('showCreate');
        inParams.showCreate = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_work_class_editor') {
      const title = 'Work class';
      const component = FootPrintManager_work_class_editorComponent;
      const inParams:{ workClassId: number } = { workClassId: null };
      if (!isNil(params.get('workClassId'))) {
        const paramValueString = params.get('workClassId');
        inParams.workClassId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_work_order_editor') {
      const title = 'Edit Work Order';
      const component = FootPrintManager_work_order_editorComponent;
      const inParams:{ workOrderId: number } = { workOrderId: null };
      if (!isNil(params.get('workOrderId'))) {
        const paramValueString = params.get('workOrderId');
        inParams.workOrderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_accessorial_tasks_grid') {
      const title = 'Accessorials';
      const component = FootPrintManager_accessorial_tasks_gridComponent;
      const inParams:{ workOrderId?: number, projectId?: number, entityStatusId: number, orderId?: number, warehouseId: number, shippingContainerId?: number, showAllOpCodes?: boolean, shipmentId?: number } = { workOrderId: null, projectId: null, entityStatusId: null, orderId: null, warehouseId: null, shippingContainerId: null, showAllOpCodes: null, shipmentId: null };
      if (!isNil(params.get('workOrderId'))) {
        const paramValueString = params.get('workOrderId');
        inParams.workOrderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('entityStatusId'))) {
        const paramValueString = params.get('entityStatusId');
        inParams.entityStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('showAllOpCodes'))) {
        const paramValueString = params.get('showAllOpCodes');
        inParams.showAllOpCodes = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_accounts_grid') {
      const title = 'Addresses';
      const component = FootPrintManager_accounts_gridComponent;
      const inParams:{ ownerId: number, accountTypeId: number, typeIds: number[], allowSelection: boolean } = { ownerId: null, accountTypeId: null, typeIds: [], allowSelection: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('accountTypeId'))) {
        const paramValueString = params.get('accountTypeId');
        inParams.accountTypeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('allowSelection'))) {
        const paramValueString = params.get('allowSelection');
        inParams.allowSelection = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_activity_grid') {
      const title = 'Activity Grid';
      const component = FootPrintManager_activity_gridComponent;
      const inParams:{ project_ids?: number[], owner_ids?: number[], warehouse_ids?: number[], operation_codes?: number[], status_ids?: number[], location_id?: number, seven_days_before_flag?: boolean } = { project_ids: [], owner_ids: [], warehouse_ids: [], operation_codes: [], status_ids: [], location_id: null, seven_days_before_flag: null };
      if (!isNil(params.get('project_ids'))) {
        const paramValueString = params.get('project_ids');
        inParams.project_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('owner_ids'))) {
        const paramValueString = params.get('owner_ids');
        inParams.owner_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('operation_codes'))) {
        const paramValueString = params.get('operation_codes');
        inParams.operation_codes = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('status_ids'))) {
        const paramValueString = params.get('status_ids');
        inParams.status_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('seven_days_before_flag'))) {
        const paramValueString = params.get('seven_days_before_flag');
        inParams.seven_days_before_flag = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_asn_order_children_tasks_grid') {
      const title = 'Asn order children tasks';
      const component = FootPrintManager_asn_order_children_tasks_gridComponent;
      const inParams:{ taskId: number } = { taskId: null };
      if (!isNil(params.get('taskId'))) {
        const paramValueString = params.get('taskId');
        inParams.taskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_asn_order_licenseplates_grid') {
      const title = 'License plates';
      const component = FootPrintManager_asn_order_licenseplates_gridComponent;
      const inParams:{ order_id: number, order_status_id: number, project_id: number, can_unreceive?: boolean } = { order_id: null, order_status_id: null, project_id: null, can_unreceive: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_status_id'))) {
        const paramValueString = params.get('order_status_id');
        inParams.order_status_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('can_unreceive'))) {
        const paramValueString = params.get('can_unreceive');
        inParams.can_unreceive = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_asn_order_licenseplates_receiving_tasks_grid') {
      const title = 'Receiving tasks';
      const component = FootPrintManager_asn_order_licenseplates_receiving_tasks_gridComponent;
      const inParams:{ licenseplateId: number, orderId: number, projectId: number, orderStatusId: number } = { licenseplateId: null, orderId: null, projectId: null, orderStatusId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_asn_order_receiving_tasks_grid') {
      const title = 'Receiving tasks';
      const component = FootPrintManager_asn_order_receiving_tasks_gridComponent;
      const inParams:{ orderId: number, orderStatusId: number } = { orderId: null, orderStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_asn_order_tasks_grid') {
      const title = 'Asn order tasks';
      const component = FootPrintManager_asn_order_tasks_gridComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_asn_orderlines_grid') {
      const title = 'Order lines';
      const component = FootPrintManager_asn_orderlines_gridComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_assigned_orders_on_wave_grid') {
      const title = 'Assigned orders';
      const component = FootPrintManager_assigned_orders_on_wave_gridComponent;
      const inParams:{ waveId: number, waveStatusId: number } = { waveId: null, waveStatusId: null };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('waveStatusId'))) {
        const paramValueString = params.get('waveStatusId');
        inParams.waveStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_audit_serial_numbers_grid') {
      const title = 'Audit serial numbers';
      const component = FootPrintManager_audit_serial_numbers_gridComponent;
      const inParams:{ scannedBarcodes: { barcode: string, serialNumber: string, isValid: boolean, serialNumberId: number }[], watchList: { barcode: string, serialNumber: string }[], context?: string } = { scannedBarcodes: [], watchList: [], context: null };
      if (!isNil(params.get('scannedBarcodes'))) {
        const paramValueString = params.get('scannedBarcodes');
        inParams.scannedBarcodes = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('watchList'))) {
        const paramValueString = params.get('watchList');
        inParams.watchList = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('context'))) {
        const paramValueString = params.get('context');
        // TODO: date
        inParams.context = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_available_inventory_grid') {
      const title = 'Available inventory';
      const component = FootPrintManager_available_inventory_gridComponent;
      const inParams:{ materialId: number, warehouseId: number, recommendedAmount?: number, taskId?: number, lotId?: number, vendorLotId?: number } = { materialId: null, warehouseId: null, recommendedAmount: null, taskId: null, lotId: null, vendorLotId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('recommendedAmount'))) {
        const paramValueString = params.get('recommendedAmount');
        inParams.recommendedAmount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('taskId'))) {
        const paramValueString = params.get('taskId');
        inParams.taskId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotId'))) {
        const paramValueString = params.get('vendorLotId');
        inParams.vendorLotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_contract_lines_grid') {
      const title = 'Billing contract lines';
      const component = FootPrintManager_billing_contract_lines_gridComponent;
      const inParams:{ contractId: number, strategyName?: string, edit?: boolean, hide?: string[], statusId?: number } = { contractId: null, strategyName: null, edit: null, hide: [], statusId: null };
      if (!isNil(params.get('contractId'))) {
        const paramValueString = params.get('contractId');
        inParams.contractId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('strategyName'))) {
        const paramValueString = params.get('strategyName');
        // TODO: date
        inParams.strategyName = paramValueString;
              }
      if (!isNil(params.get('edit'))) {
        const paramValueString = params.get('edit');
        inParams.edit = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('hide'))) {
        const paramValueString = params.get('hide');
        inParams.hide = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_contracts_grid') {
      const title = 'Billing contracts';
      const component = FootPrintManager_billing_contracts_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, statusIds?: number[], endDate?: string } = { ownerId: null, projectId: null, statusIds: [], endDate: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('endDate'))) {
        const paramValueString = params.get('endDate');
        // TODO: date
        inParams.endDate = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_projects_grid') {
      const title = 'Billing projects grid';
      const component = FootPrintManager_billing_projects_gridComponent;
      const inParams:{ billingContractLineId: number, edit: boolean } = { billingContractLineId: null, edit: null };
      if (!isNil(params.get('billingContractLineId'))) {
        const paramValueString = params.get('billingContractLineId');
        inParams.billingContractLineId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('edit'))) {
        const paramValueString = params.get('edit');
        inParams.edit = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_rates_grid') {
      const title = 'Billing rates';
      const component = FootPrintManager_billing_rates_gridComponent;
      const inParams:{ billingContractLineId?: number, edit: boolean, typeId: number, accessorialTemplateLineId?: number } = { billingContractLineId: null, edit: null, typeId: null, accessorialTemplateLineId: null };
      if (!isNil(params.get('billingContractLineId'))) {
        const paramValueString = params.get('billingContractLineId');
        inParams.billingContractLineId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('edit'))) {
        const paramValueString = params.get('edit');
        inParams.edit = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('accessorialTemplateLineId'))) {
        const paramValueString = params.get('accessorialTemplateLineId');
        inParams.accessorialTemplateLineId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_records_by_invoiceline_grid') {
      const title = 'Billing records by invoice line';
      const component = FootPrintManager_billing_records_by_invoiceline_gridComponent;
      const inParams:{ invoiceLineId: number } = { invoiceLineId: null };
      if (!isNil(params.get('invoiceLineId'))) {
        const paramValueString = params.get('invoiceLineId');
        inParams.invoiceLineId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_records_grid') {
      const title = 'Billing records';
      const component = FootPrintManager_billing_records_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, materialIds?: number[], warehouseIds?: number[], orderIds?: number[], includeCancelled?: boolean, includeInvoiced?: boolean, shipmentIds?: number[], workOrderId?: number } = { ownerId: null, projectId: null, materialIds: [], warehouseIds: [], orderIds: [], includeCancelled: null, includeInvoiced: null, shipmentIds: [], workOrderId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderIds'))) {
        const paramValueString = params.get('orderIds');
        inParams.orderIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('includeCancelled'))) {
        const paramValueString = params.get('includeCancelled');
        inParams.includeCancelled = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('includeInvoiced'))) {
        const paramValueString = params.get('includeInvoiced');
        inParams.includeInvoiced = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('shipmentIds'))) {
        const paramValueString = params.get('shipmentIds');
        inParams.shipmentIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('workOrderId'))) {
        const paramValueString = params.get('workOrderId');
        inParams.workOrderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_supplemental_fees_grid') {
      const title = 'Billing supplemental fees grid';
      const component = FootPrintManager_billing_supplemental_fees_gridComponent;
      const inParams:{ billingContractLineId: number, edit: boolean } = { billingContractLineId: null, edit: null };
      if (!isNil(params.get('billingContractLineId'))) {
        const paramValueString = params.get('billingContractLineId');
        inParams.billingContractLineId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('edit'))) {
        const paramValueString = params.get('edit');
        inParams.edit = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_blind_shipping_container_return_tasks_grid') {
      const title = 'Return tasks';
      const component = FootPrintManager_blind_shipping_container_return_tasks_gridComponent;
      const inParams:{ warehouseId?: number, locationId?: number, sourceShippingContainerId: number, sourceLicensePlateId?: number, inputScan?: string } = { warehouseId: null, locationId: null, sourceShippingContainerId: null, sourceLicensePlateId: null, inputScan: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('sourceShippingContainerId'))) {
        const paramValueString = params.get('sourceShippingContainerId');
        inParams.sourceShippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('sourceLicensePlateId'))) {
        const paramValueString = params.get('sourceLicensePlateId');
        inParams.sourceLicensePlateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('inputScan'))) {
        const paramValueString = params.get('inputScan');
        // TODO: date
        inParams.inputScan = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_carriers_grid') {
      const title = 'Carriers';
      const component = FootPrintManager_carriers_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_carrierservices_grid') {
      const title = 'Carrier services';
      const component = FootPrintManager_carrierservices_gridComponent;
      const inParams:{ carrierId: number } = { carrierId: null };
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_carrierservicetypes_grid') {
      const title = 'Service types';
      const component = FootPrintManager_carrierservicetypes_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_child_serialnumbers_grid') {
      const title = 'Child serial numbers';
      const component = FootPrintManager_child_serialnumbers_gridComponent;
      const inParams:{ chainHead: number, archived: boolean, fullTextSearch?: string } = { chainHead: null, archived: null, fullTextSearch: null };
      if (!isNil(params.get('chainHead'))) {
        const paramValueString = params.get('chainHead');
        inParams.chainHead = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('fullTextSearch'))) {
        const paramValueString = params.get('fullTextSearch');
        // TODO: date
        inParams.fullTextSearch = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_contact_addresses_grid') {
      const title = 'Addresses';
      const component = FootPrintManager_contact_addresses_gridComponent;
      const inParams:{ entity: string, entityId: number, typeIds?: number[] } = { entity: null, entityId: null, typeIds: [] };
      if (!isNil(params.get('entity'))) {
        const paramValueString = params.get('entity');
        // TODO: date
        inParams.entity = paramValueString;
              }
      if (!isNil(params.get('entityId'))) {
        const paramValueString = params.get('entityId');
        inParams.entityId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_container_type_assignment_grid') {
      const title = 'Container assignment grid';
      const component = FootPrintManager_container_type_assignment_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, materialId?: number, enabled?: boolean, assignmentLevel?: string } = { ownerId: null, projectId: null, materialId: null, enabled: null, assignmentLevel: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('enabled'))) {
        const paramValueString = params.get('enabled');
        inParams.enabled = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('assignmentLevel'))) {
        const paramValueString = params.get('assignmentLevel');
        // TODO: date
        inParams.assignmentLevel = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_container_types_grid') {
      const title = 'Container types';
      const component = FootPrintManager_container_types_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, materialId?: number } = { ownerId: null, projectId: null, materialId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_count_tasks_grid') {
      const title = 'Count tasks';
      const component = FootPrintManager_count_tasks_gridComponent;
      const inParams:{ inventoryCountId?: number, allowEdit?: boolean, statusIds?: number[], warehouseIds?: number[] } = { inventoryCountId: null, allowEdit: null, statusIds: [], warehouseIds: [] };
      if (!isNil(params.get('inventoryCountId'))) {
        const paramValueString = params.get('inventoryCountId');
        inParams.inventoryCountId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('allowEdit'))) {
        const paramValueString = params.get('allowEdit');
        inParams.allowEdit = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_count_tasks_no_filter_grid') {
      const title = 'Count tasks';
      const component = FootPrintManager_count_tasks_no_filter_gridComponent;
      const inParams:{ allowEdit?: boolean, statusIds?: number[], warehouseIds?: number[] } = { allowEdit: null, statusIds: [], warehouseIds: [] };
      if (!isNil(params.get('allowEdit'))) {
        const paramValueString = params.get('allowEdit');
        inParams.allowEdit = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_customer_return_shipping_containers_grid') {
      const title = 'Shipping containers';
      const component = FootPrintManager_customer_return_shipping_containers_gridComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointment_types_grid') {
      const title = 'Dock appointment types';
      const component = FootPrintManager_dock_appointment_types_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointments_dashboard_grid') {
      const title = 'Dock appointments dashboard';
      const component = FootPrintManager_dock_appointments_dashboard_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseIds: number[], typeIds?: number[], statusIds?: number[], fromDate?: string, toDate?: string } = { ownerId: null, projectId: null, warehouseIds: [], typeIds: [], statusIds: [], fromDate: null, toDate: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('fromDate'))) {
        const paramValueString = params.get('fromDate');
        // TODO: date
        inParams.fromDate = paramValueString;
              }
      if (!isNil(params.get('toDate'))) {
        const paramValueString = params.get('toDate');
        // TODO: date
        inParams.toDate = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointments_grid') {
      const title = 'Dock appointments';
      const component = FootPrintManager_dock_appointments_gridComponent;
      const inParams:{ typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], warehouseIds: number[], fromDate?: string, toDate?: string, dockDoorIds?: number[] } = { typeIds: [], ownerId: null, projectId: null, carrierId: null, statusIds: [], warehouseIds: [], fromDate: null, toDate: null, dockDoorIds: [] };
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('fromDate'))) {
        const paramValueString = params.get('fromDate');
        // TODO: date
        inParams.fromDate = paramValueString;
              }
      if (!isNil(params.get('toDate'))) {
        const paramValueString = params.get('toDate');
        // TODO: date
        inParams.toDate = paramValueString;
              }
      if (!isNil(params.get('dockDoorIds'))) {
        const paramValueString = params.get('dockDoorIds');
        inParams.dockDoorIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointments_selection_grid') {
      const title = 'Dock appointments';
      const component = FootPrintManager_dock_appointments_selection_gridComponent;
      const inParams:{ typeIds?: number[], carrierId?: number, statusIds?: number[], warehouseId?: number, scheduledProjectId?: number, excludedDockAppointmentIds?: number[] } = { typeIds: [], carrierId: null, statusIds: [], warehouseId: null, scheduledProjectId: null, excludedDockAppointmentIds: [] };
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('scheduledProjectId'))) {
        const paramValueString = params.get('scheduledProjectId');
        inParams.scheduledProjectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excludedDockAppointmentIds'))) {
        const paramValueString = params.get('excludedDockAppointmentIds');
        inParams.excludedDockAppointmentIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_entity_attachments_grid') {
      const title = 'Attachments';
      const component = FootPrintManager_entity_attachments_gridComponent;
      const inParams:{ entityType: string, entityKeys: { name?: string, value?: any }[] } = { entityType: null, entityKeys: [] };
      if (!isNil(params.get('entityType'))) {
        const paramValueString = params.get('entityType');
        // TODO: date
        inParams.entityType = paramValueString;
              }
      if (!isNil(params.get('entityKeys'))) {
        const paramValueString = params.get('entityKeys');
        inParams.entityKeys = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_entity_import_requests_grid') {
      const title = 'Entity imports';
      const component = FootPrintManager_entity_import_requests_gridComponent;
      const inParams:{ statusCodes?: string[], projects?: string[], warehouses?: string[], singleWarehouse?: string, singleProjectName?: string, singleProjectLookupCode?: string, requestId?: string, projectsExact?: string[], warehousesExact?: string[], dateFrom?: string, dateTo?: string, dateType?: string } = { statusCodes: [], projects: [], warehouses: [], singleWarehouse: null, singleProjectName: null, singleProjectLookupCode: null, requestId: null, projectsExact: [], warehousesExact: [], dateFrom: null, dateTo: null, dateType: null };
      if (!isNil(params.get('statusCodes'))) {
        const paramValueString = params.get('statusCodes');
        inParams.statusCodes = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projects'))) {
        const paramValueString = params.get('projects');
        inParams.projects = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouses'))) {
        const paramValueString = params.get('warehouses');
        inParams.warehouses = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('singleWarehouse'))) {
        const paramValueString = params.get('singleWarehouse');
        // TODO: date
        inParams.singleWarehouse = paramValueString;
              }
      if (!isNil(params.get('singleProjectName'))) {
        const paramValueString = params.get('singleProjectName');
        // TODO: date
        inParams.singleProjectName = paramValueString;
              }
      if (!isNil(params.get('singleProjectLookupCode'))) {
        const paramValueString = params.get('singleProjectLookupCode');
        // TODO: date
        inParams.singleProjectLookupCode = paramValueString;
              }
      if (!isNil(params.get('requestId'))) {
        const paramValueString = params.get('requestId');
        // TODO: date
        inParams.requestId = paramValueString;
              }
      if (!isNil(params.get('projectsExact'))) {
        const paramValueString = params.get('projectsExact');
        inParams.projectsExact = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehousesExact'))) {
        const paramValueString = params.get('warehousesExact');
        inParams.warehousesExact = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('dateFrom'))) {
        const paramValueString = params.get('dateFrom');
        // TODO: date
        inParams.dateFrom = paramValueString;
              }
      if (!isNil(params.get('dateTo'))) {
        const paramValueString = params.get('dateTo');
        // TODO: date
        inParams.dateTo = paramValueString;
              }
      if (!isNil(params.get('dateType'))) {
        const paramValueString = params.get('dateType');
        // TODO: date
        inParams.dateType = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_entity_user_defined_field_values_grid') {
      const title = 'Custom fields';
      const component = FootPrintManager_entity_user_defined_field_values_gridComponent;
      const inParams:{ entityType: string, entityKeys: { name?: string, value?: any }[] } = { entityType: null, entityKeys: [] };
      if (!isNil(params.get('entityType'))) {
        const paramValueString = params.get('entityType');
        // TODO: date
        inParams.entityType = paramValueString;
              }
      if (!isNil(params.get('entityKeys'))) {
        const paramValueString = params.get('entityKeys');
        inParams.entityKeys = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_field_selector_grid') {
      const title = 'Select fields';
      const component = FootPrintManager_field_selector_gridComponent;
      const inParams:{ Columns: { Key?: string, Name?: string, IsSelected?: boolean }[] } = { Columns: [] };
      if (!isNil(params.get('Columns'))) {
        const paramValueString = params.get('Columns');
        inParams.Columns = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inbound_orders_grid') {
      const title = 'Inbound orders';
      const component = FootPrintManager_inbound_orders_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], orderClassIds?: number[], reference?: string, materialIds?: number[] } = { ownerId: null, projectId: null, warehouseId: [], statusIds: [], orderClassIds: [], reference: null, materialIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderClassIds'))) {
        const paramValueString = params.get('orderClassIds');
        inParams.orderClassIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('reference'))) {
        const paramValueString = params.get('reference');
        // TODO: date
        inParams.reference = paramValueString;
              }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inspection_operation_codes_grid') {
      const title = 'Operation codes';
      const component = FootPrintManager_inspection_operation_codes_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inspection_tasks_grid') {
      const title = 'Inspection tasks';
      const component = FootPrintManager_inspection_tasks_gridComponent;
      const inParams:{ warehouseId: number, operationCodeIds?: number[] } = { warehouseId: null, operationCodeIds: [] };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('operationCodeIds'))) {
        const paramValueString = params.get('operationCodeIds');
        inParams.operationCodeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_instructions_grid') {
      const title = 'Instructions';
      const component = FootPrintManager_instructions_gridComponent;
      const inParams:{ entityName: string, enabled: boolean, typeIds?: number[], entityKey1: number, entityKey2?: number, entityKey3?: number, entityKey4?: number } = { entityName: null, enabled: null, typeIds: [], entityKey1: null, entityKey2: null, entityKey3: null, entityKey4: null };
      if (!isNil(params.get('entityName'))) {
        const paramValueString = params.get('entityName');
        // TODO: date
        inParams.entityName = paramValueString;
              }
      if (!isNil(params.get('enabled'))) {
        const paramValueString = params.get('enabled');
        inParams.enabled = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('entityKey1'))) {
        const paramValueString = params.get('entityKey1');
        inParams.entityKey1 = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('entityKey2'))) {
        const paramValueString = params.get('entityKey2');
        inParams.entityKey2 = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('entityKey3'))) {
        const paramValueString = params.get('entityKey3');
        inParams.entityKey3 = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('entityKey4'))) {
        const paramValueString = params.get('entityKey4');
        inParams.entityKey4 = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_licenseplate_selection_grid') {
      const title = 'Inventory by lp';
      const component = FootPrintManager_inventory_by_licenseplate_selection_gridComponent;
      const inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number } = { warehouseId: null, materialId: null, packagingId: null, lotId: null, projectId: null, packagedAmountRequest: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmountRequest'))) {
        const paramValueString = params.get('packagedAmountRequest');
        inParams.packagedAmountRequest = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_licenseplate_single_selection_grid') {
      const title = 'Inventory by lp single';
      const component = FootPrintManager_inventory_by_licenseplate_single_selection_gridComponent;
      const inParams:{ warehouseId: number, materialId: number, packagingId: number, lotId?: number, projectId: number, packagedAmountRequest: number } = { warehouseId: null, materialId: null, packagingId: null, lotId: null, projectId: null, packagedAmountRequest: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmountRequest'))) {
        const paramValueString = params.get('packagedAmountRequest');
        inParams.packagedAmountRequest = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_location_selection_grid') {
      const title = 'Inventory by location';
      const component = FootPrintManager_inventory_by_location_selection_gridComponent;
      const inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number } = { warehouseId: null, materialId: null, packagingId: null, lotId: null, projectId: null, packagedAmountRequest: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmountRequest'))) {
        const paramValueString = params.get('packagedAmountRequest');
        inParams.packagedAmountRequest = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_lot_selection_grid') {
      const title = 'Inventory by lot';
      const component = FootPrintManager_inventory_by_lot_selection_gridComponent;
      const inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number } = { warehouseId: null, materialId: null, packagingId: null, lotId: null, projectId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_lot_single_selection_grid') {
      const title = 'Inventory by lot single';
      const component = FootPrintManager_inventory_by_lot_single_selection_gridComponent;
      const inParams:{ warehouseId: number, materialId: number, packagingId: number, projectId: number, packagedAmountRequest: number } = { warehouseId: null, materialId: null, packagingId: null, projectId: null, packagedAmountRequest: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmountRequest'))) {
        const paramValueString = params.get('packagedAmountRequest');
        inParams.packagedAmountRequest = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_serialnumber_selection_grid') {
      const title = 'Inventory by serialnumber';
      const component = FootPrintManager_inventory_by_serialnumber_selection_gridComponent;
      const inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number } = { warehouseId: null, materialId: null, packagingId: null, lotId: null, projectId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_serialnumber_single_selection_grid') {
      const title = 'Inventory by sn single';
      const component = FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent;
      const inParams:{ warehouseId: number, materialId: number, packagingId: number, lotId?: number, projectId: number } = { warehouseId: null, materialId: null, packagingId: null, lotId: null, projectId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_counts_grid') {
      const title = 'Inventory counts';
      const component = FootPrintManager_inventory_counts_gridComponent;
      const inParams:{ warehouseIds?: number[], statusIds?: number[] } = { warehouseIds: [], statusIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_licenseplate_grid') {
      const title = 'Inventory by LP';
      const component = FootPrintManager_inventory_licenseplate_gridComponent;
      const inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] } = { licenseplateId: null, ownerId: null, projectId: null, warehouseId: null, materialIds: [] };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_licenseplate_snapshot_grid') {
      const title = 'Inventory licenseplate snapshot';
      const component = FootPrintManager_inventory_licenseplate_snapshot_gridComponent;
      const inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] } = { captureDate: null, projectId: null, warehouseId: null, materialIds: [] };
      if (!isNil(params.get('captureDate'))) {
        const paramValueString = params.get('captureDate');
        // TODO: date
        inParams.captureDate = paramValueString;
              }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_location_grid') {
      const title = 'Inventory';
      const component = FootPrintManager_inventory_location_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], lotId?: number } = { ownerId: null, projectId: null, warehouseId: null, locationId: null, materialIds: [], lotId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_lot_grid') {
      const title = 'Inventory by lot';
      const component = FootPrintManager_inventory_lot_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] } = { ownerId: null, projectId: null, warehouseId: null, materialIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_lot_snapshot_grid') {
      const title = 'Inventory lot snapshot';
      const component = FootPrintManager_inventory_lot_snapshot_gridComponent;
      const inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] } = { captureDate: null, projectId: null, warehouseId: null, materialIds: [] };
      if (!isNil(params.get('captureDate'))) {
        const paramValueString = params.get('captureDate');
        // TODO: date
        inParams.captureDate = paramValueString;
              }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_material_grid') {
      const title = 'Inventory by material';
      const component = FootPrintManager_inventory_material_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[] } = { ownerId: null, projectId: null, warehouseId: null, materialIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_material_snapshot_grid') {
      const title = 'Inventory material snapshot';
      const component = FootPrintManager_inventory_material_snapshot_gridComponent;
      const inParams:{ captureDate: string, projectId: number, warehouseId: number, materialIds?: number[] } = { captureDate: null, projectId: null, warehouseId: null, materialIds: [] };
      if (!isNil(params.get('captureDate'))) {
        const paramValueString = params.get('captureDate');
        // TODO: date
        inParams.captureDate = paramValueString;
              }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_measurement_units_grid') {
      const title = 'Packages';
      const component = FootPrintManager_inventory_measurement_units_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_parent_licenseplate_grid') {
      const title = 'Inventory by parent lp';
      const component = FootPrintManager_inventory_parent_licenseplate_gridComponent;
      const inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number[], materialIds?: number[] } = { licenseplateId: null, ownerId: null, projectId: null, warehouseId: [], materialIds: [] };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_statuses_grid') {
      const title = 'Inventory statuses';
      const component = FootPrintManager_inventory_statuses_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_tasks_grid') {
      const title = 'Inventory tasks';
      const component = FootPrintManager_inventory_tasks_gridComponent;
      const inParams:{ lotId?: number, materialIds?: number[], projectId?: number, licenseplateId?: number, locationId?: number, ownerId?: number, warehouseIds?: number[], materialId?: number, serialnumberId?: number } = { lotId: null, materialIds: [], projectId: null, licenseplateId: null, locationId: null, ownerId: null, warehouseIds: [], materialId: null, serialnumberId: null };
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('serialnumberId'))) {
        const paramValueString = params.get('serialnumberId');
        inParams.serialnumberId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_transfer_lines_grid') {
      const title = 'Inventory transfer lines';
      const component = FootPrintManager_inventory_transfer_lines_gridComponent;
      const inParams:{ inventoryTransferId: number, sourceProjectId?: number, targetProjectId?: number, warehouseId?: number, can_update?: boolean, can_delete?: boolean } = { inventoryTransferId: null, sourceProjectId: null, targetProjectId: null, warehouseId: null, can_update: null, can_delete: null };
      if (!isNil(params.get('inventoryTransferId'))) {
        const paramValueString = params.get('inventoryTransferId');
        inParams.inventoryTransferId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('sourceProjectId'))) {
        const paramValueString = params.get('sourceProjectId');
        inParams.sourceProjectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('targetProjectId'))) {
        const paramValueString = params.get('targetProjectId');
        inParams.targetProjectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('can_update'))) {
        const paramValueString = params.get('can_update');
        inParams.can_update = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('can_delete'))) {
        const paramValueString = params.get('can_delete');
        inParams.can_delete = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_transfers_grid') {
      const title = 'Inventory transfers';
      const component = FootPrintManager_inventory_transfers_gridComponent;
      const inParams:{ warehouses?: number[], sourceProjectId?: number, targetProjectId?: number, statusIds?: number[] } = { warehouses: [], sourceProjectId: null, targetProjectId: null, statusIds: [] };
      if (!isNil(params.get('warehouses'))) {
        const paramValueString = params.get('warehouses');
        inParams.warehouses = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('sourceProjectId'))) {
        const paramValueString = params.get('sourceProjectId');
        inParams.sourceProjectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('targetProjectId'))) {
        const paramValueString = params.get('targetProjectId');
        inParams.targetProjectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_invoice_lines_grid') {
      const title = 'Invoice lines';
      const component = FootPrintManager_invoice_lines_gridComponent;
      const inParams:{ invoiceId: number } = { invoiceId: null };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_invoices_grid') {
      const title = 'Invoices';
      const component = FootPrintManager_invoices_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, statusIds?: number[] } = { ownerId: null, projectId: null, statusIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_licenseplate_grid') {
      const title = 'Select a licenseplate';
      const component = FootPrintManager_licenseplate_gridComponent;
      const inParams:{ locationId: number, lotId: number, packagedId: number } = { locationId: null, lotId: null, packagedId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedId'))) {
        const paramValueString = params.get('packagedId');
        inParams.packagedId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_load_container_orders_grid') {
      const title = 'Orders by load container';
      const component = FootPrintManager_load_container_orders_gridComponent;
      const inParams:{ loadContainerId: number, edit?: boolean, warehouseId?: number, orderTypeId?: number, status_id?: number } = { loadContainerId: null, edit: null, warehouseId: null, orderTypeId: null, status_id: null };
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('edit'))) {
        const paramValueString = params.get('edit');
        inParams.edit = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderTypeId'))) {
        const paramValueString = params.get('orderTypeId');
        inParams.orderTypeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('status_id'))) {
        const paramValueString = params.get('status_id');
        inParams.status_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_load_container_unassigned_orders_grid') {
      const title = 'Unassigned orders for load containers';
      const component = FootPrintManager_load_container_unassigned_orders_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, statusIds?: number[], orderTypeId?: number, warehouseId?: number, loadContainerId?: number, lookupCode?: string } = { ownerId: null, projectId: null, statusIds: [], orderTypeId: null, warehouseId: null, loadContainerId: null, lookupCode: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderTypeId'))) {
        const paramValueString = params.get('orderTypeId');
        inParams.orderTypeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lookupCode'))) {
        const paramValueString = params.get('lookupCode');
        // TODO: date
        inParams.lookupCode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_load_containers_grid') {
      const title = 'Load containers';
      const component = FootPrintManager_load_containers_gridComponent;
      const inParams:{ statusIds?: number[], ownerId?: number, orderTypeIds?: number[] } = { statusIds: [], ownerId: null, orderTypeIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderTypeIds'))) {
        const paramValueString = params.get('orderTypeIds');
        inParams.orderTypeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_location_projects_grid') {
      const title = 'Location project assignments';
      const component = FootPrintManager_location_projects_gridComponent;
      const inParams:{ locationId: number } = { locationId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_locations_grid') {
      const title = 'Locations';
      const component = FootPrintManager_locations_gridComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_locations_navigational_grid') {
      const title = 'Locations';
      const component = FootPrintManager_locations_navigational_gridComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_locations_replenishments_grid') {
      const title = 'Replenishments';
      const component = FootPrintManager_locations_replenishments_gridComponent;
      const inParams:{ materialId?: number[], warehouseIds?: number[], locationId?: number[], projectId?: number, ownerId?: number } = { materialId: [], warehouseIds: [], locationId: [], projectId: null, ownerId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_locations_selector_grid') {
      const title = 'Location selector';
      const component = FootPrintManager_locations_selector_gridComponent;
      const inParams:{ warehouseId: number, selectedLocationIds?: number[], isExclude?: boolean, allowEdit: boolean } = { warehouseId: null, selectedLocationIds: [], isExclude: null, allowEdit: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('selectedLocationIds'))) {
        const paramValueString = params.get('selectedLocationIds');
        inParams.selectedLocationIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('isExclude'))) {
        const paramValueString = params.get('isExclude');
        inParams.isExclude = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('allowEdit'))) {
        const paramValueString = params.get('allowEdit');
        inParams.allowEdit = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_lots_grid') {
      const title = 'Lots grid';
      const component = FootPrintManager_lots_gridComponent;
      const inParams:{ ownerIds?: number[], projectIds?: number[], materialIds?: number[], statusIds?: number[] } = { ownerIds: [], projectIds: [], materialIds: [], statusIds: [] };
      if (!isNil(params.get('ownerIds'))) {
        const paramValueString = params.get('ownerIds');
        inParams.ownerIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectIds'))) {
        const paramValueString = params.get('projectIds');
        inParams.projectIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_lots_grid_flat') {
      const title = 'Lots grid from flat data source';
      const component = FootPrintManager_lots_grid_flatComponent;
      const inParams:{ ownerIds?: number[], projectIds?: number[], materialIds?: number[], statusIds?: number[] } = { ownerIds: [], projectIds: [], materialIds: [], statusIds: [] };
      if (!isNil(params.get('ownerIds'))) {
        const paramValueString = params.get('ownerIds');
        inParams.ownerIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectIds'))) {
        const paramValueString = params.get('projectIds');
        inParams.projectIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_lots_udf_grid') {
      const title = 'Lots udf';
      const component = FootPrintManager_lots_udf_gridComponent;
      const inParams:{ projectId?: number, ownerId?: number, materialIds?: number[], lotId?: number } = { projectId: null, ownerId: null, materialIds: [], lotId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_manifesting_shipments_grid') {
      const title = 'Shipments';
      const component = FootPrintManager_manifesting_shipments_gridComponent;
      const inParams:{ shipmentIds: number[], printerId?: number, length?: number, width?: number, height?: number, weight?: number, utilizeShipmentContainer?: boolean, labelSize?: number, contentsExplanation?: string, containerCount?: number, printerIdPackinglist?: number } = { shipmentIds: [], printerId: null, length: null, width: null, height: null, weight: null, utilizeShipmentContainer: null, labelSize: null, contentsExplanation: null, containerCount: null, printerIdPackinglist: null };
      if (!isNil(params.get('shipmentIds'))) {
        const paramValueString = params.get('shipmentIds');
        inParams.shipmentIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('printerId'))) {
        const paramValueString = params.get('printerId');
        inParams.printerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('length'))) {
        const paramValueString = params.get('length');
        inParams.length = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('width'))) {
        const paramValueString = params.get('width');
        inParams.width = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('height'))) {
        const paramValueString = params.get('height');
        inParams.height = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('weight'))) {
        const paramValueString = params.get('weight');
        inParams.weight = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('utilizeShipmentContainer'))) {
        const paramValueString = params.get('utilizeShipmentContainer');
        inParams.utilizeShipmentContainer = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('labelSize'))) {
        const paramValueString = params.get('labelSize');
        inParams.labelSize = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('contentsExplanation'))) {
        const paramValueString = params.get('contentsExplanation');
        // TODO: date
        inParams.contentsExplanation = paramValueString;
              }
      if (!isNil(params.get('containerCount'))) {
        const paramValueString = params.get('containerCount');
        inParams.containerCount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('printerIdPackinglist'))) {
        const paramValueString = params.get('printerIdPackinglist');
        inParams.printerIdPackinglist = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_manual_allocations_grid') {
      const title = 'Manual allocations';
      const component = FootPrintManager_manual_allocations_gridComponent;
      const inParams:{ waveId?: number, orderId?: number } = { waveId: null, orderId: null };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_components_grid') {
      const title = 'Material components';
      const component = FootPrintManager_material_components_gridComponent;
      const inParams:{ material_id: number, packaging_id: number, project_id: number, suppress_new_row: boolean } = { material_id: null, packaging_id: null, project_id: null, suppress_new_row: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packaging_id'))) {
        const paramValueString = params.get('packaging_id');
        inParams.packaging_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('suppress_new_row'))) {
        const paramValueString = params.get('suppress_new_row');
        inParams.suppress_new_row = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_kits_grid') {
      const title = 'Material kits';
      const component = FootPrintManager_material_kits_gridComponent;
      const inParams:{ material_id: number, project_id: number } = { material_id: null, project_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_packagings_by_project_grid') {
      const title = 'Packagings by project';
      const component = FootPrintManager_material_packagings_by_project_gridComponent;
      const inParams:{ materialIds?: number[], ownerId?: number, projectId?: number } = { materialIds: [], ownerId: null, projectId: null };
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_packagings_grid') {
      const title = 'Packagings';
      const component = FootPrintManager_material_packagings_gridComponent;
      const inParams:{ materialId?: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_substitutions_grid') {
      const title = 'Material substitutions';
      const component = FootPrintManager_material_substitutions_gridComponent;
      const inParams:{ material_id: number, packaging_id: number, project_id: number } = { material_id: null, packaging_id: null, project_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packaging_id'))) {
        const paramValueString = params.get('packaging_id');
        inParams.packaging_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_materials_by_base_packaging_grid') {
      const title = 'Materials';
      const component = FootPrintManager_materials_by_base_packaging_gridComponent;
      const inParams:{ projectId?: number, ownerId?: number } = { projectId: null, ownerId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_materials_navigational_grid') {
      const title = 'Materials';
      const component = FootPrintManager_materials_navigational_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, materialIds?: number[] } = { ownerId: null, projectId: null, materialIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_materials_selector_grid') {
      const title = 'Material selector';
      const component = FootPrintManager_materials_selector_gridComponent;
      const inParams:{ selectedMaterialIds?: number[], isExclude?: boolean, allowEdit: boolean } = { selectedMaterialIds: [], isExclude: null, allowEdit: null };
      if (!isNil(params.get('selectedMaterialIds'))) {
        const paramValueString = params.get('selectedMaterialIds');
        inParams.selectedMaterialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('isExclude'))) {
        const paramValueString = params.get('isExclude');
        inParams.isExclude = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('allowEdit'))) {
        const paramValueString = params.get('allowEdit');
        inParams.allowEdit = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_materials_udf_grid') {
      const title = 'Materials advanced';
      const component = FootPrintManager_materials_udf_gridComponent;
      const inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] } = { projectId: null, ownerId: null, materialIds: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_orderaddresses_grid') {
      const title = 'Order addresses';
      const component = FootPrintManager_orderaddresses_gridComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_outbound_orders_eligible_for_return_grid') {
      const title = 'Completed outbound orders';
      const component = FootPrintManager_outbound_orders_eligible_for_return_gridComponent;
      const inParams:{ warehouseIds?: number[], ownerId?: number, projectId?: number } = { warehouseIds: [], ownerId: null, projectId: null };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_outbound_orders_grid') {
      const title = 'Outbound orders';
      const component = FootPrintManager_outbound_orders_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, materialIds?: number[], waveId?: number } = { ownerId: null, projectId: null, warehouseId: [], statusIds: [], countries: [], carrierId: null, carrierServiceId: null, reference: null, materialIds: [], waveId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('countries'))) {
        const paramValueString = params.get('countries');
        inParams.countries = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierServiceId'))) {
        const paramValueString = params.get('carrierServiceId');
        inParams.carrierServiceId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('reference'))) {
        const paramValueString = params.get('reference');
        // TODO: date
        inParams.reference = paramValueString;
              }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_owners_navigation_grid') {
      const title = 'Owners';
      const component = FootPrintManager_owners_navigation_gridComponent;
      const inParams:{ ownerStatusId?: number } = { ownerStatusId: null };
      if (!isNil(params.get('ownerStatusId'))) {
        const paramValueString = params.get('ownerStatusId');
        inParams.ownerStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_ownersaccounts_grid') {
      const title = 'Addresses';
      const component = FootPrintManager_ownersaccounts_gridComponent;
      const inParams:{ ownerId: number, accountTypeId: number, typeIds: number[], allowSelection: boolean } = { ownerId: null, accountTypeId: null, typeIds: [], allowSelection: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('accountTypeId'))) {
        const paramValueString = params.get('accountTypeId');
        inParams.accountTypeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('allowSelection'))) {
        const paramValueString = params.get('allowSelection');
        inParams.allowSelection = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_ownerscarrierslookup_grid') {
      const title = 'Owner carriers lookup';
      const component = FootPrintManager_ownerscarrierslookup_gridComponent;
      const inParams:{ ownerId: number } = { ownerId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pack_verification_instructions_grid') {
      const title = 'Instructions';
      const component = FootPrintManager_pack_verification_instructions_gridComponent;
      const inParams:{ shippingContainerId: number, startVerificationTaskId: number } = { shippingContainerId: null, startVerificationTaskId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('startVerificationTaskId'))) {
        const paramValueString = params.get('startVerificationTaskId');
        inParams.startVerificationTaskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pack_verification_shipping_container_contents_grid') {
      const title = 'Container contents';
      const component = FootPrintManager_pack_verification_shipping_container_contents_gridComponent;
      const inParams:{ shippingContainerId: number, canAddManually?: boolean, startVerificationTaskId: number, isReadOnly?: boolean, scannedValues: { scannedValue?: string, lotId?: number, packagingId?: number }[], isBlind?: boolean, expectedSerialNumbers?: { lookupCode: string, lotId: number, packagingId: number, id: number, licensePlateId: number }[], locationId: number } = { shippingContainerId: null, canAddManually: null, startVerificationTaskId: null, isReadOnly: null, scannedValues: [], isBlind: null, expectedSerialNumbers: [], locationId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('canAddManually'))) {
        const paramValueString = params.get('canAddManually');
        inParams.canAddManually = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('startVerificationTaskId'))) {
        const paramValueString = params.get('startVerificationTaskId');
        inParams.startVerificationTaskId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('isReadOnly'))) {
        const paramValueString = params.get('isReadOnly');
        inParams.isReadOnly = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('scannedValues'))) {
        const paramValueString = params.get('scannedValues');
        inParams.scannedValues = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('isBlind'))) {
        const paramValueString = params.get('isBlind');
        inParams.isBlind = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('expectedSerialNumbers'))) {
        const paramValueString = params.get('expectedSerialNumbers');
        inParams.expectedSerialNumbers = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pallet_classes_grid') {
      const title = 'Pallet classes grid';
      const component = FootPrintManager_pallet_classes_gridComponent;
      const inParams:{ pallet_class_id?: number } = { pallet_class_id: null };
      if (!isNil(params.get('pallet_class_id'))) {
        const paramValueString = params.get('pallet_class_id');
        inParams.pallet_class_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pallet_inventory_by_class_grid') {
      const title = 'Pallet inventory by class grid';
      const component = FootPrintManager_pallet_inventory_by_class_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pallet_inventory_by_project_grid') {
      const title = 'pallet_inventory_by_project_grid';
      const component = FootPrintManager_pallet_inventory_by_project_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pallet_transactions_grid') {
      const title = 'Pallet transactions grid';
      const component = FootPrintManager_pallet_transactions_gridComponent;
      const inParams:{ project_id?: number, pallet_class_id?: number, project_lookup_code?: string, pallet_class_name?: string } = { project_id: null, pallet_class_id: null, project_lookup_code: null, pallet_class_name: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('pallet_class_id'))) {
        const paramValueString = params.get('pallet_class_id');
        inParams.pallet_class_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_lookup_code'))) {
        const paramValueString = params.get('project_lookup_code');
        // TODO: date
        inParams.project_lookup_code = paramValueString;
              }
      if (!isNil(params.get('pallet_class_name'))) {
        const paramValueString = params.get('pallet_class_name');
        // TODO: date
        inParams.pallet_class_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pallet_transactions_shipment_grid') {
      const title = 'Pallet transactions shipment grid';
      const component = FootPrintManager_pallet_transactions_shipment_gridComponent;
      const inParams:{ project_id?: number, shipment_id?: number, order_lookup_code?: string } = { project_id: null, shipment_id: null, order_lookup_code: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipment_id'))) {
        const paramValueString = params.get('shipment_id');
        inParams.shipment_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_lookup_code'))) {
        const paramValueString = params.get('order_lookup_code');
        // TODO: date
        inParams.order_lookup_code = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pick_slips_grid') {
      const title = 'Pick slips';
      const component = FootPrintManager_pick_slips_gridComponent;
      const inParams:{ waveId: number } = { waveId: null };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pick_waves_expanded_grid') {
      const title = 'Pick waves expanded';
      const component = FootPrintManager_pick_waves_expanded_gridComponent;
      const inParams:{ WaveId: number } = { WaveId: null };
      if (!isNil(params.get('WaveId'))) {
        const paramValueString = params.get('WaveId');
        inParams.WaveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pick_waves_grid') {
      const title = 'Pick waves';
      const component = FootPrintManager_pick_waves_gridComponent;
      const inParams:{ ownerId?: number[], projectId?: number[], warehouseId?: number[], statusIds?: number[] } = { ownerId: [], projectId: [], warehouseId: [], statusIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_projects_grid') {
      const title = 'Projects';
      const component = FootPrintManager_projects_gridComponent;
      const inParams:{ projectStatusId?: number, ownerId?: number, projectIds?: number[] } = { projectStatusId: null, ownerId: null, projectIds: [] };
      if (!isNil(params.get('projectStatusId'))) {
        const paramValueString = params.get('projectStatusId');
        inParams.projectStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectIds'))) {
        const paramValueString = params.get('projectIds');
        inParams.projectIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_purchase_order_bulk_receiving_by_taskId_grid') {
      const title = 'Bulk receiving task';
      const component = FootPrintManager_purchase_order_bulk_receiving_by_taskId_gridComponent;
      const inParams:{ taskId: number, palletCount: number, fullPalletQuantity?: number } = { taskId: null, palletCount: null, fullPalletQuantity: null };
      if (!isNil(params.get('taskId'))) {
        const paramValueString = params.get('taskId');
        inParams.taskId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('palletCount'))) {
        const paramValueString = params.get('palletCount');
        inParams.palletCount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('fullPalletQuantity'))) {
        const paramValueString = params.get('fullPalletQuantity');
        inParams.fullPalletQuantity = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_purchase_order_children_tasks_grid') {
      const title = 'Purchase order children tasks';
      const component = FootPrintManager_purchase_order_children_tasks_gridComponent;
      const inParams:{ taskId: number } = { taskId: null };
      if (!isNil(params.get('taskId'))) {
        const paramValueString = params.get('taskId');
        inParams.taskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_purchase_order_lines_grid') {
      const title = 'Order lines';
      const component = FootPrintManager_purchase_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_purchase_order_received_inventory_grid') {
      const title = 'Received inventory';
      const component = FootPrintManager_purchase_order_received_inventory_gridComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_purchase_order_receiving_grid') {
      const title = 'Receiving tasks';
      const component = FootPrintManager_purchase_order_receiving_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, orderStatusId: number } = { orderId: null, shipmentId: null, orderStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_purchase_order_tasks_grid') {
      const title = 'Purchase order tasks';
      const component = FootPrintManager_purchase_order_tasks_gridComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_reason_codes_grid') {
      const title = 'Reason codes';
      const component = FootPrintManager_reason_codes_gridComponent;
      const inParams:{ parentEntity?: string } = { parentEntity: null };
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_replenishment_inventory_licenseplate_grid') {
      const title = 'Inventory by lp';
      const component = FootPrintManager_replenishment_inventory_licenseplate_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], materialIds?: number[], locationIds?: number[] } = { ownerId: null, projectId: null, warehouseIds: [], materialIds: [], locationIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationIds'))) {
        const paramValueString = params.get('locationIds');
        inParams.locationIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_replenishment_tasks_grid') {
      const title = 'Replenishment tasks';
      const component = FootPrintManager_replenishment_tasks_gridComponent;
      const inParams:{ materialIds?: number[], projectId?: number, locationIds?: number[], ownerId?: number, warehouseIds?: number[] } = { materialIds: [], projectId: null, locationIds: [], ownerId: null, warehouseIds: [] };
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationIds'))) {
        const paramValueString = params.get('locationIds');
        inParams.locationIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_return_order_lines_grid') {
      const title = 'Order lines';
      const component = FootPrintManager_return_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_return_orders_grid') {
      const title = 'Return orders';
      const component = FootPrintManager_return_orders_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], statusIds?: number[], orderClassIds?: number[], reference?: string, blindShippingContainerId?: number } = { ownerId: null, projectId: null, warehouseIds: [], statusIds: [], orderClassIds: [], reference: null, blindShippingContainerId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderClassIds'))) {
        const paramValueString = params.get('orderClassIds');
        inParams.orderClassIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('reference'))) {
        const paramValueString = params.get('reference');
        // TODO: date
        inParams.reference = paramValueString;
              }
      if (!isNil(params.get('blindShippingContainerId'))) {
        const paramValueString = params.get('blindShippingContainerId');
        inParams.blindShippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_return_shipping_containers_grid') {
      const title = 'Return shipping containers';
      const component = FootPrintManager_return_shipping_containers_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], orderStatusIds?: number[], orderClassIds?: number[], reference?: string } = { ownerId: null, projectId: null, warehouseIds: [], orderStatusIds: [], orderClassIds: [], reference: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderStatusIds'))) {
        const paramValueString = params.get('orderStatusIds');
        inParams.orderStatusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderClassIds'))) {
        const paramValueString = params.get('orderClassIds');
        inParams.orderClassIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('reference'))) {
        const paramValueString = params.get('reference');
        // TODO: date
        inParams.reference = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_children_tasks_grid') {
      const title = 'Sales order children tasks';
      const component = FootPrintManager_sales_order_children_tasks_gridComponent;
      const inParams:{ taskId: number } = { taskId: null };
      if (!isNil(params.get('taskId'))) {
        const paramValueString = params.get('taskId');
        inParams.taskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_line_components_grid') {
      const title = 'Sales order line kit components';
      const component = FootPrintManager_sales_order_line_components_gridComponent;
      const inParams:{ order_id: number, shipment_id: number, project_id: number, order_status_id: number, warehouse_id: number, line_number: number } = { order_id: null, shipment_id: null, project_id: null, order_status_id: null, warehouse_id: null, line_number: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipment_id'))) {
        const paramValueString = params.get('shipment_id');
        inParams.shipment_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_status_id'))) {
        const paramValueString = params.get('order_status_id');
        inParams.order_status_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('line_number'))) {
        const paramValueString = params.get('line_number');
        inParams.line_number = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_line_kit_grid') {
      const title = 'Sales order line kit management';
      const component = FootPrintManager_sales_order_line_kit_gridComponent;
      const inParams:{ material_id: number, packaging_id: number, project_id: number, order_id: number, line_number: number, line_status_id: number, packaged_amount: number, warehouse_id: number, shipment_id: number } = { material_id: null, packaging_id: null, project_id: null, order_id: null, line_number: null, line_status_id: null, packaged_amount: null, warehouse_id: null, shipment_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packaging_id'))) {
        const paramValueString = params.get('packaging_id');
        inParams.packaging_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('line_number'))) {
        const paramValueString = params.get('line_number');
        inParams.line_number = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('line_status_id'))) {
        const paramValueString = params.get('line_status_id');
        inParams.line_status_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packaged_amount'))) {
        const paramValueString = params.get('packaged_amount');
        inParams.packaged_amount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipment_id'))) {
        const paramValueString = params.get('shipment_id');
        inParams.shipment_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_line_substitutions_grid') {
      const title = 'Sales order line substitutions management';
      const component = FootPrintManager_sales_order_line_substitutions_gridComponent;
      const inParams:{ material_id: number, packaging_id: number, warehouse_id: number } = { material_id: null, packaging_id: null, warehouse_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packaging_id'))) {
        const paramValueString = params.get('packaging_id');
        inParams.packaging_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_lines_grid') {
      const title = 'Order lines';
      const component = FootPrintManager_sales_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null, warehouseId: null, orderState: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderState'))) {
        const paramValueString = params.get('orderState');
        inParams.orderState = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_picking_grid') {
      const title = 'Picking tasks';
      const component = FootPrintManager_sales_order_picking_gridComponent;
      const inParams:{ orderId?: number, shipmentId?: number, waveId?: number, orderStatusId?: number, waveStatusId?: number } = { orderId: null, shipmentId: null, waveId: null, orderStatusId: null, waveStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('waveStatusId'))) {
        const paramValueString = params.get('waveStatusId');
        inParams.waveStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_sales_order_tasks_grid') {
      const title = 'Sales Order Tasks';
      const component = FootPrintManager_sales_order_tasks_gridComponent;
      const inParams:{ orderId?: number, shipmentId?: number, waveId?: number } = { orderId: null, shipmentId: null, waveId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_scanned_values_grid') {
      const title = 'Scanned values';
      const component = FootPrintManager_scanned_values_gridComponent;
      const inParams:{ scannedValues: { Value?: string, Note?: string }[], expectedValues?: string[] } = { scannedValues: [], expectedValues: [] };
      if (!isNil(params.get('scannedValues'))) {
        const paramValueString = params.get('scannedValues');
        inParams.scannedValues = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('expectedValues'))) {
        const paramValueString = params.get('expectedValues');
        inParams.expectedValues = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_serialnumbers_creation_grid') {
      const title = 'Create serial numbers';
      const component = FootPrintManager_serialnumbers_creation_gridComponent;
      const inParams:{ materialId: number, packagingId: number, packagedAmount: number, averageGross?: number, averageNet?: number, weightUomId?: number, averageLength?: number, averageWidth?: number, averageHeight?: number, dimensionUomId?: number, lotId?: number, licensePlateId?: number } = { materialId: null, packagingId: null, packagedAmount: null, averageGross: null, averageNet: null, weightUomId: null, averageLength: null, averageWidth: null, averageHeight: null, dimensionUomId: null, lotId: null, licensePlateId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmount'))) {
        const paramValueString = params.get('packagedAmount');
        inParams.packagedAmount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageGross'))) {
        const paramValueString = params.get('averageGross');
        inParams.averageGross = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageNet'))) {
        const paramValueString = params.get('averageNet');
        inParams.averageNet = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('weightUomId'))) {
        const paramValueString = params.get('weightUomId');
        inParams.weightUomId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageLength'))) {
        const paramValueString = params.get('averageLength');
        inParams.averageLength = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageWidth'))) {
        const paramValueString = params.get('averageWidth');
        inParams.averageWidth = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageHeight'))) {
        const paramValueString = params.get('averageHeight');
        inParams.averageHeight = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('dimensionUomId'))) {
        const paramValueString = params.get('dimensionUomId');
        inParams.dimensionUomId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licensePlateId'))) {
        const paramValueString = params.get('licensePlateId');
        inParams.licensePlateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_serialnumbers_grid') {
      const title = 'Serial Numbers';
      const component = FootPrintManager_serialnumbers_gridComponent;
      const inParams:{ licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, search?: string, packagingId?: number, orderId?: number, orderLineNumber?: number } = { licenseplateIds: [], locationId: null, warehouseIds: [], lotId: null, materialIds: [], projectId: null, ownerId: null, archived: null, search: null, packagingId: null, orderId: null, orderLineNumber: null };
      if (!isNil(params.get('licenseplateIds'))) {
        const paramValueString = params.get('licenseplateIds');
        inParams.licenseplateIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('search'))) {
        const paramValueString = params.get('search');
        // TODO: date
        inParams.search = paramValueString;
              }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_serialnumbers_selection_grid') {
      const title = 'Select serial numbers';
      const component = FootPrintManager_serialnumbers_selection_gridComponent;
      const inParams:{ lotId: number, licenseplateId: number, packagingId: number, packagedAmountRequest: number, serialNumberIds?: number[], filterSerialNumberIds?: number[] } = { lotId: null, licenseplateId: null, packagingId: null, packagedAmountRequest: null, serialNumberIds: [], filterSerialNumberIds: [] };
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmountRequest'))) {
        const paramValueString = params.get('packagedAmountRequest');
        inParams.packagedAmountRequest = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('serialNumberIds'))) {
        const paramValueString = params.get('serialNumberIds');
        inParams.serialNumberIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filterSerialNumberIds'))) {
        const paramValueString = params.get('filterSerialNumberIds');
        inParams.filterSerialNumberIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_shipping_container_tasks_child_grid') {
      const title = 'Shipping container child tasks';
      const component = FootPrintManager_shipping_container_tasks_child_gridComponent;
      const inParams:{ shippingContainerId: number, chainHead?: number } = { shippingContainerId: null, chainHead: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('chainHead'))) {
        const paramValueString = params.get('chainHead');
        inParams.chainHead = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_shipping_container_tasks_grid') {
      const title = 'Shipping container tasks';
      const component = FootPrintManager_shipping_container_tasks_gridComponent;
      const inParams:{ shippingContainerId: number, chainHead?: number } = { shippingContainerId: null, chainHead: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('chainHead'))) {
        const paramValueString = params.get('chainHead');
        inParams.chainHead = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_shipping_containers_grid') {
      const title = 'Shipping containers grid';
      const component = FootPrintManager_shipping_containers_gridComponent;
      const inParams:{ ownerIds?: number[], projectIds?: number[], warehouseIds?: number[], orderTypeIds?: number[], shipmentStatuses?: number[] } = { ownerIds: [], projectIds: [], warehouseIds: [], orderTypeIds: [], shipmentStatuses: [] };
      if (!isNil(params.get('ownerIds'))) {
        const paramValueString = params.get('ownerIds');
        inParams.ownerIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectIds'))) {
        const paramValueString = params.get('projectIds');
        inParams.projectIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderTypeIds'))) {
        const paramValueString = params.get('orderTypeIds');
        inParams.orderTypeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('shipmentStatuses'))) {
        const paramValueString = params.get('shipmentStatuses');
        inParams.shipmentStatuses = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_shipping_containers_to_verify_grid') {
      const title = 'Containers to verify';
      const component = FootPrintManager_shipping_containers_to_verify_gridComponent;
      const inParams:{ warehouseId: number, locationId?: number, shippingContainerCode?: string, isFailed: boolean } = { warehouseId: null, locationId: null, shippingContainerCode: null, isFailed: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shippingContainerCode'))) {
        const paramValueString = params.get('shippingContainerCode');
        // TODO: date
        inParams.shippingContainerCode = paramValueString;
              }
      if (!isNil(params.get('isFailed'))) {
        const paramValueString = params.get('isFailed');
        inParams.isFailed = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_shippingcontents_grid') {
      const title = 'Shipping contents';
      const component = FootPrintManager_shippingcontents_gridComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_storage_categories_grid') {
      const title = 'Storage categories';
      const component = FootPrintManager_storage_categories_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_storage_category_rules_grid') {
      const title = 'Storage category rules';
      const component = FootPrintManager_storage_category_rules_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_submitted_survey_inputs_grid') {
      const title = 'Survey inputs';
      const component = FootPrintManager_submitted_survey_inputs_gridComponent;
      const inParams:{ surveyId: number } = { surveyId: null };
      if (!isNil(params.get('surveyId'))) {
        const paramValueString = params.get('surveyId');
        inParams.surveyId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_submitted_surveys_grid') {
      const title = 'Submitted surveys';
      const component = FootPrintManager_submitted_surveys_gridComponent;
      const inParams:{ entities?: { type?: string, ids?: number[] }[], operationContextTypes?: string[], warehouseIds?: number[] } = { entities: [], operationContextTypes: [], warehouseIds: [] };
      if (!isNil(params.get('entities'))) {
        const paramValueString = params.get('entities');
        inParams.entities = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('operationContextTypes'))) {
        const paramValueString = params.get('operationContextTypes');
        inParams.operationContextTypes = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_support_tests_grid') {
      const title = 'Support tests grid';
      const component = FootPrintManager_support_tests_gridComponent;
      const inParams:{ test_ids?: number[], test_runs?: number, rows_override?: number } = { test_ids: [], test_runs: null, rows_override: null };
      if (!isNil(params.get('test_ids'))) {
        const paramValueString = params.get('test_ids');
        inParams.test_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('test_runs'))) {
        const paramValueString = params.get('test_runs');
        inParams.test_runs = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('rows_override'))) {
        const paramValueString = params.get('rows_override');
        inParams.rows_override = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_survey_grid_input_values_grid') {
      const title = 'Grid inputs';
      const component = FootPrintManager_survey_grid_input_values_gridComponent;
      const inParams:{ values: { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string }[], headers?: { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string } } = { values: [], headers: { header1: null, header2: null, header3: null, header4: null, header5: null } };
      if (!isNil(params.get('values'))) {
        const paramValueString = params.get('values');
        inParams.values = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('headers'))) {
        const paramValueString = params.get('headers');
        inParams.headers = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_task_assignment_grid') {
      const title = 'Task assignment';
      const component = FootPrintManager_task_assignment_gridComponent;
      const inParams:{ warehouseIds?: number[], workClassIds?: number[], fromDate?: string, toDate?: string, projectId?: number, ownerId?: number } = { warehouseIds: [], workClassIds: [], fromDate: null, toDate: null, projectId: null, ownerId: null };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('workClassIds'))) {
        const paramValueString = params.get('workClassIds');
        inParams.workClassIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('fromDate'))) {
        const paramValueString = params.get('fromDate');
        // TODO: date
        inParams.fromDate = paramValueString;
              }
      if (!isNil(params.get('toDate'))) {
        const paramValueString = params.get('toDate');
        // TODO: date
        inParams.toDate = paramValueString;
              }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_temperature_capture_reading_points_grid') {
      const title = 'Temperature capture reading points';
      const component = FootPrintManager_temperature_capture_reading_points_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_temperature_capture_reading_types_grid') {
      const title = 'Temperature capture reading types';
      const component = FootPrintManager_temperature_capture_reading_types_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_temperature_readings_grid') {
      const title = 'Temperature readings grid';
      const component = FootPrintManager_temperature_readings_gridComponent;
      const inParams:{ loadContainerId?: number, shipmentId?: number, licensePlateId?: number, orderId?: number } = { loadContainerId: null, shipmentId: null, licensePlateId: null, orderId: null };
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licensePlateId'))) {
        const paramValueString = params.get('licensePlateId');
        inParams.licensePlateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_transload_order_lines_grid') {
      const title = 'Order lines';
      const component = FootPrintManager_transload_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_transload_order_received_inventory_grid') {
      const title = 'Received inventory';
      const component = FootPrintManager_transload_order_received_inventory_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, transload_id: number } = { orderId: null, shipmentId: null, transload_id: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('transload_id'))) {
        const paramValueString = params.get('transload_id');
        inParams.transload_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_transload_order_receiving_grid') {
      const title = 'Receiving tasks';
      const component = FootPrintManager_transload_order_receiving_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, orderStatusId: number, transload_id: number } = { orderId: null, shipmentId: null, orderStatusId: null, transload_id: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('transload_id'))) {
        const paramValueString = params.get('transload_id');
        inParams.transload_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_transload_orders_grid') {
      const title = 'Transload orders';
      const component = FootPrintManager_transload_orders_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], materialIds?: number[] } = { ownerId: null, projectId: null, warehouseId: [], statusIds: [], materialIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_transload_outbound_shipments_grid') {
      const title = 'Transload outbound shipments';
      const component = FootPrintManager_transload_outbound_shipments_gridComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_vendorlots_selector_grid') {
      const title = 'Vendor lot selector';
      const component = FootPrintManager_vendorlots_selector_gridComponent;
      const inParams:{ selectedVendorlotIds?: number[], isExclude?: boolean, allowEdit: boolean } = { selectedVendorlotIds: [], isExclude: null, allowEdit: null };
      if (!isNil(params.get('selectedVendorlotIds'))) {
        const paramValueString = params.get('selectedVendorlotIds');
        inParams.selectedVendorlotIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('isExclude'))) {
        const paramValueString = params.get('isExclude');
        inParams.isExclude = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('allowEdit'))) {
        const paramValueString = params.get('allowEdit');
        inParams.allowEdit = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_inbound_licenseplates_grid') {
      const title = 'License plates';
      const component = FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent;
      const inParams:{ orderId: number, orderStatusId: number } = { orderId: null, orderStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid') {
      const title = 'Receiving tasks';
      const component = FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_gridComponent;
      const inParams:{ licenseplateId: number, orderId: number } = { licenseplateId: null, orderId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_grid') {
      const title = 'Receiving tasks';
      const component = FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent;
      const inParams:{ orderId: number, readOnly?: boolean } = { orderId: null, readOnly: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('readOnly'))) {
        const paramValueString = params.get('readOnly');
        inParams.readOnly = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_inbound_orderlines_grid') {
      const title = 'Order lines';
      const component = FootPrintManager_warehouse_transfer_inbound_orderlines_gridComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_outbound_order_lines_grid') {
      const title = 'Order lines';
      const component = FootPrintManager_warehouse_transfer_outbound_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, warehouseTransferId: number, readOnly?: boolean } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null, warehouseId: null, warehouseTransferId: null, readOnly: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseTransferId'))) {
        const paramValueString = params.get('warehouseTransferId');
        inParams.warehouseTransferId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('readOnly'))) {
        const paramValueString = params.get('readOnly');
        inParams.readOnly = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_outbound_order_manual_allocations_grid') {
      const title = 'Manual allocations';
      const component = FootPrintManager_warehouse_transfer_outbound_order_manual_allocations_gridComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_outbound_order_shippingcontents_grid') {
      const title = 'Shipping contents';
      const component = FootPrintManager_warehouse_transfer_outbound_order_shippingcontents_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, orderStatusId: number } = { orderId: null, shipmentId: null, orderStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfer_picking_grid') {
      const title = 'Picking tasks';
      const component = FootPrintManager_warehouse_transfer_picking_gridComponent;
      const inParams:{ orderId?: number, shipmentId?: number, waveId?: number, orderStatusId?: number, waveStatusId?: number } = { orderId: null, shipmentId: null, waveId: null, orderStatusId: null, waveStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('waveStatusId'))) {
        const paramValueString = params.get('waveStatusId');
        inParams.waveStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfers_grid') {
      const title = 'Warehouse transfers';
      const component = FootPrintManager_warehouse_transfers_gridComponent;
      const inParams:{ warehouseIds?: number[], statusIds?: number[] } = { warehouseIds: [], statusIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouses_navigation_grid') {
      const title = 'Warehouses';
      const component = FootPrintManager_warehouses_navigation_gridComponent;
      const inParams:{ warehouseId?: number, timezone?: string[] } = { warehouseId: null, timezone: [] };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('timezone'))) {
        const paramValueString = params.get('timezone');
        inParams.timezone = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_wave_creation_grid') {
      const title = 'Create wave';
      const component = FootPrintManager_wave_creation_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], waveId?: number, showCreate?: boolean, carrierId?: number, carrierServiceId?: number } = { ownerId: null, projectId: null, warehouseIds: [], waveId: null, showCreate: null, carrierId: null, carrierServiceId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('showCreate'))) {
        const paramValueString = params.get('showCreate');
        inParams.showCreate = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierServiceId'))) {
        const paramValueString = params.get('carrierServiceId');
        inParams.carrierServiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_work_class_operation_codes_grid') {
      const title = 'Operation codes';
      const component = FootPrintManager_work_class_operation_codes_gridComponent;
      const inParams:{ workClassId: number, operationCodes?: any } = { workClassId: null, operationCodes: null };
      if (!isNil(params.get('workClassId'))) {
        const paramValueString = params.get('workClassId');
        inParams.workClassId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('operationCodes'))) {
        const paramValueString = params.get('operationCodes');
        inParams.operationCodes = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_work_class_users_grid') {
      const title = 'Work class users';
      const component = FootPrintManager_work_class_users_gridComponent;
      const inParams:{ users?: any[] } = { users: [] };
      if (!isNil(params.get('users'))) {
        const paramValueString = params.get('users');
        inParams.users = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_work_classes_grid') {
      const title = 'Work classes';
      const component = FootPrintManager_work_classes_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_work_orders_grid') {
      const title = 'Work orders';
      const component = FootPrintManager_work_orders_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, statusIds?: number[] } = { ownerId: null, projectId: null, warehouseId: null, statusIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_activity_hub') {
      const title = 'Activity Hub';
      const component = FootPrintManager_activity_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_audit_serial_numbers_hub') {
      const title = 'Audit serial numbers';
      const component = FootPrintManager_audit_serial_numbers_hubComponent;
      const inParams:{ licensePlateId?: number } = { licensePlateId: null };
      if (!isNil(params.get('licensePlateId'))) {
        const paramValueString = params.get('licensePlateId');
        inParams.licensePlateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_contracts_hub') {
      const title = 'Billing Contracts Hub';
      const component = FootPrintManager_billing_contracts_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_records_hub') {
      const title = 'Billing Records Hub';
      const component = FootPrintManager_billing_records_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_blind_return_shipping_container_hub') {
      const title = 'Blind return';
      const component = FootPrintManager_blind_return_shipping_container_hubComponent;
      const inParams:{ shippingContainerId?: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_configurations_inventory_hub') {
      const title = 'Inventory configurations';
      const component = FootPrintManager_configurations_inventory_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_configurations_storage_categories_hub') {
      const title = 'Storage Configurations';
      const component = FootPrintManager_configurations_storage_categories_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointments_hub') {
      const title = 'Dock Appointments Hub';
      const component = FootPrintManager_dock_appointments_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointments_hubClone') {
      const title = 'Dock Appointments Hub - Clone';
      const component = FootPrintManager_dock_appointments_hubCloneComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_doors_hub') {
      const title = 'Dock Doors Hub';
      const component = FootPrintManager_dock_doors_hubComponent;
      const inParams:{ warehouseIds?: number[] } = { warehouseIds: [] };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_driver_check_out_hub') {
      const title = 'Driver check out hub';
      const component = FootPrintManager_driver_check_out_hubComponent;
      const inParams:{ orderId?: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_entity_import_hub') {
      const title = 'Entity Import Hub';
      const component = FootPrintManager_entity_import_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inbound_orders_hub') {
      const title = 'Inbound hub';
      const component = FootPrintManager_inbound_orders_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inspections_hub') {
      const title = 'Inspections hub';
      const component = FootPrintManager_inspections_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_counts_hub') {
      const title = 'Inventory Counts';
      const component = FootPrintManager_inventory_counts_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_hub') {
      const title = 'Inventory Hub';
      const component = FootPrintManager_inventory_hubComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number } = { ownerId: null, projectId: null, warehouseId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_transfers_hub') {
      const title = 'Inventory transfers hub';
      const component = FootPrintManager_inventory_transfers_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_invoices_hub') {
      const title = 'Invoices Hub';
      const component = FootPrintManager_invoices_hubComponent;
      const inParams:{ ownerId?: number, projectId?: number } = { ownerId: null, projectId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_labor_management_hub') {
      const title = 'Labor management';
      const component = FootPrintManager_labor_management_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_load_containers_hub') {
      const title = 'Load Containers Hub';
      const component = FootPrintManager_load_containers_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_lots_hub') {
      const title = 'Lots Hub';
      const component = FootPrintManager_lots_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_manifesting_hub') {
      const title = 'Manifesting Hub';
      const component = FootPrintManager_manifesting_hubComponent;
      const inParams:{ shipmentIds: number[] } = { shipmentIds: [] };
      if (!isNil(params.get('shipmentIds'))) {
        const paramValueString = params.get('shipmentIds');
        inParams.shipmentIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_material_attachments_hub') {
      const title = 'Material attachments';
      const component = FootPrintManager_material_attachments_hubComponent;
      const inParams:{ materialId: number, packagingId?: number } = { materialId: null, packagingId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_materials_hub') {
      const title = 'Materials Hub';
      const component = FootPrintManager_materials_hubComponent;
      const inParams:{ ownerId?: number, projectId?: number } = { ownerId: null, projectId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_orders_attachments_hub') {
      const title = 'orders_attachments_hub';
      const component = FootPrintManager_orders_attachments_hubComponent;
      const inParams:{ orderId?: number, shipmentId?: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_orders_hub') {
      const title = 'Orders Hub';
      const component = FootPrintManager_orders_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_outbound_orders_hub') {
      const title = 'Outbound Orders Hub';
      const component = FootPrintManager_outbound_orders_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_owners_hub') {
      const title = 'Owners Hub';
      const component = FootPrintManager_owners_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pack_verification_hub') {
      const title = 'Pack verification hub';
      const component = FootPrintManager_pack_verification_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_projects_hub') {
      const title = 'Projects Hub';
      const component = FootPrintManager_projects_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_replenishment_hub') {
      const title = 'Replenishment Hub';
      const component = FootPrintManager_replenishment_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_returns_hub') {
      const title = 'Returns hub';
      const component = FootPrintManager_returns_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_serialnumbers_hub') {
      const title = 'Serialnumbers Hub';
      const component = FootPrintManager_serialnumbers_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_shipping_containers_hub') {
      const title = 'Shipping containers hub';
      const component = FootPrintManager_shipping_containers_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_single_licenseplate_hub') {
      const title = 'Single Licenseplate Hub';
      const component = FootPrintManager_single_licenseplate_hubComponent;
      const inParams:{ licenseplateId: number } = { licenseplateId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_single_location_hub') {
      const title = 'Single Location Hub';
      const component = FootPrintManager_single_location_hubComponent;
      const inParams:{ locationId: number } = { locationId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_single_lot_hub') {
      const title = 'Single Lot Hub';
      const component = FootPrintManager_single_lot_hubComponent;
      const inParams:{ lotId: number } = { lotId: null };
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_single_material_hub') {
      const title = 'Single Material Hub';
      const component = FootPrintManager_single_material_hubComponent;
      const inParams:{ materialId: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_single_owner_hub') {
      const title = 'Single Owner Hub';
      const component = FootPrintManager_single_owner_hubComponent;
      const inParams:{ ownerId: number } = { ownerId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_single_parent_licenseplate_hub') {
      const title = 'Single Parent Licenseplate Hub';
      const component = FootPrintManager_single_parent_licenseplate_hubComponent;
      const inParams:{ licenseplateId: number } = { licenseplateId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_single_project_hub') {
      const title = 'Single Project Hub';
      const component = FootPrintManager_single_project_hubComponent;
      const inParams:{ projectId: number, ownerId?: number } = { projectId: null, ownerId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_single_serialnumber_hub') {
      const title = 'Single Serialnumber Hub';
      const component = FootPrintManager_single_serialnumber_hubComponent;
      const inParams:{ serialnumberId: number } = { serialnumberId: null };
      if (!isNil(params.get('serialnumberId'))) {
        const paramValueString = params.get('serialnumberId');
        inParams.serialnumberId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_single_survey_hub') {
      const title = 'Submitted survey';
      const component = FootPrintManager_single_survey_hubComponent;
      const inParams:{ surveyId: number } = { surveyId: null };
      if (!isNil(params.get('surveyId'))) {
        const paramValueString = params.get('surveyId');
        inParams.surveyId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_single_warehouse_hub') {
      const title = 'Single Warehouse Hub';
      const component = FootPrintManager_single_warehouse_hubComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_single_wave_hub') {
      const title = 'Single wave';
      const component = FootPrintManager_single_wave_hubComponent;
      const inParams:{ waveId: number, waveDescription?: string, waveStatus?: string, ownerId?: number, projectId?: number, warehouseIds?: number[] } = { waveId: null, waveDescription: null, waveStatus: null, ownerId: null, projectId: null, warehouseIds: [] };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('waveDescription'))) {
        const paramValueString = params.get('waveDescription');
        // TODO: date
        inParams.waveDescription = paramValueString;
              }
      if (!isNil(params.get('waveStatus'))) {
        const paramValueString = params.get('waveStatus');
        // TODO: date
        inParams.waveStatus = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_support_tests_hub') {
      const title = 'Support Tests Hub';
      const component = FootPrintManager_support_tests_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_surveys_hub') {
      const title = 'Surveys hub';
      const component = FootPrintManager_surveys_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_transload_orders_hub') {
      const title = 'Transload Orders Hub';
      const component = FootPrintManager_transload_orders_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouse_transfers_hub') {
      const title = 'Warehouse Transfers';
      const component = FootPrintManager_warehouse_transfers_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_warehouses_hub') {
      const title = 'Warehouses Hub';
      const component = FootPrintManager_warehouses_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_waves_hub') {
      const title = 'Waves hub';
      const component = FootPrintManager_waves_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_work_classes_hub') {
      const title = 'Manage work classes';
      const component = FootPrintManager_work_classes_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_work_orders_hub') {
      const title = 'Work Orders Hub';
      const component = FootPrintManager_work_orders_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointments_calendar') {
      const title = 'Dock appointments';
      const component = FootPrintManager_dock_appointments_calendarComponent;
      const inParams:{ warehouseIds: number[], typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], dockDoorIds?: number[], viewDate?: string } = { warehouseIds: [], typeIds: [], ownerId: null, projectId: null, carrierId: null, statusIds: [], dockDoorIds: [], viewDate: null };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('dockDoorIds'))) {
        const paramValueString = params.get('dockDoorIds');
        inParams.dockDoorIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('viewDate'))) {
        const paramValueString = params.get('viewDate');
        // TODO: date
        inParams.viewDate = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointment_selection_wizard') {
      const title = 'Dock Appointment Selection';
      const component = FootPrintManager_dock_appointment_selection_wizardComponent;
      const inParams:{ warehouseId?: number, dockDoor?: number, carrierId?: number, orderId?: number, scheduledProjectId?: number, excludedDockAppointmentIds?: number[], typeIds?: number[] } = { warehouseId: null, dockDoor: null, carrierId: null, orderId: null, scheduledProjectId: null, excludedDockAppointmentIds: [], typeIds: [] };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('dockDoor'))) {
        const paramValueString = params.get('dockDoor');
        inParams.dockDoor = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('scheduledProjectId'))) {
        const paramValueString = params.get('scheduledProjectId');
        inParams.scheduledProjectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('excludedDockAppointmentIds'))) {
        const paramValueString = params.get('excludedDockAppointmentIds');
        inParams.excludedDockAppointmentIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_location_selection_wizard') {
      const title = 'Inventory by Location Selection Wizard';
      const component = FootPrintManager_inventory_by_location_selection_wizardComponent;
      const inParams:{ warehouseId: number, projectId: number, materialId?: number, packagingId?: number, lotId?: number, packagedAmountRequest?: number } = { warehouseId: null, projectId: null, materialId: null, packagingId: null, lotId: null, packagedAmountRequest: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmountRequest'))) {
        const paramValueString = params.get('packagedAmountRequest');
        inParams.packagedAmountRequest = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_lot_selection_wizard') {
      const title = 'Inventory By Lot Selection Wizard';
      const component = FootPrintManager_inventory_by_lot_selection_wizardComponent;
      const inParams:{ warehouseId: number, projectId: number } = { warehouseId: null, projectId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_lot_single_selection_wizard') {
      const title = 'Inventory By Lot Single Selection Wizard';
      const component = FootPrintManager_inventory_by_lot_single_selection_wizardComponent;
      const inParams:{ warehouseId: number, projectId: number, materialId: number, packagingId: number, packagedAmountRequest: number } = { warehouseId: null, projectId: null, materialId: null, packagingId: null, packagedAmountRequest: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmountRequest'))) {
        const paramValueString = params.get('packagedAmountRequest');
        inParams.packagedAmountRequest = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_lp_selection_wizard') {
      const title = 'Inventory by LP Selection Wizard';
      const component = FootPrintManager_inventory_by_lp_selection_wizardComponent;
      const inParams:{ warehouseId: number, projectId: number, materialId?: number, packagingId?: number, lotId?: number, packagedAmountRequest?: number } = { warehouseId: null, projectId: null, materialId: null, packagingId: null, lotId: null, packagedAmountRequest: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmountRequest'))) {
        const paramValueString = params.get('packagedAmountRequest');
        inParams.packagedAmountRequest = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_lp_single_selection_wizard') {
      const title = 'Inventory by LP Single Selection Wizard';
      const component = FootPrintManager_inventory_by_lp_single_selection_wizardComponent;
      const inParams:{ warehouseId: number, projectId: number, materialId: number, packagingId: number, lotId?: number, packagedAmountRequest: number } = { warehouseId: null, projectId: null, materialId: null, packagingId: null, lotId: null, packagedAmountRequest: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmountRequest'))) {
        const paramValueString = params.get('packagedAmountRequest');
        inParams.packagedAmountRequest = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_serialnumber_selection_wizard') {
      const title = 'Inventory By Serialnumber Selection Wizard';
      const component = FootPrintManager_inventory_by_serialnumber_selection_wizardComponent;
      const inParams:{ warehouseId: number, projectId: number } = { warehouseId: null, projectId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_by_serialnumber_single_selection_wizard') {
      const title = 'Inventory By SN Single Selection Wizard';
      const component = FootPrintManager_inventory_by_serialnumber_single_selection_wizardComponent;
      const inParams:{ warehouseId: number, projectId: number, materialId: number, packagingId: number, lotId?: number } = { warehouseId: null, projectId: null, materialId: null, packagingId: null, lotId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pack_verification_wizard') {
      const title = 'Pack verification';
      const component = FootPrintManager_pack_verification_wizardComponent;
      const inParams:{ shippingContainerId: number, startVerificationTaskId?: number, targetLocationId?: number, isBlind?: boolean } = { shippingContainerId: null, startVerificationTaskId: null, targetLocationId: null, isBlind: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('startVerificationTaskId'))) {
        const paramValueString = params.get('startVerificationTaskId');
        inParams.startVerificationTaskId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('targetLocationId'))) {
        const paramValueString = params.get('targetLocationId');
        inParams.targetLocationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('isBlind'))) {
        const paramValueString = params.get('isBlind');
        inParams.isBlind = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_purchase_order_bulk_receiving_by_taskId_wizard') {
      const title = 'Bulk Receiving';
      const component = FootPrintManager_purchase_order_bulk_receiving_by_taskId_wizardComponent;
      const inParams:{ taskId: number, materialId: number, packagingId: number } = { taskId: null, materialId: null, packagingId: null };
      if (!isNil(params.get('taskId'))) {
        const paramValueString = params.get('taskId');
        inParams.taskId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_record_activity_list') {
      const title = 'Billing record activity';
      const component = FootPrintManager_billing_record_activity_listComponent;
      const inParams:{ billingTaskId: number } = { billingTaskId: null };
      if (!isNil(params.get('billingTaskId'))) {
        const paramValueString = params.get('billingTaskId');
        inParams.billingTaskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_count_tasks_list') {
      const title = 'Count tasks';
      const component = FootPrintManager_count_tasks_listComponent;
      const inParams:{ countTaskId: number } = { countTaskId: null };
      if (!isNil(params.get('countTaskId'))) {
        const paramValueString = params.get('countTaskId');
        inParams.countTaskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointment_activity_list') {
      const title = 'Dock appointment activity list';
      const component = FootPrintManager_dock_appointment_activity_listComponent;
      const inParams:{ dockAppointmentId: number } = { dockAppointmentId: null };
      if (!isNil(params.get('dockAppointmentId'))) {
        const paramValueString = params.get('dockAppointmentId');
        inParams.dockAppointmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_doors_list') {
      const title = 'Dock doors';
      const component = FootPrintManager_dock_doors_listComponent;
      const inParams:{ warehouseIds?: number[], appointmentType?: string, active?: boolean } = { warehouseIds: [], appointmentType: null, active: null };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('appointmentType'))) {
        const paramValueString = params.get('appointmentType');
        // TODO: date
        inParams.appointmentType = paramValueString;
              }
      if (!isNil(params.get('active'))) {
        const paramValueString = params.get('active');
        inParams.active = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_licenseplate_list') {
      const title = 'License plates';
      const component = FootPrintManager_licenseplate_listComponent;
      const inParams:{ locationId?: number, materialId?: number, projectId?: number, lotId?: number } = { locationId: null, materialId: null, projectId: null, lotId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_licenseplatecontent_list') {
      const title = 'License plate content';
      const component = FootPrintManager_licenseplatecontent_listComponent;
      const inParams:{ licenseplateId: number } = { licenseplateId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_licenseplates_by_shipping_container_list') {
      const title = 'License plates by shipping container';
      const component = FootPrintManager_licenseplates_by_shipping_container_listComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_shipping_containers_list') {
      const title = 'Shipping containers';
      const component = FootPrintManager_shipping_containers_listComponent;
      const inParams:{ shipmentId: number } = { shipmentId: null };
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_view_xml_payload') {
      const title = 'view_xml_payload';
      const component = FootPrintManager_view_xml_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_commercial_invoice_content') {
      const title = 'Commercial invoice';
      const component = FootPrintManager_commercial_invoice_contentComponent;
      const inParams:{ url: string } = { url: null };
      if (!isNil(params.get('url'))) {
        const paramValueString = params.get('url');
        // TODO: date
        inParams.url = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_shipping_label_content') {
      const title = 'Shipping label';
      const component = FootPrintManager_shipping_label_contentComponent;
      const inParams:{ url: string } = { url: null };
      if (!isNil(params.get('url'))) {
        const paramValueString = params.get('url');
        // TODO: date
        inParams.url = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_archived_options_dd_single') {
      const title = 'archived_options_dd';
      const component = FootPrintManager_archived_options_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_daysOfWeek_zt_single') {
      const title = 'daysOfWeek_zt';
      const component = FootPrintManager_daysOfWeek_zt_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_hourly_options_dd_single') {
      const title = 'hourly_options_dd';
      const component = FootPrintManager_hourly_options_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_count_statuses_dd_alt_single') {
      const title = 'inventory_count_statuses_dd_alt';
      const component = FootPrintManager_inventory_count_statuses_dd_alt_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_kit_view_sales_order_lines_single') {
      const title = 'Kit view options';
      const component = FootPrintManager_kit_view_sales_order_lines_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_lots_dd_single') {
      const title = 'Lots dropdown';
      const component = FootPrintManager_lots_dd_singleComponent;
      const inParams:{ materialId: number, vendorLotId?: number, lotLookupCode?: string, vendorLotLookupCode?: string } = { materialId: null, vendorLotId: null, lotLookupCode: null, vendorLotLookupCode: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotId'))) {
        const paramValueString = params.get('vendorLotId');
        inParams.vendorLotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotLookupCode'))) {
        const paramValueString = params.get('lotLookupCode');
        // TODO: date
        inParams.lotLookupCode = paramValueString;
              }
      if (!isNil(params.get('vendorLotLookupCode'))) {
        const paramValueString = params.get('vendorLotLookupCode');
        // TODO: date
        inParams.vendorLotLookupCode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_monthsOfYear_zt_single') {
      const title = 'monthsOfYear_zt';
      const component = FootPrintManager_monthsOfYear_zt_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_orderclasses_inbound_dd_single') {
      const title = 'orderclasses_inbound_dd';
      const component = FootPrintManager_orderclasses_inbound_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_orders_by_project_dd_single') {
      const title = 'orders_by_project_dd';
      const component = FootPrintManager_orders_by_project_dd_singleComponent;
      const inParams:{ project_id: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pallet_classes_dd_single') {
      const title = 'pallet_classes_dd';
      const component = FootPrintManager_pallet_classes_dd_singleComponent;
      const inParams:{ project_id?: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_print_order_labels_options_dd_single') {
      const title = 'print_order_labels_options_dd';
      const component = FootPrintManager_print_order_labels_options_dd_singleComponent;
      const inParams:{ include?: string[], exclude?: string[] } = { include: [], exclude: [] };
      if (!isNil(params.get('include'))) {
        const paramValueString = params.get('include');
        inParams.include = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('exclude'))) {
        const paramValueString = params.get('exclude');
        inParams.exclude = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_reason_code_parent_entities_dd_single') {
      const title = 'reason_code_parent_entities_dd';
      const component = FootPrintManager_reason_code_parent_entities_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_reason_code_parent_entity_ids_dd_single') {
      const title = 'reason_code_parent_entity_ids_dd';
      const component = FootPrintManager_reason_code_parent_entity_ids_dd_singleComponent;
      const inParams:{ id?: number, parent?: string } = { id: null, parent: null };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parent'))) {
        const paramValueString = params.get('parent');
        // TODO: date
        inParams.parent = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_reason_codes_dd_single') {
      const title = 'reason_codes_dd';
      const component = FootPrintManager_reason_codes_dd_singleComponent;
      const inParams:{ parentEntity?: string, parentId?: number } = { parentEntity: null, parentId: null };
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_support_tests_dd_single') {
      const title = 'support_tests_dd';
      const component = FootPrintManager_support_tests_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_vendorlots_dd_single') {
      const title = 'Vendor Lots dropdown';
      const component = FootPrintManager_vendorlots_dd_singleComponent;
      const inParams:{ materialId: number, lotId?: number, vendorLotLookupCode?: string } = { materialId: null, lotId: null, vendorLotLookupCode: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotLookupCode'))) {
        const paramValueString = params.get('vendorLotLookupCode');
        // TODO: date
        inParams.vendorLotLookupCode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_weight_field_dd_single') {
      const title = 'weight_field_dd';
      const component = FootPrintManager_weight_field_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_archived_options_dd_multi') {
      const title = 'archived_options_dd';
      const component = FootPrintManager_archived_options_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_daysOfWeek_zt_multi') {
      const title = 'daysOfWeek_zt';
      const component = FootPrintManager_daysOfWeek_zt_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_hourly_options_dd_multi') {
      const title = 'hourly_options_dd';
      const component = FootPrintManager_hourly_options_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_inventory_count_statuses_dd_alt_multi') {
      const title = 'inventory_count_statuses_dd_alt';
      const component = FootPrintManager_inventory_count_statuses_dd_alt_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_kit_view_sales_order_lines_multi') {
      const title = 'Kit view options';
      const component = FootPrintManager_kit_view_sales_order_lines_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_lots_dd_multi') {
      const title = 'Lots dropdown';
      const component = FootPrintManager_lots_dd_multiComponent;
      const inParams:{ materialId: number, vendorLotId?: number, lotLookupCode?: string, vendorLotLookupCode?: string } = { materialId: null, vendorLotId: null, lotLookupCode: null, vendorLotLookupCode: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotId'))) {
        const paramValueString = params.get('vendorLotId');
        inParams.vendorLotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotLookupCode'))) {
        const paramValueString = params.get('lotLookupCode');
        // TODO: date
        inParams.lotLookupCode = paramValueString;
              }
      if (!isNil(params.get('vendorLotLookupCode'))) {
        const paramValueString = params.get('vendorLotLookupCode');
        // TODO: date
        inParams.vendorLotLookupCode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_monthsOfYear_zt_multi') {
      const title = 'monthsOfYear_zt';
      const component = FootPrintManager_monthsOfYear_zt_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_orderclasses_inbound_dd_multi') {
      const title = 'orderclasses_inbound_dd';
      const component = FootPrintManager_orderclasses_inbound_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_orders_by_project_dd_multi') {
      const title = 'orders_by_project_dd';
      const component = FootPrintManager_orders_by_project_dd_multiComponent;
      const inParams:{ project_id: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_pallet_classes_dd_multi') {
      const title = 'pallet_classes_dd';
      const component = FootPrintManager_pallet_classes_dd_multiComponent;
      const inParams:{ project_id?: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_print_order_labels_options_dd_multi') {
      const title = 'print_order_labels_options_dd';
      const component = FootPrintManager_print_order_labels_options_dd_multiComponent;
      const inParams:{ include?: string[], exclude?: string[] } = { include: [], exclude: [] };
      if (!isNil(params.get('include'))) {
        const paramValueString = params.get('include');
        inParams.include = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('exclude'))) {
        const paramValueString = params.get('exclude');
        inParams.exclude = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_reason_code_parent_entities_dd_multi') {
      const title = 'reason_code_parent_entities_dd';
      const component = FootPrintManager_reason_code_parent_entities_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_reason_code_parent_entity_ids_dd_multi') {
      const title = 'reason_code_parent_entity_ids_dd';
      const component = FootPrintManager_reason_code_parent_entity_ids_dd_multiComponent;
      const inParams:{ id?: number, parent?: string } = { id: null, parent: null };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parent'))) {
        const paramValueString = params.get('parent');
        // TODO: date
        inParams.parent = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_reason_codes_dd_multi') {
      const title = 'reason_codes_dd';
      const component = FootPrintManager_reason_codes_dd_multiComponent;
      const inParams:{ parentEntity?: string, parentId?: number } = { parentEntity: null, parentId: null };
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_support_tests_dd_multi') {
      const title = 'support_tests_dd';
      const component = FootPrintManager_support_tests_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_vendorlots_dd_multi') {
      const title = 'Vendor Lots dropdown';
      const component = FootPrintManager_vendorlots_dd_multiComponent;
      const inParams:{ materialId: number, lotId?: number, vendorLotLookupCode?: string } = { materialId: null, lotId: null, vendorLotLookupCode: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('vendorLotLookupCode'))) {
        const paramValueString = params.get('vendorLotLookupCode');
        // TODO: date
        inParams.vendorLotLookupCode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_weight_field_dd_multi') {
      const title = 'weight_field_dd';
      const component = FootPrintManager_weight_field_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_billing_record_activity_card') {
      const title = 'Billing Record Activity Card';
      const component = FootPrintManager_billing_record_activity_cardComponent;
      const inParams:{ billingTaskId: number, activityDetails?: string, activityDate?: string, activityUser?: string, style?: string, title?: string } = { billingTaskId: null, activityDetails: null, activityDate: null, activityUser: null, style: null, title: null };
      if (!isNil(params.get('billingTaskId'))) {
        const paramValueString = params.get('billingTaskId');
        inParams.billingTaskId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('activityDetails'))) {
        const paramValueString = params.get('activityDetails');
        // TODO: date
        inParams.activityDetails = paramValueString;
              }
      if (!isNil(params.get('activityDate'))) {
        const paramValueString = params.get('activityDate');
        // TODO: date
        inParams.activityDate = paramValueString;
              }
      if (!isNil(params.get('activityUser'))) {
        const paramValueString = params.get('activityUser');
        // TODO: date
        inParams.activityUser = paramValueString;
              }
      if (!isNil(params.get('style'))) {
        const paramValueString = params.get('style');
        // TODO: date
        inParams.style = paramValueString;
              }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_count_task_card') {
      const title = 'count_task_card';
      const component = FootPrintManager_count_task_cardComponent;
      const inParams:{ taskId: number } = { taskId: null };
      if (!isNil(params.get('taskId'))) {
        const paramValueString = params.get('taskId');
        inParams.taskId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appoinment_activity_card') {
      const title = 'Dock Appointment Activity Card';
      const component = FootPrintManager_dock_appoinment_activity_cardComponent;
      const inParams:{ dockAppointmentId: number, activityDetails?: string, activityDate?: string, activityUser?: string, style?: string, title?: string } = { dockAppointmentId: null, activityDetails: null, activityDate: null, activityUser: null, style: null, title: null };
      if (!isNil(params.get('dockAppointmentId'))) {
        const paramValueString = params.get('dockAppointmentId');
        inParams.dockAppointmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('activityDetails'))) {
        const paramValueString = params.get('activityDetails');
        // TODO: date
        inParams.activityDetails = paramValueString;
              }
      if (!isNil(params.get('activityDate'))) {
        const paramValueString = params.get('activityDate');
        // TODO: date
        inParams.activityDate = paramValueString;
              }
      if (!isNil(params.get('activityUser'))) {
        const paramValueString = params.get('activityUser');
        // TODO: date
        inParams.activityUser = paramValueString;
              }
      if (!isNil(params.get('style'))) {
        const paramValueString = params.get('style');
        // TODO: date
        inParams.style = paramValueString;
              }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_appointment_card') {
      const title = 'Dock Appointment Card';
      const component = FootPrintManager_dock_appointment_cardComponent;
      const inParams:{ dockAppointment: { lookupCode?: string, carrier?: string, referenceNumber?: string, status?: string, id?: number, typeId?: number, warehouseId?: number, orderId?: number } } = { dockAppointment: { lookupCode: null, carrier: null, referenceNumber: null, status: null, id: null, typeId: null, warehouseId: null, orderId: null } };
      if (!isNil(params.get('dockAppointment'))) {
        const paramValueString = params.get('dockAppointment');
        inParams.dockAppointment = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_dock_door_card') {
      const title = 'Dock Door Card';
      const component = FootPrintManager_dock_door_cardComponent;
      const inParams:{ dockDoorId: number } = { dockDoorId: null };
      if (!isNil(params.get('dockDoorId'))) {
        const paramValueString = params.get('dockDoorId');
        inParams.dockDoorId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_licenseplate_card') {
      const title = 'Licenseplate Card';
      const component = FootPrintManager_licenseplate_cardComponent;
      const inParams:{ licenseplate: { totalPackagedAmount?: number, lookupCode?: string, totalGrossWeight?: number, locationName?: string, licenseplateId?: number } } = { licenseplate: { totalPackagedAmount: null, lookupCode: null, totalGrossWeight: null, locationName: null, licenseplateId: null } };
      if (!isNil(params.get('licenseplate'))) {
        const paramValueString = params.get('licenseplate');
        inParams.licenseplate = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_licenseplate_content_card') {
      const title = 'License plate content';
      const component = FootPrintManager_licenseplate_content_cardComponent;
      const inParams:{ licenseplateId: number, lotId: number, packagingId: number } = { licenseplateId: null, lotId: null, packagingId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_licenseplate_with_content_card') {
      const title = 'Licenseplate with content card';
      const component = FootPrintManager_licenseplate_with_content_cardComponent;
      const inParams:{ licenseplateId: number } = { licenseplateId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootPrintManager_shipping_container_card') {
      const title = 'Shipping container';
      const component = FootPrintManager_shipping_container_cardComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Discussions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.EasyPost.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.BarcodeParsing.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.BarTender.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Addresses.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.AsnOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Attachments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Carriers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.EntityImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Cartonization.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelInventoryImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Instructions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Inspections.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelProjectImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelOrderImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.InventoryTransfers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Locations.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Lots.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.LoadContainers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.LaborManagement.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Manifesting.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Owners.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Returns.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ShippingContainers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.TemperatureReadings.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Surveys.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.WarehouseTransfers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Waves.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Equipment.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelMaterialImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Replenishments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PalletTransactions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SerialNumbers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelLocationImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Inventory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.InventoryCounts.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.TransloadOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.DockAppointments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.WorkOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PackVerification.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Invoices.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PrintNode.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Materials.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
