import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'

import { FootPrintManager_audit_serial_numbers_gridComponent } from './FootPrintManager.audit_serial_numbers_grid.component';
import { SerialNumbers_audit_entities_dd_singleComponent } from './SerialNumbers.audit_entities_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_audit_serial_numbers_gridComponent),
    forwardRef(() => SerialNumbers_audit_entities_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
  ],
  selector: 'FootPrintManager-audit_serial_numbers_hub',
  templateUrl: './FootPrintManager.audit_serial_numbers_hub.component.html'
})
export class FootPrintManager_audit_serial_numbers_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { licensePlateId?: number } = { licensePlateId: null };
  //#region Inputs
  @Input('licensePlateId') set $inParams_licensePlateId(v: number) {
    this.inParams.licensePlateId = v;
  }
  get $inParams_licensePlateId(): number {
    return this.inParams.licensePlateId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { scannedBarcodes?: { barcode: string, serialNumber: string, serialNumberId: number, isValid: boolean }[], watchList?: { barcode: string, serialNumber: string }[], startDateTime?: string, expectedSerialNumbers?: { id?: number, lookupCode?: string }[], isAbort?: boolean } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    audit_entity: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shipping_container: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    license_plate: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    input_scan: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notify_on_watch_list_match: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notify_duplicate_scan: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notify_invalid_scan: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  toolbar = {
      finish_audit: new ToolModel(new ButtonModel('finish_audit', new ButtonStyles(['primary'], null), false, 'Finish audit', 'icon-ic_fluent_clipboard_search_20_regular')
    )
  };

  actionbar = {
  };

 filters = {
    audit_entity: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['audit_entity'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Audit entity', true)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', false)
,
    location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['location'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Location', false)
,
    shipping_container: new FieldModel(new TextBoxModel(this.formGroup.controls['shipping_container'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Shipping container', false)
,
    license_plate: new FieldModel(new TextBoxModel(this.formGroup.controls['license_plate'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'License plate', false)
,
    input_scan: new FieldModel(new TextBoxModel(this.formGroup.controls['input_scan'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Scan serial barcode', false)
,
    notify_on_watch_list_match: new FieldModel(new CheckBoxModel(this.formGroup.controls['notify_on_watch_list_match'] as DatexFormControl, null, false, 'Notify on watch list match')
, new ControlContainerStyles(null, null), '', false)
,
    notify_duplicate_scan: new FieldModel(new CheckBoxModel(this.formGroup.controls['notify_duplicate_scan'] as DatexFormControl, null, false, 'Notify on duplicate scan')
, new ControlContainerStyles(null, null), '', false)
,
    notify_invalid_scan: new FieldModel(new CheckBoxModel(this.formGroup.controls['notify_invalid_scan'] as DatexFormControl, null, false, 'Notify on invalid scan')
, new ControlContainerStyles(null, null), '', false)
,
  };


  filtersets = {
  main: new FieldsetModel('', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      scanned_serials: new TabItemModel(
        this.rootTabGroup, 
        'Scanned serials', 
        ),
      watch_list: new TabItemModel(
        this.rootTabGroup, 
        'Watch list', 
        ),
    };
  
    //#region tabs inParams
    cacheValueFor_$tabs_scanned_serials_audit_serial_numbers_grid_inParams_scannedBarcodes: { barcode: string, serialNumber: string, isValid: boolean, serialNumberId: number }[];
    get $tabs_scanned_serials_audit_serial_numbers_grid_inParams_scannedBarcodes(): { barcode: string, serialNumber: string, isValid: boolean, serialNumberId: number }[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.scannedBarcodes;
      
      if(!isEqual(this.cacheValueFor_$tabs_scanned_serials_audit_serial_numbers_grid_inParams_scannedBarcodes, expr)) {
        this.cacheValueFor_$tabs_scanned_serials_audit_serial_numbers_grid_inParams_scannedBarcodes = expr;
      }
      return this.cacheValueFor_$tabs_scanned_serials_audit_serial_numbers_grid_inParams_scannedBarcodes;
    }
  
    cacheValueFor_$tabs_scanned_serials_audit_serial_numbers_grid_inParams_watchList: { barcode: string, serialNumber: string }[];
    get $tabs_scanned_serials_audit_serial_numbers_grid_inParams_watchList(): { barcode: string, serialNumber: string }[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.watchList;
      
      if(!isEqual(this.cacheValueFor_$tabs_scanned_serials_audit_serial_numbers_grid_inParams_watchList, expr)) {
        this.cacheValueFor_$tabs_scanned_serials_audit_serial_numbers_grid_inParams_watchList = expr;
      }
      return this.cacheValueFor_$tabs_scanned_serials_audit_serial_numbers_grid_inParams_watchList;
    }
  
    get $tabs_scanned_serials_audit_serial_numbers_grid_inParams_context(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Scan';
      
      return expr;
    }
  
    cacheValueFor_$tabs_watch_list_audit_serial_numbers_grid_inParams_scannedBarcodes: { barcode: string, serialNumber: string, isValid: boolean, serialNumberId: number }[];
    get $tabs_watch_list_audit_serial_numbers_grid_inParams_scannedBarcodes(): { barcode: string, serialNumber: string, isValid: boolean, serialNumberId: number }[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.scannedBarcodes;
      
      if(!isEqual(this.cacheValueFor_$tabs_watch_list_audit_serial_numbers_grid_inParams_scannedBarcodes, expr)) {
        this.cacheValueFor_$tabs_watch_list_audit_serial_numbers_grid_inParams_scannedBarcodes = expr;
      }
      return this.cacheValueFor_$tabs_watch_list_audit_serial_numbers_grid_inParams_scannedBarcodes;
    }
  
    cacheValueFor_$tabs_watch_list_audit_serial_numbers_grid_inParams_watchList: { barcode: string, serialNumber: string }[];
    get $tabs_watch_list_audit_serial_numbers_grid_inParams_watchList(): { barcode: string, serialNumber: string }[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.watchList;
      
      if(!isEqual(this.cacheValueFor_$tabs_watch_list_audit_serial_numbers_grid_inParams_watchList, expr)) {
        this.cacheValueFor_$tabs_watch_list_audit_serial_numbers_grid_inParams_watchList = expr;
      }
      return this.cacheValueFor_$tabs_watch_list_audit_serial_numbers_grid_inParams_watchList;
    }
  
    get $tabs_watch_list_audit_serial_numbers_grid_inParams_context(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'WatchList';
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_scanned_serials', { read: FootPrintManager_audit_serial_numbers_gridComponent }) $tabs_scanned_serials: FootPrintManager_audit_serial_numbers_gridComponent;
      @ViewChild('$tabs_watch_list', { read: FootPrintManager_audit_serial_numbers_gridComponent }) $tabs_watch_list: FootPrintManager_audit_serial_numbers_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_location_selector_inParams_warehouseId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.warehouse.control.value;
    
    return expr;
  }



  get $fields_location_selector_inParams_typeId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 3;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Audit serial numbers`;
  }


  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.scanned_serials,
      this.tabs.watch_list,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Audit serial numbers';
    
    const $hub = this;
    const $utils = this.utils;

    
    
    
    (this.filters.notify_on_watch_list_match.control as CheckBoxModel).reset(true);
    (this.filters.notify_duplicate_scan.control as CheckBoxModel).reset(false);
    (this.filters.notify_invalid_scan.control as CheckBoxModel).reset(true);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['audit_entity']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_audit_entity_value_change();
      });
    this.formGroup
      .controls['license_plate']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_license_plate_change();
      });
    this.formGroup
      .controls['input_scan']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_input_scan_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_scanned_serials') {
      if (!isNil(this.$tabs_scanned_serials) && !this.tabs.scanned_serials.hidden) {
        this.$tabs_scanned_serials.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_watch_list') {
      if (!isNil(this.$tabs_watch_list) && !this.tabs.watch_list.hidden) {
        this.$tabs_watch_list.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_input_scan_change(event = null) {
    return this.on_input_scan_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_input_scan_changeInternal(
    $hub: FootPrintManager_audit_serial_numbers_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let isValid = true;
  
  try {
      if ($utils.isDefinedTrimmed($hub.filters.input_scan.control.value)) {
          let serialNumber = await getSerial($hub.filters.input_scan.control.value);
  
          if ($hub.tabs.scanned_serials.active) { // Scanned serials
              if ($utils.isDefined(serialNumber.GTIN)) {
                  await validateSerialByGtin(serialNumber);
              }
              else {
                  await validateSerialByCode(serialNumber.LookupCode);
              }
          }
          else { // Watch list
              if (!$hub.vars.watchList.find(wl => wl.serialNumber.trim().toUpperCase() === serialNumber.LookupCode.trim().toUpperCase())) {
                  $hub.vars.watchList.unshift({
                      barcode: $hub.filters.input_scan.control.value.trim(),
                      serialNumber: serialNumber.LookupCode
                  });
              }
          }
  
          await $hub.refresh();
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      if ($utils.isDefinedTrimmed(error.message)) {
          await $shell.SerialNumbers.openErrorDialog('Error scanning barcode', error.message);
      }
  }
  
  $hub.filters.input_scan.control.value = null;
  $hub.filters.input_scan.control.focus();
  
  await $hub.set_hub_state();
  
  
  /*****************************************
   * FUNCTIONS
  ******************************************/
  async function invalidSerialCheck(message: string) {
      isValid = false;
  
      if (!$hub.filters.notify_invalid_scan.control.value) { return; }
      
      let isContinue = await $shell.SerialNumbers.openConfirmationDialog('Invalid serial number', message, 'Continue audit', 'End audit') ?? true;
  
      if (!isContinue) {
          $hub.vars.isAbort = true;
          // Get reason code
          await $hub.on_finish_audit_clicked();
      }
  }
  
  
  async function getSerial(inputScan: string) {
      // Parse barcode
      let parseResult = (await $flows.BarcodeParsing.parse_gs1_barcode_flow({ inputScan: inputScan }));
  
      if ($utils.isDefined(parseResult.reasons)) {
          return { LookupCode: inputScan };
      }
  
      // Get serialNumber
      let serialResult = (await $flows.BarcodeParsing.get_serial_number_code_by_parsedValues({ parsedValues: parseResult.extractedValues }));
  
      if ($utils.isDefined(serialResult.reasons)) {
          throw new Error(`Error getting serial number from parsed values: ${(await $flows.Utilities.grammar_format_string_array_flow({ values: serialResult.reasons })).formattedValue}`);
      }
  
      return {
          LookupCode: serialResult.serialNumberCode,
          GTIN: parseResult.extractedValues.gtin,
          LotCode: parseResult.extractedValues.lotNumber
      };
  }
  
  
  async function validateSerialByGtin(serialNumber: { LookupCode: string, GTIN?: string, LotCode?: string }) {
      // Get matching materials
      let materials = (await $datasources.Materials.ds_get_materials_by_gtin.get({ gtin: serialNumber.GTIN })).result;
  
      // Find serial number
      let matchingSerials = (await $datasources.SerialNumbers.ds_get_serial_numbers_by_materialIds.get({
          materialIds: materials.map(m => m.Id),
          vendorLotCode: serialNumber.LotCode,
          serialNumberCode: serialNumber.LookupCode
      })).result;
  
      // Check if duplicate
      if ($utils.isDefined($hub.vars.scannedBarcodes.find(l => l.serialNumber.trim().toUpperCase() === serialNumber.LookupCode.trim().toUpperCase()))) {
          if ($hub.filters.notify_duplicate_scan.control.value) {
              throw new Error(`Serial number '${serialNumber.LookupCode}' has already been scanned.`);
          }
          else {
              // Breakout without throwing message
              throw new Error();
          }
      }
  
      let isValid = $hub.vars.expectedSerialNumbers.findIndex(sn => sn.lookupCode.trim().toUpperCase() === serialNumber.LookupCode.trim().toUpperCase()) >= 0;
  
      // Check watch list
      if ($hub.vars.watchList.find(wl => wl.serialNumber.trim().toUpperCase() === serialNumber.LookupCode.trim().toUpperCase()) && $hub.filters.notify_on_watch_list_match.control.value) {
          await $shell.SerialNumbers.openInfoDialog(`Found serial on watch list`, `Serial number '${serialNumber.LookupCode}' from watch list has been found.`);
      }
  
      // Add to list if not already exists
      $hub.vars.scannedBarcodes.unshift({
          barcode: $hub.filters.input_scan.control.value.trim(),
          serialNumber: serialNumber.LookupCode,
          serialNumberId: matchingSerials[0]?.Id,
          isValid: $hub.vars.expectedSerialNumbers.findIndex(sn => sn.lookupCode.trim().toUpperCase() === serialNumber.LookupCode.trim().toUpperCase()) >= 0
      });
  
      if (matchingSerials.length === 0) { invalidSerialCheck(`No record found for serial number '${serialNumber.LookupCode}'.`); }
      else if (matchingSerials[0].LicensePlate.Id != $hub.inParams.licensePlateId) {
          if (matchingSerials[0].Archived) {
              invalidSerialCheck(`Serial number '${serialNumber.LookupCode}' ${$utils.isDefined(matchingSerials[0].LicensePlate.Shipment) ? `shipped out on order '${matchingSerials[0].LicensePlate.Shipment.OrderLookups[0].Order.LookupCode}'` : 'is archived'}.`)
          }
          else {
              invalidSerialCheck(`Serial number '${serialNumber.LookupCode}' is on license plate '${matchingSerials[0].LicensePlate.LookupCode}', in location '${matchingSerials[0].LicensePlate.Location.Name}'.`);
          }
      }
  
      if (!isValid && $hub.filters.notify_invalid_scan.control.value) {
          let serialNumber = (await $datasources.SerialNumbers.ds_get_serialnumber_by_serialnumberId.get({ serialnumberId: matchingSerials[0].Id })).result;
          await $shell.SerialNumbers.openInfoDialog(`Invalid serial number`, `Serial number '${serialNumber.LookupCode}' is on another license plate: '${serialNumber.LicensePlate.LookupCode}' in location '${serialNumber.LicensePlate.Location.Name}'.`);
      }
  }
  
  
  async function validateSerialByCode(serialCode: string) {
      let matchingSerials = (await $datasources.SerialNumbers.ds_get_serialnumber_by_code.get({ code: serialCode })).result;
  
      // Check if duplicate
      if ($utils.isDefined($hub.vars.scannedBarcodes.find(l => l.serialNumber.trim().toUpperCase() === serialCode.trim().toUpperCase()))) {
          if ($hub.filters.notify_duplicate_scan.control.value) {
              throw new Error(`Serial number '${serialCode}' has already been scanned.`);
          }
          else {
              // Breakout without throwing message
              throw new Error();
          }
      }
  
      if ($hub.vars.watchList.find(wl => wl.serialNumber.trim().toUpperCase() === serialCode.trim().toUpperCase()) && $hub.filters.notify_on_watch_list_match.control.value) {
          await $shell.SerialNumbers.openInfoDialog(`Found serial on watch list`, `Serial number '${serialCode}' from watch list has been found.`);
      }
  
      let isValid = $hub.vars.expectedSerialNumbers.findIndex(sn => sn.lookupCode.trim().toUpperCase() === serialCode.trim().toUpperCase()) >= 0;
  
      $hub.vars.scannedBarcodes.unshift({
          barcode: serialCode,
          serialNumber: serialCode,
          serialNumberId: matchingSerials[0]?.Id,
          isValid: isValid
      });
  
      if (matchingSerials.length === 0) {
          invalidSerialCheck(`Serial number '${serialCode}' does not exist.`);
      }
      else if (matchingSerials.length > 1) {
          invalidSerialCheck(`Found multiple matches for serial number '${serialCode}' in the system. Please scan full barcode, if available.`);
      }
      else if (!isValid) {
          let serialNumber = (await $datasources.SerialNumbers.ds_get_serialnumber_by_serialnumberId.get({ serialnumberId: matchingSerials[0].Id })).result;
          
          if (serialNumber.Archived) {
              invalidSerialCheck(`Serial number '${serialNumber.LookupCode}' ${$utils.isDefined(serialNumber.LicensePlate.Shipment) ? `shipped out on order '${serialNumber.LicensePlate.Shipment.OrderLookups[0].Order.LookupCode}'` : 'is archived'}.`)
          }
          else {
              invalidSerialCheck(`Serial number '${serialNumber.LookupCode}' is on license plate '${serialNumber.LicensePlate.LookupCode}', in location '${serialNumber.LicensePlate.Location.Name}'.`);
          }
      }
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_audit_serial_numbers_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.vars.scannedBarcodes = [];
  $hub.vars.watchList = [];
  
  $hub.vars.isAbort = false;
  $hub.vars.startDateTime = $utils.date.now();
  
  $hub.toolbar.finish_audit.control.readOnly = true;
  
  if ($utils.isDefined($hub.inParams.licensePlateId)) {
      $hub.filters.warehouse.hidden = true;
      $hub.filters.location.hidden = true;
      $hub.filters.shipping_container.hidden = true;
      $hub.filters.license_plate.hidden = false;
  
      let licensePlate = (await $datasources.Inventory.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: $hub.inParams.licensePlateId })).result;
  
      $hub.filters.audit_entity.control.value = 'LicensePlate';
      
      $hub.filters.license_plate.control.value = licensePlate.LookupCode;
  
      $hub.filters.audit_entity.control.readOnly = true;
      $hub.filters.license_plate.control.readOnly = true;
  
      $hub.on_license_plate_change();
  
  } else {
      $hub.filters.warehouse.hidden = true;
      $hub.filters.location.hidden = true;
      $hub.filters.shipping_container.hidden = true;
      $hub.filters.license_plate.hidden = true;
  
      $hub.filters.input_scan.control.readOnly = true;
  }
  
  }
  remove_serials_from_scanned_barcodes(event = null) {
    return this.remove_serials_from_scanned_barcodesInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async remove_serials_from_scanned_barcodesInternal(
    $hub: FootPrintManager_audit_serial_numbers_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.vars.scannedBarcodes = $hub.vars.scannedBarcodes.filter(b => !$event.includes(b.serialNumber.trim().toUpperCase()));
  
  await new Promise(result => setTimeout(result, 100));
  
  await $hub.refresh();
  
  await $hub.set_hub_state();
  }
  remove_serials_from_watch_list(event = null) {
    return this.remove_serials_from_watch_listInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async remove_serials_from_watch_listInternal(
    $hub: FootPrintManager_audit_serial_numbers_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.vars.watchList = $hub.vars.watchList.filter(b => !$event.includes(b.serialNumber.trim().toUpperCase()));
  
  await $hub.refresh();
  
  await $hub.set_hub_state();
  }
  on_license_plate_change(event = null) {
    return this.on_license_plate_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_license_plate_changeInternal(
    $hub: FootPrintManager_audit_serial_numbers_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($hub.filters.license_plate.control.value)) {
      $hub.filters.input_scan.control.readOnly = false;
  
      // Get expected serial numbers
      $hub.vars.expectedSerialNumbers = (await $datasources.SerialNumbers.ds_find_serialnumbers.get({ licenseplateId: $hub.inParams.licensePlateId, archived: false })).result.map(sn => ({id: sn.Id, lookupCode: sn.LookupCode}));
  
      // Get license plate and set other filters
      let licenseplate = (await $datasources.SerialNumbers.ds_get_license_plate_by_licensePlateId.get({ licensePlateId: $hub.inParams.licensePlateId })).result;
  
      $hub.filters.warehouse.control.value = licenseplate.WarehouseId;
      $hub.filters.location.control.value = licenseplate.LocationId;
      $hub.filters.shipping_container.control.value = licenseplate.ShippingContainerId;
  }
  else {
      $hub.filters.input_scan.control.readOnly = true;
  }
  }
  on_audit_entity_value_change(event = null) {
    return this.on_audit_entity_value_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_audit_entity_value_changeInternal(
    $hub: FootPrintManager_audit_serial_numbers_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  switch ($hub.filters.audit_entity.control.value.trim().toUpperCase()) {
      case 'WAREHOUSE': {
          showFilter($hub.filters.warehouse);
          hideFilter($hub.filters.location);
          hideFilter($hub.filters.shipping_container);
          hideFilter($hub.filters.license_plate);
  
          break;
      }
      case 'LOCATION': {
          showFilter($hub.filters.warehouse);
          showFilter($hub.filters.location);
          hideFilter($hub.filters.shipping_container);
          hideFilter($hub.filters.license_plate);
  
          break;
      }
      case 'SHIPPINGCONTAINER': {
          showFilter($hub.filters.warehouse);
          hideFilter($hub.filters.location);
          showFilter($hub.filters.shipping_container);
          hideFilter($hub.filters.license_plate);
  
          break;
      }
      case 'LICENSEPLATE': {
          showFilter($hub.filters.warehouse);
          hideFilter($hub.filters.location);
          hideFilter($hub.filters.shipping_container);
          showFilter($hub.filters.license_plate);
  
          break;
      }
      default: {
          hideFilter($hub.filters.license_plate);
  
          await $shell.SerialNumbers.openErrorDialog('Invalid entity', `Entity '${$hub.filters.audit_entity.control.value}' is not currently supported.`);
      }
  }
  
  
  /**************************************
   * FUNCTIONS
  ***************************************/
  function showFilter(filter: any) {
      filter.hidden = false;
      filter.control.value = null;
  }
  
  function hideFilter(filter: any) {
      filter.hidden = true;
      filter.control.value = null;
  }
  
  }
  on_finish_audit_clicked(event = null) {
    return this.on_finish_audit_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_finish_audit_clickedInternal(
    $hub: FootPrintManager_audit_serial_numbers_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      let result = await $shell.FootPrintManager.openfinalize_audit_formDialog({
          missingSerialNumberIds: $hub.vars.expectedSerialNumbers.filter(e => !$hub.vars.scannedBarcodes.find(a => a.serialNumber.trim().toUpperCase() === e.lookupCode.trim().toUpperCase())).map(sn => sn.id),
          foundSerialNumberIds: $hub.vars.scannedBarcodes.filter(sb => !$hub.vars.expectedSerialNumbers.find(e => e.id === sb.serialNumberId )).map(sb => sb.serialNumberId),
          licensePlateId: $hub.inParams.licensePlateId,
          warehouseId: $hub.filters.warehouse.control.value,
          locationId: $hub.filters.location.control.value,
          shippingContainerId: $hub.filters.shipping_container.control.value
      });
  
      if (result.isConfirm) {
           let taskResult = await $flows.SerialNumbers.create_audit_task({
          isAbort: $hub.vars.isAbort,
          entity: {
              warehouseId: $hub.filters.warehouse.control.value,
              locationId: $hub.filters.location.control.value,
              shippingContainerId: $hub.filters.shipping_container.control.value,
              licensePlateId: $hub.inParams.licensePlateId
          },
          reasonCodeId: result.reasonCodeId,
          expectedSerialNumberCodes: $hub.vars.expectedSerialNumbers.map(sn => sn.lookupCode),
          scannedSerialNumberCodes: $hub.vars.scannedBarcodes.map(sb => sb.serialNumber),
          startDateTime: $hub.vars.startDateTime
      });
  
      if ($utils.isDefined(taskResult.reasons)) {
          throw new Error(`Error creating audit task: ${(await $flows.Utilities.grammar_format_string_array_flow({ values: taskResult.reasons })).formattedValue}`);
      }
  
          $hub.close();
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      if ($utils.isDefinedTrimmed(error.message)) {
          await $shell.SerialNumbers.openErrorDialog('Error finalizing audit', error.message);
      }
  }
  }
  set_hub_state(event = null) {
    return this.set_hub_stateInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_hub_stateInternal(
    $hub: FootPrintManager_audit_serial_numbers_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($hub.vars.scannedBarcodes)) {
      $hub.toolbar.finish_audit.control.readOnly = false;
  
      $hub.filters.audit_entity.control.readOnly = true;
      $hub.filters.warehouse.control.readOnly = true;
      $hub.filters.location.control.readOnly = true;
      $hub.filters.shipping_container.control.readOnly = true;
      $hub.filters.license_plate.control.readOnly = true;
  }
  else {
      $hub.toolbar.finish_audit.control.readOnly = true;
  
      $hub.filters.warehouse.control.readOnly = false;
      $hub.filters.location.control.readOnly = false;
      $hub.filters.shipping_container.control.readOnly = false;
      $hub.filters.license_plate.control.readOnly = $utils.isDefined($hub.inParams.licensePlateId);
  
      $hub.filters.audit_entity.control.readOnly = $hub.filters.license_plate.control.readOnly;
  }
  }
  //#endregion private flows
}
