import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manifesting_ShellService, EModalSize, EToasterType, EToasterPosition } from './Manifesting.shell.service';
import { Manifesting_OperationService } from './Manifesting.operation.service';
import { Manifesting_DatasourceService } from './Manifesting.datasource.index';
import { Manifesting_FlowService } from './Manifesting.flow.index';
import { Manifesting_ReportService } from './Manifesting.report.index';
import { Manifesting_LocalizationService } from './Manifesting.localization.service';
import { Language } from './localization.service';
import { $types } from './Manifesting.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Manifesting-manifesting_library_home',
  templateUrl: './Manifesting.manifesting_library_home.component.html'
})
export class Manifesting_manifesting_library_homeComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    description: new FieldModel(new LabelModel(null, null)
, new ControlContainerStyles(null, null), 'Description', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Manifesting Library', false, true, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Manifesting_ShellService,
private datasources: Manifesting_DatasourceService,
private flows: Manifesting_FlowService,
private reports: Manifesting_ReportService,
private localization: Manifesting_LocalizationService,
private operations: Manifesting_OperationService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Home';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.description.control as LabelModel).label = `Library to access manifesting capabilities for FootPrint shipments. Utilizes 3rd party libraries of EasyPost and PrintNode.`;


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
}
