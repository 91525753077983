import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { Locations_licenseplate_statuses_dd_singleComponent } from './Locations.licenseplate_statuses_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'


import { FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_gridComponent } from './FootPrintManager.warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid.component';

interface IFootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentEntity {
LookupCode?: string, Id?: number, StatusId?: number, StatusName?: string, LocationId?: number, LocationName?: string}

interface IFootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentInParams {
  orderId: number, orderStatusId: number}


class FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent;
  entity: IFootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    lookupcode_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    status_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    location_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['lookupcode_edit'] as DatexFormControl, null, false, '')
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['status_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['location_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
  }

  get $fields_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.vars.shipments[0].warehouseId;
    
    return expr;
  }
  get $fields_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_location_selector_inParams_isLoose(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = false;
    
    return expr;
  }

  //#region rowExpand inParams


  get $rowExpand_FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid_inParams_licenseplateId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.Id;
    
    return expr;
  }


  get $rowExpand_FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid_inParams_orderId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.orderId;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent, entity: IFootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent, entity?: IFootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      shipmentId:  $grid.vars.shipments[0]?.id ,
    };
    const data = await this.datasources.WarehouseTransfers.ds_warehouse_transfer_inbound_order_licenseplates_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity?.Id?.toString();
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity?.LookupCode;
    (this.cells.lookupcode.editControl as TextBoxModel).reset($row.entity?.LookupCode);
    (this.cells.status.displayControl as TextModel).text = $row.entity.StatusName;
    (this.cells.status.editControl as SelectBoxModel).reset($row.entity?.StatusId ?? $row.cells.status.editControl.value);
    (this.cells.location.displayControl as TextModel).text = $row.entity.LocationName;
    (this.cells.location.editControl as SelectBoxModel).reset($row.entity?.LocationId ?? $row.cells.location.editControl.value);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'id' : {
        this.on_click_id();
        break;
      }
    }
  }

  //#region private flows
  on_click_id(event = null) {
    return this.on_click_idInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_click_idInternal(
    $row: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.opensingle_licenseplate_hub({licenseplateId: $row.entity.Id});
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Update LicensePlate
  try {
      if ($row.cells.lookupcode.editControl.isChanged || $row.cells.location.editControl.isChanged) {
          await updateLicensePlate(
              $row.entity.Id,
              $row.cells.lookupcode.editControl.value,
              $row.cells.location.editControl.value,
              $row.cells.status.editControl.value
          );
  
          await $row.refresh();
      }
  }
  catch (ex) {
      let message = $utils.isDefined(ex.error?.error?.message) ? ex.error.error.message : ex.message;
      $shell.FootPrintManager.openErrorDialog('Error', message);
      throw Error(message); // To prevent display mode
  }
  
  
  /****************************************** 
   * FUNCTIONS
  ******************************************/
  async function updateLicensePlate(licenseplateId: number, lookupcode: string, locationId: number, statusId: number) {
      let payload = {
          "LookupCode": lookupcode,
          "LocationId": locationId,
          "StatusId": statusId
      }
  
      await $flows.Utilities.crud_update_flow({
          entitySet: "LicensePlates",
          id: licenseplateId,
          entity: payload
      });
  
      let tasks = (await $datasources.WarehouseTransfers.ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid.get({ licenseplateId: licenseplateId, orderId: $grid.inParams.orderId })).result;
  
      if ($utils.isDefined(tasks)) {
          for (let task of tasks) {
              let properties = {
                  "ActualTargetLocationId": locationId,
                  "ExpectedTargetLocationId": locationId
              }
  
              await $flows.Utilities.crud_update_flow({ entitySet: "Tasks", id: task.Id, entity: properties })
          }
      }
  
  }
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      // Check that all required fields are populated
      if (!$utils.isAllDefined(
          $row.cells.lookupcode.editControl.value,
          $row.cells.location.editControl.value
      )) {
          $shell.FootPrintManager.openErrorDialog('Error', 'Please enter all required fields.');
      }
  
      // Create new LicensePlate
      $row.entity.Id = await createLicensePlate(
          $row.cells.lookupcode.editControl.value,
          $row.cells.location.editControl.value,
          $row.cells.status.editControl.value,
          $grid.vars.shipments[0].id
      );
  
      await $row.refresh();
  }
  catch (ex) {
      let message = $utils.isDefined(ex.error?.error?.message) ? ex.error.error.message : ex.message;
      $shell.FootPrintManager.openErrorDialog('Error', message);
      throw Error(message); // To prevent display mode
  }
  
  /****************************************** 
   * FUNCTIONS
  ******************************************/
  async function createLicensePlate(lookupcode: string, locationId: number, statusId: number, shipmentId: number) {
      let licenseplateId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'LicensePlate' })).nextId;
  
      let locationResults = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: locationId })).result;
      let warehouseId = locationResults[0].WarehouseId;
  
      let payload = {
          "Archived": false,
          "ContainsPortalVisibleAttachments": false,
          "Id": licenseplateId,
          "LocationId": locationId,
          "LookupCode": lookupcode,
          "ShipmentId": shipmentId,
          "StatusId": statusId,
          "TypeId": 5,
          "WarehouseId": warehouseId
      }
  
      await $flows.Utilities.crud_create_flow({
          entitySet: "LicensePlates",
          entity: payload
      });
  
      return licenseplateId;
  }
  
  async function updateOrderStatus(orderId: number, shipmentId: number) {
      let order = (await $datasources.WarehouseTransfers.ds_get_order_by_orderId.get({ orderId })).result;
  
      if (order.OrderStatusId === 1) {
          $flows.Utilities.crud_update_flow({ entitySet: "Orders", id: orderId, entity: { "OrderStatusId": 2 } });
          $flows.Utilities.crud_update_flow({ entitySet: "Shipments", id: shipmentId, entity: { "StatusId": 2 } })
      }
  }
  }
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      let defaultLocationId = (await $datasources.Locations.ds_get_warehouse_by_warehouseId.get({ warehouseId: $grid.vars.shipments[0].warehouseId })).result.DefaultReceivingLocationId;
  
      $row.cells.location.editControl.value = defaultLocationId;
      $row.cells.status.editControl.value = 1;
  }
  catch (ex) {
      $shell.FootPrintManager.openErrorDialog('Error', ex.message);
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_licenseplate_statuses_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => FootPrintManager_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_gridComponent)
  ],
  selector: 'FootPrintManager-warehouse_transfer_inbound_licenseplates_grid',
  templateUrl: './FootPrintManager.warehouse_transfer_inbound_licenseplates_grid.component.html'
})
export class FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('headersWidth', null);

  fullTextSearch: string;

  inParams: IFootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentInParams = { orderId: null, orderStatusId: null };


  //#region Variables
  vars: { shipments?: { id?: number, warehouseId?: number }[] } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'ID', false, false, null),       lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Lookup', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       location: new GridHeaderModel(new HeaderStyles(null, null), 'Location', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('orderStatusId') set $inParams_orderStatusId(value: any) {
    this.inParams['orderStatusId'] = value;
  }
  get $inParams_orderStatusId(): any {
    return this.inParams['orderStatusId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    super();
    this.title = 'License plates';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.orderStatusId)) {
        this.$missingRequiredInParams.push('orderStatusId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      shipmentId:  $grid.vars.shipments[0]?.id ,
    };
    try {
    const data = await this.datasources.WarehouseTransfers.ds_warehouse_transfer_inbound_order_licenseplates_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_selection_changed();
  }

  async addRow(entity?: IFootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentEntity) {
    const row = new FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Call common flow to apply the role based permissions
  await $grid.apply_operations();
  }
  on_delete_licenseplates(event = null) {
    return this.on_delete_licenseplatesInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_licenseplatesInternal(
    $grid: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let licensePlates: any[] = [];
  
  for (let row of $grid.selectedRows) {
      let tasks = (await $datasources.WarehouseTransfers.ds_warehouse_transfer_inbound_order_licenseplates_receiving_tasks_grid.get({ licenseplateId: row.entity.Id, orderId: $grid.inParams.orderId })).result
  
      licensePlates.push({
          Id: row.entity.Id,
          Tasks: tasks.map(t => ({ Id: t.Id, ExpectedPackagedAmount: t.ExpectedPackagedAmount, OrderId: t.OrderId, OrderLineNumber: t.OrderLineNumber, ShipmentLineId: t.ShipmentLineId, LotId: t.Lot.Id, VendorLotId: t.VendorLot.Id, MaterialId: t.Material.Id, PackagingId: t.ExpectedPackagedPack.Id }))
      });
  }
  
  let licensePlateCount = licensePlates.length;
  let totalPackagedAmount = licensePlates.reduce((total, lp) => total + lp.Tasks.reduce((lpTotal, task) => lpTotal + task.ExpectedPackagedAmount, 0), 0);
  let message: string = `Are you sure you want to delete ${licensePlateCount} ${licensePlateCount === 1 ? 'license plate' : 'license plates'}${totalPackagedAmount > 0 ? ` along with ${totalPackagedAmount} packaged content` : ''}?`;
  
  
  let isConfirmed = await $shell.FootPrintManager.openConfirmationDialog('Confirm: Delete License Plate(s)', message, 'Confirm', 'Cancel');
  
  if ($utils.isDefined(isConfirmed) && isConfirmed) {
      for (let licensePlate of licensePlates) {
          for (let task of licensePlate.Tasks) {
              await $flows.WarehouseTransfers.set_target_inbound_receiving_task_flow({
                  license_plate_id: licensePlate.Id,
                  lot_id: task.LotId,
                  packaging_id: task.PackagingId,
                  order_id: $grid.inParams.orderId,
                  material_id: task.MaterialId,
                  vendor_lot_id: task.VendorLotId,
                  packaged_amount: 0,
                  existing_task_id: task.Id
              });
          }
  
          await $flows.Utilities.crud_delete_flow({ id: licensePlate.Id, entitySet: "LicensePlates" });
          $grid.rows.splice($grid.rows.indexOf($grid.rows.find(r => r.entity.Id === licensePlate.Id)), 1);
      }
  }
  
  /********************************************************************************
  *********************************************************************************
   * FUNCTIONS
  ********************************************************************************
  *******************************************************************************/
  async function updateShipmentLinePackagedAmount(shipmentLineId: number, adjustPackagedAmount: number) {
      let shipmentLine = (await $datasources.WarehouseTransfers.ds_get_shipmentline_by_shipmentlineId.get({ shipmentLineId: shipmentLineId })).result;
  
      if ($utils.isDefined(shipmentLine)) {
          if (shipmentLine.ExpectedPackagedAmount + adjustPackagedAmount > 0) {
              await $flows.Utilities.crud_update_flow({
                  entitySet: "ShipmentLines",
                  id: shipmentLineId,
                  entity: {
                      "ExpectedPackagedAmount": shipmentLine.ExpectedPackagedAmount + adjustPackagedAmount,
                      "ExpectedAmount": (shipmentLine.ExpectedPackagedAmount + adjustPackagedAmount) * (shipmentLine.ExpectedAmount / shipmentLine.ExpectedPackagedAmount)
                  }
              });
          }
          else {
              await $flows.Utilities.crud_delete_flow({ id: shipmentLineId, entitySet: "ShipmentLines" });
          }
      }
  }
  
  
  async function updateOrderLinePackagedAmount(orderId: number, orderLineNumber: number, adjustPackagedAmount: number) {
      let orderLine = (await $datasources.WarehouseTransfers.ds_get_orderlines_by_orderId_linenumber.get({ orderId: orderId, linenumber: orderLineNumber })).result;
  
      // Update or Delete OrderLine
      if ($utils.isDefined(orderLine)) {
          if (orderLine.PackagedAmount + adjustPackagedAmount > 0) {
              await $flows.Utilities.crud_update_dual_pk_flow({
                  entitySet: "OrderLines",
                  pk1String: "OrderId",
                  pk1: orderLine.OrderId,
                  pk2String: "LineNumber",
                  pk2: orderLine.LineNumber,
                  entity: {
                      "PackagedAmount": orderLine.PackagedAmount + adjustPackagedAmount,
                      "Amount": (orderLine.PackagedAmount + adjustPackagedAmount) * (orderLine.Amount / orderLine.PackagedAmount)
                  }
              });
          }
          else {
              await $flows.Utilities.crud_delete_dual_pk_flow({
                  entitySet: "OrderLines",
                  pk1String: "OrderId",
                  pk1: orderLine.OrderId,
                  pk2String: "LineNumber",
                  pk2: orderLine.LineNumber
              });
          }
      }
  }
  }
  on_selection_changed(event = null) {
    return this.on_selection_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_selection_changedInternal(
    $grid: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $grid: FootPrintManager_warehouse_transfer_inbound_licenseplates_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let order = (await $datasources.WarehouseTransfers.ds_get_order_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
  $grid.vars.shipments = order.ShipmentOrderLookups.map(r =>
      ({ id: r.Shipment.Id, warehouseId: r.Shipment.ActualWarehouseId ?? r.Shipment.ExpectedWarehouseId })
  );
  
  // Set whether grid can be edited
  
  if (!(await $operations.FootPrintManager.Disable_AsnOrders_LicenseplateEditing.isAuthorized()) && order.ShipmentOrderLookups.find(r => r.Shipment.StatusId !== 8 && r.Shipment.StatusId !== 16)) {
      $grid.canEdit = true;
      $grid.canAdd = true;
  }
  else {
      $grid.canEdit = false;
      $grid.canAdd = false;
  }
  
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
