import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'


interface IFootPrintManager_locations_replenishments_gridComponentEntity {
Id?: number, ComparisonTypeId?: number, LocationId?: number, MaterialId?: number, MaximumPackagedAmount?: number, MaximumPackingId?: number, MinimumPackagedAmount?: number, MinimumPackagingId?: number, OnDemand?: boolean, ReplenishmentPackagedAmount?: number, ReplenishmentPackagingId?: number, SystemTriggered?: boolean, Location?: { Name?: string, WarehouseId?: number, Warehouse?: { Name?: string } }, Material?: { LookupCode?: string, Name?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } }, ComparisonType?: { DisplayName?: string }}

interface IFootPrintManager_locations_replenishments_gridComponentInParams {
  materialId?: number[], warehouseIds?: number[], locationId?: number[], projectId?: number, ownerId?: number}


class FootPrintManager_locations_replenishments_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_locations_replenishments_gridComponent;
  entity: IFootPrintManager_locations_replenishments_gridComponentEntity;


  options: { edit_rule_button: ButtonModel } = {
    edit_rule_button: new ButtonModel('edit_rule_button', new ButtonStyles(null, null), false, 'Edit rule', '')

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    on_demand_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    system_triggered_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    location: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    material_lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    comparison_type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    minimum_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    replenishment_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    maximum_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    on_demand: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['on_demand_display'] as DatexFormControl, null, true, '')
,
null
      ),
    system_triggered: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['system_triggered_display'] as DatexFormControl, null, true, '')
,
null
      ),
    owner_lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    project_lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_locations_replenishments_gridComponent, entity: IFootPrintManager_locations_replenishments_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_locations_replenishments_gridComponent, entity?: IFootPrintManager_locations_replenishments_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      materialId:  $grid.inParams.materialId ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      locationId:  $grid.inParams.locationId ,
      projectId:  $grid.inParams.projectId ,
      ownerId:  $grid.inParams.ownerId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.Replenishments.ds_locations_replenishments_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.location.displayControl as TextModel).text = $row.entity.Location?.Name;
    (this.cells.material_lookupcode.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.comparison_type.displayControl as TextModel).text = $row.entity.ComparisonType?.DisplayName;
    (this.cells.minimum_packaged_amount.displayControl as TextModel).text = $row.entity.MinimumPackagedAmount?.toString();
    (this.cells.replenishment_packaged_amount.displayControl as TextModel).text = $row.entity.ReplenishmentPackagedAmount?.toString();
    (this.cells.maximum_packaged_amount.displayControl as TextModel).text = $row.entity.MaximumPackagedAmount?.toString();
    (this.cells.on_demand.displayControl as CheckBoxModel).reset($row.entity.OnDemand);
    (this.cells.system_triggered.displayControl as CheckBoxModel).reset($row.entity.SystemTriggered);
    (this.cells.owner_lookupcode.displayControl as TextModel).text = $row.entity.Material?.Project?.Owner?.LookupCode;
    (this.cells.project_lookupcode.displayControl as TextModel).text = $row.entity.Material?.Project?.LookupCode;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.Location?.Warehouse?.Name;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'location' : {
        this.on_location_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_edit_rule_clicked(event = null) {
    return this.on_edit_rule_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_rule_clickedInternal(
    $row: FootPrintManager_locations_replenishments_gridComponentRowModel,
  $grid: FootPrintManager_locations_replenishments_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const dialogResults = (await $shell.FootPrintManager.openlocation_replenishment_editorDialog({locationReplenishmentId: $row.entity.Id}, 'flyout'));
  
  if ($utils.isDefined(dialogResults)){
  
      const confirm = dialogResults.confirm;
      if ($utils.isDefined(confirm)){
  
  
              if (confirm){
  
                  $row.refresh();
              }
  
      }
  
  
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_locations_replenishments_gridComponentRowModel,
  $grid: FootPrintManager_locations_replenishments_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const minimumPackagedId = $row.entity.MinimumPackagingId;
  const maximumPackagedId = $row.entity.MaximumPackingId;
  const replenishmentPackagedId = $row.entity.ReplenishmentPackagingId;
  const locationId = $row.entity.LocationId;
  const materialId = $row.entity.MaterialId;
  
  
  
  if ($utils.isDefined(minimumPackagedId)) {
  
      const minimum = (await $datasources.Replenishments.ds_get_inventorymeasurementunit_by_packagedId.get({ packagedId: minimumPackagedId })).result;
  
      if ($utils.isDefined(minimum)) {
  
          const packaging = minimum.ShortName;
          const amount = $row.entity.MinimumPackagedAmount;
          if ($utils.isAllDefined(packaging, amount)) {
              $row.cells.minimum_packaged_amount.displayControl.text = `${amount} ${packaging}`
          }
  
  
      }
  
      //if ($utils.isAllDefined(locationId, materialId)) {
  
      //    const available = (await $datasources.Inventory.ds_get_available_inventory_by_materialId_packagedId_locationd.get({
      //        locationId: locationId,
      //        materialId: materialId,
      //        packagedId: minimumPackagedId
      //    })).result;
  
      //    const minimum = (await $datasources.Replenishments.ds_get_inventorymeasurementunit_by_packagedId.get({ packagedId: minimumPackagedId })).result;
  
      //    if ($utils.isAllDefined(available,minimum)){
      //        const packaging = minimum.ShortName;
      //        const availableAmount = available.TotalAvailablePackagedAmount;
      //        if ($utils.isAllDefined(packaging,availableAmount)){
      //            $row.cells.available_packaged_amount.displayControl.text = `${availableAmount} ${packaging}`
      //        }
      //    }
      //}
  
  }
  
  if ($utils.isDefined(maximumPackagedId)) {
  
      const maximum = (await $datasources.Replenishments.ds_get_inventorymeasurementunit_by_packagedId.get({ packagedId: maximumPackagedId })).result;
  
      if ($utils.isDefined(maximum)) {
  
          const packaging = maximum.ShortName;
          const amount = $row.entity.MaximumPackagedAmount;
          if ($utils.isAllDefined(packaging, amount)) {
              $row.cells.maximum_packaged_amount.displayControl.text = `${amount} ${packaging}`
          }
  
      }
  
  }
  
  
  if ($utils.isDefined(replenishmentPackagedId)) {
  
      const replenishment = (await $datasources.Replenishments.ds_get_inventorymeasurementunit_by_packagedId.get({ packagedId: replenishmentPackagedId })).result;
  
      if ($utils.isDefined(replenishment)) {
  
          const packaging = replenishment.ShortName;
          const amount = $row.entity.ReplenishmentPackagedAmount;
          if ($utils.isAllDefined(packaging, amount)) {
              $row.cells.replenishment_packaged_amount.displayControl.text = `${amount} ${packaging}`
          }
  
      }
  
  }
  }
  on_location_clicked(event = null) {
    return this.on_location_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_clickedInternal(
    $row: FootPrintManager_locations_replenishments_gridComponentRowModel,
  $grid: FootPrintManager_locations_replenishments_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.FootPrintManager.opensingle_location_hub({locationId: $row.entity.LocationId});
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-locations_replenishments_grid',
  templateUrl: './FootPrintManager.locations_replenishments_grid.component.html'
})
export class FootPrintManager_locations_replenishments_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_locations_replenishments_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_locations_replenishments_gridComponentInParams = { materialId: [], warehouseIds: [], locationId: [], projectId: null, ownerId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     location: new GridHeaderModel(new HeaderStyles(null, null), 'Location', false, false, null),       material_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       comparison_type: new GridHeaderModel(new HeaderStyles(null, null), 'Rule trigger', false, false, null),       minimum_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Minimum quantity', false, false, null),       replenishment_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Replenishment quantity', false, false, null),       maximum_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Maximum quantity', false, false, null),       on_demand: new GridHeaderModel(new HeaderStyles(null, null), 'On demand', false, false, null),       system_triggered: new GridHeaderModel(new HeaderStyles(null, null), 'System triggered', false, false, null),       owner_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Owner', false, false, null),       project_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_locations_replenishments_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('materialId') set $inParams_materialId(value: any) {
    this.inParams['materialId'] = value;
  }
  get $inParams_materialId(): any {
    return this.inParams['materialId'] ;
  }
  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }
  @Input('locationId') set $inParams_locationId(value: any) {
    this.inParams['locationId'] = value;
  }
  get $inParams_locationId(): any {
    return this.inParams['locationId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }

  topToolbar = {
      add_rule: new ToolModel(new ButtonModel('add_rule', new ButtonStyles(null, null), false, 'Add rule', 'icon-ic_fluent_add_20_regular')
    ),
      top_up: new ToolModel(new ButtonModel('top_up', new ButtonStyles(null, null), false, 'Top-up', 'ms-Icon ms-Icon--PaddingTop')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    super();
    this.title = 'Replenishments';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      materialId:  $grid.inParams.materialId ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      locationId:  $grid.inParams.locationId ,
      projectId:  $grid.inParams.projectId ,
      ownerId:  $grid.inParams.ownerId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.Replenishments.ds_locations_replenishments_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_locations_replenishments_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_add_rule_clicked(event = null) {
    return this.on_add_rule_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_rule_clickedInternal(
    $grid: FootPrintManager_locations_replenishments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Initialize the first material
  let materialId = null;
  
  if ($utils.isDefined($grid.inParams.materialId)) {
      materialId = $grid.inParams.materialId[0];
  };
  
  // Initialize the first location 
  let locationId = null;
  
  if ($utils.isDefined($grid.inParams.locationId)) {
      locationId = $grid.inParams.locationId[0];
  };
  
  // Initialize the first warehouse
  let warehouse_id = null;
  
  if ($utils.isDefined($grid.inParams.warehouseIds)){
      warehouse_id = $grid.inParams.warehouseIds[0];
  }
  
  
  
  
  
  
  const dialogResults = (await $shell.FootPrintManager.openlocation_replenishment_creation_formDialog({
      warehouseId: warehouse_id,
      ownerId: $grid.inParams.ownerId,
      projectId: $grid.inParams.projectId,
      materialId: materialId,
      locationId: locationId
  
  },'flyout'));
  
  if ($utils.isDefined(dialogResults)) {
  
      const confirm = dialogResults.confirm;
      if ($utils.isDefined(confirm)) {
  
          if (confirm) {
              $grid.refresh();
          }
  
      }
  
  
  
  }
  }
  on_top_up_clicked(event = null) {
    return this.on_top_up_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_top_up_clickedInternal(
    $grid: FootPrintManager_locations_replenishments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  throw new Error ('Top-up function under development')
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_locations_replenishments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Rule Delete Error', 'No rules selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          
           // Placeholder to check if the rule can be deleted.
              candidates.push(row);
          
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Rule delete errors';
          const errorMessage = `Rule for Material(s) ${failures.map(c => c.entity.Material.LookupCode.toString()).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Material rule(s) ${candidates.map(c => c.entity.Material.LookupCode.toString()).join(',')} - Once deleted, the rule(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some rule(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following rule(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          locationReplenishmentId: candidate.entity.Id
                      }
                      const result = await $flows.Replenishments.delete_location_replenishment_threshold_flow(flowParams);
  
                      processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Material rule ${candidate.entity.Material.LookupCode.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All material rule(s) deleted';
                  const message = `Material rule(s) ${processSuccess.map(c => c.entity.Material.LookupCode.toString()).join(',')} deleted`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All material rule(s) failed to delete';
                      const message = `Material rule(s) ${processFailures.map(c => c.entity.Material.LookupCode.toString()).join(',')} could not be deleted`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some material rule(s) could not be deleted';
                      const success = `Material rule(s) ${processSuccess.map(c => c.entity.Material.LookupCode.toString()).join(',')} were deleted.`;
                      const errors = `The following material rule(s) could not be deleted: ${processFailures.map(c => c.entity.Material.LookupCode.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_locations_replenishments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  
  // One or more rows selected
  if (selectedRowsCount > 1) {
      $grid.topToolbar.on_delete.control.readOnly = false;
      $grid.topToolbar.top_up.control.readOnly = true;
      $grid.topToolbar.add_rule.control.readOnly = true;
  
  
  
  }
  else if (selectedRowsCount == 1) {
      $grid.topToolbar.on_delete.control.readOnly = false;
      $grid.topToolbar.top_up.control.readOnly = false;
      $grid.topToolbar.add_rule.control.readOnly = true;
  
  }
  else {
      $grid.topToolbar.top_up.control.readOnly = true;
      $grid.topToolbar.add_rule.control.readOnly = false;
      $grid.topToolbar.on_delete.control.readOnly = true;
  
  }
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
