import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Attachments_DatasourceService } from './Attachments.datasource.index';
import { Instructions_DatasourceService } from './Instructions.datasource.index';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Materials_DatasourceService } from './Materials.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';

import { FootPrintApiManager_ds_base_order_editorService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_base_order_lines_gridService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_entity_import_requestsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_entity_import_requests_gridService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_entity_user_defined_fieldsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_addressService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_carriersService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_invoicesService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_licenseplatesService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_lotsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_material_packagingsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_materialsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_operation_codesService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_order_address_typesService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_order_by_transaction_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_order_classService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_ordersService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_projectsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_reason_codesService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_serialnumbersService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_shipment_transmission_by_transaction_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_task_transmission_by_transaction_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_tasksService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_vendorlotsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_find_warehousesService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_generic_order_addresses_schemaService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_addresses_by_entity_and_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_adjustment_tasksService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_billing_records_by_invoice_line_idsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_carrier_by_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_completed_receiving_tasks_by_orderId_top1Service } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_completed_shipments_without_transmissionsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_currency_by_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_first_warehouseService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_inventory_by_license_plate_lot_by_project_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_inventory_by_location_lot_by_project_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_invoice_by_invoice_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_invoice_lines_by_invoice_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_latest_order_by_projectService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_license_plates_for_adjustmentsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_licenseplate_by_licenseplateIdService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_locations_by_locationIdsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_lots_by_lotIdsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_material_by_lookupcode_and_project_lookupcodeService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_material_packagings_by_upcService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_materials_and_packages_by_project_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_materials_by_materialCodes_projectIdService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_materials_with_inventoryService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_measurement_unitsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_order_addresses_by_order_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_order_by_licenseplateService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_order_information_by_shipment_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_order_line_information_by_order_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_order_status_by_transaction_idsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_orderlines_by_orderIdService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_orders_by_idsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_orders_without_shipmentsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_owner_by_ownerCodeService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_pallet_transactions_by_shipment_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_physical_inventory_by_license_plate_lot_by_project_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_project_and_owner_by_lookup_codeService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_projects_by_projectCodeService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_projects_by_project_and_ownerService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_receiving_tasks_by_shipment_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_request_xml_by_requestId_editorService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_sample_data_for_adjustmentService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_serial_numbers_by_idsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_serial_numbers_by_project_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_serial_numbers_by_shipment_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_shipmentLines_by_orderId_shipmentIdService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_shipment_contents_by_shipment_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_shipments_by_idsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_shipments_by_orderIdService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_shipments_by_shipmentIdService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_source_license_plate_tasks_by_shipmentIdService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_status_change_tasksService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_tagsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_tasks_by_licenseplateIdService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_tax_schedule_by_idService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_warehouses_by_warehouseIdsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_wavelength_order_transmissionsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_wavelength_shipment_transmissionsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_wavelength_transmission_typeService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_get_work_orders_by_idsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_material_statusesService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_message_stagingService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_order_serial_tasks_by_shipmentIdService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_adjustmentsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_configurationsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_controlService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_control_partners_widgetService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_integrationsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_lifecycleService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_logsService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_message_statuses_widgetService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_messagesService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_processesService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_statusesService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_storage_transaction_typesService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_ds_transaction_reportService } from './FootPrintApiManager.datasource.index';

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Attachments = this.injector.get(Attachments_DatasourceService);
    this.Instructions = this.injector.get(Instructions_DatasourceService);
    this.Owners = this.injector.get(Owners_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.Inventory = this.injector.get(Inventory_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.Materials = this.injector.get(Materials_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Attachments: Attachments_DatasourceService;
    public Instructions: Instructions_DatasourceService;
    public Owners: Owners_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public Inventory: Inventory_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public Materials: Materials_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
  public FootPrintApiManager: FootPrintApiManager_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_base_order_editor: FootPrintApiManager_ds_base_order_editorService;
  public get ds_base_order_editor(): FootPrintApiManager_ds_base_order_editorService {
    if(!this._ds_base_order_editor) {
      this._ds_base_order_editor = this.injector.get(FootPrintApiManager_ds_base_order_editorService);
    }
    return this._ds_base_order_editor;
  }
  private _ds_base_order_lines_grid: FootPrintApiManager_ds_base_order_lines_gridService;
  public get ds_base_order_lines_grid(): FootPrintApiManager_ds_base_order_lines_gridService {
    if(!this._ds_base_order_lines_grid) {
      this._ds_base_order_lines_grid = this.injector.get(FootPrintApiManager_ds_base_order_lines_gridService);
    }
    return this._ds_base_order_lines_grid;
  }
  private _ds_entity_import_requests: FootPrintApiManager_ds_entity_import_requestsService;
  public get ds_entity_import_requests(): FootPrintApiManager_ds_entity_import_requestsService {
    if(!this._ds_entity_import_requests) {
      this._ds_entity_import_requests = this.injector.get(FootPrintApiManager_ds_entity_import_requestsService);
    }
    return this._ds_entity_import_requests;
  }
  private _ds_entity_import_requests_grid: FootPrintApiManager_ds_entity_import_requests_gridService;
  public get ds_entity_import_requests_grid(): FootPrintApiManager_ds_entity_import_requests_gridService {
    if(!this._ds_entity_import_requests_grid) {
      this._ds_entity_import_requests_grid = this.injector.get(FootPrintApiManager_ds_entity_import_requests_gridService);
    }
    return this._ds_entity_import_requests_grid;
  }
  private _ds_entity_user_defined_fields: FootPrintApiManager_ds_entity_user_defined_fieldsService;
  public get ds_entity_user_defined_fields(): FootPrintApiManager_ds_entity_user_defined_fieldsService {
    if(!this._ds_entity_user_defined_fields) {
      this._ds_entity_user_defined_fields = this.injector.get(FootPrintApiManager_ds_entity_user_defined_fieldsService);
    }
    return this._ds_entity_user_defined_fields;
  }
  private _ds_find_address: FootPrintApiManager_ds_find_addressService;
  public get ds_find_address(): FootPrintApiManager_ds_find_addressService {
    if(!this._ds_find_address) {
      this._ds_find_address = this.injector.get(FootPrintApiManager_ds_find_addressService);
    }
    return this._ds_find_address;
  }
  private _ds_find_carriers: FootPrintApiManager_ds_find_carriersService;
  public get ds_find_carriers(): FootPrintApiManager_ds_find_carriersService {
    if(!this._ds_find_carriers) {
      this._ds_find_carriers = this.injector.get(FootPrintApiManager_ds_find_carriersService);
    }
    return this._ds_find_carriers;
  }
  private _ds_find_invoices: FootPrintApiManager_ds_find_invoicesService;
  public get ds_find_invoices(): FootPrintApiManager_ds_find_invoicesService {
    if(!this._ds_find_invoices) {
      this._ds_find_invoices = this.injector.get(FootPrintApiManager_ds_find_invoicesService);
    }
    return this._ds_find_invoices;
  }
  private _ds_find_licenseplates: FootPrintApiManager_ds_find_licenseplatesService;
  public get ds_find_licenseplates(): FootPrintApiManager_ds_find_licenseplatesService {
    if(!this._ds_find_licenseplates) {
      this._ds_find_licenseplates = this.injector.get(FootPrintApiManager_ds_find_licenseplatesService);
    }
    return this._ds_find_licenseplates;
  }
  private _ds_find_lots: FootPrintApiManager_ds_find_lotsService;
  public get ds_find_lots(): FootPrintApiManager_ds_find_lotsService {
    if(!this._ds_find_lots) {
      this._ds_find_lots = this.injector.get(FootPrintApiManager_ds_find_lotsService);
    }
    return this._ds_find_lots;
  }
  private _ds_find_material_packagings: FootPrintApiManager_ds_find_material_packagingsService;
  public get ds_find_material_packagings(): FootPrintApiManager_ds_find_material_packagingsService {
    if(!this._ds_find_material_packagings) {
      this._ds_find_material_packagings = this.injector.get(FootPrintApiManager_ds_find_material_packagingsService);
    }
    return this._ds_find_material_packagings;
  }
  private _ds_find_materials: FootPrintApiManager_ds_find_materialsService;
  public get ds_find_materials(): FootPrintApiManager_ds_find_materialsService {
    if(!this._ds_find_materials) {
      this._ds_find_materials = this.injector.get(FootPrintApiManager_ds_find_materialsService);
    }
    return this._ds_find_materials;
  }
  private _ds_find_operation_codes: FootPrintApiManager_ds_find_operation_codesService;
  public get ds_find_operation_codes(): FootPrintApiManager_ds_find_operation_codesService {
    if(!this._ds_find_operation_codes) {
      this._ds_find_operation_codes = this.injector.get(FootPrintApiManager_ds_find_operation_codesService);
    }
    return this._ds_find_operation_codes;
  }
  private _ds_find_order_address_types: FootPrintApiManager_ds_find_order_address_typesService;
  public get ds_find_order_address_types(): FootPrintApiManager_ds_find_order_address_typesService {
    if(!this._ds_find_order_address_types) {
      this._ds_find_order_address_types = this.injector.get(FootPrintApiManager_ds_find_order_address_typesService);
    }
    return this._ds_find_order_address_types;
  }
  private _ds_find_order_by_transaction_id: FootPrintApiManager_ds_find_order_by_transaction_idService;
  public get ds_find_order_by_transaction_id(): FootPrintApiManager_ds_find_order_by_transaction_idService {
    if(!this._ds_find_order_by_transaction_id) {
      this._ds_find_order_by_transaction_id = this.injector.get(FootPrintApiManager_ds_find_order_by_transaction_idService);
    }
    return this._ds_find_order_by_transaction_id;
  }
  private _ds_find_order_class: FootPrintApiManager_ds_find_order_classService;
  public get ds_find_order_class(): FootPrintApiManager_ds_find_order_classService {
    if(!this._ds_find_order_class) {
      this._ds_find_order_class = this.injector.get(FootPrintApiManager_ds_find_order_classService);
    }
    return this._ds_find_order_class;
  }
  private _ds_find_orders: FootPrintApiManager_ds_find_ordersService;
  public get ds_find_orders(): FootPrintApiManager_ds_find_ordersService {
    if(!this._ds_find_orders) {
      this._ds_find_orders = this.injector.get(FootPrintApiManager_ds_find_ordersService);
    }
    return this._ds_find_orders;
  }
  private _ds_find_projects: FootPrintApiManager_ds_find_projectsService;
  public get ds_find_projects(): FootPrintApiManager_ds_find_projectsService {
    if(!this._ds_find_projects) {
      this._ds_find_projects = this.injector.get(FootPrintApiManager_ds_find_projectsService);
    }
    return this._ds_find_projects;
  }
  private _ds_find_reason_codes: FootPrintApiManager_ds_find_reason_codesService;
  public get ds_find_reason_codes(): FootPrintApiManager_ds_find_reason_codesService {
    if(!this._ds_find_reason_codes) {
      this._ds_find_reason_codes = this.injector.get(FootPrintApiManager_ds_find_reason_codesService);
    }
    return this._ds_find_reason_codes;
  }
  private _ds_find_serialnumbers: FootPrintApiManager_ds_find_serialnumbersService;
  public get ds_find_serialnumbers(): FootPrintApiManager_ds_find_serialnumbersService {
    if(!this._ds_find_serialnumbers) {
      this._ds_find_serialnumbers = this.injector.get(FootPrintApiManager_ds_find_serialnumbersService);
    }
    return this._ds_find_serialnumbers;
  }
  private _ds_find_shipment_transmission_by_transaction_id: FootPrintApiManager_ds_find_shipment_transmission_by_transaction_idService;
  public get ds_find_shipment_transmission_by_transaction_id(): FootPrintApiManager_ds_find_shipment_transmission_by_transaction_idService {
    if(!this._ds_find_shipment_transmission_by_transaction_id) {
      this._ds_find_shipment_transmission_by_transaction_id = this.injector.get(FootPrintApiManager_ds_find_shipment_transmission_by_transaction_idService);
    }
    return this._ds_find_shipment_transmission_by_transaction_id;
  }
  private _ds_find_task_transmission_by_transaction_id: FootPrintApiManager_ds_find_task_transmission_by_transaction_idService;
  public get ds_find_task_transmission_by_transaction_id(): FootPrintApiManager_ds_find_task_transmission_by_transaction_idService {
    if(!this._ds_find_task_transmission_by_transaction_id) {
      this._ds_find_task_transmission_by_transaction_id = this.injector.get(FootPrintApiManager_ds_find_task_transmission_by_transaction_idService);
    }
    return this._ds_find_task_transmission_by_transaction_id;
  }
  private _ds_find_tasks: FootPrintApiManager_ds_find_tasksService;
  public get ds_find_tasks(): FootPrintApiManager_ds_find_tasksService {
    if(!this._ds_find_tasks) {
      this._ds_find_tasks = this.injector.get(FootPrintApiManager_ds_find_tasksService);
    }
    return this._ds_find_tasks;
  }
  private _ds_find_vendorlots: FootPrintApiManager_ds_find_vendorlotsService;
  public get ds_find_vendorlots(): FootPrintApiManager_ds_find_vendorlotsService {
    if(!this._ds_find_vendorlots) {
      this._ds_find_vendorlots = this.injector.get(FootPrintApiManager_ds_find_vendorlotsService);
    }
    return this._ds_find_vendorlots;
  }
  private _ds_find_warehouses: FootPrintApiManager_ds_find_warehousesService;
  public get ds_find_warehouses(): FootPrintApiManager_ds_find_warehousesService {
    if(!this._ds_find_warehouses) {
      this._ds_find_warehouses = this.injector.get(FootPrintApiManager_ds_find_warehousesService);
    }
    return this._ds_find_warehouses;
  }
  private _ds_generic_order_addresses_schema: FootPrintApiManager_ds_generic_order_addresses_schemaService;
  public get ds_generic_order_addresses_schema(): FootPrintApiManager_ds_generic_order_addresses_schemaService {
    if(!this._ds_generic_order_addresses_schema) {
      this._ds_generic_order_addresses_schema = this.injector.get(FootPrintApiManager_ds_generic_order_addresses_schemaService);
    }
    return this._ds_generic_order_addresses_schema;
  }
  private _ds_get_addresses_by_entity_and_id: FootPrintApiManager_ds_get_addresses_by_entity_and_idService;
  public get ds_get_addresses_by_entity_and_id(): FootPrintApiManager_ds_get_addresses_by_entity_and_idService {
    if(!this._ds_get_addresses_by_entity_and_id) {
      this._ds_get_addresses_by_entity_and_id = this.injector.get(FootPrintApiManager_ds_get_addresses_by_entity_and_idService);
    }
    return this._ds_get_addresses_by_entity_and_id;
  }
  private _ds_get_adjustment_tasks: FootPrintApiManager_ds_get_adjustment_tasksService;
  public get ds_get_adjustment_tasks(): FootPrintApiManager_ds_get_adjustment_tasksService {
    if(!this._ds_get_adjustment_tasks) {
      this._ds_get_adjustment_tasks = this.injector.get(FootPrintApiManager_ds_get_adjustment_tasksService);
    }
    return this._ds_get_adjustment_tasks;
  }
  private _ds_get_billing_records_by_invoice_line_ids: FootPrintApiManager_ds_get_billing_records_by_invoice_line_idsService;
  public get ds_get_billing_records_by_invoice_line_ids(): FootPrintApiManager_ds_get_billing_records_by_invoice_line_idsService {
    if(!this._ds_get_billing_records_by_invoice_line_ids) {
      this._ds_get_billing_records_by_invoice_line_ids = this.injector.get(FootPrintApiManager_ds_get_billing_records_by_invoice_line_idsService);
    }
    return this._ds_get_billing_records_by_invoice_line_ids;
  }
  private _ds_get_carrier_by_id: FootPrintApiManager_ds_get_carrier_by_idService;
  public get ds_get_carrier_by_id(): FootPrintApiManager_ds_get_carrier_by_idService {
    if(!this._ds_get_carrier_by_id) {
      this._ds_get_carrier_by_id = this.injector.get(FootPrintApiManager_ds_get_carrier_by_idService);
    }
    return this._ds_get_carrier_by_id;
  }
  private _ds_get_completed_receiving_tasks_by_orderId_top1: FootPrintApiManager_ds_get_completed_receiving_tasks_by_orderId_top1Service;
  public get ds_get_completed_receiving_tasks_by_orderId_top1(): FootPrintApiManager_ds_get_completed_receiving_tasks_by_orderId_top1Service {
    if(!this._ds_get_completed_receiving_tasks_by_orderId_top1) {
      this._ds_get_completed_receiving_tasks_by_orderId_top1 = this.injector.get(FootPrintApiManager_ds_get_completed_receiving_tasks_by_orderId_top1Service);
    }
    return this._ds_get_completed_receiving_tasks_by_orderId_top1;
  }
  private _ds_get_completed_shipments_without_transmissions: FootPrintApiManager_ds_get_completed_shipments_without_transmissionsService;
  public get ds_get_completed_shipments_without_transmissions(): FootPrintApiManager_ds_get_completed_shipments_without_transmissionsService {
    if(!this._ds_get_completed_shipments_without_transmissions) {
      this._ds_get_completed_shipments_without_transmissions = this.injector.get(FootPrintApiManager_ds_get_completed_shipments_without_transmissionsService);
    }
    return this._ds_get_completed_shipments_without_transmissions;
  }
  private _ds_get_currency_by_id: FootPrintApiManager_ds_get_currency_by_idService;
  public get ds_get_currency_by_id(): FootPrintApiManager_ds_get_currency_by_idService {
    if(!this._ds_get_currency_by_id) {
      this._ds_get_currency_by_id = this.injector.get(FootPrintApiManager_ds_get_currency_by_idService);
    }
    return this._ds_get_currency_by_id;
  }
  private _ds_get_first_warehouse: FootPrintApiManager_ds_get_first_warehouseService;
  public get ds_get_first_warehouse(): FootPrintApiManager_ds_get_first_warehouseService {
    if(!this._ds_get_first_warehouse) {
      this._ds_get_first_warehouse = this.injector.get(FootPrintApiManager_ds_get_first_warehouseService);
    }
    return this._ds_get_first_warehouse;
  }
  private _ds_get_inventory_by_license_plate_lot_by_project_id: FootPrintApiManager_ds_get_inventory_by_license_plate_lot_by_project_idService;
  public get ds_get_inventory_by_license_plate_lot_by_project_id(): FootPrintApiManager_ds_get_inventory_by_license_plate_lot_by_project_idService {
    if(!this._ds_get_inventory_by_license_plate_lot_by_project_id) {
      this._ds_get_inventory_by_license_plate_lot_by_project_id = this.injector.get(FootPrintApiManager_ds_get_inventory_by_license_plate_lot_by_project_idService);
    }
    return this._ds_get_inventory_by_license_plate_lot_by_project_id;
  }
  private _ds_get_inventory_by_location_lot_by_project_id: FootPrintApiManager_ds_get_inventory_by_location_lot_by_project_idService;
  public get ds_get_inventory_by_location_lot_by_project_id(): FootPrintApiManager_ds_get_inventory_by_location_lot_by_project_idService {
    if(!this._ds_get_inventory_by_location_lot_by_project_id) {
      this._ds_get_inventory_by_location_lot_by_project_id = this.injector.get(FootPrintApiManager_ds_get_inventory_by_location_lot_by_project_idService);
    }
    return this._ds_get_inventory_by_location_lot_by_project_id;
  }
  private _ds_get_invoice_by_invoice_id: FootPrintApiManager_ds_get_invoice_by_invoice_idService;
  public get ds_get_invoice_by_invoice_id(): FootPrintApiManager_ds_get_invoice_by_invoice_idService {
    if(!this._ds_get_invoice_by_invoice_id) {
      this._ds_get_invoice_by_invoice_id = this.injector.get(FootPrintApiManager_ds_get_invoice_by_invoice_idService);
    }
    return this._ds_get_invoice_by_invoice_id;
  }
  private _ds_get_invoice_lines_by_invoice_id: FootPrintApiManager_ds_get_invoice_lines_by_invoice_idService;
  public get ds_get_invoice_lines_by_invoice_id(): FootPrintApiManager_ds_get_invoice_lines_by_invoice_idService {
    if(!this._ds_get_invoice_lines_by_invoice_id) {
      this._ds_get_invoice_lines_by_invoice_id = this.injector.get(FootPrintApiManager_ds_get_invoice_lines_by_invoice_idService);
    }
    return this._ds_get_invoice_lines_by_invoice_id;
  }
  private _ds_get_latest_order_by_project: FootPrintApiManager_ds_get_latest_order_by_projectService;
  public get ds_get_latest_order_by_project(): FootPrintApiManager_ds_get_latest_order_by_projectService {
    if(!this._ds_get_latest_order_by_project) {
      this._ds_get_latest_order_by_project = this.injector.get(FootPrintApiManager_ds_get_latest_order_by_projectService);
    }
    return this._ds_get_latest_order_by_project;
  }
  private _ds_get_license_plates_for_adjustments: FootPrintApiManager_ds_get_license_plates_for_adjustmentsService;
  public get ds_get_license_plates_for_adjustments(): FootPrintApiManager_ds_get_license_plates_for_adjustmentsService {
    if(!this._ds_get_license_plates_for_adjustments) {
      this._ds_get_license_plates_for_adjustments = this.injector.get(FootPrintApiManager_ds_get_license_plates_for_adjustmentsService);
    }
    return this._ds_get_license_plates_for_adjustments;
  }
  private _ds_get_licenseplate_by_licenseplateId: FootPrintApiManager_ds_get_licenseplate_by_licenseplateIdService;
  public get ds_get_licenseplate_by_licenseplateId(): FootPrintApiManager_ds_get_licenseplate_by_licenseplateIdService {
    if(!this._ds_get_licenseplate_by_licenseplateId) {
      this._ds_get_licenseplate_by_licenseplateId = this.injector.get(FootPrintApiManager_ds_get_licenseplate_by_licenseplateIdService);
    }
    return this._ds_get_licenseplate_by_licenseplateId;
  }
  private _ds_get_locations_by_locationIds: FootPrintApiManager_ds_get_locations_by_locationIdsService;
  public get ds_get_locations_by_locationIds(): FootPrintApiManager_ds_get_locations_by_locationIdsService {
    if(!this._ds_get_locations_by_locationIds) {
      this._ds_get_locations_by_locationIds = this.injector.get(FootPrintApiManager_ds_get_locations_by_locationIdsService);
    }
    return this._ds_get_locations_by_locationIds;
  }
  private _ds_get_lots_by_lotIds: FootPrintApiManager_ds_get_lots_by_lotIdsService;
  public get ds_get_lots_by_lotIds(): FootPrintApiManager_ds_get_lots_by_lotIdsService {
    if(!this._ds_get_lots_by_lotIds) {
      this._ds_get_lots_by_lotIds = this.injector.get(FootPrintApiManager_ds_get_lots_by_lotIdsService);
    }
    return this._ds_get_lots_by_lotIds;
  }
  private _ds_get_material_by_lookupcode_and_project_lookupcode: FootPrintApiManager_ds_get_material_by_lookupcode_and_project_lookupcodeService;
  public get ds_get_material_by_lookupcode_and_project_lookupcode(): FootPrintApiManager_ds_get_material_by_lookupcode_and_project_lookupcodeService {
    if(!this._ds_get_material_by_lookupcode_and_project_lookupcode) {
      this._ds_get_material_by_lookupcode_and_project_lookupcode = this.injector.get(FootPrintApiManager_ds_get_material_by_lookupcode_and_project_lookupcodeService);
    }
    return this._ds_get_material_by_lookupcode_and_project_lookupcode;
  }
  private _ds_get_material_packagings_by_upc: FootPrintApiManager_ds_get_material_packagings_by_upcService;
  public get ds_get_material_packagings_by_upc(): FootPrintApiManager_ds_get_material_packagings_by_upcService {
    if(!this._ds_get_material_packagings_by_upc) {
      this._ds_get_material_packagings_by_upc = this.injector.get(FootPrintApiManager_ds_get_material_packagings_by_upcService);
    }
    return this._ds_get_material_packagings_by_upc;
  }
  private _ds_get_materials_and_packages_by_project_id: FootPrintApiManager_ds_get_materials_and_packages_by_project_idService;
  public get ds_get_materials_and_packages_by_project_id(): FootPrintApiManager_ds_get_materials_and_packages_by_project_idService {
    if(!this._ds_get_materials_and_packages_by_project_id) {
      this._ds_get_materials_and_packages_by_project_id = this.injector.get(FootPrintApiManager_ds_get_materials_and_packages_by_project_idService);
    }
    return this._ds_get_materials_and_packages_by_project_id;
  }
  private _ds_get_materials_by_materialCodes_projectId: FootPrintApiManager_ds_get_materials_by_materialCodes_projectIdService;
  public get ds_get_materials_by_materialCodes_projectId(): FootPrintApiManager_ds_get_materials_by_materialCodes_projectIdService {
    if(!this._ds_get_materials_by_materialCodes_projectId) {
      this._ds_get_materials_by_materialCodes_projectId = this.injector.get(FootPrintApiManager_ds_get_materials_by_materialCodes_projectIdService);
    }
    return this._ds_get_materials_by_materialCodes_projectId;
  }
  private _ds_get_materials_with_inventory: FootPrintApiManager_ds_get_materials_with_inventoryService;
  public get ds_get_materials_with_inventory(): FootPrintApiManager_ds_get_materials_with_inventoryService {
    if(!this._ds_get_materials_with_inventory) {
      this._ds_get_materials_with_inventory = this.injector.get(FootPrintApiManager_ds_get_materials_with_inventoryService);
    }
    return this._ds_get_materials_with_inventory;
  }
  private _ds_get_measurement_units: FootPrintApiManager_ds_get_measurement_unitsService;
  public get ds_get_measurement_units(): FootPrintApiManager_ds_get_measurement_unitsService {
    if(!this._ds_get_measurement_units) {
      this._ds_get_measurement_units = this.injector.get(FootPrintApiManager_ds_get_measurement_unitsService);
    }
    return this._ds_get_measurement_units;
  }
  private _ds_get_order_addresses_by_order_id: FootPrintApiManager_ds_get_order_addresses_by_order_idService;
  public get ds_get_order_addresses_by_order_id(): FootPrintApiManager_ds_get_order_addresses_by_order_idService {
    if(!this._ds_get_order_addresses_by_order_id) {
      this._ds_get_order_addresses_by_order_id = this.injector.get(FootPrintApiManager_ds_get_order_addresses_by_order_idService);
    }
    return this._ds_get_order_addresses_by_order_id;
  }
  private _ds_get_order_by_licenseplate: FootPrintApiManager_ds_get_order_by_licenseplateService;
  public get ds_get_order_by_licenseplate(): FootPrintApiManager_ds_get_order_by_licenseplateService {
    if(!this._ds_get_order_by_licenseplate) {
      this._ds_get_order_by_licenseplate = this.injector.get(FootPrintApiManager_ds_get_order_by_licenseplateService);
    }
    return this._ds_get_order_by_licenseplate;
  }
  private _ds_get_order_information_by_shipment_id: FootPrintApiManager_ds_get_order_information_by_shipment_idService;
  public get ds_get_order_information_by_shipment_id(): FootPrintApiManager_ds_get_order_information_by_shipment_idService {
    if(!this._ds_get_order_information_by_shipment_id) {
      this._ds_get_order_information_by_shipment_id = this.injector.get(FootPrintApiManager_ds_get_order_information_by_shipment_idService);
    }
    return this._ds_get_order_information_by_shipment_id;
  }
  private _ds_get_order_line_information_by_order_id: FootPrintApiManager_ds_get_order_line_information_by_order_idService;
  public get ds_get_order_line_information_by_order_id(): FootPrintApiManager_ds_get_order_line_information_by_order_idService {
    if(!this._ds_get_order_line_information_by_order_id) {
      this._ds_get_order_line_information_by_order_id = this.injector.get(FootPrintApiManager_ds_get_order_line_information_by_order_idService);
    }
    return this._ds_get_order_line_information_by_order_id;
  }
  private _ds_get_order_status_by_transaction_ids: FootPrintApiManager_ds_get_order_status_by_transaction_idsService;
  public get ds_get_order_status_by_transaction_ids(): FootPrintApiManager_ds_get_order_status_by_transaction_idsService {
    if(!this._ds_get_order_status_by_transaction_ids) {
      this._ds_get_order_status_by_transaction_ids = this.injector.get(FootPrintApiManager_ds_get_order_status_by_transaction_idsService);
    }
    return this._ds_get_order_status_by_transaction_ids;
  }
  private _ds_get_orderlines_by_orderId: FootPrintApiManager_ds_get_orderlines_by_orderIdService;
  public get ds_get_orderlines_by_orderId(): FootPrintApiManager_ds_get_orderlines_by_orderIdService {
    if(!this._ds_get_orderlines_by_orderId) {
      this._ds_get_orderlines_by_orderId = this.injector.get(FootPrintApiManager_ds_get_orderlines_by_orderIdService);
    }
    return this._ds_get_orderlines_by_orderId;
  }
  private _ds_get_orders_by_ids: FootPrintApiManager_ds_get_orders_by_idsService;
  public get ds_get_orders_by_ids(): FootPrintApiManager_ds_get_orders_by_idsService {
    if(!this._ds_get_orders_by_ids) {
      this._ds_get_orders_by_ids = this.injector.get(FootPrintApiManager_ds_get_orders_by_idsService);
    }
    return this._ds_get_orders_by_ids;
  }
  private _ds_get_orders_without_shipments: FootPrintApiManager_ds_get_orders_without_shipmentsService;
  public get ds_get_orders_without_shipments(): FootPrintApiManager_ds_get_orders_without_shipmentsService {
    if(!this._ds_get_orders_without_shipments) {
      this._ds_get_orders_without_shipments = this.injector.get(FootPrintApiManager_ds_get_orders_without_shipmentsService);
    }
    return this._ds_get_orders_without_shipments;
  }
  private _ds_get_owner_by_ownerCode: FootPrintApiManager_ds_get_owner_by_ownerCodeService;
  public get ds_get_owner_by_ownerCode(): FootPrintApiManager_ds_get_owner_by_ownerCodeService {
    if(!this._ds_get_owner_by_ownerCode) {
      this._ds_get_owner_by_ownerCode = this.injector.get(FootPrintApiManager_ds_get_owner_by_ownerCodeService);
    }
    return this._ds_get_owner_by_ownerCode;
  }
  private _ds_get_pallet_transactions_by_shipment_id: FootPrintApiManager_ds_get_pallet_transactions_by_shipment_idService;
  public get ds_get_pallet_transactions_by_shipment_id(): FootPrintApiManager_ds_get_pallet_transactions_by_shipment_idService {
    if(!this._ds_get_pallet_transactions_by_shipment_id) {
      this._ds_get_pallet_transactions_by_shipment_id = this.injector.get(FootPrintApiManager_ds_get_pallet_transactions_by_shipment_idService);
    }
    return this._ds_get_pallet_transactions_by_shipment_id;
  }
  private _ds_get_physical_inventory_by_license_plate_lot_by_project_id: FootPrintApiManager_ds_get_physical_inventory_by_license_plate_lot_by_project_idService;
  public get ds_get_physical_inventory_by_license_plate_lot_by_project_id(): FootPrintApiManager_ds_get_physical_inventory_by_license_plate_lot_by_project_idService {
    if(!this._ds_get_physical_inventory_by_license_plate_lot_by_project_id) {
      this._ds_get_physical_inventory_by_license_plate_lot_by_project_id = this.injector.get(FootPrintApiManager_ds_get_physical_inventory_by_license_plate_lot_by_project_idService);
    }
    return this._ds_get_physical_inventory_by_license_plate_lot_by_project_id;
  }
  private _ds_get_project_and_owner_by_lookup_code: FootPrintApiManager_ds_get_project_and_owner_by_lookup_codeService;
  public get ds_get_project_and_owner_by_lookup_code(): FootPrintApiManager_ds_get_project_and_owner_by_lookup_codeService {
    if(!this._ds_get_project_and_owner_by_lookup_code) {
      this._ds_get_project_and_owner_by_lookup_code = this.injector.get(FootPrintApiManager_ds_get_project_and_owner_by_lookup_codeService);
    }
    return this._ds_get_project_and_owner_by_lookup_code;
  }
  private _ds_get_projects_by_projectCode: FootPrintApiManager_ds_get_projects_by_projectCodeService;
  public get ds_get_projects_by_projectCode(): FootPrintApiManager_ds_get_projects_by_projectCodeService {
    if(!this._ds_get_projects_by_projectCode) {
      this._ds_get_projects_by_projectCode = this.injector.get(FootPrintApiManager_ds_get_projects_by_projectCodeService);
    }
    return this._ds_get_projects_by_projectCode;
  }
  private _ds_get_projects_by_project_and_owner: FootPrintApiManager_ds_get_projects_by_project_and_ownerService;
  public get ds_get_projects_by_project_and_owner(): FootPrintApiManager_ds_get_projects_by_project_and_ownerService {
    if(!this._ds_get_projects_by_project_and_owner) {
      this._ds_get_projects_by_project_and_owner = this.injector.get(FootPrintApiManager_ds_get_projects_by_project_and_ownerService);
    }
    return this._ds_get_projects_by_project_and_owner;
  }
  private _ds_get_receiving_tasks_by_shipment_id: FootPrintApiManager_ds_get_receiving_tasks_by_shipment_idService;
  public get ds_get_receiving_tasks_by_shipment_id(): FootPrintApiManager_ds_get_receiving_tasks_by_shipment_idService {
    if(!this._ds_get_receiving_tasks_by_shipment_id) {
      this._ds_get_receiving_tasks_by_shipment_id = this.injector.get(FootPrintApiManager_ds_get_receiving_tasks_by_shipment_idService);
    }
    return this._ds_get_receiving_tasks_by_shipment_id;
  }
  private _ds_get_request_xml_by_requestId_editor: FootPrintApiManager_ds_get_request_xml_by_requestId_editorService;
  public get ds_get_request_xml_by_requestId_editor(): FootPrintApiManager_ds_get_request_xml_by_requestId_editorService {
    if(!this._ds_get_request_xml_by_requestId_editor) {
      this._ds_get_request_xml_by_requestId_editor = this.injector.get(FootPrintApiManager_ds_get_request_xml_by_requestId_editorService);
    }
    return this._ds_get_request_xml_by_requestId_editor;
  }
  private _ds_get_sample_data_for_adjustment: FootPrintApiManager_ds_get_sample_data_for_adjustmentService;
  public get ds_get_sample_data_for_adjustment(): FootPrintApiManager_ds_get_sample_data_for_adjustmentService {
    if(!this._ds_get_sample_data_for_adjustment) {
      this._ds_get_sample_data_for_adjustment = this.injector.get(FootPrintApiManager_ds_get_sample_data_for_adjustmentService);
    }
    return this._ds_get_sample_data_for_adjustment;
  }
  private _ds_get_serial_numbers_by_ids: FootPrintApiManager_ds_get_serial_numbers_by_idsService;
  public get ds_get_serial_numbers_by_ids(): FootPrintApiManager_ds_get_serial_numbers_by_idsService {
    if(!this._ds_get_serial_numbers_by_ids) {
      this._ds_get_serial_numbers_by_ids = this.injector.get(FootPrintApiManager_ds_get_serial_numbers_by_idsService);
    }
    return this._ds_get_serial_numbers_by_ids;
  }
  private _ds_get_serial_numbers_by_project_id: FootPrintApiManager_ds_get_serial_numbers_by_project_idService;
  public get ds_get_serial_numbers_by_project_id(): FootPrintApiManager_ds_get_serial_numbers_by_project_idService {
    if(!this._ds_get_serial_numbers_by_project_id) {
      this._ds_get_serial_numbers_by_project_id = this.injector.get(FootPrintApiManager_ds_get_serial_numbers_by_project_idService);
    }
    return this._ds_get_serial_numbers_by_project_id;
  }
  private _ds_get_serial_numbers_by_shipment_id: FootPrintApiManager_ds_get_serial_numbers_by_shipment_idService;
  public get ds_get_serial_numbers_by_shipment_id(): FootPrintApiManager_ds_get_serial_numbers_by_shipment_idService {
    if(!this._ds_get_serial_numbers_by_shipment_id) {
      this._ds_get_serial_numbers_by_shipment_id = this.injector.get(FootPrintApiManager_ds_get_serial_numbers_by_shipment_idService);
    }
    return this._ds_get_serial_numbers_by_shipment_id;
  }
  private _ds_get_shipmentLines_by_orderId_shipmentId: FootPrintApiManager_ds_get_shipmentLines_by_orderId_shipmentIdService;
  public get ds_get_shipmentLines_by_orderId_shipmentId(): FootPrintApiManager_ds_get_shipmentLines_by_orderId_shipmentIdService {
    if(!this._ds_get_shipmentLines_by_orderId_shipmentId) {
      this._ds_get_shipmentLines_by_orderId_shipmentId = this.injector.get(FootPrintApiManager_ds_get_shipmentLines_by_orderId_shipmentIdService);
    }
    return this._ds_get_shipmentLines_by_orderId_shipmentId;
  }
  private _ds_get_shipment_contents_by_shipment_id: FootPrintApiManager_ds_get_shipment_contents_by_shipment_idService;
  public get ds_get_shipment_contents_by_shipment_id(): FootPrintApiManager_ds_get_shipment_contents_by_shipment_idService {
    if(!this._ds_get_shipment_contents_by_shipment_id) {
      this._ds_get_shipment_contents_by_shipment_id = this.injector.get(FootPrintApiManager_ds_get_shipment_contents_by_shipment_idService);
    }
    return this._ds_get_shipment_contents_by_shipment_id;
  }
  private _ds_get_shipments_by_ids: FootPrintApiManager_ds_get_shipments_by_idsService;
  public get ds_get_shipments_by_ids(): FootPrintApiManager_ds_get_shipments_by_idsService {
    if(!this._ds_get_shipments_by_ids) {
      this._ds_get_shipments_by_ids = this.injector.get(FootPrintApiManager_ds_get_shipments_by_idsService);
    }
    return this._ds_get_shipments_by_ids;
  }
  private _ds_get_shipments_by_orderId: FootPrintApiManager_ds_get_shipments_by_orderIdService;
  public get ds_get_shipments_by_orderId(): FootPrintApiManager_ds_get_shipments_by_orderIdService {
    if(!this._ds_get_shipments_by_orderId) {
      this._ds_get_shipments_by_orderId = this.injector.get(FootPrintApiManager_ds_get_shipments_by_orderIdService);
    }
    return this._ds_get_shipments_by_orderId;
  }
  private _ds_get_shipments_by_shipmentId: FootPrintApiManager_ds_get_shipments_by_shipmentIdService;
  public get ds_get_shipments_by_shipmentId(): FootPrintApiManager_ds_get_shipments_by_shipmentIdService {
    if(!this._ds_get_shipments_by_shipmentId) {
      this._ds_get_shipments_by_shipmentId = this.injector.get(FootPrintApiManager_ds_get_shipments_by_shipmentIdService);
    }
    return this._ds_get_shipments_by_shipmentId;
  }
  private _ds_get_source_license_plate_tasks_by_shipmentId: FootPrintApiManager_ds_get_source_license_plate_tasks_by_shipmentIdService;
  public get ds_get_source_license_plate_tasks_by_shipmentId(): FootPrintApiManager_ds_get_source_license_plate_tasks_by_shipmentIdService {
    if(!this._ds_get_source_license_plate_tasks_by_shipmentId) {
      this._ds_get_source_license_plate_tasks_by_shipmentId = this.injector.get(FootPrintApiManager_ds_get_source_license_plate_tasks_by_shipmentIdService);
    }
    return this._ds_get_source_license_plate_tasks_by_shipmentId;
  }
  private _ds_get_status_change_tasks: FootPrintApiManager_ds_get_status_change_tasksService;
  public get ds_get_status_change_tasks(): FootPrintApiManager_ds_get_status_change_tasksService {
    if(!this._ds_get_status_change_tasks) {
      this._ds_get_status_change_tasks = this.injector.get(FootPrintApiManager_ds_get_status_change_tasksService);
    }
    return this._ds_get_status_change_tasks;
  }
  private _ds_get_tags: FootPrintApiManager_ds_get_tagsService;
  public get ds_get_tags(): FootPrintApiManager_ds_get_tagsService {
    if(!this._ds_get_tags) {
      this._ds_get_tags = this.injector.get(FootPrintApiManager_ds_get_tagsService);
    }
    return this._ds_get_tags;
  }
  private _ds_get_tasks_by_licenseplateId: FootPrintApiManager_ds_get_tasks_by_licenseplateIdService;
  public get ds_get_tasks_by_licenseplateId(): FootPrintApiManager_ds_get_tasks_by_licenseplateIdService {
    if(!this._ds_get_tasks_by_licenseplateId) {
      this._ds_get_tasks_by_licenseplateId = this.injector.get(FootPrintApiManager_ds_get_tasks_by_licenseplateIdService);
    }
    return this._ds_get_tasks_by_licenseplateId;
  }
  private _ds_get_tax_schedule_by_id: FootPrintApiManager_ds_get_tax_schedule_by_idService;
  public get ds_get_tax_schedule_by_id(): FootPrintApiManager_ds_get_tax_schedule_by_idService {
    if(!this._ds_get_tax_schedule_by_id) {
      this._ds_get_tax_schedule_by_id = this.injector.get(FootPrintApiManager_ds_get_tax_schedule_by_idService);
    }
    return this._ds_get_tax_schedule_by_id;
  }
  private _ds_get_warehouses_by_warehouseIds: FootPrintApiManager_ds_get_warehouses_by_warehouseIdsService;
  public get ds_get_warehouses_by_warehouseIds(): FootPrintApiManager_ds_get_warehouses_by_warehouseIdsService {
    if(!this._ds_get_warehouses_by_warehouseIds) {
      this._ds_get_warehouses_by_warehouseIds = this.injector.get(FootPrintApiManager_ds_get_warehouses_by_warehouseIdsService);
    }
    return this._ds_get_warehouses_by_warehouseIds;
  }
  private _ds_get_wavelength_order_transmissions: FootPrintApiManager_ds_get_wavelength_order_transmissionsService;
  public get ds_get_wavelength_order_transmissions(): FootPrintApiManager_ds_get_wavelength_order_transmissionsService {
    if(!this._ds_get_wavelength_order_transmissions) {
      this._ds_get_wavelength_order_transmissions = this.injector.get(FootPrintApiManager_ds_get_wavelength_order_transmissionsService);
    }
    return this._ds_get_wavelength_order_transmissions;
  }
  private _ds_get_wavelength_shipment_transmissions: FootPrintApiManager_ds_get_wavelength_shipment_transmissionsService;
  public get ds_get_wavelength_shipment_transmissions(): FootPrintApiManager_ds_get_wavelength_shipment_transmissionsService {
    if(!this._ds_get_wavelength_shipment_transmissions) {
      this._ds_get_wavelength_shipment_transmissions = this.injector.get(FootPrintApiManager_ds_get_wavelength_shipment_transmissionsService);
    }
    return this._ds_get_wavelength_shipment_transmissions;
  }
  private _ds_get_wavelength_transmission_type: FootPrintApiManager_ds_get_wavelength_transmission_typeService;
  public get ds_get_wavelength_transmission_type(): FootPrintApiManager_ds_get_wavelength_transmission_typeService {
    if(!this._ds_get_wavelength_transmission_type) {
      this._ds_get_wavelength_transmission_type = this.injector.get(FootPrintApiManager_ds_get_wavelength_transmission_typeService);
    }
    return this._ds_get_wavelength_transmission_type;
  }
  private _ds_get_work_orders_by_ids: FootPrintApiManager_ds_get_work_orders_by_idsService;
  public get ds_get_work_orders_by_ids(): FootPrintApiManager_ds_get_work_orders_by_idsService {
    if(!this._ds_get_work_orders_by_ids) {
      this._ds_get_work_orders_by_ids = this.injector.get(FootPrintApiManager_ds_get_work_orders_by_idsService);
    }
    return this._ds_get_work_orders_by_ids;
  }
  private _ds_material_statuses: FootPrintApiManager_ds_material_statusesService;
  public get ds_material_statuses(): FootPrintApiManager_ds_material_statusesService {
    if(!this._ds_material_statuses) {
      this._ds_material_statuses = this.injector.get(FootPrintApiManager_ds_material_statusesService);
    }
    return this._ds_material_statuses;
  }
  private _ds_message_staging: FootPrintApiManager_ds_message_stagingService;
  public get ds_message_staging(): FootPrintApiManager_ds_message_stagingService {
    if(!this._ds_message_staging) {
      this._ds_message_staging = this.injector.get(FootPrintApiManager_ds_message_stagingService);
    }
    return this._ds_message_staging;
  }
  private _ds_order_serial_tasks_by_shipmentId: FootPrintApiManager_ds_order_serial_tasks_by_shipmentIdService;
  public get ds_order_serial_tasks_by_shipmentId(): FootPrintApiManager_ds_order_serial_tasks_by_shipmentIdService {
    if(!this._ds_order_serial_tasks_by_shipmentId) {
      this._ds_order_serial_tasks_by_shipmentId = this.injector.get(FootPrintApiManager_ds_order_serial_tasks_by_shipmentIdService);
    }
    return this._ds_order_serial_tasks_by_shipmentId;
  }
  private _ds_storage_adjustments: FootPrintApiManager_ds_storage_adjustmentsService;
  public get ds_storage_adjustments(): FootPrintApiManager_ds_storage_adjustmentsService {
    if(!this._ds_storage_adjustments) {
      this._ds_storage_adjustments = this.injector.get(FootPrintApiManager_ds_storage_adjustmentsService);
    }
    return this._ds_storage_adjustments;
  }
  private _ds_storage_configurations: FootPrintApiManager_ds_storage_configurationsService;
  public get ds_storage_configurations(): FootPrintApiManager_ds_storage_configurationsService {
    if(!this._ds_storage_configurations) {
      this._ds_storage_configurations = this.injector.get(FootPrintApiManager_ds_storage_configurationsService);
    }
    return this._ds_storage_configurations;
  }
  private _ds_storage_control: FootPrintApiManager_ds_storage_controlService;
  public get ds_storage_control(): FootPrintApiManager_ds_storage_controlService {
    if(!this._ds_storage_control) {
      this._ds_storage_control = this.injector.get(FootPrintApiManager_ds_storage_controlService);
    }
    return this._ds_storage_control;
  }
  private _ds_storage_control_partners_widget: FootPrintApiManager_ds_storage_control_partners_widgetService;
  public get ds_storage_control_partners_widget(): FootPrintApiManager_ds_storage_control_partners_widgetService {
    if(!this._ds_storage_control_partners_widget) {
      this._ds_storage_control_partners_widget = this.injector.get(FootPrintApiManager_ds_storage_control_partners_widgetService);
    }
    return this._ds_storage_control_partners_widget;
  }
  private _ds_storage_integrations: FootPrintApiManager_ds_storage_integrationsService;
  public get ds_storage_integrations(): FootPrintApiManager_ds_storage_integrationsService {
    if(!this._ds_storage_integrations) {
      this._ds_storage_integrations = this.injector.get(FootPrintApiManager_ds_storage_integrationsService);
    }
    return this._ds_storage_integrations;
  }
  private _ds_storage_lifecycle: FootPrintApiManager_ds_storage_lifecycleService;
  public get ds_storage_lifecycle(): FootPrintApiManager_ds_storage_lifecycleService {
    if(!this._ds_storage_lifecycle) {
      this._ds_storage_lifecycle = this.injector.get(FootPrintApiManager_ds_storage_lifecycleService);
    }
    return this._ds_storage_lifecycle;
  }
  private _ds_storage_logs: FootPrintApiManager_ds_storage_logsService;
  public get ds_storage_logs(): FootPrintApiManager_ds_storage_logsService {
    if(!this._ds_storage_logs) {
      this._ds_storage_logs = this.injector.get(FootPrintApiManager_ds_storage_logsService);
    }
    return this._ds_storage_logs;
  }
  private _ds_storage_message_statuses_widget: FootPrintApiManager_ds_storage_message_statuses_widgetService;
  public get ds_storage_message_statuses_widget(): FootPrintApiManager_ds_storage_message_statuses_widgetService {
    if(!this._ds_storage_message_statuses_widget) {
      this._ds_storage_message_statuses_widget = this.injector.get(FootPrintApiManager_ds_storage_message_statuses_widgetService);
    }
    return this._ds_storage_message_statuses_widget;
  }
  private _ds_storage_messages: FootPrintApiManager_ds_storage_messagesService;
  public get ds_storage_messages(): FootPrintApiManager_ds_storage_messagesService {
    if(!this._ds_storage_messages) {
      this._ds_storage_messages = this.injector.get(FootPrintApiManager_ds_storage_messagesService);
    }
    return this._ds_storage_messages;
  }
  private _ds_storage_processes: FootPrintApiManager_ds_storage_processesService;
  public get ds_storage_processes(): FootPrintApiManager_ds_storage_processesService {
    if(!this._ds_storage_processes) {
      this._ds_storage_processes = this.injector.get(FootPrintApiManager_ds_storage_processesService);
    }
    return this._ds_storage_processes;
  }
  private _ds_storage_statuses: FootPrintApiManager_ds_storage_statusesService;
  public get ds_storage_statuses(): FootPrintApiManager_ds_storage_statusesService {
    if(!this._ds_storage_statuses) {
      this._ds_storage_statuses = this.injector.get(FootPrintApiManager_ds_storage_statusesService);
    }
    return this._ds_storage_statuses;
  }
  private _ds_storage_transaction_types: FootPrintApiManager_ds_storage_transaction_typesService;
  public get ds_storage_transaction_types(): FootPrintApiManager_ds_storage_transaction_typesService {
    if(!this._ds_storage_transaction_types) {
      this._ds_storage_transaction_types = this.injector.get(FootPrintApiManager_ds_storage_transaction_typesService);
    }
    return this._ds_storage_transaction_types;
  }
  private _ds_transaction_report: FootPrintApiManager_ds_transaction_reportService;
  public get ds_transaction_report(): FootPrintApiManager_ds_transaction_reportService {
    if(!this._ds_transaction_report) {
      this._ds_transaction_report = this.injector.get(FootPrintApiManager_ds_transaction_reportService);
    }
    return this._ds_transaction_report;
  }
}

