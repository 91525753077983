import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { FootPrintManager_lots_dd_singleComponent } from './FootPrintManager.lots_dd_single.component'
import { FootPrintManager_vendorlots_dd_singleComponent } from './FootPrintManager.vendorlots_dd_single.component'


interface IFootPrintManager_purchase_order_receiving_gridComponentEntity {
Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string }, ExpectedPackagedPack?: { ShortName?: string }, ActualPackagedPack?: { ShortName?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string, Vat?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string, ShortName?: string } }, OrderLine?: { GrossWeight?: number, NetWeight?: number, PackagedAmount?: number, WeightUomId?: number }}

interface IFootPrintManager_purchase_order_receiving_gridComponentInParams {
  orderId: number, shipmentId: number, orderStatusId: number}


class FootPrintManager_purchase_order_receiving_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_purchase_order_receiving_gridComponent;
  entity: IFootPrintManager_purchase_order_receiving_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    packaging_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    quantity_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    damage_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    location_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    licenseplate_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    lot_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    vendorlot_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    lineNumber: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    received: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    remaining: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    quantity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['quantity_edit'] as DatexFormControl, null, false, '', '')
      ),
    damage: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['damage_edit'] as DatexFormControl, null, false, '', '')
      ),
    location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['location_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    licenseplate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['licenseplate_edit'] as DatexFormControl, null, false, '')
      ),
    new_lot: new GridCellModel(
      new CellStyles(null, null),
      new ButtonModel('', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Add')
,
null
      ),
    lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['lot_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['vendorlot_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
  }

  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.MaterialId;
    
    return expr;
  }
  get $fields_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.WarehouseId;
    
    return expr;
  }
  get $fields_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.MaterialId;
    
    return expr;
  }
  get $fields_lot_selector_inParams_vendorLotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.vendorlot.editControl.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.MaterialId;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.lot.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
    this.formGroup
      .controls['packaging_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_packaging_changed();
      });
    this.formGroup
      .controls['quantity_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_quantity_changed();
      });
    this.formGroup
      .controls['location_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_change();
      });
    this.formGroup
      .controls['licenseplate_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_licenseplate_change();
      });
    this.formGroup
      .controls['lot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_change();
      });
    this.formGroup
      .controls['vendorlot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_change();
      });
  }

  async $initializeExisting(grid: FootPrintManager_purchase_order_receiving_gridComponent, entity: IFootPrintManager_purchase_order_receiving_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_purchase_order_receiving_gridComponent, entity?: IFootPrintManager_purchase_order_receiving_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.PurchaseOrders.ds_purchase_order_receiving_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lineNumber.displayControl as TextModel).text = $row.entity.OrderLineNumber?.toString();
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.ActualPackagedPack?.ShortName;
    (this.cells.packaging.editControl as SelectBoxModel).reset($row.entity.ActualPackagedPackId);
    (this.cells.damage.displayControl as TextModel).text = '';
    (this.cells.damage.editControl as NumberBoxModel).reset(null);
    (this.cells.location.displayControl as TextModel).text = $row.entity.ActualTargetLocation?.Name;
    (this.cells.location.editControl as SelectBoxModel).reset($row.entity.ActualTargetLocationId);
    (this.cells.licenseplate.displayControl as TextModel).text = '';
    (this.cells.licenseplate.editControl as TextBoxModel).reset('');
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.lot.editControl as SelectBoxModel).reset($row.entity.LotId);
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.VendorLot?.LookupCode;
    (this.cells.vendorlot.editControl as SelectBoxModel).reset($row.entity.VendorLotId);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_licenseplate_change(event = null) {
    return this.on_licenseplate_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_licenseplate_changeInternal(
    $row: FootPrintManager_purchase_order_receiving_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_receiving_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefinedTrimmed($row.cells.licenseplate.editControl.value)) {
          let exists = true;
  
          const licenseplate = (await $datasources.Locations.ds_get_licenseplates_by_lookupcode_and_warehouseId.get({
              warehouseId: $row.entity.WarehouseId,
              lookupcode: $row.cells.licenseplate.editControl.value
          })).result;
          exists = $utils.isDefined(licenseplate);
  
          if (exists) {
              $row.cells.licenseplate.styles.setAttentionClass();
          } else {
              $row.cells.licenseplate.styles.resetClasses();
          }
      } else {
          $row.cells.licenseplate.styles.resetClasses();
      }
  }
  on_location_change(event = null) {
    return this.on_location_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_changeInternal(
    $row: FootPrintManager_purchase_order_receiving_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_receiving_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefinedTrimmed($row.cells.location.editControl.value)) {
  
      const licenseplate = (await $datasources.Locations.ds_get_licenseplate_by_loose_locationId.get({
          locationId: $row.cells.location.editControl.value
      })).result;
      if ($utils.isDefined(licenseplate)) {
          $row.cells.licenseplate.editControl.reset(licenseplate[0].LookupCode);
          $row.cells.licenseplate.displayControl.text = licenseplate[0].LookupCode;
          $row.cells.licenseplate.editControl.readOnly = true;
          $row.cells.licenseplate.editControl.styles.setStyle('background', 'transparent');
      } else {
          $row.cells.licenseplate.editControl.reset('');
          $row.cells.licenseplate.editControl.readOnly = false;
          $row.cells.licenseplate.editControl.styles.resetStyle();
      }
  
  }
  
  
  }
  on_lot_change(event = null) {
    return this.on_lot_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changeInternal(
    $row: FootPrintManager_purchase_order_receiving_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_receiving_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.cells.lot.editControl.value)) {
      const lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({
          lotId: $row.cells.lot.editControl.value
      })).result;
      if ($utils.isDefined(lot)) {
          $row.cells.vendorlot.editControl.value = lot[0].VendorLotId;
      }
  } else {
      $row.cells.vendorlot.editControl.value = null;
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_purchase_order_receiving_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_receiving_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Set remaining amount
  const actualPackagedAmount = $row.entity.ActualPackagedAmount;
  $row.cells.remaining.displayControl.text =
      `${$row.entity.ExpectedPackagedAmount} ${$row.entity.ExpectedPackagedPack?.ShortName}`;
  if ($row.entity.ExpectedPackagedAmount !== actualPackagedAmount &&
      actualPackagedAmount > 0) {
      $row.cells.remaining.styles.setAttentionClass();
  } else {
      $row.cells.remaining.styles.resetClasses();
  }
  
  // Set received amount
  $row.cells.received.displayControl.text =
      `${$row.entity.ShipmentLine.ActualPackagedAmount} ${$row.entity.ShipmentLine.ActualPackaged?.ShortName}`;
  
  
  // Set quantity to expected packaged amount from task
  $row.cells.quantity.displayControl.text = $row.entity.ExpectedPackagedAmount.toString();
  $row.cells.quantity.editControl.value = $row.entity.ExpectedPackagedAmount;
  
  const orderIsInProcessingStatus = $grid.inParams.orderStatusId === 2;
  if (orderIsInProcessingStatus) {
      const licenseplate = (await $datasources.Locations.ds_get_licenseplate_by_loose_locationId.get({
          locationId: $row.cells.location.editControl.value
      })).result;
      if ($utils.isDefined(licenseplate)) {
          $row.cells.licenseplate.editControl.reset(licenseplate[0].LookupCode);
          $row.cells.licenseplate.displayControl.text = licenseplate[0].LookupCode;
          $row.cells.licenseplate.editControl.readOnly = true;
          $row.cells.licenseplate.editControl.styles.setStyle('background', 'transparent');
      } else {
          $row.cells.licenseplate.editControl.reset('');
          $row.cells.licenseplate.editControl.readOnly = false;
          $row.cells.licenseplate.editControl.styles.resetStyle();
      }
  }
  
  var materialId = $row.entity.MaterialId;
  // Check if material is serial controlled and or lot controlled
  const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
      materialId: materialId
  })).result;
  
  
  const materialControllerTypeId = material[0].ControllerTypeId;
  
  // Lot controlled
  if (materialControllerTypeId === 2 || materialControllerTypeId === 4) {
      $row.cells.lot.editControl.readOnly = false;
      $row.cells.lot.editControl.styles.resetStyle();
      $row.cells.vendorlot.editControl.readOnly = false;
      $row.cells.vendorlot.editControl.styles.resetStyle();
      $row.cells.new_lot.displayControl.styles.resetStyle();
      $row.cells.new_lot.displayControl.readOnly = false;
  } else {
      $row.cells.lot.editControl.readOnly = true;
      $row.cells.lot.editControl.styles.setStyle('background', 'transparent');
      $row.cells.vendorlot.editControl.readOnly = true;
      $row.cells.vendorlot.editControl.styles.setStyle('background', 'transparent');
      $row.cells.new_lot.displayControl.styles.setStyle('background', 'transparent');
      $row.cells.new_lot.displayControl.readOnly = true;
  }
  
  ($row as any).receivingReady = true;
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_purchase_order_receiving_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_receiving_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
  
  $row.cells.packaging.displayControl.text = $row.cells.packaging.editControl.displayText;
  $row.cells.licenseplate.displayControl.text = $row.cells.licenseplate.editControl.value;
  $row.cells.location.displayControl.text = $row.cells.location.editControl.displayText;
  $row.cells.lot.displayControl.text = $row.cells.lot.editControl.displayText;
  $row.cells.vendorlot.displayControl.text = $row.cells.vendorlot.editControl.displayText;
  
  
  const listOfSuccess = [];
  const listOfFailures = [];
  const dialogMessageList = [];
  const dialogMessageListDetails = [];
  
  
  try {
  
      // Handle damaged quantity input first
  
      if ($utils.isDefined($row.cells.damage.editControl.value)) {
  
          const result = await $flows.PurchaseOrders.create_damage_receiving_task_flow({
              orderId: $row.entity.OrderId,
              linenumber: $row.entity.OrderLineNumber,
              shipmentId: $grid.inParams.shipmentId,
              packagedAmount: $row.cells.damage.editControl.value,
              packagingId: $row.cells.packaging.editControl.value
  
          });
          const reason = result.reason;
  
          if ($utils.isDefined(reason)) {
              await $shell.FootPrintManager.openErrorDialog('Damaged quantity task could not be created.', reason);
              return;
          }
  
      }
  
  
      // Move to confirming the receiving task
  
      if ($row.cells.quantity.editControl.value > 0) {
  
          const materials = (await $datasources.Materials.ds_get_material_by_materialId.get({
              materialId: $row.entity.MaterialId
          })).result;
          if ($utils.isDefined(materials)) {
  
              var controllerTypeId = materials[0].ControllerTypeId;
              var isFixedWeight = materials[0].IsFixedWeight;
              var isFixedLength = materials[0].IsFixedLength;
              var isFixedVolume = materials[0].IsFixedVolume;
  
          } else {
              throw new Error('Unable to determine material properties.')
          }
  
          // Check if material is serial controlled
          if (controllerTypeId === 3 || controllerTypeId === 4 || isFixedWeight === false || isFixedLength === false || isFixedVolume === false) {
  
              // Only pass in average weight from order line if the material is not fixed weight and the data is available on the order line
              if ($utils.isAllDefined($row.entity.OrderLine.GrossWeight, $row.entity.OrderLine.WeightUomId) && isFixedWeight === false) {
                  var averageGross = $row.entity.OrderLine.GrossWeight / $row.entity.OrderLine.PackagedAmount;
                  var weightUomId = $row.entity.OrderLine.WeightUomId;
              }
  
              const dialogResult = await $shell.FootPrintManager.openserialnumbers_creation_gridDialog({
                  packagingId: $row.cells.packaging.editControl.value,
                  materialId: $row.entity.MaterialId,
                  packagedAmount: $row.cells.quantity.editControl.value,
                  averageGross: averageGross,
                  averageNet: averageGross,
                  weightUomId: weightUomId
              }, 'modal');
  
  
              var serialnumbersResult = dialogResult.serialNumbers;
              var confirmResult = dialogResult.confirm;
              if (confirmResult === false) {
                  throw new Error('Serial numbers were not created, please try again.')
              }
  
          }
          //Re-initialize variable
          var serialNumbers = [];
  
          if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === $row.cells.quantity.editControl.value) {
  
              serialNumbers = serialnumbersResult;
  
          }
          if (confirmResult === true && (!$utils.isDefined(serialnumbersResult) || serialnumbersResult.length !== $row.cells.quantity.editControl.value)) {
  
              throw new Error('Error creating serial numbers, please try again.')
  
          }
  
          // Check if target license plate exists, use 2 different APIs based on result
          const licenseplates = (await $datasources.Locations.ds_get_licenseplates_by_lookupcode_and_warehouseId.get({
              warehouseId: $row.entity.WarehouseId,
              lookupcode: $row.cells.licenseplate.editControl.value
          })).result;
          if (!$utils.isDefined(licenseplates)) {
  
              const result = (await $flows.PurchaseOrders.receive_on_new_licenseplate_flow({
                  licenseplateLookupcode: $row.cells.licenseplate.editControl.value,
                  locationId: $row.cells.location.editControl.value,
                  lotId: $row.cells.lot.editControl.value,
                  packagedAmount: $row.cells.quantity.editControl.value,
                  packagingId: $row.cells.packaging.editControl.value,
                  taskId: $row.entity.Id,
                  serialNumbers: serialNumbers
              }));
  
              if ($utils.isDefined(result.reasons)) {
  
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
  
              }
  
  
          } else {
              const licenseplateId = licenseplates[0].Id;
  
              const result = (await $flows.PurchaseOrders.receive_on_existing_licenseplate_flow({
                  licenseplateId: licenseplateId,
                  lotId: $row.cells.lot.editControl.value,
                  packagedAmount: $row.cells.quantity.editControl.value,
                  packagingId: $row.cells.packaging.editControl.value,
                  taskId: $row.entity.Id,
                  serialNumbers: serialNumbers
              }));
  
              if ($utils.isDefined(result.reasons)) {
  
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
  
              }
  
          }
  
  
          listOfSuccess.push($row.entity.OrderLineNumber);
          await $row.refresh();
  
      }
  
  } catch (error) {
      listOfFailures.push($row.entity.OrderLineNumber);
  
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = ''.concat('Line ', $row.entity.OrderLineNumber.toString(), ' - ', errorMessage);
      dialogMessageList.push(errorDescription)
      dialogMessageListDetails.push({ message: errorDescription, detail: errorDetail });
  }
  
  if (listOfFailures.length > 0) {
      $shell.FootPrintManager.openErrorDialog('Receiving', 'Selected task could not be received.', dialogMessageList, '', dialogMessageListDetails);
  }
  
  
  }
  on_quantity_changed(event = null) {
    return this.on_quantity_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_quantity_changedInternal(
    $row: FootPrintManager_purchase_order_receiving_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_receiving_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // set-row-remaining.flow.json
  const actualPackagedAmount = $row.cells.quantity.editControl.value;
  $row.cells.remaining.displayControl.text =
      `${$row.entity.ExpectedPackagedAmount} ${$row.entity.ExpectedPackagedPack?.ShortName}`;
  if ($row.entity.ExpectedPackagedAmount !== actualPackagedAmount &&
      actualPackagedAmount > 0) {
      $row.cells.remaining.styles.setAttentionClass();
  } else {
      $row.cells.remaining.styles.resetClasses();
  }
  
  
  var materialId = $row.entity.MaterialId;
  var packagingId = $row.cells.packaging.editControl.value;
  
  
  // Default the decimal formatting
  const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
      materialId: materialId,
      packagingId: packagingId
  }));
  
  if ($utils.isDefined(decimalFormat)) {
      $row.cells.quantity.editControl.format = decimalFormat.format;
  
  }
  
  
  }
  on_vendorlot_change(event = null) {
    return this.on_vendorlot_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_changeInternal(
    $row: FootPrintManager_purchase_order_receiving_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_receiving_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.cells.lot.editControl.value = null;
  
  
  }
  on_new_lot_clicked(event = null) {
    return this.on_new_lot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_lot_clickedInternal(
    $row: FootPrintManager_purchase_order_receiving_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_receiving_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const materialId = $row.entity.MaterialId;
  
  
  const dialogResult = await $shell.FootPrintManager.openlot_creation_formDialog({ materialId: materialId }, 'modal');
  const userConfirmed = dialogResult.confirm;
  const lotId = dialogResult.lotId;
  
  
  
  if (userConfirmed === true && $utils.isDefined(lotId)) {
      const lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: lotId })).result;
      const vendorLotId = lot[0].VendorLotId;
  
      $row.cells.lot.editControl.value = lotId;
      $row.cells.lot.displayControl.text = lot[0].LookupCode;
      $row.cells.vendorlot.editControl.value = vendorLotId;
      $row.cells.vendorlot.displayControl.text = lot[0].VendorLot.LookupCode;
      const payload: any = {};
  
      payload.LotId = lotId;
      payload.VendorLotId = vendorLotId;
  
      try {
  
          await $flows.Utilities.crud_update_flow({
              entitySet: 'Tasks',
              id: $row.entity.Id,
              entity: payload
          });
  
  
      } catch (error) {
          $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  
  } else {
      throw new Error('Error creating new lot')
  }
  
  }
  on_packaging_changed(event = null) {
    return this.on_packaging_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_changedInternal(
    $row: FootPrintManager_purchase_order_receiving_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_receiving_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var materialId = $row.entity.MaterialId;
  var packagingId = $row.cells.packaging.editControl.value;
  
  
  
  
  
  // Default the decimal formatting
  const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
      materialId: materialId,
      packagingId: packagingId
  }));
  
  if ($utils.isDefined(decimalFormat)) {
      $row.cells.quantity.editControl.format = decimalFormat.format;
  
  }
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => FootPrintManager_lots_dd_singleComponent),
    forwardRef(() => FootPrintManager_vendorlots_dd_singleComponent),
  ],
  selector: 'FootPrintManager-purchase_order_receiving_grid',
  templateUrl: './FootPrintManager.purchase_order_receiving_grid.component.html'
})
export class FootPrintManager_purchase_order_receiving_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_purchase_order_receiving_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_purchase_order_receiving_gridComponentInParams = { orderId: null, shipmentId: null, orderStatusId: null };


  //#region Variables
  vars: { entities?: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string }, ExpectedPackagedPack?: { Name?: string }, ActualPackagedPack?: { Name?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string } } }[], totalCount?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lineNumber: new GridHeaderModel(new HeaderStyles(null, null), 'Line #', false, false, null),       material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', true, false, null),       received: new GridHeaderModel(new HeaderStyles(null, null), 'Received', false, false, null),       remaining: new GridHeaderModel(new HeaderStyles(null, null), 'Remaining', false, false, null),       quantity: new GridHeaderModel(new HeaderStyles(null, null), 'Receive quantity', true, false, null),       damage: new GridHeaderModel(new HeaderStyles(null, null), 'Damaged qty', false, false, null),       location: new GridHeaderModel(new HeaderStyles(null, null), 'Receive location', true, false, null),       licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'Receive LP', true, false, null),       new_lot: new GridHeaderModel(new HeaderStyles(null, null), ' ', false, false, null),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', true, false, null),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor lot', true, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_purchase_order_receiving_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('shipmentId') set $inParams_shipmentId(value: any) {
    this.inParams['shipmentId'] = value;
  }
  get $inParams_shipmentId(): any {
    return this.inParams['shipmentId'] ;
  }
  @Input('orderStatusId') set $inParams_orderStatusId(value: any) {
    this.inParams['orderStatusId'] = value;
  }
  get $inParams_orderStatusId(): any {
    return this.inParams['orderStatusId'] ;
  }

  topToolbar = {
      bulk_lot_change: new ToolModel(new ButtonModel('bulk_lot_change', new ButtonStyles(null, null), false, 'Bulk lot change', 'icon-datex-Lot')
    ),
      bulk_receiving: new ToolModel(new ButtonModel('bulk_receiving', new ButtonStyles(null, null), false, 'Bulk receiving', 'icon-datex-Receive')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    super();
    this.title = 'Receiving tasks';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.shipmentId)) {
        this.$missingRequiredInParams.push('shipmentId');
      }
      if(isNil(this.inParams.orderStatusId)) {
        this.$missingRequiredInParams.push('orderStatusId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.PurchaseOrders.ds_purchase_order_receiving_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_purchase_order_receiving_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_purchase_order_receiving_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const orderIsInProcessingStatus = $grid.inParams.orderStatusId === 2;
  if (orderIsInProcessingStatus) {
  
      $grid.canEdit = true;
  
      $grid.headers.quantity.required = true;
      $grid.headers.packaging.required = true;
      $grid.headers.lot.required = true;
      $grid.headers.vendorlot.required = true;
      $grid.headers.licenseplate.required = true;
      $grid.headers.location.required = true;
  
  } else {
      
      $grid.canEdit = false;
  
      $grid.headers.quantity.required = false;
      $grid.headers.packaging.required = false;
      $grid.headers.lot.required = false;
      $grid.headers.vendorlot.required = false;
      $grid.headers.licenseplate.required = false;
      $grid.headers.location.required = false;
  
  }
  
  
  
  
  
  }
  on_bulk_lot_change(event = null) {
    return this.on_bulk_lot_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_bulk_lot_changeInternal(
    $grid: FootPrintManager_purchase_order_receiving_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const orderId = $grid.inParams.orderId
  
  
  
  const dialogResult = await $shell.FootPrintManager.openlot_bulk_update_formDialog();
  const userConfirmed = dialogResult.confirm;
  const lotLookupCode = dialogResult.lotLookupCode;
  const vendorLookupCode = $utils.isDefined(dialogResult.vendorLookupCode) ? dialogResult.vendorLookupCode : dialogResult.lotLookupCode;
  const expirationDate = dialogResult.expirationDate;
  const manufacturedDate = dialogResult.manufacturedDate;
  
  
  if (userConfirmed) {
  
      const receivingTasks = (await $datasources.PurchaseOrders.ds_get_receiving_tasks_by_orderId.get({ orderId: orderId })).result;
  
      if (receivingTasks.length > 0) {
          const tasks = receivingTasks;
          for (let task of tasks) {
              let taskId = task.Id;
              let materialId = task.MaterialId;
  
              const materials = (await $datasources.Materials.ds_get_material_by_materialId.get({
                  materialId: materialId
              })).result;
              if ($utils.isDefined(materials)) {
  
                  var controllerTypeId = materials[0].ControllerTypeId;
  
                  // Only lot controlled materials
                  if (controllerTypeId === 2 || controllerTypeId === 4) {
  
  
                      const lot = (await $datasources.Lots.ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode.get({
                          materialId: materialId,
                          vendorLookupcode: vendorLookupCode,
                          lotLookupcode: lotLookupCode
                      })).result;
  
                      if ($utils.isDefined(lot)) {
                          const lotId = lot[0].Id;
                          const vendorLotId = lot[0].VendorLotId
  
                          const taskPayload = {
                              "LotId": lotId,
                              "VendorLotId": vendorLotId
                          };
                          await $flows.Utilities.crud_update_flow({ entitySet: 'Tasks', id: taskId, entity: taskPayload });
  
  
                      } else {
  
                          // Check if vendor lot exists
                          var vendorLotId;
  
                          const vendorLot = (await $datasources.Lots.ds_get_vendorlot_by_lookupcode_and_materialId.get({
                              materialId: materialId,
                              vendorLookupcode: vendorLookupCode
                          })).result;
                          if ($utils.isDefined(vendorLot)) {
                              var foundVendorLotId = vendorLot[0].Id
                              vendorLotId = foundVendorLotId
                          }
  
                          else {
  
                              const vendorlot = (await $flows.Lots.create_vendor_lot({
  
                                  materialId: materialId,
                                  lookupCode: vendorLookupCode,
                                  manufactureDate: $utils.date.now(),
                                  expirationDate: $utils.date.now()
                              }));
  
                              vendorLotId = vendorlot.vendorLotId;
  
                          }
  
                          const lot = (await $flows.Lots.create_lot({
  
                              lookupCode: lotLookupCode,
                              vendorLotId: vendorLotId,
                              statusId: 1
                          }));
  
                          var nextLotId = lot.lotId;
  
                          const taskPayload = {
                              "LotId": nextLotId,
                              "VendorLotId": vendorLotId
                          };
                          await $flows.Utilities.crud_update_flow({ entitySet: 'Tasks', id: taskId, entity: taskPayload });
  
                      }
  
  
                      await $grid.refresh();
  
                  }
  
              }
          }
  
      }
  
  }
  }
  on_bulk_receiving_clicked(event = null) {
    return this.on_bulk_receiving_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_bulk_receiving_clickedInternal(
    $grid: FootPrintManager_purchase_order_receiving_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  await $shell.FootPrintManager.openpurchase_order_bulk_receiving_by_taskId_wizardDialog({
      taskId:  $grid.selectedRows[0].entity.Id,
      materialId: $grid.selectedRows[0].entity.MaterialId,
      packagingId: $grid.selectedRows[0].entity.ActualPackagedPackId
  })
  
  await $grid.refresh();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_purchase_order_receiving_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const hasOneSelectedRows = $grid.selectedRows.length === 1;
  
  
  $grid.topToolbar.bulk_receiving.control.readOnly = !hasOneSelectedRows;
  
  
  if ($grid.selectedRows.length > 0) {
      $grid.topToolbar.bulk_lot_change.control.readOnly = true;
  } else {
      $grid.topToolbar.bulk_lot_change.control.readOnly = false;
  }
  
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootPrintManager_purchase_order_receiving_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Initialize
  var wb = $event;
  var transactions = [];
  
  try {
  
      var response = (await $datasources.PurchaseOrders.ds_purchase_order_receiving_grid.get({
          orderId: $grid.inParams.orderId,
          fullTextSearch: $grid.fullTextSearch
      })).result;
     
      // Compile all data into JSON
      for (let record of response) {
  
          transactions.push({
  
              "Line #": record?.OrderLineNumber?.toString(),
              "Material": record.Material?.LookupCode,
              "Packaging": record.ActualPackagedPack?.ShortName,
              "Received": record.ShipmentLine?.ActualPackagedAmount + ' ' + record.ShipmentLine?.ActualPackaged?.ShortName,
              "Remaining": record.ExpectedPackagedAmount + ' ' + record.ExpectedPackagedPack?.ShortName
          });
      }
  
      var ws = $utils.excel.json_to_sheet(transactions);
      $utils.excel.book_append_sheet(wb, ws, "ReceivingTasksExport");
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootPrintManager_purchase_order_receiving_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
