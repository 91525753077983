import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormGroup, FormControl, Validators} from '@angular/forms';
import { DatexFormControl } from './models/datex-form-control';
import { ListItemModel, ELoadingStatus } from './components/list.component';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'


import { FootPrintManager_licenseplate_cardComponent } from './FootPrintManager.licenseplate_card.component';

interface IFootPrintManager_licenseplate_listComponentEntity {
TotalPackagedAmount?: number, TotalGrossWeight?: number, TotalNetWeight?: number, LicensePlateId?: number, licenseplate?: { Id?: number, LookupCode?: string, Location?: { Name?: string } }}

class FootPrintManager_licenseplate_listComponentItemModel extends ListItemModel {
  list: FootPrintManager_licenseplate_listComponent;
  entity: IFootPrintManager_licenseplate_listComponentEntity;


  cacheValueFor_$content_FootPrintManager_licenseplate_card_inParams_licenseplate: { totalPackagedAmount?: number, lookupCode?: string, totalGrossWeight?: number, locationName?: string, licenseplateId?: number };

  get $content_FootPrintManager_licenseplate_card_inParams_licenseplate(): { totalPackagedAmount?: number, lookupCode?: string, totalGrossWeight?: number, locationName?: string, licenseplateId?: number } {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = { licenseplateId: $item.entity?.LicensePlateId, totalPackagedAmount:$item.entity?.TotalPackagedAmount, lookupCode: $item.entity?.licenseplate?.LookupCode.toString(), totalGrossWeight: $item.entity?.TotalGrossWeight, locationName: $item.entity?.licenseplate?.Location?.Name, };
    
    if(!isEqual(this.cacheValueFor_$content_FootPrintManager_licenseplate_card_inParams_licenseplate, expr)) {
      this.cacheValueFor_$content_FootPrintManager_licenseplate_card_inParams_licenseplate = expr;
    }
    return this.cacheValueFor_$content_FootPrintManager_licenseplate_card_inParams_licenseplate;
  }

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
   ) {
    super();
  }

  private $unsubscribe$ = new Subject();
  destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $initializeExisting(list: FootPrintManager_licenseplate_listComponent, entity: IFootPrintManager_licenseplate_listComponentEntity) {
    this.list = list;
    this.entity = entity;

    this.styles = new Styles(
      ['small-card'], 
      { 
        width: '17%', 
        height: '195px' 
      }
    );
  }
}

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_licenseplate_cardComponent)
  ],
  selector: 'FootPrintManager-licenseplate_list',
  templateUrl: './FootPrintManager.licenseplate_list.component.html'
})
export class FootPrintManager_licenseplate_listComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { locationId?: number, materialId?: number, projectId?: number, lotId?: number } = { locationId: null, materialId: null, projectId: null, lotId: null };
  //#region Inputs
  @Input('locationId') set $inParams_locationId(v: number) {
    this.inParams.locationId = v;
  }
  get $inParams_locationId(): number {
    return this.inParams.locationId;
  }
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('lotId') set $inParams_lotId(v: number) {
    this.inParams.lotId = v;
  }
  get $inParams_lotId(): number {
    return this.inParams.lotId;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title



  entities: IFootPrintManager_licenseplate_listComponentEntity[] = [];
  pageSize = 40;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus: ELoadingStatus = ELoadingStatus.Loading;

  items: FootPrintManager_licenseplate_listComponentItemModel[] = [];
  

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) { 
    super();
    this.title = 'License plates';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {

    const $list = this;
    const $utils = this.utils;



    this.initialized = true;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.refresh();
  }
 
  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    this.$reset();
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    
  }

  $reset() {
    this.pageSkip = 0;
    this.entities = [];
    this.items.forEach(i => i.destroy());
    this.items = [];
  }


  $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $list = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      lotId:  $list.inParams.lotId ,
      materialId:  $list.inParams.materialId ,
      locationId:  $list.inParams.locationId ,
      projectId:  $list.inParams.projectId ,
    };
    this.datasources.Inventory.ds_licenseplate_navigation_list.getList(inParams)
    .then(data => {
      this.entities.push(...data.result);
      this.totalCount = data.totalCount;
      this.$dataLoaded(data.result);
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    })
    .catch(error => {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    });
  }

  async $dataLoaded(data: IFootPrintManager_licenseplate_listComponentEntity[]) {
    for (let entity of data) {
      const item = new FootPrintManager_licenseplate_listComponentItemModel(
        this.utils,
        this.settings,
        this.shell, 
        this.datasources,
        this.flows,
        this.reports,
        this.localization,
        this.operations);
      item.$initializeExisting(this, entity);
      this.items.push(item);
    } 
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  

  close() {
    this.$finish.emit();
  }
}
